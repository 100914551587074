import cn from 'classnames';
import React from 'react';
import classes from './PageHeader.module.scss';

interface Props {
  className?: string;
  children?: React.ReactNode | string;
  title: string | React.ReactNode;
  mobileNotWrap?: boolean;
  childrenWrapperClassName?: string;
}

const PageHeader = ({
  className,
  children,
  title,
  mobileNotWrap,
  childrenWrapperClassName,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        'i-page-header',
        {
          [classes.mobileNotWrap]: mobileNotWrap,
        },
        className
      )}
    >
      <div className={classes.inner}>
        <h1>{title}</h1>
        {!!children && (
          <div
            className={cn(classes.childrenWrapper, childrenWrapperClassName)}
          >
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
