import * as noticeActions from 'src/store/actions/notice';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const noticesStore = useReducerData('notice', 'notices', {});
  return {
    notices: noticesStore.data || [],
    noticesLoading: noticesStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...noticeActions,
  });
};
