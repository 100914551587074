import { Dispatch } from 'redux';
import { apiGetResidents } from 'src/api';
import Types from '../types/resident';

export const getResidents =
  (data = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = data;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_RESIDENTS,
      asyncCall: () => {
        return apiGetResidents(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
