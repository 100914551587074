import range from 'lodash/range';
import React from 'react';
import { Badge, Table } from 'src/components';

export const columns = [
  {
    key: 'badge',
    render: (val) => (
      <Badge circle color="dark-red">
        {val}
      </Badge>
    ),
    width: '40px',
    align: 'center',
  },
  {
    key: 'name',
    render: (val) => val,
    width: '320px',
  },
  {
    key: 'name1',
    render: (val) => val,
    width: '160px',
  },
  {
    key: 'day',
    render: (val) => (
      <Badge color={val > 1 ? 'yellow' : 'red'}>
        {val === 0 ? 'Today' : `${val} Day${val > 1 ? 's' : ''}`}
      </Badge>
    ),
    width: '100px',
  },
  {
    key: 'action',
    render: () => {},
  },
];
export const data = range(10).map((i) => ({
  badge: 2,
  name: '3rd Party Income Verification 1 of 2 ',
  name1: 'Johnson, Income Verification',
  day: i === 8 ? 1 : i === 9 ? 2 : 0,
}));
export const tabs = [
  {
    key: 'Upcoming',
    title: 'Upcoming',
    content: (
      <Table
        columns={columns}
        data={data}
        hideColumn
        onView={() => {}}
        hoverColor
      />
    ),
  },
  {
    key: 'Appointments',
    title: 'Appointments',
    content: (
      <Table columns={columns} data={data} hideColumn onView={() => {}} />
    ),
  },
  {
    key: 'Overdue',
    title: 'Overdue',
    content: (
      <Table columns={columns} data={data} hideColumn onView={() => {}} />
    ),
  },
  {
    key: 'Inbox',
    title: 'Inbox',
    content: (
      <Table columns={columns} data={data} hideColumn onView={() => {}} />
    ),
  },
];
