import cn from 'classnames';
import { ReactComponent as PencilSvg } from 'src/assets/icons/Pencil.svg';
import { Input } from 'src/components/FormFields';
import { DUE_BY_VALUES } from 'src/helpers/constants/options';
import { getDPDate } from 'src/utils/date';
import classes from './CertDates.module.scss';

interface Props {
  dateValues: {
    responseDate: string;
    effectiveDate: string;
  };
  onDateChange: (value: string, key: string) => void;
  onDateSave: (value: string, key: string) => void;
  data: any;
  certKey: string;
  isWorkflow: boolean;
}

const CertDates = ({
  data,
  dateValues,
  onDateChange,
  onDateSave,
  certKey,
  isWorkflow,
}: Props) => {
  return (
    <div
      className={cn(classes.wrapper, {
        [classes.isWorkflow]: isWorkflow,
      })}
    >
      <ul>
        {isWorkflow ? (
          <>
            {data?.workflow?.due_by === DUE_BY_VALUES.SPECIFIC_DATE && (
              <li>
                <span>Frequency</span>
                <span>{data?.workflow?.frequency}</span>
              </li>
            )}
          </>
        ) : (
          <>
            <li
              className={cn(
                classes.effectiveDateWrapper,
                classes.responseDateWrapper
              )}
            >
              <span>
                Response Date
                <PencilSvg />
              </span>
              <Input
                inputType="mask"
                mask="99/99/9999"
                placeholder="00/00/0000"
                autoComplete="date"
                value={dateValues.responseDate}
                onChange={(e) => {
                  onDateChange(e.target.value, 'responseDate');
                }}
                onBlur={(e) => {
                  onDateSave(e.target.value, 'responseDate');
                }}
                className={classes.effectiveDateInput}
              />
            </li>
            <li className={classes.effectiveDateWrapper}>
              <span>
                Effective Date
                <PencilSvg />
              </span>
              <Input
                inputType="mask"
                mask="99/99/9999"
                placeholder="00/00/0000"
                autoComplete="date"
                value={dateValues.effectiveDate}
                onChange={(e) => {
                  onDateChange(e.target.value, 'effectiveDate');
                }}
                onBlur={(e) => {
                  onDateSave(e.target.value, 'effectiveDate');
                }}
                className={classes.effectiveDateInput}
              />
            </li>
          </>
        )}
        <li>
          <span>Due Date</span>
          <span>{getDPDate(data?.[certKey]?.due_date)}</span>
        </li>
      </ul>
    </div>
  );
};

export default CertDates;
