import React from 'react';
import { FormCardContent } from 'src/components';
import { FormikInput, FormikSelect } from 'src/components/FormFields';
import { REPORT_ROLE_OPTIONS } from 'src/helpers/constants/options';
import { getIsEmptyEntities } from 'src/utils/object';
import SectionFormArray from '../SectionFormArray';

interface Props {
  values: any;
  isDisabled?: boolean;
  onValuesChange?: () => void;
}

const InvolvedParties = ({ values, isDisabled, onValuesChange }: Props) => {
  return (
    <SectionFormArray
      onAddCb={onValuesChange}
      onRemoveCb={onValuesChange}
      addHidden={isDisabled}
      onNoValue={() => {
        return getIsEmptyEntities(values.involved_party);
      }}
      values={values}
      title="Involved Parties"
      parentName="involved_party"
      renderChild={(index) => {
        return (
          <>
            <FormikInput
              name={`involved_party.${index}.name`}
              label="Name"
              required={true}
              placeholder="Name"
              type="text"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormikInput
              name={`involved_party.${index}.address`}
              label="Address"
              placeholder="Address"
              type="text"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormCardContent column={2} inside>
              <FormikInput
                name={`involved_party.${index}.phone_number`}
                label="Phone Number"
                placeholder="000-000-0000"
                inputType="mask"
                mask="999-999-9999"
                autoComplete="time"
                errorOverlay
                disabledOverlay={isDisabled}
                onChangeCb={onValuesChange}
              />
              <FormikSelect
                data={REPORT_ROLE_OPTIONS}
                name={`involved_party.${index}.role`}
                label="Role"
                placeholder="Select"
                maxDropdownHeight={300}
                disabledOverlay={isDisabled}
                onChangeCb={onValuesChange}
              />
            </FormCardContent>
          </>
        );
      }}
      newObj={{
        name: '',
        address: '',
        phone_number: '',
        role: '',
      }}
      newBtnText="+ Add an Involved Party"
      gridColumn={3}
    />
  );
};

export default InvolvedParties;
