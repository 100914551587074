import * as residentActions from 'src/store/actions/resident';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const residentsStore = useReducerData('resident', 'residents', {});
  const residentsMeta = residentsStore.data || {};
  return {
    residentsMeta,
    residents: residentsMeta.data || [],
    residentsLoading: residentsStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...residentActions,
  });
};
