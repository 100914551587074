import cn from 'classnames';
import { Form, Formik } from 'formik';
import pick from 'lodash/pick';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as MicrosoftIcon } from 'src/assets/icons/microsoft.svg';
import {
  Button,
  ButtonSocialLogin,
  Color,
  LoadingOverlay,
} from 'src/components';
import {
  FakeInput,
  FormikInput,
  FormikSelect,
} from 'src/components/FormFields';
import { LANGUAGE_OPTIONS } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { getCurrentTimezone } from 'src/utils/date';
import { notification } from 'src/utils/notification';
import { getUrlParamValueByKey } from 'src/utils/routes';
import classes from './Invite.module.scss';
import { useActions, useIndexData } from './selectorData';
import { getInitialValues } from './utils';

const Invite = () => {
  const { timezoneOptions } = useOptions();
  const { verifyTokenLoading, verifyTokenData, acceptInviteLoading } =
    useIndexData();
  const { verifyInviteToken, acceptInvite } = useActions();
  const schema = Yup.object().shape({
    email: Yup.string()
      .required('This field is required!')
      .email('Invalid email!'),
    password: Yup.string().required('This field is required!'),
    confirmPassword: Yup.string()
      .required('This field is required!')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });
  const navigate = useNavigate();
  const location = useLocation();
  const token = String(getUrlParamValueByKey(location.search, 'token'));

  const renderForm = () => {
    return (
      <Form className={classes.form} onChange={() => {}}>
        <div className={classes.fields}>
          <FormikInput
            name="preferred_name"
            label={'Preferred name'}
            placeholder={'Enter your preferred name'}
          />
          <div className={classes.fields}>
            <FormikSelect
              data={LANGUAGE_OPTIONS}
              name="language"
              label="Language"
              placeholder="Language"
            />
            <FormikSelect
              data={timezoneOptions}
              name="timezone"
              label="Time Zone"
              placeholder="Time Zone"
              maxDropdownHeight={300}
            />
          </div>
          <FormikInput
            name="email"
            type="email"
            label={'Email'}
            placeholder={'Enter your email'}
            disabled
          />
          <FormikInput
            name="office_phone"
            label="Office phone number"
            placeholder="Enter your office phone number"
            inputType="mask"
            mask="999-999-9999"
            autoComplete="office-phone"
          />
          <FakeInput />
          <FormikInput
            name="password"
            type="password"
            label={'Password'}
            placeholder={'Enter a password'}
            required
            autoComplete="new-password"
          />
          <FormikInput
            name="confirmPassword"
            type="password"
            label={'Confirm Password'}
            placeholder={'Re-enter a password'}
            required
            autoComplete="confirm-password"
          />
        </div>
        <div className={classes.buttons}>
          <Button type="submit" loading={acceptInviteLoading}>
            Sign Up
          </Button>
          <Color>or</Color>
          <ButtonSocialLogin social="microsoft" leftIcon={<MicrosoftIcon />}>
            Sign Up with Microsoft 365
          </ButtonSocialLogin>
        </div>
      </Form>
    );
  };

  useEffect(() => {
    verifyInviteToken(
      token,
      {
        timezone: getCurrentTimezone(),
      },
      actionCb(null, () => {
        navigate('/login');
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (verifyTokenLoading) {
    return <LoadingOverlay visible fixed />;
  }

  return (
    <div className={cn(classes.wrapper, {})}>
      <div className={classes.heading}>
        <h1>Sign Up</h1>
        <p>Please enter your information to create an account</p>
      </div>
      <Formik
        initialValues={getInitialValues(verifyTokenData)}
        validateOnMount
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          acceptInvite(
            {
              ...pick(values, [
                'email',
                'password',
                'preferred_name',
                'office_phone',
                'language',
                'timezone',
              ]),
              token,
            },
            actionCb(
              () => {
                notification({
                  title: 'Sign up successfull!',
                  message: 'You can login to use OneTree',
                });
                navigate('/login');
              },
              null,
              'Create account failed!'
            )
          );
        }}
      >
        {renderForm}
      </Formik>
    </div>
  );
};

export default Invite;
