import find from 'lodash/find';
import isArray from 'lodash/isArray';
import { displayName } from 'src/utils/text';

export const getBreadCrumb = ({
  isCertDetail,
  certFields,
  certId,
  isCertCertificationDetailPage,
  certKey,
  isWorkflow,
}) => {
  const residentInfo = certFields?.[certKey]?.unit?.resident || {};

  return isCertDetail
    ? [
        {
          label: isWorkflow ? 'Workflows' : 'Certifications',
          link: isWorkflow ? '/workflows' : '/certifications',
        },
        {
          label: isCertCertificationDetailPage
            ? certFields?.[certKey]?.name
            : 'Certifications List',
          link: isCertCertificationDetailPage
            ? `/${
                isWorkflow
                  ? 'workflow-list-builder'
                  : 'certifications-list-builder'
              }/${certId}`
            : '/certifications-list',
        },
        {
          label:
            displayName(residentInfo.first_name, residentInfo.last_name) ||
            '[Resident name]',
        },
      ]
    : [
        {
          label: 'Winston Bishop',
        },
      ];
};
export const getFieldByKey = (
  fields,
  value,
  key,
  findKey = 'label',
  wholeObj?: boolean
) => {
  if (!isArray(fields)) return '';
  const foundObj = find(fields, { [findKey]: value });
  return wholeObj ? foundObj : foundObj?.[key];
};
