import * as reportActions from 'src/store/actions/report';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    addUpdateReportLoading: useReducerData(
      'report',
      'addUpdateReport.loading',
      false
    ),
    uploadReportFilesLoading: useReducerData(
      'report',
      'uploadReportFiles.loading',
      false
    ),
    reportDetail: useReducerData('report', 'reportDetail.data', {}),
  };
};
export const useLoadingData = () => {
  return {
    reportDetailLoading: useReducerData(
      'report',
      'reportDetail.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...reportActions,
  });
};
