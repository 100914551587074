export const getInitialValues = () => {
  return {
    property_id: '',
    title: '',
    notice_file: null,
  };
};
export const getNoticeBR = (values) => {
  const fData = new FormData();
  fData.append('property_id', values.property_id);
  fData.append('file', values.notice_file);
  fData.append('title', values.title);
  return fData;
};
