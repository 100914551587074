import * as reportActions from 'src/store/actions/report';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const reportsStore = useReducerData('report', 'reports', {});
  return {
    reports: reportsStore.data || [],
    reportsLoading: reportsStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...reportActions,
  });
};
