import CryptoJS from 'crypto-js';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import { ENCRYPT_KEYS } from 'src/helpers/constants/encrypt';

export const decodeEncryptData = (encryptData: string) => {
  const originalKey = process.env.REACT_APP_ENCRYPT_KEY;
  // const originalKey = 'Dwz1uNhqOaiD/jfaytHtaXmCXXI8l0x/vQSmEYLxy4Y=';
  if (originalKey && encryptData) {
    try {
      const encrypted = atob(encryptData);
      const encryptedObj = JSON.parse(encrypted);
      const iv = CryptoJS.enc.Base64.parse(encryptedObj.iv);
      const encryptedValue = encryptedObj.value;
      const key = CryptoJS.enc.Base64.parse(originalKey);
      const decryptedStr = CryptoJS.AES.decrypt(encryptedValue, key, {
        iv: iv,
      });
      const decrypted = decryptedStr.toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (error) {
      return encryptData;
    }
  }
  return '';
};
export const decodeNormalObj = (obj) => {
  const newObj = cloneDeep(obj);
  if (!isEmpty(newObj)) {
    ENCRYPT_KEYS.map((key: string) => {
      newObj[key] = decodeEncryptData(obj[key]);
    });
  }
  return newObj;
};
