import * as certificationActions from 'src/store/actions/certification';
import * as sharedActions from 'src/store/actions/shared';
import * as workflowActions from 'src/store/actions/workflow';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    buildCertificateLoading: useReducerData(
      'certification',
      'buildCertificate.loading',
      false
    ),
    certDetail: useReducerData('certification', 'certificateDetail.data', {}),
    workflowDetail: useReducerData(
      'workflow',
      'workflowBuilderDetail.data',
      {}
    ),
    currentUser: useReducerData('auth', 'meData.data', {}),
  };
};
export const useLoadingData = () => {
  return {
    certDetailLoading: useReducerData(
      'certification',
      'certificateDetail.loading',
      false
    ),
    workflowDetailLoading: useReducerData(
      'workflow',
      'workflowBuilderDetail.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...certificationActions,
    ...workflowActions,
    ...sharedActions,
  });
};
