import { Reducer } from 'redux';
import { getUpdatedUsers } from 'src/utils/store';
import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/user';

const initialState = {
  users: {
    ...defaultObj,
  },
  user: {
    ...defaultObj,
  },
  inviteUser: {
    ...defaultObj,
  },
  verifyInviteToken: {
    ...defaultObj,
  },
  acceptInvite: {
    ...defaultObj,
  },
  updateUserActivate: {
    ...defaultObj,
  },
  editUser: {
    ...defaultObj,
  },
  editUserStatus: {
    ...defaultObj,
  },
  dismissPopup: {
    ...defaultObj,
  },
};

const UserReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: {
            ...state.users.data,
            data: getUpdatedUsers(
              state.users.data.data,
              payload.id,
              payload.data
            ),
          },
        },
      };
    case Types.GET_USERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          users: {
            ...prevState.users,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          users: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          users: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_USER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          user: {
            loading: true,
            data: {},
            error: '',
          },
        }),
        success: (prevState) => ({
          ...prevState,
          user: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          user: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.INVITE_USER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          inviteUser: {
            ...prevState.inviteUser,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          inviteUser: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          inviteUser: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.VERIFY_INVITE_TOKEN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          verifyInviteToken: {
            ...prevState.verifyInviteToken,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          verifyInviteToken: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          verifyInviteToken: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.ACCEPT_INVITE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          acceptInvite: {
            ...prevState.acceptInvite,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          acceptInvite: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          acceptInvite: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_USER_ACTIVATE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateUserActivate: {
            ...prevState.updateUserActivate,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateUserActivate: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateUserActivate: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.EDIT_USER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          editUser: {
            ...prevState.editUser,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          editUser: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          editUser: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.EDIT_USER_STATUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          editUserStatus: {
            ...prevState.editUserStatus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          editUserStatus: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          editUserStatus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DISMISS_POPUP:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          dismissPopup: {
            ...prevState.dismissPopup,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          dismissPopup: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          dismissPopup: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default UserReducer;
