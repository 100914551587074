import { ROLES } from 'src/helpers/constants';
import { adminPermission } from './admin';
import { complianceManagerPermission } from './complianceManager';
import { complianceSpecialistPermission } from './complianceSpecialist';
import { executivePermission } from './executive';
import { otherPermission } from './other';
import { propertyManagerPermission } from './propertyManager';
import { regionalManagerPermission } from './regionalManager';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
  [ROLES.COMPLIANCE_SPECIALIST]: complianceSpecialistPermission,
  [ROLES.PROPERTY_MANAGER]: propertyManagerPermission,
  [ROLES.COMPLIANCE_MANAGER]: complianceManagerPermission,
  [ROLES.EXECUTIVE]: executivePermission,
  [ROLES.REGIONAL_MANAGER]: regionalManagerPermission,
};

const getPermission = (role) => {
  return permissions[role] || otherPermission;
};

export default getPermission;
