import cn from 'classnames';
import React from 'react';
import { TableResult } from 'src/components';
import { SizeType } from 'src/types';
import classes from './PageContent.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  paddingTop?: SizeType;
  gap?: SizeType;
  vertical?: boolean;
  inputGray?: boolean;
}

const PageContentTitle = ({ className = '', children }) => {
  return (
    <div className={cn(classes.pageContentTitle, className)}>{children}</div>
  );
};
const PageContentHeader = ({
  className = '',
  children,
  tableResult = null,
}) => {
  return (
    <div className={cn(classes.pageContentHeader, className)}>
      {children}
      {typeof tableResult === 'number' && (
        <TableResult count={tableResult} className={classes.tableResult} />
      )}
    </div>
  );
};
const FilterBar = ({ className = '', children }) => {
  return <div className={cn(classes.filterBar, className)}>{children}</div>;
};
const ButtonBar = ({ className = '', children, mobileFullWidth = false }) => {
  return (
    <div
      className={cn(
        classes.buttonBar,
        {
          [classes.mobileFullWidth]: mobileFullWidth,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

const PageContent = ({
  className,
  children,
  paddingTop,
  gap,
  vertical,
  inputGray,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.vertical]: vertical,
          [classes.inputGray]: inputGray,
        },
        'i-page-content',
        classes[`paddingTop_${paddingTop}`],
        classes[`gap_${gap}`],
        className
      )}
    >
      {children}
    </div>
  );
};

export {
  PageContentHeader,
  PageContentTitle,
  PageContent,
  FilterBar,
  ButtonBar,
};
