import cn from 'classnames';
import { ReactComponent as InfoCircleSvg } from 'src/assets/icons/InfoCircle.svg';
import Tooltip from 'src/components/Tooltip';
import classes from './InfoTooltip.module.scss';

const InfoTooltip = ({ className = '', tooltip, position }) => {
  return (
    <Tooltip
      label={tooltip}
      color="white"
      transitionDuration={200}
      position={position}
    >
      <span className={cn(classes.wrapper, className)}>
        <InfoCircleSvg />
      </span>
    </Tooltip>
  );
};

export default InfoTooltip;
