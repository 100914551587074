import { Dispatch } from 'redux';
import { apiGetDashboard, apiReadAlert } from 'src/api';
import Types from '../types/dashboard';

export const getDashboard =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_DASHBOARD,
      asyncCall: () => {
        return apiGetDashboard(params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const readAlert = (id: number, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: { alertId: id },
    type: Types.READ_ALERT,
    asyncCall: () => {
      return apiReadAlert(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
