import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { FIELD_PARSE_KEY } from 'src/helpers/constants';
import {
  DUE_BY_VALUES,
  NOT_STARTED_LABEL,
  OPTION_ALL_VALUE,
} from 'src/helpers/constants/options';
import { CertFlowBuilder } from 'src/types/certification';
import {
  getNewCertificationSection,
  getNewCertificationSubSection,
} from 'src/utils/certification';
import { cleanPayloadValue } from 'src/utils/form';
import { cleanUnAvailableOptions } from 'src/utils/options';
import {
  ASSIGN_STATUS_KEY,
  ASSOCIATED_FILE_KEY,
  BUILD_CERTIFICATE_NAME_MAPPING,
  BUILD_WORKFLOW_NAME_MAPPING,
  DATE_INPUT_KEY,
  DATE_INPUT_SPLIT_KEY,
  DROPDOWN_KEY,
  NOTICE_FIELD_MAPPING,
  SECTION_FIELD_MAPPING,
} from './constants';

const getInitNoticeSection = (fields, mappingKeys, isSection = false) => {
  const initData = {};
  const associatedFile = find(fields, {
    label: 'Associated File',
  });
  Object.keys(mappingKeys).forEach((key) => {
    if (isSection && key === ASSOCIATED_FILE_KEY) {
      initData[key] = !isEmpty(associatedFile?.value)
        ? {
            name: associatedFile?.value?.name,
            url: associatedFile.value?.file,
            existingId: associatedFile.id,
          }
        : '';
    } else if (isSection && key === DROPDOWN_KEY) {
      const tValue = find(fields, {
        label: mappingKeys[key]?.label,
      })?.value;
      const firstValue = tValue?.[0] || '';
      const tChecklist = isArray(tValue)
        ? tValue
            .filter((_v, i) => i !== 0)
            .map((v, i) => {
              return {
                checked: true,
                id: i + 1,
                value: v,
              };
            })
        : [];
      if (firstValue || tChecklist.length > 0) {
        initData[key] = {
          value: firstValue,
          checklist: tChecklist,
        };
      }
    } else if (isSection && key === ASSIGN_STATUS_KEY) {
      const tValue = find(fields, {
        label: mappingKeys[key]?.label,
      })?.value;
      initData[key] = tValue ? Number(tValue) : '';
    } else if (isSection && key === DATE_INPUT_KEY) {
      const dateFields = fields.filter(
        (obj) => obj.label.includes('Date Input') && obj.type === 'date'
      );
      initData[key] = dateFields.map((obj) => obj.value);
      initData[`${key}_id`] = dateFields.map((obj) => obj.id);
    } else {
      const tValue = find(fields, {
        label: mappingKeys[key]?.label,
      })?.value;
      initData[key] = tValue === null ? '' : tValue;
    }
  });
  return initData;
};

export const getInitialValues = (certDetail, settings?: any) => {
  const isWorkflow = !!settings?.isWorkflow;
  const {
    permissionOptions,
    typeOptions,
    programOptions,
    locationOptions,
    statusOptions,
  } = settings || {};
  const defaultStatusId = find(statusOptions, {
    label: NOT_STARTED_LABEL,
  })?.value;
  const initValues: any = {
    name_of_certification: certDetail.name || '',
    certification_type: null,
    workflow_type: null,
    locations: null,
    permissions: null,
    status_options: null,
    is_active: certDetail.is_active === 0 ? false : 1,
    flowBuilder: {
      id: 'first-1',
      type: 'first',
      children: [],
    } as CertFlowBuilder,
  };
  if (isWorkflow) {
    initValues.due_by = null;
    initValues.due_in_how_many_days = '';
    initValues.frequency = null;
    initValues.recurring_date = '';
  } else {
    initValues.program_type = null;
  }
  if (!isEmpty(certDetail)) {
    const {
      permissions,
      programs,
      types,
      properties,
      statuses,
      due_by,
      due_in_how_many_days,
      frequency,
      recurring_date,
      workflow_type,
    } = certDetail;
    const tForms = orderBy(certDetail.forms || [], ['position']).filter(
      (obj) => !obj.parent_id
    );
    initValues.permissions = cleanUnAvailableOptions(
      permissions.map((permission) => permission.role?.name),
      permissionOptions
    );
    if (isWorkflow) {
      initValues.due_by = due_by || null;
      initValues.workflow_type = workflow_type || null;
      initValues.due_in_how_many_days = due_in_how_many_days || '';
      initValues.frequency = frequency || null;
      initValues.recurring_date = recurring_date
        ? new Date(recurring_date)
        : '';
    } else {
      initValues.program_type = cleanUnAvailableOptions(
        programs.map((program) => program.program_id),
        programOptions
      );
      initValues.certification_type = cleanUnAvailableOptions(
        types.map((type) => type.certificate_type_id),
        typeOptions
      );
    }
    initValues.locations = cleanUnAvailableOptions(
      properties.map((property) => property.property_id),
      locationOptions
    );
    initValues.status_options = statuses.map((status) => status.status?.id);
    tForms.forEach((obj) => {
      if (obj.type === 'notice') {
        const noticeFields = obj.fields || [];
        const initNotice = {
          ...getNewCertificationSection(
            initValues.flowBuilder.children.length,
            'notice'
          ),
          existingId: obj.id,
          opened: false,
          ...getInitNoticeSection(noticeFields, NOTICE_FIELD_MAPPING),
        };
        initValues.flowBuilder.children.push(initNotice);
      } else {
        const sectionFields = obj.fields || [];
        const subSections = orderBy(obj.childs || [], ['position']);
        const initSection = {
          ...getNewCertificationSection(
            initValues.flowBuilder.children.length,
            'section'
          ),
          opened: false,
          existingId: obj.id,
          ...getInitNoticeSection(sectionFields, SECTION_FIELD_MAPPING, true),
        };
        if (subSections.length > 0) {
          subSections.forEach((subSection) => {
            const subSectionFields = subSection.fields || [];
            const initSubSection = {
              ...getNewCertificationSubSection(initSection),
              opened: false,
              existingId: subSection.id,
              ...getInitNoticeSection(
                subSectionFields,
                SECTION_FIELD_MAPPING,
                true
              ),
            };
            initSection.children.push(initSubSection);
          });
        }
        initValues.flowBuilder.children.push(initSection);
      }
    });
  }
  if (
    defaultStatusId &&
    !initValues.status_options?.includes(defaultStatusId)
  ) {
    if (initValues.status_options) {
      initValues.status_options.push(defaultStatusId);
    } else {
      initValues.status_options = [defaultStatusId];
    }
  }
  return initValues;
};
export const getCertificationBR = (values) => {
  const returnObj = {} as any;
  Object.keys(values).map((key) => {
    const parseKey = ['effective_date', 'response_date'].includes(key)
      ? FIELD_PARSE_KEY.DATE
      : '';
    returnObj[key] = cleanPayloadValue(values[key], parseKey);
    return true;
  });
  return returnObj;
};
export const getAnySectionIsOpened = (values) => {
  const sections = values.flowBuilder?.children || [];
  const tSections = [];
  sections.forEach((section) => {
    if (section) {
      tSections.push(section);
      (section.children || []).forEach((sSection) => {
        tSections.push(sSection);
      });
    }
  });
  return tSections.filter((s) => s?.opened).length > 0;
};
const getFormDataName = (
  index,
  kIndex,
  key,
  fieldIsArray = false,
  childIndex?: number
) => {
  return `form[${index}]${
    childIndex >= 0 ? `[childs][${childIndex}]` : ''
  }[fields][${kIndex}][${key}]${fieldIsArray ? '[]' : ''}`;
};
const setFormDataNotice = (notice, index, formData) => {
  Object.keys(NOTICE_FIELD_MAPPING).forEach((k, kIndex) => {
    const tKObj = NOTICE_FIELD_MAPPING[k];
    const tValue = notice[k];
    formData.append(getFormDataName(index, kIndex, 'label'), tKObj.label);
    formData.append(getFormDataName(index, kIndex, 'type'), tKObj.type);
    if (isArray(tValue)) {
      tValue
        .filter((v) => v !== OPTION_ALL_VALUE)
        .forEach((v) => {
          formData.append(getFormDataName(index, kIndex, 'value', true), v);
        });
    } else {
      formData.append(
        getFormDataName(index, kIndex, 'value'),
        tValue === true ? 1 : tValue === false ? 0 : tValue || ''
      );
    }
  });
};
const setFormDataSection = (section, index, formData) => {
  const tSectionChild = section.children || [];
  let parentSectionIndex = 0;
  if (section.check_schedule_delete?.length > 0) {
    section.check_schedule_delete.forEach((id, dIndex) => {
      formData.append(`form[${index}][delete_fields][${dIndex}]`, id);
    });
  }
  Object.keys(SECTION_FIELD_MAPPING).forEach((k) => {
    const tKObj = SECTION_FIELD_MAPPING[k];
    const tValue = section[k];
    if (k !== DATE_INPUT_KEY) {
      formData.append(
        getFormDataName(index, parentSectionIndex, 'label'),
        tKObj.label
      );
      formData.append(
        getFormDataName(index, parentSectionIndex, 'type'),
        tKObj.type
      );
    }
    if (isArray(tValue) && k !== DATE_INPUT_KEY) {
      tValue
        .filter((v) => v !== OPTION_ALL_VALUE)
        .forEach((v) => {
          formData.append(
            getFormDataName(index, parentSectionIndex, 'value', true),
            v
          );
        });
    } else {
      if (k === ASSOCIATED_FILE_KEY) {
        formData.append(
          getFormDataName(index, parentSectionIndex, 'value'),
          tValue?.existingId ? '' : tValue || null
        );
      } else if (k === DATE_INPUT_KEY) {
        if (tValue && isArray(tValue)) {
          tValue.forEach((v, vIndex) => {
            formData.append(
              getFormDataName(index, parentSectionIndex, 'label'),
              `${tKObj.label} ${vIndex + 1}`
            );
            formData.append(
              getFormDataName(index, parentSectionIndex, 'type'),
              tKObj.type
            );
            formData.append(
              getFormDataName(index, parentSectionIndex, 'value'),
              v || ''
            );
            parentSectionIndex += 1;
          });
        }
      } else if (k === DROPDOWN_KEY) {
        const tOptions = [];
        if (tValue?.value) {
          tOptions.push(tValue.value);
        }
        if (tValue?.checklist?.length) {
          tValue.checklist
            .filter((obj) => obj.checked)
            .forEach((obj) => {
              tOptions.push(obj.value);
            });
        }
        tOptions.forEach((option) => {
          formData.append(
            getFormDataName(index, parentSectionIndex, 'value', true),
            option
          );
        });
      } else {
        formData.append(
          getFormDataName(index, parentSectionIndex, 'value'),
          tValue === true ? 1 : tValue === false ? 0 : tValue || ''
        );
      }
    }
    if (k !== DATE_INPUT_KEY) {
      parentSectionIndex += 1;
    }
  });
  tSectionChild.forEach((sSection, sIndex) => {
    formData.append(`form[${index}][childs][${sIndex}][position]`, sIndex + 1);
    formData.append(`form[${index}][childs][${sIndex}][type]`, 'section');
    if (sSection.check_schedule_delete?.length > 0) {
      sSection.check_schedule_delete.forEach((id, dIndex) => {
        formData.append(
          `form[${index}][childs][${sIndex}][delete_fields][${dIndex}]`,
          id
        );
      });
    }
    if (sSection.existingId) {
      formData.append(
        `form[${index}][childs][${sIndex}][id]`,
        sSection.existingId
      );
    }
    let subSectionIndex = 0;
    Object.keys(SECTION_FIELD_MAPPING).forEach((subK) => {
      const tSKObj = SECTION_FIELD_MAPPING[subK];
      const tSValue = sSection[subK];
      if (subK !== DATE_INPUT_KEY) {
        formData.append(
          getFormDataName(index, subSectionIndex, 'label', false, sIndex),
          tSKObj.label
        );
        formData.append(
          getFormDataName(index, subSectionIndex, 'type', false, sIndex),
          tSKObj.type
        );
      }
      if (isArray(tSValue) && subK !== DATE_INPUT_KEY) {
        tSValue
          .filter((v) => v !== OPTION_ALL_VALUE)
          .forEach((v) => {
            formData.append(
              getFormDataName(index, subSectionIndex, 'value', true, sIndex),
              v
            );
          });
      } else {
        if (subK === ASSOCIATED_FILE_KEY) {
          formData.append(
            getFormDataName(index, subSectionIndex, 'value', false, sIndex),
            tSValue?.existingId ? '' : tSValue || null
          );
        } else if (subK === DATE_INPUT_KEY) {
          formData.append(
            getFormDataName(index, subSectionIndex, 'value', false, sIndex),
            tSValue && isArray(tSValue)
              ? tSValue.join(DATE_INPUT_SPLIT_KEY)
              : ''
          );
          if (tSValue && isArray(tSValue)) {
            tSValue.forEach((v, vIndex) => {
              formData.append(
                getFormDataName(index, subSectionIndex, 'label', false, sIndex),
                `${tSKObj.label} ${vIndex + 1}`
              );
              formData.append(
                getFormDataName(index, subSectionIndex, 'type', false, sIndex),
                tSKObj.type
              );
              formData.append(
                getFormDataName(index, subSectionIndex, 'value', false, sIndex),
                v || ''
              );
              if (vIndex < tSValue.length) {
                subSectionIndex += 1;
              }
            });
          }
        } else if (subK === DROPDOWN_KEY) {
          const tOptions = [];
          if (tSValue?.value) {
            tOptions.push(tSValue.value);
          }
          if (tSValue?.checklist?.length) {
            tSValue.checklist
              .filter((obj) => obj.checked)
              .forEach((obj) => {
                tOptions.push(obj.value);
              });
          }
          tOptions.forEach((option) => {
            formData.append(
              getFormDataName(index, subSectionIndex, 'value', true, sIndex),
              option
            );
          });
        } else {
          formData.append(
            getFormDataName(index, subSectionIndex, 'value', false, sIndex),
            tSValue === true ? 1 : tSValue === false ? 0 : tSValue || ''
          );
        }
      }
      if (subK !== DATE_INPUT_KEY) {
        subSectionIndex += 1;
      }
    });
  });
};
export const getBuildCertificateBR = (
  values,
  certId?: number | string,
  settings?: any
) => {
  const flowBuilderSections = values.flowBuilder?.children || [];
  const formData = new FormData() as any;
  const NAME_MAPPING: any = settings?.isWorkflow
    ? BUILD_WORKFLOW_NAME_MAPPING
    : BUILD_CERTIFICATE_NAME_MAPPING;
  Object.keys(NAME_MAPPING).forEach((key) => {
    const tValue = values[key] || '';
    if (isArray(tValue)) {
      tValue
        .filter(
          (v) =>
            v !== OPTION_ALL_VALUE &&
            (key !== 'locations' ||
              (key === 'locations' && typeof v !== 'string'))
        )
        .forEach((val) => {
          formData.append(`${NAME_MAPPING[key]}[]`, val);
        });
    } else if (key === NAME_MAPPING.due_in_how_many_days) {
      if (values.due_by === DUE_BY_VALUES.START_DATE) {
        formData.append(NAME_MAPPING[key], tValue);
      }
    } else if (
      [NAME_MAPPING.frequency, NAME_MAPPING.recurring_date].includes(key)
    ) {
      if (values.due_by === DUE_BY_VALUES.SPECIFIC_DATE) {
        if (key === NAME_MAPPING.recurring_date) {
          const tDateString = tValue
            ? `${tValue.getFullYear()}-${
                tValue.getMonth() + 1
              }-${tValue.getDate()}`
            : '';
          formData.append(NAME_MAPPING[key], tDateString);
        } else {
          formData.append(NAME_MAPPING[key], tValue);
        }
      }
    } else {
      formData.append(
        NAME_MAPPING[key],
        key === 'is_active' ? (tValue ? 1 : 0) : tValue
      );
    }
  });
  flowBuilderSections.forEach((section, index) => {
    const tSectionType = section.sectionType;
    formData.append(`form[${index}][position]`, index + 1);
    formData.append(`form[${index}][type]`, tSectionType);
    if (section.existingId) {
      formData.append(`form[${index}][id]`, section.existingId);
    }
    if (tSectionType === 'notice') {
      setFormDataNotice(section, index, formData);
    } else if (tSectionType === 'section') {
      setFormDataSection(section, index, formData);
    }
  });
  if (certId) {
    formData.append('id', certId);
  }
  // for (const pair of formData.entries()) {
  //   console.log(`${pair[0]}, ${pair[1]}`);
  // }
  return formData;
};
