export const USER_ATTR_MAPPING = {
  firstName: 'first_name',
  lastName: 'last_name',
  preferredName: 'preferred_name',
  ssn: 'ssn',
  ukgId: 'hris_id',
  hireDate: 'hire_date',
  terminationDate: 'termination_date',
  userRole: 'role',
  jobTitle: 'job_title',
  organization: 'organization',
  department: 'department_id',
  supervisor: 'supervisor_id',
  email: 'email',
  cellPhone: 'cell_phone',
  officePhone: 'office_phone',
  faxNumber: 'fax_number',
  properties: 'properties',
};
export const DATE_FIELDS = [
  USER_ATTR_MAPPING.hireDate,
  USER_ATTR_MAPPING.terminationDate,
];
export const FORMAT_FIELDS = [
  USER_ATTR_MAPPING.ssn,
  USER_ATTR_MAPPING.cellPhone,
  USER_ATTR_MAPPING.officePhone,
  USER_ATTR_MAPPING.faxNumber,
];
export const DROPDOWN_INT_FIELDS = [USER_ATTR_MAPPING.supervisor];
export const TABS = {
  DETAILS: 'Details',
  PROPERTIES: 'Properties',
};
