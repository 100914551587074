import find from 'lodash/find';
import { REST_FILES_KEY } from 'src/helpers/constants';
import { CERTIFICATE_FULL_FILE_ID } from './constants';

export const getCertFieldsBR = (fields) => {
  const formData = new FormData() as any;
  fields.forEach((field, index) => {
    if (field.valueId) {
      formData.append(`fields[${index}][id]`, field.valueId);
    }
    formData.append(`fields[${index}][field_id]`, field.fieldId);
    formData.append(`fields[${index}][value]`, field.value);
    if (field.fileValues?.length > 0) {
      field.fileValues.forEach((v) => {
        formData.append(`fields[${index}][files][]`, v);
      });
    }
  });
  return formData;
};
export const getCertAllFiles = (certDetail, isWorkflow) => {
  const certKey = isWorkflow ? 'workflow' : 'certificate';
  const values = certDetail?.values || [];
  const allFiles = [];
  values.forEach((value) => {
    (value.files || []).forEach((f) => {
      allFiles.push(f);
    });
  });
  if (
    certDetail?.[certKey]?.full_file &&
    certDetail?.[certKey]?.full_file_path
  ) {
    allFiles.push({
      id: CERTIFICATE_FULL_FILE_ID,
      name: certDetail?.[certKey]?.full_file,
      file: certDetail?.[certKey]?.full_file_path,
    });
  }
  return allFiles;
};
export const getUploadFullFileBR = (tFile) => {
  if (!tFile) return '';
  const formData = new FormData() as any;
  formData.append('full_file', tFile);
  return formData;
};
export const getPreviewNoticeValues = (setting, noticeId) => {
  return [
    ...(setting?.[noticeId]?.notices || []).map((obj, oIndex) => ({
      id: oIndex + 1,
      certificate_schema_field_id: 2,
      value: obj.value,
      files: obj.files,
      updated_at: obj.files?.length ? new Date() : '',
    })),
    {
      certificate_schema_field_id: 4,
      value: setting?.[noticeId]?.interviewSchedule,
    },
  ];
};
export const getPreviewSectionValues = (setting, sectionId) => {
  return [
    {
      certificate_schema_field_id: 4,
      files: setting?.[sectionId]?.files,
    },
  ];
};
export const getAllPreviewSubFiles = (setting, tSection) => {
  const allFiles = [];
  (tSection.children || []).forEach((sub) => {
    (setting?.[sub.id]?.files || []).forEach((tFile) => {
      allFiles.push(tFile);
    });
  });
  return allFiles;
};
export const getAllPreviewFiles = (setting) => {
  const allFiles = [];
  Object.keys(setting || {}).forEach((key) => {
    if (key === REST_FILES_KEY) {
      (setting[key] || []).forEach((tFile, tIndex) => {
        tFile.sId = `${key}_withIndex_${tIndex}`;
        allFiles.push(tFile);
      });
    } else if (key.includes('C-S')) {
      if (setting[key]?.notices?.length > 0) {
        setting[key]?.notices.forEach((tNotice) => {
          (tNotice.files || []).forEach((tFile, tIndex) => {
            tFile.sId = `${key}_withNotice_${tNotice.value}_withIndex_${tIndex}`;
            allFiles.push(tFile);
          });
        });
      } else {
        (setting[key]?.files || []).forEach((tFile, tIndex) => {
          tFile.sId = `${key}_withIndex_${tIndex}`;
          allFiles.push(tFile);
        });
      }
    }
  });
  return allFiles;
};
export const getPreviewSectionFiles = (
  setting,
  sectionId,
  noticeValue?: string,
  existingSections = null
) => {
  const tExistingSections = existingSections || [];
  const allFiles = [];
  const tSettingObj = setting?.[sectionId] || {};
  if (tSettingObj.notices?.length > 0 && !tSettingObj.files?.length) {
    if (noticeValue) {
      tSettingObj.notices.forEach((tNotice) => {
        if (tNotice.value === noticeValue) {
          (tNotice.files || []).forEach((tFile, tIndex) => {
            tFile.sId = `${sectionId}_withNotice_${noticeValue}_withIndex_${tIndex}`;
            allFiles.push(tFile);
          });
        }
      });
    }
  } else {
    (tSettingObj.files || []).forEach((tFile, tIndex) => {
      tFile.sId = `${sectionId}_withIndex_${tIndex}`;
      allFiles.push(tFile);
    });
    (find(tExistingSections, { id: sectionId })?.children || []).forEach(
      (sub) => {
        (setting[sub.id]?.files || []).forEach((tFile, tIndex) => {
          tFile.sId = `${sub.id}_withIndex_${tIndex}`;
          allFiles.push(tFile);
        });
      }
    );
  }
  return allFiles;
};
export const extractPreviewDeletedFile = (tFile) => {
  const tId = tFile.sId || '';
  const withIndexIds = tId.split('_withIndex_');
  const noticeValueIds = withIndexIds[0].split('_withNotice_');
  const tIndex = Number(withIndexIds[1]);
  const tNoticeValue = noticeValueIds[1] || '';
  const tSectionId = tNoticeValue ? noticeValueIds[0] : withIndexIds[0];
  return { index: tIndex, sectionId: tSectionId, noticeValue: tNoticeValue };
};
export const getPreviousCheckIds = (checks) => {
  return Object.keys(checks || {})
    .filter((key) => !!checks[key])
    .map((key) => Number(key));
};
export const getAllSubCompleted = (subs, checks) => {
  const tSubs = subs || [];
  const isAllSubCompleted = !(
    tSubs.filter((sub) => !sub.is_completed && !checks[sub.id]).length > 0
  );
  return isAllSubCompleted;
};
export const getSectionObjFromList = (fields: any[], sectionId: number) => {
  let returnObj = null;
  fields.forEach((field) => {
    if (field.id === sectionId) {
      returnObj = field;
    }
    if (field.childs?.length > 0 && !returnObj) {
      field.childs.forEach((child) => {
        if (child.id === sectionId) {
          returnObj = child;
        }
      });
    }
  });
  return returnObj;
};
