import { showNotification } from '@mantine/notifications';

interface NotificationParams {
  title: string;
  message?: string;
  color?: string;
}
export const notification = (params: NotificationParams) => {
  const { title, message, ...other } = params;
  showNotification({
    title,
    message: message || '',
    ...other,
  });
};
