import cn from 'classnames';
import { Arrow } from 'src/components';
import { Item } from 'src/types/Item';
import classes from './ResponsiveCard.module.scss';

interface Props {
  className?: string;
  items: Item[];
  boxItems: Item[];
  onView?: () => void;
}

const ItemEl = ({
  item,
  colon = false,
  align = '',
  hasButton = false,
  className = '',
}) => {
  return (
    <li
      className={cn(
        classes.item,
        classes[`itemAlign${align}`],
        {
          [classes.hasButton]: hasButton,
        },
        className
      )}
    >
      <span>
        <span>{item.label}</span>
        {colon && <span>:</span>}
      </span>
      <span>{item.value}</span>
    </li>
  );
};
const ResponsiveCard = ({ className, items, boxItems, onView }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <ul>
        {items.map((item, index) => {
          return <ItemEl key={index} item={item} colon />;
        })}
      </ul>
      <ul className={classes.box}>
        {boxItems.map((item, index) => {
          return (
            <ItemEl
              key={index}
              item={item}
              align={item.align}
              hasButton={item.hasButton}
              className={item.className}
            />
          );
        })}
        <li className={classes.arrow}>
          <Arrow direction="right" onClick={onView} />
        </li>
      </ul>
    </div>
  );
};

export default ResponsiveCard;
