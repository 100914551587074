import get from 'lodash/get';
import { ROLES } from 'src/helpers/constants';
import { useReducerData } from 'src/store/hooks';

const useCurrentUser = () => {
  return useReducerData('auth', 'meData.data', {});
};
const useCurrentRole = () => {
  const currentUser = useCurrentUser();
  return get(currentUser, 'roles[0].name', '');
};
const useIsAdminAndExecutive = () => {
  const currentUser = useCurrentUser();
  const roles = (currentUser.roles || [])
    .map((r) => r?.name)
    .filter((v) => !!v);
  return (
    roles.filter((v) =>
      [ROLES.SUPER_ADMIN, ROLES.ADMIN, ROLES.EXECUTIVE].includes(v)
    ).length > 0
  );
};

export { useCurrentUser, useIsAdminAndExecutive };
export default useCurrentRole;
