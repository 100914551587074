import { Field } from 'formik';
import get from 'lodash/get';
import Switch, { OSwitchProps } from '../Switch';

type Props = OSwitchProps & {
  forceError?: string | boolean;
  name: string;
  type?: string;
};

const FormikSwitch = (props: Props) => {
  const { name, forceError = false, ...otherProps } = props;

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { touched, errors, setFieldValue } = form;
        const value = field.value;
        const errorMessage = get(errors, field.name);
        return (
          <Switch
            {...otherProps}
            {...field}
            onChange={(e) => {
              setFieldValue(name, e.target.checked);
            }}
            value={value}
            checked={value}
            error={
              (get(touched, field.name) && !!errorMessage
                ? errorMessage
                : '') || forceError
            }
          />
        );
      }}
    </Field>
  );
};

export default FormikSwitch;
