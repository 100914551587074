import cn from 'classnames';
import React from 'react';
import { UserType } from 'src/types/user';
import LoadingOverlay from '../LoadingOverlay';
import classes from './CardList.module.scss';
import ContentCard, {
  CalendarCardItem,
  CalendarView,
  ProfileColumn,
} from './ContentCard';

interface Props {
  className?: string;
  widths?: string[];
  headers?: Record<string, string | number | React.ReactNode>[];
  data: any[];
  renderContentCard: (record: UserType) => React.ReactNode;
  singleHeader?: boolean;
  loading?: boolean;
  hideHeader?: boolean;
}

const CardList = ({
  className,
  widths,
  headers,
  data,
  renderContentCard,
  singleHeader,
  loading,
  hideHeader,
}: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <LoadingOverlay visible={loading} className={classes.loading} />
      {!hideHeader && (
        <>
          {headers?.length && (
            <div className={classes.header}>
              {headers.map((header, index: number) => {
                return (
                  <span
                    key={String(header.key)}
                    style={{
                      width: singleHeader ? '100%' : widths[index],
                      minWidth: String(header.minWidth),
                    }}
                  >
                    {header.label}
                  </span>
                );
              })}
            </div>
          )}
        </>
      )}
      <div className={classes.content}>
        {data.map((d) => {
          return (
            <React.Fragment key={d.id}>{renderContentCard(d)}</React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export { ContentCard, ProfileColumn, CalendarCardItem, CalendarView };
export default CardList;
