import { DatePickerProps, DatePicker as ManDatePicker } from '@mantine/dates';
import cn from 'classnames';
import { ReactComponent as DatePickerIconSvg } from 'src/assets/icons/DatePickerIcon.svg';
import classes from './DatePicker.module.scss';

export type ODatePickerProps = DatePickerProps & {
  className?: string;
};

const DatePicker = ({ className, ...other }: ODatePickerProps) => {
  return (
    <ManDatePicker
      {...other}
      className={cn(classes.wrapper, className)}
      rightSection={!other.value ? <DatePickerIconSvg /> : undefined}
    />
  );
};

export default DatePicker;
