import { Field } from 'formik';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import Upload, { OUploadProps } from '../Upload';

type Props = OUploadProps & {
  forceError?: string | boolean;
  name: string;
  type?: string;
  onFilesChanged?: (f) => void;
  onFileRemoved?: (f, index?: number) => void;
};

const FormikUpload = (props: Props) => {
  const {
    name,
    forceError = false,
    onFilesChanged,
    multiple,
    onFileRemoved,
    loading,
    ...otherProps
  } = props;

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { touched, errors, setFieldValue } = form;
        const value = field.value;
        const errorMessage = get(errors, field.name);
        return (
          <Upload
            {...otherProps}
            {...field}
            onChange={(f) => {
              setFieldValue(
                name,
                multiple ? [...(value || []), ...(f || [])] : f
              );
              if (onFilesChanged) onFilesChanged(f);
            }}
            value={value}
            multiple={multiple}
            error={
              (get(touched, field.name) && !!errorMessage
                ? errorMessage
                : '') || forceError
            }
            onRemove={
              loading
                ? undefined
                : (removeIndex) => {
                    if (onFileRemoved)
                      onFileRemoved(
                        isArray(value) ? value[removeIndex] : value,
                        removeIndex
                      );
                    const newFiles = isArray(value)
                      ? value.filter((_, i) => i !== removeIndex)
                      : undefined;
                    setFieldValue(name, newFiles);
                  }
            }
            loading={loading}
          />
        );
      }}
    </Field>
  );
};

export default FormikUpload;
