import * as sharedActions from 'src/store/actions/shared';
import * as workflowActions from 'src/store/actions/workflow';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const workflowBuilderStore = useReducerData(
    'workflow',
    'workflowBuilderList',
    {}
  );
  const workflowBuilderMeta = workflowBuilderStore.data || {};
  return {
    workflowBuilderMeta,
    workflowBuilderList: workflowBuilderMeta.data || [],
    workflowBuilderListLoading: workflowBuilderMeta.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...workflowActions,
    ...sharedActions,
  });
};
