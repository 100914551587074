import cn from 'classnames';
import classes from './Color.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode | string;
}

const Color = ({ className, children }: Props) => {
  return <span className={cn(classes.wrapper, className)}>{children}</span>;
};

export default Color;
