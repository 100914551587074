export const SECTION_TYPE = {
  NOTICE: 'notice',
  SECTION: 'section',
};
export const DEFAULT_CERTIFICATION_SECTION = {
  section_name: '',
  section_options: null,
  associated_file: null,
  check_upload_file: false,
  check_schedule: false,
  notice_information: '',
};
export const COMPLETE_CERT_STATUS_VALUE = 'Complete';
