// import { Arrow } from 'src/components';
import { NOTICE_STATUS_OPTIONS } from 'src/helpers/constants/options';
import {
  renderAvatarColumn,
  renderDateColumn,
  renderDropdownColumn,
  renderPrintColumn,
  renderStandardColumn,
  renderStandardWithStatusColumn,
} from 'src/utils/table';
import classes from './Notices.module.scss';

export const getColumns = ({ onStatusChange }) => {
  return [
    {
      key: 'title',
      render: renderStandardWithStatusColumn({
        willShow: (record) => record.status === 'Not Viewed',
      }),
      title: 'Uploaded',
    },
    {
      key: 'user',
      render: renderAvatarColumn,
      title: 'Added by',
      align: 'center',
      titleAlign: 'center',
    },
    {
      key: 'property',
      render: (_, record) => {
        return renderStandardColumn(record.property?.name);
      },
      title: 'Property',
    },
    {
      key: 'created_at',
      render: renderDateColumn,
      title: 'Date Uploaded',
    },
    {
      key: 'status',
      render: renderDropdownColumn({
        options: NOTICE_STATUS_OPTIONS,
        onChange: onStatusChange,
      }),
      title: 'Status',
      width: 210,
      className: classes.dropdownColumn,
    },
    {
      key: 'file_path',
      render: renderPrintColumn,
      title: '',
    },
    // {
    //   key: 'action',
    //   render: () => {},
    //   renderAction: () => {
    //     return <Arrow direction="right" />;
    //   },
    // },
  ];
};
