import * as Yup from 'yup';
import { MESSAGE, PATTERN } from 'src/helpers/constants';
import {
  testDateField,
  testFormatField,
  testFormatRequireField,
  testTime12Field,
  testTimeField,
} from 'src/utils/form';

export const schema = Yup.object().shape({
  status: Yup.string().required('This field is required!'),
  insurance_name: Yup.string().nullable().matches(PATTERN.NAME, MESSAGE.NAME),
  insurance_email: Yup.string().nullable().email('Invalid email!'),
  insurance_phone: Yup.string()
    .nullable()
    .test(
      'invalid-filled-phone-number',
      'Invalid Phone Number',
      testFormatField
    ),
  property_id: Yup.string().required('This field is required!'),
  type: Yup.string().required('This field is required!'),
  manager_on_duty: Yup.string().nullable().matches(PATTERN.NAME, MESSAGE.NAME),
  summary: Yup.string().required('This field is required!'),
  filled_name: Yup.string()
    .required('This field is required!')
    .matches(PATTERN.NAME, MESSAGE.NAME),
  filled_phone_numbner: Yup.string()
    .nullable()
    .test(
      'invalid-filled-phone-number',
      'Invalid Phone Number',
      testFormatField
    ),
  notified_time: Yup.string()
    .nullable()
    .test('invalid-notified-time', 'Invalid Time', testTimeField)
    .test(
      'invalid-notified-time-12',
      'The time should be in the 12-hour format',
      testTime12Field
    ),
  incident_time: Yup.string()
    .nullable()
    .test('invalid-incident-time', 'Invalid Time', testTimeField)
    .test(
      'invalid-incident-time-12',
      'The time should be in the 12-hour format',
      testTime12Field
    ),
  incident_date: Yup.string()
    .required('This field is required!')
    .test(
      'required-incident-date',
      'This field is required!',
      testFormatRequireField
    )
    .test('invalid-incident-date', 'Invalid Date', testDateField),
  filled_date: Yup.string()
    .nullable()
    .test('invalid-filled-date', 'Invalid Date', testDateField),
  notified_date: Yup.string()
    .nullable()
    .test('invalid-notified-date', 'Invalid Date', testDateField),
  involved_party: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required('This field is required!')
        .matches(PATTERN.NAME, MESSAGE.NAME),
    })
  ),
  authority: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable().matches(PATTERN.NAME, MESSAGE.NAME),
    })
  ),
  uninvolved_witness: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable().matches(PATTERN.NAME, MESSAGE.NAME),
    })
  ),
  physician: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable().matches(PATTERN.NAME, MESSAGE.NAME),
    })
  ),
  hospital: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().nullable().matches(PATTERN.NAME, MESSAGE.NAME),
    })
  ),
});

export const INSURANCE_NAME_MAPPING = {
  insurance_name: 'agent_name',
  insurance_phone: 'agent_phone_number',
  insurance_email: 'agent_email',
  insurance_number: 'claim_number',
};

export const WEATHER_CONDITIONS_NAME_MAPPING = {
  weather_condition: 'conditions',
  weather_temperature: 'temperature',
  weather_additional_notes: 'additional_notes',
};

export const ADDITIONAL_INFORMATION_NAME_MAPPING = {
  photos_additional_information: 'photos_additional_info',
  photos_additional_upload_na: 'photos_na',
  photos_additional_upload_ids: 'photos',
  photos_required: 'photos_confirmed',
  witness_additional_information: 'witness_statements_additional_info',
  witness_additional_upload_na: 'witness_statements_na',
  witness_additional_upload_ids: 'witness_statements',
  witness_required: 'witness_statements_confirmed',
  related_documents_additional_information:
    'other_additional_documents_additional_info',
  related_documents_additional_upload_na: 'other_additional_documents_na',
  related_documents_additional_upload_ids: 'other_additional_documents',
  related_documents_required: 'other_additional_documents_confirmed',
};

export const UPLOAD_NAME_MAPPING = {
  photos: 'photos_additional_upload_ids',
  witness_statements: 'witness_additional_upload_ids',
  other_additional_documents: 'related_documents_additional_upload_ids',
};
