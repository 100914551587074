import cn from 'classnames';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Folder1Svg } from 'src/assets/icons/Folder1.svg';
import { FilePopover, Tooltip } from 'src/components';
import { RECENT_DELETED_FOLDER } from 'src/pages/Documents/constants';
import classes from './FolderBlock.module.scss';

const FolderBlock = ({
  className = '',
  title,
  onDelete = undefined,
  path,
  isMarginTop = false,
  icon = undefined,
  isActive = false,
  folderId = 0,
  onDocumentMoved = null,
  onDetail = null,
  onDuplicate = null,
  data,
}) => {
  const isRecentlyDeletedFolder = String(folderId) === RECENT_DELETED_FOLDER.id;
  const [opened, setOpened] = useState(false);
  const [offsetX, setOffsetX] = useState(0);

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isMarginTop]: isMarginTop,
          [classes.isActive]: isActive,
        },
        className
      )}
      onDrop={(event) => {
        const dragObjStr = event.dataTransfer.getData('dragObj');
        const tDragObj = dragObjStr ? JSON.parse(dragObjStr) : {};
        const sourceDocumentId = tDragObj.id;
        const parentDirectoryId = tDragObj.directory_id;
        const rootFolderId = tDragObj.root_directory_id;
        if (
          (rootFolderId
            ? rootFolderId !== folderId
            : parentDirectoryId !== folderId) &&
          onDocumentMoved
        ) {
          onDocumentMoved(sourceDocumentId, folderId);
        }
        event.preventDefault();
        event.stopPropagation();
        return;
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
    >
      <FilePopover
        opened={opened}
        setOpened={setOpened}
        offsetX={offsetX}
        dropdownOffset={0}
        items={[
          ...(isRecentlyDeletedFolder
            ? []
            : [
                ...(data?.can_view
                  ? [
                      {
                        label: 'View Details',
                        onClick: onDetail,
                      },
                    ]
                  : []),
                ...(data?.can_edit
                  ? [
                      {
                        label: 'Duplicate Folder',
                        onClick: onDuplicate,
                      },
                    ]
                  : []),
                ...(data?.can_delete
                  ? [
                      {
                        label: 'Delete this folder',
                        onClick: onDelete,
                      },
                    ]
                  : []),
              ]),
        ]}
      >
        <Link
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setOffsetX(e.screenX);
            setOpened(true);
          }}
          to={path}
        >
          {icon || <Folder1Svg />}
          <Tooltip
            label={title}
            color="dark"
            withArrow
            transitionDuration={200}
          >
            <span>{title}</span>
          </Tooltip>
        </Link>
      </FilePopover>
    </div>
  );
};

export default FolderBlock;
