import findIndex from 'lodash/findIndex';

export const getUpdatedUsers = (users, id, data, replaceAll = false) => {
  return users.map((user) => {
    if (user.id === id) {
      return replaceAll
        ? data
        : {
            ...user,
            ...data,
          };
    }
    return user;
  });
};
export const getUserGroupByDeparment = (payload) => {
  if (typeof payload !== 'object') return [];
  const users = [];
  (Object.keys(payload) || []).forEach((key: string) => {
    (payload[key] || []).map((user) => users.push(user));
  });
  return users;
};
export const getLatestWorkflowTypeBuilder = (tList, obj) => {
  const tArr = tList || [];
  const isExisting = findIndex(tArr, { id: obj.id }) >= 0;
  return isExisting ? tArr : [...tArr, obj];
};
