import cn from 'classnames';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/CloseCircle.svg';
import { AvatarGroup, Icon } from 'src/components';
import { getPropertyAddress } from 'src/utils/address';
import { displayName, intialName } from 'src/utils/text';
import classes from './UserProperty.module.scss';

const UserProperty = ({ className = '', data, onRemove = undefined }) => {
  const firstUser = data?.users?.[0]?.user || {};
  const first8Users = (data?.users || []).filter((_, i) => i <= 7);
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.inner}>
        <div className={classes.left}>
          <h3>{data?.name}</h3>
          <span>{getPropertyAddress(data)}</span>
        </div>
        <div className={classes.right}>
          <div>
            <AvatarGroup
              spacing="sm"
              items={first8Users.map((user) => ({
                name: intialName(user.user?.first_name, user.user?.last_name),
                avtSrc: user.user?.profile_image_path,
              }))}
            />
          </div>
          <p>
            {data?.users_count > 0 && (
              <>
                <b>{displayName(firstUser.first_name, firstUser.last_name)}</b>{' '}
                and {data?.users_count - 1} others are assigned to this property
              </>
            )}
          </p>
        </div>
      </div>
      {!!onRemove && (
        <Icon className={classes.removeIcon} onClick={onRemove}>
          <CloseCircleSvg />
        </Icon>
      )}
    </div>
  );
};

export default UserProperty;
