import { Reducer } from 'redux';

import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/report';

const initialState = {
  reports: {
    ...defaultArray,
  },
  reportDetail: {
    ...defaultObj,
  },
  addUpdateReport: {
    ...defaultObj,
  },
  uploadReportFiles: {
    ...defaultObj,
  },
  deleteReportFiles: {
    ...defaultObj,
  },
};

const ReportReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_SET_REPORT_DETAIL: {
      return {
        ...state,
        reportDetail: {
          ...state.reportDetail,
          data: {
            ...action.payload,
          },
        },
      };
    }
    case Types.GET_REPORTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          reports: {
            ...prevState.reports,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          reports: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          reports: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_REPORT_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          reportDetail: {
            ...prevState.reportDetail,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          reportDetail: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          reportDetail: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.ADD_UPDATE_REPORT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addUpdateReport: {
            ...prevState.addUpdateReport,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addUpdateReport: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addUpdateReport: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPLOAD_REPORT_FILES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadReportFiles: {
            ...prevState.uploadReportFiles,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadReportFiles: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadReportFiles: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_REPORT_FILES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadReportFiles: {
            ...prevState.uploadReportFiles,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadReportFiles: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadReportFiles: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default ReportReducer;
