import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './Breadcrumb.module.scss';

export type BreadcrumbItem = {
  label: string | React.ReactNode;
  link?: string;
};

interface Props {
  className?: string;
  items: BreadcrumbItem[];
}

const Split = () => {
  return <span className={classes.split}>&gt;</span>;
};

const Breadcrumb = ({ className, items }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <ul>
        {items.map((item: BreadcrumbItem, index: number) => {
          return (
            <li key={index}>
              {item.link ? (
                <Link to={item.link}>{item.label}</Link>
              ) : (
                <span>{item.label}</span>
              )}
              {index < items.length - 1 && <Split />}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Breadcrumb;
