import * as certificationActions from 'src/store/actions/certification';
import * as sharedActions from 'src/store/actions/shared';
import * as workflowActions from 'src/store/actions/workflow';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const certificationsStore = useReducerData(
    'certification',
    'certifications',
    {}
  );
  const certificationsMeta = certificationsStore.data || {};
  const workflowsStore = useReducerData('workflow', 'workflows', {});
  const workflowsMeta = workflowsStore.data || {};

  return {
    certificationsMeta,
    certifications: certificationsMeta.data || [],
    certificationsLoading: certificationsStore.loading,
    workflowsMeta,
    workflows: workflowsMeta.data || [],
    workflowsLoading: workflowsStore.loading,
    certDetail: useReducerData('certification', 'certificateDetail.data', {}),
    workflowDetail: useReducerData(
      'workflow',
      'workflowBuilderDetail.data',
      {}
    ),
  };
};
export const useLoadingData = () => {
  return {
    certDetailLoading: useReducerData(
      'certification',
      'certificateDetail.loading',
      false
    ),
    workflowDetailLoading: useReducerData(
      'workflow',
      'workflowBuilderDetail.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...certificationActions,
    ...workflowActions,
    ...sharedActions,
  });
};
