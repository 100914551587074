import { FormikTextarea } from 'src/components/FormFields';
import NewReportSection from '../Section';

const CauseIncident = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="Cause of Incident"
      onNoValue={() => {
        return !values.cause;
      }}
    >
      <FormikTextarea
        name="cause"
        placeholder="Cause of Incident"
        maxLength={300}
        disabledOverlay={isDisabled}
        onChangeCb={onValuesChange}
      ></FormikTextarea>
    </NewReportSection>
  );
};

export default CauseIncident;
