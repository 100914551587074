import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { ReactComponent as GripVerticalSvg } from 'src/assets/icons/GripVertical.svg';
import { ReactComponent as InfoCircleSvg } from 'src/assets/icons/InfoCircle.svg';
import { Arrow, Collapse } from 'src/components';
import { CertificationSection } from 'src/types/certification';
import DateInputModal from '../DateInputModal';
import DropdownModal from '../DropdownModal';
import SectionForm from './Form';
import classes from './Section.module.scss';

interface Props {
  data: CertificationSection;
  className?: string;
  dragHandleProps: any;
  parentName: string;
  toggle: () => void;
  isSectionValid?: boolean;
  onAddSection: () => void;
  onCancelSection?: (data: CertificationSection) => void;
  parentSectionTitle?: string;
  onDateInputChanged?: (val: string) => void;
  onDropdownChanged?: (val: string, checklist: any[]) => void;
  selectedStatuses?: number[];
  onDeleteSection?: (eId?: number, cb?: any) => void;
  isDragDisabled?: boolean;
}

const Section = ({
  className,
  dragHandleProps,
  data,
  parentName,
  toggle,
  isSectionValid,
  onAddSection,
  onCancelSection,
  parentSectionTitle,
  onDateInputChanged,
  onDropdownChanged,
  selectedStatuses,
  onDeleteSection,
  isDragDisabled,
}: Props) => {
  const [selectDI, setSelectDI] = useState('');
  const [selectDropdown, setSelectDropdown] = useState({} as any);
  const isSub = data.type === 'second';
  const handleToggle = () => {
    if (!data.opened) {
      toggle();
    } else {
      onCancelSection(data);
    }
  };

  return (
    <>
      <div className={cn(classes.wrapper, className)}>
        {!isDragDisabled ? (
          <div
            {...dragHandleProps}
            className={cn(dragHandleProps.className, classes.handler, {
              hide: data.opened,
            })}
          >
            <GripVerticalSvg />
          </div>
        ) : (
          <div
            className={cn(classes.handler, {
              hide: data.opened,
            })}
          ></div>
        )}
        <div className={classes.content}>
          {data.opened ? (
            <div></div>
          ) : (
            <div className={classes.title}>
              <span>{data.section_name}</span>
              <InfoCircleSvg />
              <Arrow
                direction="bottom"
                className={classes.arrow}
                onClick={handleToggle}
              />
            </div>
          )}
          <Collapse in={data.opened} className={classes.collapse}>
            <SectionForm
              toggle={handleToggle}
              parentName={parentName}
              isSectionValid={isSectionValid}
              onAddSection={onAddSection}
              type={data.sectionType}
              existingId={data.existingId}
              status={data.status}
              isSub={isSub}
              parentSectionTitle={parentSectionTitle}
              onOpenDI={(tName) => {
                setSelectDI(tName);
              }}
              onOpenDropdown={(tName, tValue) => {
                setSelectDropdown({
                  value: `${tName}---${tValue?.value ? tValue.value : ''}`,
                  checklist: tValue?.checklist,
                });
              }}
              selectedStatuses={selectedStatuses}
              onSectionDeleted={(isAdded, cb) => {
                if (!isAdded) {
                  handleToggle();
                } else {
                  if (onDeleteSection) onDeleteSection(data.existingId, cb);
                }
              }}
            />
          </Collapse>
        </div>
      </div>
      <DateInputModal
        isOpened={!!selectDI}
        onClose={() => {
          setSelectDI('');
        }}
        onSubmit={(tVal) => {
          if (onDateInputChanged) onDateInputChanged(tVal);
          setSelectDI('');
        }}
      />
      <DropdownModal
        isOpened={!isEmpty(selectDropdown)}
        onClose={() => {
          setSelectDropdown({});
        }}
        onSubmit={(tVal, tChecklist) => {
          if (onDropdownChanged) onDropdownChanged(tVal, tChecklist);
          setSelectDropdown({});
        }}
        defaultValue={{
          value: selectDropdown?.value
            ? selectDropdown.value.split('---')[1] || ''
            : '',
          checklist: selectDropdown?.checklist,
        }}
      />
    </>
  );
};

export default Section;
