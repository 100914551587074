import { notification } from 'src/utils/notification';
import { getErrorMessageFromResponse } from 'src/utils/object';

export const actionCb =
  (cb?: any, errCb?: any, errorTitle?: string) => (res, err) => {
    if (res) {
      if (cb) cb(res);
    } else {
      if (errCb) errCb();
      if (errorTitle && err.errorStatus !== 403) {
        notification({
          title: errorTitle,
          message: getErrorMessageFromResponse(err) || 'Something went wrong!',
          color: 'red',
        });
      }
    }
  };
