import cn from 'classnames';
import { ReactComponent as LogoSvg } from 'src/assets/images/Logo.svg';
import classes from './Logo.module.scss';

interface Props {
  className?: string;
}
const Logo = ({ className }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <LogoSvg />
    </div>
  );
};

export default Logo;
