import * as inoutActions from 'src/store/actions/inout';
import * as scheduleActions from 'src/store/actions/schedule';
import * as userActions from 'src/store/actions/user';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const profileStore = useReducerData('auth', 'meData', {});
  const updateUserStatusStore = useReducerData('inout', 'updateUserStatus', {});
  const pmUsersStore = useReducerData('inout', 'pmUsers', {});
  const omUsersStore = useReducerData('inout', 'omUsers', {});
  const schedulesStore = useReducerData('schedule', 'schedules', {});
  return {
    profile: profileStore.data,
    updateUserStatusLoading: updateUserStatusStore.loading,
    schedules: schedulesStore.data || [],
    schedulesLoading: schedulesStore.loading,
    pmUsers: pmUsersStore.data || {},
    omUsers: omUsersStore.data || {},
    profileMeta: useReducerData('auth', 'meMeta', {}),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...inoutActions,
    ...scheduleActions,
    ...userActions,
  });
};
