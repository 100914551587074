import * as userActions from 'src/store/actions/user';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const verifyTokenStore = useReducerData('user', 'verifyInviteToken', {});
  return {
    verifyTokenData: verifyTokenStore.data || {},
    verifyTokenLoading: verifyTokenStore.loading,
    acceptInviteLoading: useReducerData('user', 'acceptInvite.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...userActions,
  });
};
