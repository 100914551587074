import { Dispatch } from 'redux';
import {
  apiAcceptInvite,
  apiEditUser,
  apiEditUserStatus,
  apiGetUser,
  apiGetUsers,
  apiInviteUser,
  apiUpdateUserActivate,
  apiUserDismissPopup,
  apiVerifyInviteToken,
} from 'src/api';
import Types from '../types/user';

export const syncUpdateUser = (id, data) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_USER,
    payload: { id, data },
  });
};

export const getUsers =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_USERS,
      asyncCall: () => {
        return apiGetUsers(params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getUser = (userId: number, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_USER,
    asyncCall: () => {
      return apiGetUser(userId);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const inviteUser = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.INVITE_USER,
    asyncCall: () => {
      return apiInviteUser(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const verifyInviteToken =
  (token, data, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.VERIFY_INVITE_TOKEN,
      asyncCall: () => {
        return apiVerifyInviteToken(token, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const acceptInvite = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.ACCEPT_INVITE,
    asyncCall: () => {
      return apiAcceptInvite(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const updateUserActivate =
  (userId, key, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_USER_ACTIVATE,
      asyncCall: () => {
        return apiUpdateUserActivate(userId, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const editUser = (userId, data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.EDIT_USER,
    asyncCall: () => {
      return apiEditUser(userId, data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const editUserStatus =
  (userId, data, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.EDIT_USER_STATUS,
      asyncCall: () => {
        return apiEditUserStatus(userId, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const dismissWelcomePopup = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DISMISS_POPUP,
    asyncCall: () => {
      return apiUserDismissPopup(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
