import { MainContent, TopHeader } from 'src/components/Page';
import classes from './Help.module.scss';

const Help = () => {
  const helpSrc =
    'https://scribehow.com/page-embed/OneTree_In_and_Out_Board___nwqvU0DSkK5MYojfjYGHw';
  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Help',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <iframe src={helpSrc} frameBorder="0"></iframe>
      </MainContent>
    </>
  );
};

export default Help;
