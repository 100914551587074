import * as noticeActions from 'src/store/actions/notice';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    addNoticeLoading: useReducerData('notice', 'addNotice.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...noticeActions,
  });
};
