import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { Button, Card } from 'src/components';
import { FormikSwitch } from 'src/components/FormFields';
import CertPreviewModal from '../CertPreviewModal';
import classes from './Footer.module.scss';

const Footer = ({
  className = '',
  loading = false,
  isSubmitDisabled = false,
  isEditCertificate = false,
  formProps,
  isWorkflow = false,
}) => {
  const [previewData, setPreviewData] = useState({} as any);
  return (
    <>
      <Card
        className={cn(classes.wrapper, className)}
        contentGap={16}
        notPaddingContentTop
        contentPaddingBottom={0}
      >
        <Button
          radius="md"
          textWeight="xl"
          onClick={() => {
            setPreviewData(formProps.values);
          }}
          className={classes.previewBtn}
        >
          Preview {isWorkflow ? 'Workflow' : 'Certification'}
        </Button>
        <FormikSwitch
          name={'is_active'}
          label="Active"
          labelPosition="left"
          color="green"
        />
        <Button
          type="submit"
          radius="md"
          textWeight="xl"
          loading={loading}
          disabled={isSubmitDisabled}
        >
          {isEditCertificate
            ? `Save ${isWorkflow ? 'Workflow' : 'Certification'}`
            : `Save and Publish ${isWorkflow ? 'Workflow' : 'Certification'}`}
        </Button>
      </Card>
      <CertPreviewModal
        isOpened={!isEmpty(previewData)}
        defaultData={previewData}
        onClose={() => {
          setPreviewData({});
        }}
        isWorkflow={isWorkflow}
      />
    </>
  );
};

export default Footer;
