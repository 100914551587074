import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { ReactComponent as MicrosoftIcon } from 'src/assets/icons/microsoft.svg';
import NWRealPng from 'src/assets/images/NorthWestReal.png';
import TamarackPng from 'src/assets/images/Tamarack.png';
import { Alert, Button, ButtonSocialLogin, Color, Link } from 'src/components';
import { Checkbox, FormikInput } from 'src/components/FormFields';
import { BETA_DISABLED, DEFAULT_URL } from 'src/helpers/constants';
import { useCurrentRole } from 'src/helpers/hooks';
import classes from './Login.module.scss';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';

const Login = () => {
  const hideForgotPassword = true;
  const { t } = useTranslation(['login', 'common']);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required(t('validation.emailRequired', { ns: 'common' }))
      .email(t('validation.emailInvalid', { ns: 'common' })),
    password: Yup.string().required(
      t('validation.passwordRequired', { ns: 'common' })
    ),
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { login } = useActions();
  const { loginLoading, isLoggedIn } = useIndexData();
  const [loginErr, setLoginErr] = useState(false);
  const [socialError, setSocialError] = useState(false);
  const role = useCurrentRole();
  const permission = getPermission(role);

  const renderForm = () => {
    return (
      <Form
        className={classes.form}
        onChange={() => {
          setLoginErr(false);
        }}
      >
        {loginErr && (
          <div className={classes.errorMsg}>
            {t('login.error.invalidCredential')}
          </div>
        )}
        {socialError && (
          <Alert
            color="red"
            withCloseButton
            variant="filled"
            onClose={() => {
              setSocialError(false);
              navigate('/login');
            }}
            className={classes.alert}
          >
            {t('login.error.invalidMS')}
          </Alert>
        )}
        <div className={classes.fields}>
          <FormikInput
            name="email"
            type="email"
            label={t('formFields.email', { ns: 'common' })}
            placeholder={t('formFields.emailPlaceholder', { ns: 'common' })}
            forceError={loginErr}
          />
          <FormikInput
            name="password"
            type="password"
            label={t('formFields.password', { ns: 'common' })}
            placeholder={t('formFields.passwordPlaceholder', { ns: 'common' })}
            forceError={loginErr}
          />
        </div>
        <div className={classes.actions}>
          <div>
            <Checkbox label={t('login.remember30days')} />
          </div>
          {hideForgotPassword ? (
            <span></span>
          ) : (
            <Link onClick={() => {}}>{t('login.forgotPassword')}</Link>
          )}
        </div>
        <div className={classes.buttons}>
          <Button loading={loginLoading} type="submit">
            {t('buttons.login', { ns: 'common' })}
          </Button>
          <Color>or</Color>
          <ButtonSocialLogin social="microsoft" leftIcon={<MicrosoftIcon />}>
            {t('buttons.loginMS', { ns: 'common' })}
          </ButtonSocialLogin>
        </div>
      </Form>
    );
  };
  useEffect(() => {
    if ((location.search || '').includes('success=false')) {
      setSocialError(true);
    }
  }, [location.search]);

  if (isLoggedIn) {
    return (
      <Navigate
        to={BETA_DISABLED ? DEFAULT_URL : permission.defaultUrl}
        replace
      />
    );
  }

  return (
    <div
      className={cn(classes.wrapper, {
        [classes.error]: loginErr,
      })}
    >
      <div className={classes.heading}>
        <h1>{t('login.title')}</h1>
        <p>{t('login.subTitle')}</p>
      </div>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validateOnMount
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          login(values, (res) => {
            if (!res) setLoginErr(true);
          });
        }}
      >
        {renderForm}
      </Formik>
      <div className={classes.partners}>
        <a href="https://www.nwrecc.org/" target="_blank" rel="noreferrer">
          <img src={NWRealPng} alt="north-west-real" title="North West Real" />
        </a>
        <a
          href="https://www.nwrecc.org/tamarack-property-management-co/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={TamarackPng} alt="tamarack" title="Tamarack" />
        </a>
      </div>
    </div>
  );
};

export default Login;
