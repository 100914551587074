import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from 'src/components';
import { Checkbox, Input } from 'src/components/FormFields';
import DocumentModal from 'src/pages/Documents/components/DocumentModal';
import classes from './DropdownModal.module.scss';

type Checklist = {
  id: number;
  checked: boolean;
  value: string;
};
const DEFAULT_CHECKLIST = {
  id: 1,
  checked: true,
  value: '',
};

const DropdownModal = ({
  className = '',
  isOpened,
  onClose,
  onSubmit,
  defaultValue = null,
}) => {
  const [val, setVal] = useState('');
  const [checklist, setChecklist] = useState<Checklist[]>([
    {
      ...DEFAULT_CHECKLIST,
    },
  ]);
  const handleChecklistChange = (id: number, { value, checked }) => {
    setChecklist(
      checklist.map((c) => {
        if (c.id === id) {
          c.value = value;
          c.checked = checked;
        }
        return c;
      })
    );
  };
  const resetState = () => {
    setVal(defaultValue?.value || '');
    const tEChecklist = defaultValue?.checklist || [];
    setChecklist(
      tEChecklist.length > 0 ? tEChecklist : [{ ...DEFAULT_CHECKLIST }]
    );
  };

  useEffect(() => {
    if (isOpened) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <DocumentModal
      isOpened={isOpened}
      onClose={onClose}
      title={'Add Dropdown'}
      size={583}
      className={cn(classes.modalWrapper, className)}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <div className={classes.content}>
        <Input
          radius="sm"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          placeholder="Dropdown Title"
        />
        <p>Add the items that will appear in the dropdown</p>
        <div className={classes.checkistWrapper}>
          <ul>
            {checklist.map((c: Checklist) => {
              return (
                <li key={c.id}>
                  <Checkbox
                    checked={c.checked}
                    onChange={(e) => {
                      handleChecklistChange(c.id, {
                        value: c.value,
                        checked: e.target.checked,
                      });
                    }}
                  />
                  <Input
                    radius="sm"
                    size="sm"
                    value={c.value}
                    onChange={(e) => {
                      handleChecklistChange(c.id, {
                        value: e.target.value,
                        checked: c.checked,
                      });
                    }}
                    placeholder=""
                  />
                </li>
              );
            })}
          </ul>
          <Button
            radius="md"
            variant="default"
            textWeight="xl"
            type="button"
            onClick={() => {
              setChecklist([
                ...checklist,
                {
                  id: checklist?.[checklist.length - 1].id + 1,
                  value: '',
                  checked: true,
                },
              ]);
            }}
          >
            + Add
          </Button>
        </div>
        <Button
          radius="md"
          textSize="xl"
          fullWidth
          type="button"
          onClick={() => {
            if (val && !!val.trim()) {
              onSubmit(val, checklist);
            }
          }}
        >
          Apply
        </Button>
      </div>
    </DocumentModal>
  );
};

export default DropdownModal;
