import * as Yup from 'yup';
import { LOCATION_FIELD_SUFFIX } from 'src/helpers/constants';
import { setFileObjFormData } from 'src/utils/file';

export const getFileBR = (
  values,
  duplicateFileNames = [],
  confirmMethod = 'replace'
) => {
  const formData = new FormData() as any;
  const filesUpload = values?.files_upload || [];
  filesUpload.forEach((f, i) => {
    setFileObjFormData(f, formData, {
      index: i,
      confirmMethod,
      duplicateFileNames,
    });
  });
  // for (const pair of formData.entries()) {
  //   console.log(`${pair[0]}, ${pair[1]}`);
  // }
  return formData;
};
export const getFileCheckBR = (values) => {
  const filesUpload = values?.files_upload || [];
  return filesUpload
    .map((f) => {
      const thisLocationPath = f[`location${LOCATION_FIELD_SUFFIX}`] || '';
      const pathARr = thisLocationPath.split('/');
      return {
        name: f.file?.name,
        directory_id: Number(pathARr[pathARr.length - 1] || 0),
      };
    })
    .filter((f) => !!f.name && !!f.directory_id);
};

export const schema = Yup.object().shape({
  files_upload: Yup.array().of(
    Yup.object().shape({
      // formNumber: Yup.string().required('This field is required!'),
      location: Yup.mixed().required('This field is required!'),
      // property: Yup.mixed().required('This field is required!'),
      // permissions: Yup.mixed().required('This field is required!'),
      // tags: Yup.mixed().required('This field is required!'),
    })
  ),
});
