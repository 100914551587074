import find from 'lodash/find';
import { CalendarCardItem, CardList, ContentCard } from 'src/components';
import { Select } from 'src/components/FormFields';
import { DAYS } from 'src/helpers/constants';
import { TIME_OPTIONS } from 'src/helpers/constants/options';
import type { ScheduleType } from 'src/types/schedule';

interface Props {
  schedules: ScheduleType[];
  onScheduleChagne: (payload: any, type: 'active' | 'update') => void;
  loading: boolean;
}

const YourWorkSchedule = ({ schedules, onScheduleChagne, loading }: Props) => {
  return (
    <CardList
      singleHeader
      headers={[
        {
          key: 'property',
          label: 'Your Work Schedule',
        },
      ]}
      data={[
        {
          id: 1,
        },
      ]}
      renderContentCard={() => {
        return (
          <ContentCard type="calendar">
            <ul>
              <li>
                <div>Day</div>
                <div>In</div>
                <div>Out</div>
                <span></span>
              </li>
              {DAYS.map((day) => {
                const schedule = (find(schedules, { day }) || {}) as any;
                const { is_active } = schedule;
                return (
                  <CalendarCardItem
                    key={day}
                    active={is_active}
                    onClick={() => {
                      onScheduleChagne(
                        {
                          day,
                          is_active: !is_active,
                        },
                        'active'
                      );
                    }}
                  >
                    <div>{day}</div>
                    <div>
                      <Select
                        data={TIME_OPTIONS}
                        radius="md"
                        disabled={!is_active}
                        value={schedule.in}
                        onChange={(val) => {
                          onScheduleChagne(
                            {
                              day,
                              in: val,
                              out: schedule.out,
                            },
                            'update'
                          );
                        }}
                      />
                    </div>
                    <div>
                      <Select
                        data={TIME_OPTIONS}
                        value={schedule.out}
                        radius="md"
                        disabled={!is_active}
                        onChange={(val) => {
                          onScheduleChagne(
                            {
                              day,
                              in: schedule.in,
                              out: val,
                            },
                            'update'
                          );
                        }}
                      />
                    </div>
                  </CalendarCardItem>
                );
              })}
            </ul>
          </ContentCard>
        );
      }}
      loading={loading}
    />
  );
};

export default YourWorkSchedule;
