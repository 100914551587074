import cn from 'classnames';
import { useEffect, useState } from 'react';
import { ReactComponent as TrashSvg } from 'src/assets/icons/Trash.svg';
import { Icon, Modal } from 'src/components';
import classes from './FileModal.module.scss';

const FileItem = ({ data, onDeleteFile }) => {
  return (
    <div className={classes.fileItem}>
      <div className={classes.fileContent}>
        <span
          className={cn({
            [classes.hasFile]: !!data?.file,
          })}
          onClick={() => {
            if (data?.file) {
              const win = window.open(data.file, '_blank');
              win.focus();
            }
          }}
        >
          {data?.name}
        </span>
      </div>
      <Icon onClick={onDeleteFile} className={classes.deleteIcon}>
        <TrashSvg />
      </Icon>
    </div>
  );
};

const FileModal = ({
  className = '',
  opened,
  onClose,
  fileList,
  title,
  onDelete,
  isPreview = false,
}) => {
  const [previewFiles, setPreviewFiles] = useState([]);
  const handlePreviewDeleteFile = (tFile, tIndex) => {
    const newPreviewFiles = previewFiles.filter((_, index) => tIndex !== index);
    setPreviewFiles(newPreviewFiles);
    if (newPreviewFiles.length === 0) {
      onClose();
    }
  };

  useEffect(() => {
    if (isPreview) {
      setPreviewFiles(fileList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={title}
      className={cn(classes.modalWrapper, className)}
      centered
    >
      <div className={classes.fileList}>
        {((isPreview ? previewFiles : fileList) || []).map((fItem, index) => {
          return (
            <FileItem
              key={`${fItem.id}_${index}`}
              data={fItem}
              onDeleteFile={
                isPreview
                  ? () => {
                      onDelete(fItem)();
                      handlePreviewDeleteFile(fItem, index);
                    }
                  : onDelete(fItem)
              }
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default FileModal;
