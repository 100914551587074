import { Switch as ManSwitch, SwitchProps } from '@mantine/core';
import cn from 'classnames';
import classes from './Switch.module.scss';

export type OSwitchProps = SwitchProps & {
  className?: string;
};

const Switch = ({ className, ...other }: OSwitchProps) => {
  return <ManSwitch {...other} className={cn(classes.wrapper, className)} />;
};

export default Switch;
