import { Reducer } from 'redux';

import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/property';

const initialState = {
  allProperties: {
    ...defaultObj,
  },
  userProperty: {
    ...defaultArray,
  },
  propertyFilters: {
    ...defaultObj,
  },
  assignUserProperty: {
    ...defaultObj,
  },
  removeUserProperty: {
    ...defaultObj,
  },
};

const PropertyReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_ALL_PROPERTIES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allProperties: {
            ...prevState.allProperties,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allProperties: {
            loading: false,
            data: {
              ...payload,
              data: meta?.payload?.isMore
                ? [...(state.allProperties.data?.data || []), ...payload.data]
                : [...payload.data],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allProperties: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_USER_PROPERTY:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          userProperty: {
            ...prevState.userProperty,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          userProperty: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          userProperty: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_PROPERTY_FILTERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          propertyFilters: {
            ...prevState.propertyFilters,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          propertyFilters: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          propertyFilters: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.ASSIGN_USER_PROPERTY:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          assignUserProperty: {
            ...prevState.assignUserProperty,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          assignUserProperty: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          assignUserProperty: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.REMOVE_USER_PROPERTY:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          removeUserProperty: {
            ...prevState.removeUserProperty,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          removeUserProperty: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          removeUserProperty: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default PropertyReducer;
