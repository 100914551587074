import { Request } from './request';

export const apiGetDashboard = async (params = {}) => {
  return Request.call({
    url: '/dashboard',
    method: 'GET',
    params,
  });
};
export const apiReadAlert = async (id: number) => {
  return Request.call({
    url: `/alert/${id}`,
    method: 'GET',
  });
};
