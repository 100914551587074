import cn from 'classnames';
import get from 'lodash/get';
import Button from 'src/components/Button';
import { BCButton } from 'src/types/form';
import classes from './ButtonCheck.module.scss';

export type OButtonCheckProps = {
  className?: string;
  label?: string | React.ReactNode;
  required?: boolean;
  buttons: BCButton[];
  onChange?: (name: string) => void;
  values?: any;
};

const ButtonCheck = ({
  className,
  required,
  label,
  buttons,
  onChange,
  values,
}: OButtonCheckProps) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      {!!label && (
        <label>
          {label}
          {required && <span className={classes.requiredMark}>*</span>}
        </label>
      )}
      <div className={classes.buttons}>
        {buttons.map((btn: BCButton, index) => {
          return (
            <Button
              variant="default"
              key={index}
              onClick={
                btn.onClick
                  ? () => {
                      btn.onClick(btn.name, get(values, btn.name));
                    }
                  : () => {
                      if (onChange) onChange(btn.name);
                    }
              }
              className={cn(classes.btn, {
                [classes.checked]: btn.checked,
              })}
            >
              {btn.label}
            </Button>
          );
        })}
      </div>
    </div>
  );
};

export default ButtonCheck;
