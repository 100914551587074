import { Request } from './request';

export const apiGetRoles = async (params = {}) => {
  return Request.call({
    url: '/roles',
    method: 'GET',
    params,
  });
};
export const apiGetProperties = async (params = {}) => {
  return Request.call({
    url: '/property?units=true',
    method: 'GET',
    params,
  });
};
export const apiGetCertificationTypes = async (params = {}) => {
  return Request.call({
    url: '/certifications/types',
    method: 'GET',
    params,
  });
};
export const apiGetWorkflowTypes = async (params = {}) => {
  return Request.call({
    url: '/workflows/types',
    method: 'GET',
    params,
  });
};
export const apiGetWorkflowTypesBuilder = async (params = {}) => {
  return Request.call({
    url: '/workflow/builder/list_workflow_types',
    method: 'GET',
    params,
  });
};
export const apiGetCertificationStatuses = async (params = {}) => {
  return Request.call({
    url: '/certifications/statuses',
    method: 'GET',
    params,
  });
};
export const apiGetCertBuilderDropdown = async (key, params = {}) => {
  return Request.call({
    url: `/${key}/builder/dropdowns`,
    method: 'GET',
    params,
  });
};
export const apiGetTags = async (params = {}) => {
  return Request.call({
    url: '/tags',
    method: 'GET',
    params,
  });
};
export const apiGetTimezones = async (params = {}) => {
  return Request.call({
    url: '/timezones',
    method: 'GET',
    params,
  });
};
export const apiGetDepartments = async (params = {}) => {
  return Request.call({
    url: '/department',
    method: 'GET',
    params,
  });
};
export const apiCreateTag = async (data = {}) => {
  return Request.call({
    url: '/tags',
    method: 'POST',
    data,
  });
};
export const apiCreateDepartment = async (data = {}) => {
  return Request.call({
    url: '/department',
    method: 'POST',
    data,
  });
};
export const apiCreateStatus = async (data = {}) => {
  return Request.call({
    url: '/status',
    method: 'POST',
    data,
  });
};
export const apiCreateWorkflowType = async (data = {}) => {
  return Request.call({
    url: '/workflow/builder/workflow_type',
    method: 'POST',
    data,
  });
};
