import cn from 'classnames';
import React from 'react';
import classes from './VerticalList.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}
const VerticalList = ({ className, children }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <ul>{children}</ul>
    </div>
  );
};

export default VerticalList;
