import findIndex from 'lodash/findIndex';

export const makeOptions = (arr, valKey, labelKey) => {
  return arr.map((a) => ({
    value: a[valKey],
    label: a[labelKey],
  }));
};
export const filterOptions = (options, key: string, values: string[]) => {
  return (options || []).filter((option) => !values.includes(option[key]));
};
export const cleanUnAvailableOptions = (selectedOptions, options) => {
  return selectedOptions.filter((value) => findIndex(options, { value }) >= 0);
};
