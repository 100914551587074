import omit from 'lodash/omit';
import { Reducer } from 'redux';
import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/auth';

const initialState = {
  meMeta: {},
  meData: {
    ...defaultObj,
  },
  login: {
    ...defaultObj,
  },
  updateProfile: {
    ...defaultObj,
  },
};

const AuthReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_SET_TOKEN: {
      return {
        ...state,
        login: {
          ...state.login,
          data: {
            ...(state.login?.data || {}),
            token: payload,
          },
        },
      };
    }
    case Types.SYNC_SET_ME_DATA: {
      return {
        ...state,
        meData: {
          ...state.meData,
          data: payload.apart
            ? {
                ...state.meData.data,
                ...payload.data,
              }
            : {
                ...payload.data,
              },
        },
      };
    }
    case Types.GET_ME:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          meData: {
            ...prevState.meData,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          meData: {
            loading: false,
            data: payload.user,
            error: '',
          },
          meMeta: omit(payload, 'user'),
        }),
        failure: (prevState) => ({
          ...prevState,
          meData: {
            loading: false,
            data: {},
            error: payload,
          },
          login: {
            loading: false,
            data: {},
            error: '',
          },
        }),
      });
    case Types.LOGIN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          login: {
            ...prevState.login,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          login: {
            loading: false,
            data: payload,
            error: '',
          },
          meData: {
            ...prevState.meData,
            data: payload.user,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          login: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_PROFILE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateProfile: {
            ...prevState.updateProfile,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateProfile: {
            loading: false,
            data: payload,
            error: '',
          },
          meData: {
            ...prevState.meData,
            data: {
              ...prevState.meData.data,
              ...omit(payload, ['roles', 'schedules', 'work_schedule']),
            },
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateProfile: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default AuthReducer;
