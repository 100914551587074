import { Request } from './request';

export const apiGetDocuments = async (params = {}) => {
  return Request.call({
    url: '/documents',
    method: 'GET',
    params,
  });
};
export const apiGetDocumentDetail = async (id, params = {}) => {
  return Request.call({
    url: `/documents/${id}`,
    method: 'GET',
    params,
  });
};
export const apiGenrateDocumentUrl = async (id, params = {}) => {
  return Request.call({
    url: `/documents/${id}/get_url`,
    method: 'GET',
    params,
  });
};
export const apiCreateFolder = async (data = {}) => {
  return Request.call({
    url: `/documents/create_dir`,
    method: 'POST',
    data,
  });
};
export const apiUploadFiles = async (data = {}) => {
  return Request.call({
    url: `/documents/upload_files`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiUpdateDocument = async (id, data = {}) => {
  return Request.call({
    url: `/documents/${id}/update`,
    method: 'POST',
    data,
  });
};
export const apiDeleteDocument = async (id, data = {}) => {
  return Request.call({
    url: `/documents/${id}`,
    method: 'DELETE',
    data,
  });
};
export const apiCheckDuplicateFiles = async (data = {}) => {
  return Request.call({
    url: `/documents/check_duplicate_files`,
    method: 'POST',
    data,
  });
};
export const apiMoveDocument = async (data = {}) => {
  return Request.call({
    url: `/documents/move`,
    method: 'POST',
    data,
  });
};
export const apiDuplicateFolder = async (data = {}) => {
  return Request.call({
    url: `/documents/duplicate_folder`,
    method: 'POST',
    data,
  });
};
