import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { ReactComponent as AlertInOutSvg } from 'src/assets/icons/Alert/InOut.svg';
import { AlertModal, Button, Tabs } from 'src/components';
import { Dropdown, Input } from 'src/components/FormFields';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import {
  ACTIVE_INACTIVE_KEY,
  DEFAULT_SCHEDULE_TIME,
} from 'src/helpers/constants';
import {
  DEFAULT_ALL_OPTION,
  IN_OUT_STATUS_OPTIONS,
  NO_PROPERTY_OPTION,
} from 'src/helpers/constants/options';
import { useMedia, useOptions } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { notification } from 'src/utils/notification';
import { filterOptions } from 'src/utils/options';
import { applySearch } from 'src/utils/search';
import { getActiveStatus } from 'src/utils/user';
import classes from './InOutBoard.module.scss';
import UpdateStatusModal from './components/UpdateStatusModal';
import UsersList from './components/UsersList';
import UserListHeader from './components/UsersList/Header';
import YourStatus from './components/YourStatus';
import YourWorkSchedule from './components/YourWorkSchedule';
import { DEFAULT_SETTING, MAIN_TABS, SUB_TABS } from './constants';
import { useActions, useIndexData } from './selectorData';
import { mainTabs, subTabs } from './uiUtils';
import { getFilterParams, getStatusBR } from './utils';

type Setting = Record<string, string | string[] | null>;

const InOutBoard = () => {
  const isTablet = useMedia('(max-width: 768px)');
  const { propertyOptions, roleDropdownOptionsWithGroup, departmentOptions } =
    useOptions();
  const {
    getPMUsers,
    getOMUsers,
    updateUserStatus,
    getSchedules,
    activeSchedule,
    syncUpdateSchedule,
    updateSchedule,
    dismissWelcomePopup,
    syncUpdateInoutUser,
    editUserStatus,
    switchUserDetailActive,
  } = useActions();
  const {
    profile,
    updateUserStatusLoading,
    schedules,
    schedulesLoading,
    pmUsers,
    omUsers,
    profileMeta,
  } = useIndexData();
  const hideDepartmentFilter = false;
  const omUserKeys = Object.keys(omUsers);
  const pmUserKeys = Object.keys(pmUsers);
  const requirePopupAlert = find(profileMeta.pop_alerts || [], {
    url: '/in-out-board',
  });
  const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [updateStatusObj, setUpdateStatusObj] = useState({} as any);
  const [setting, setSetting] = useState(DEFAULT_SETTING as Setting);
  const [activeMainTab, setActiveMainTab] = useState(MAIN_TABS.COPORATE.KEY);
  const [activeSubTab, setActiveSubTab] = useState(SUB_TABS.BOARD.KEY);
  const handleSettingChange = (key, value, notFetch?: boolean) => {
    const newSetting: Setting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (!notFetch) {
      reloadInOut(newSetting.search, newSetting);
    }
  };
  const reloadInOut = (tSearch, tSetting?: Setting) => {
    const params = getFilterParams(tSearch, tSetting);
    params.status = 'active';
    getPMUsers(params);
    getOMUsers(params);
  };
  const reloadSchedules = () => {
    getSchedules();
  };
  const widths = ['33%', '37%', '30%'];
  const handleActiveInactiveUser = (
    tKey: string,
    tDepartmentKey: string,
    tUser: any
  ) => {
    const isActive = tUser.is_active;
    switchUserDetailActive(
      tUser.id,
      {
        is_active: !isActive,
      },
      actionCb(
        () => {
          syncUpdateInoutUser(tKey, tDepartmentKey, tUser.id, {
            is_active: !isActive,
          });
        },
        null,
        `Set ${isActive ? 'Inactive' : 'Active'} failed. Please try again!`
      )
    );
  };

  useEffect(() => {
    reloadSchedules();
    reloadInOut(setting.search, setting);
    setSetting({
      ...setting,
      status: profile.status,
      note: profile.note || '',
      activeStatus: getActiveStatus(profile.is_active),
    });
    if (requirePopupAlert?.id) {
      setShowAlert(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const newActiveStatus = getActiveStatus(profile.is_active);
    if (newActiveStatus !== setting.activeStatus) {
      setSetting({
        ...setting,
        status: profile.status,
        note: profile.note || '',
        activeStatus: newActiveStatus,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.is_active]);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'In & Out',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader title="In & Out Board" className={classes.pageHeader}>
          {!hideDepartmentFilter && (
            <Dropdown
              items={[
                DEFAULT_ALL_OPTION,
                ...departmentOptions.map((option) => ({
                  ...option,
                  value: String(option.value),
                })),
              ]}
              radius="md"
              value={setting.departments as string[]}
              onChange={(value) => {
                handleSettingChange('departments', value);
              }}
              className={classes.departmentDropdown}
              placeholder="All Departments"
            />
          )}
          <Dropdown
            items={[DEFAULT_ALL_OPTION, NO_PROPERTY_OPTION, ...propertyOptions]}
            value={setting.properties as string[]}
            onChange={(val) => {
              handleSettingChange('properties', val);
            }}
            placeholder={'All Properties'}
            className={classes.filterDropdown}
          />
          <Dropdown
            items={[DEFAULT_ALL_OPTION, ...IN_OUT_STATUS_OPTIONS]}
            value={setting.workStatus as string[]}
            onChange={(val) => {
              handleSettingChange('workStatus', val);
            }}
            placeholder={'All Statuses'}
            className={classes.filterDropdown}
          />
          <Dropdown
            items={[
              DEFAULT_ALL_OPTION,
              ...filterOptions(roleDropdownOptionsWithGroup, 'label', [
                'Guest',
              ]),
            ]}
            value={setting.roles as string[]}
            onChange={(val) => {
              handleSettingChange('roles', val);
            }}
            placeholder={'All Roles'}
            className={classes.filterDropdown}
          />
          <Input
            radius="md"
            value={setting.search}
            onChange={(e) => {
              const val = e.target.value;
              handleSettingChange('search', val, true);
              applySearch(val, (tVal) => {
                reloadInOut(tVal, setting);
              });
            }}
            placeholder="Search"
            isSearch
            className={classes.searchField}
            wrapperClassName={classes.searchFieldWrapper}
          />
        </PageHeader>
        {isTablet && (
          <Tabs
            value={activeSubTab}
            onTabChange={setActiveSubTab}
            items={subTabs}
          />
        )}
        {(!isTablet || (isTablet && activeSubTab === SUB_TABS.BOARD.KEY)) && (
          <div className={classes.mainTab}>
            <Tabs
              value={activeMainTab}
              onTabChange={setActiveMainTab}
              items={mainTabs}
            />
          </div>
        )}
        <PageContent className={classes.pageContent}>
          {(!isTablet || (isTablet && activeSubTab === SUB_TABS.BOARD.KEY)) && (
            <div>
              {activeMainTab === MAIN_TABS.COPORATE.KEY && (
                <>
                  <UserListHeader
                    className={classes.userListHeader}
                    widths={widths}
                    headers={[
                      {
                        key: 'property',
                        label: 'Corporate Management',
                        minWidth: '300px',
                      },
                      {
                        key: 'notes',
                        label: 'Notes',
                      },
                      {
                        key: 'lastUpdated',
                        label: 'Last Updated',
                      },
                    ]}
                    styles={[
                      {
                        marginRight: '24px',
                        marginLeft: '-24px',
                      },
                      {},
                      {
                        marginRight: '24px',
                      },
                    ]}
                  />
                  {omUserKeys.map((key: string) => {
                    const tUsers = omUsers[key] || [];
                    return (
                      <UsersList
                        key={key}
                        widths={widths}
                        users={tUsers}
                        headers={[
                          {
                            key,
                            label: key,
                            minWidth: '300px',
                          },
                        ]}
                        minWidths={['300px']}
                        actions={[
                          {
                            label: ACTIVE_INACTIVE_KEY,
                            onClick: (obj) => {
                              handleActiveInactiveUser('omUsers', key, obj);
                            },
                          },
                          {
                            label: 'Update Status',
                            onClick: (obj) => {
                              setUpdateStatusObj({
                                ...obj,
                                userKey: 'omUsers',
                                departmentKey: key,
                              });
                            },
                          },
                        ]}
                      />
                    );
                  })}
                </>
              )}
              {activeMainTab === MAIN_TABS.PROPERTY.KEY && (
                <>
                  <UserListHeader
                    className={classes.userListHeader}
                    widths={widths}
                    headers={[
                      {
                        key: 'property',
                        label: 'Property Management',
                        minWidth: '300px',
                      },
                      {
                        key: 'notes',
                        label: 'Notes',
                      },
                      {
                        key: 'lastUpdated',
                        label: 'Last Updated',
                      },
                    ]}
                    styles={[
                      {
                        marginRight: '24px',
                        marginLeft: '-24px',
                      },
                      {},
                      {
                        marginRight: '24px',
                      },
                    ]}
                  />
                  {pmUserKeys.map((key: string) => {
                    const tUsers = pmUsers[key] || [];
                    return (
                      <UsersList
                        key={key}
                        widths={widths}
                        users={tUsers}
                        hideHeader={pmUserKeys?.length === 1}
                        headers={[
                          {
                            key,
                            label: key,
                            minWidth: '300px',
                          },
                        ]}
                        minWidths={['300px']}
                        actions={[
                          {
                            label: ACTIVE_INACTIVE_KEY,
                            onClick: (obj) => {
                              handleActiveInactiveUser('pmUsers', key, obj);
                            },
                          },
                          {
                            label: 'Update Status',
                            onClick: (obj) => {
                              setUpdateStatusObj({
                                ...obj,
                                userKey: 'pmUsers',
                                departmentKey: key,
                              });
                            },
                          },
                        ]}
                      />
                    );
                  })}
                </>
              )}
            </div>
          )}
          {(!isTablet ||
            (isTablet && activeSubTab === SUB_TABS.STATUS.KEY)) && (
            <div>
              <YourStatus
                setting={setting}
                onChange={(key, val) => {
                  handleSettingChange(key, val, true);
                }}
                onUpdate={() => {
                  const payload = getStatusBR(setting);
                  updateUserStatus(payload);
                }}
                profile={profile}
                loading={updateUserStatusLoading}
              />
              <YourWorkSchedule
                schedules={schedules}
                onScheduleChagne={(payload, type) => {
                  if (type === 'update') {
                    syncUpdateSchedule(payload);
                    updateSchedule(payload);
                  } else {
                    syncUpdateSchedule({
                      ...payload,
                      in: DEFAULT_SCHEDULE_TIME.IN,
                      out: DEFAULT_SCHEDULE_TIME.OUT,
                    });
                    activeSchedule(payload);
                  }
                }}
                loading={schedulesLoading}
              />
            </div>
          )}
        </PageContent>
        <AlertModal
          isOpened={showAlert}
          onClose={() => {
            setShowAlert(false);
          }}
        >
          <AlertInOutSvg />
          <h2>Welcome to your In & Out Board!</h2>
          <p>
            Manage your schedule and stay up to date on teammates in one easy
            dashboard.
          </p>
          <div>
            <Button
              onClick={() => {
                dismissWelcomePopup({
                  id: requirePopupAlert?.id,
                });
                setShowAlert(false);
              }}
              radius="md"
              textSize="xl"
            >
              Start Exploring
            </Button>
          </div>
        </AlertModal>
      </MainContent>
      <UpdateStatusModal
        opened={!isEmpty(updateStatusObj)}
        onClose={() => {
          setUpdateStatusObj({});
        }}
        userObj={updateStatusObj}
        submitLoading={updateStatusLoading}
        onSubmit={(tValues) => {
          setUpdateStatusLoading(true);
          const tBr = {
            note: tValues.note || '',
            status: tValues.status || '',
            is_active: tValues.activeStatus === 'Active' ? 1 : 0,
          };
          editUserStatus(
            updateStatusObj.id,
            tBr,
            actionCb(
              (res) => {
                syncUpdateInoutUser(
                  updateStatusObj.userKey,
                  updateStatusObj.departmentKey,
                  updateStatusObj.id,
                  res
                );
                notification({
                  title: 'Update status successfull!',
                });
                setUpdateStatusObj({});
                setUpdateStatusLoading(false);
              },
              () => {
                setUpdateStatusLoading(false);
              },
              'Error while update.'
            )
          );
        }}
      />
    </>
  );
};

export default InOutBoard;
