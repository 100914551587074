import * as propertyActions from 'src/store/actions/property';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const allPropertiesStore = useReducerData('property', 'allProperties', {});
  const allPropertiesMeta = allPropertiesStore.data || {};
  return {
    allPropertiesMeta,
    allProperties: allPropertiesMeta.data || [],
    allPropertiesLoading: allPropertiesStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...propertyActions,
  });
};
