import cn from 'classnames';
import styled from 'styled-components';
import classes from './Table.module.scss';

const TableResult = ({
  count,
  className = '',
  text = '',
  mobileShow = false,
  mobileTop = undefined,
  marginBottom = undefined,
}) => {
  return (
    <StyledTableResult
      className={cn(
        classes.result,
        {
          [classes.mobileShow]: mobileShow,
        },
        className
      )}
      mtop={mobileTop}
      mbottom={marginBottom}
    >
      {count} {text || 'Result'}
      {count > 1 || count === 0 ? 's' : ''}
    </StyledTableResult>
  );
};

const StyledTableResult = styled.span`
  @media only screen and (max-width: 768px) {
    margin-top: ${({ mtop }) => (mtop !== undefined ? `${mtop}px` : undefined)};
    margin-bottom: ${({ mbottom }) =>
      mbottom !== undefined ? `${mbottom}px` : undefined};
  }
`;

export default TableResult;
