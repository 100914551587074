import cn from 'classnames';
import { FieldArray } from 'formik';
import { Button, FormCardArrayBlock } from 'src/components';
import NewReportSection from '../Section';

export const SectionFormArrayContent = ({
  values,
  parentName,
  renderChild,
  newObj,
  newBtnText,
  gridColumn,
  addHidden = false,
  isNoValue = false,
  onAddCb = null,
  onRemoveCb = null,
}) => {
  const fields = values[parentName] || [];
  return (
    <FieldArray name={parentName}>
      {({ remove, push }) => {
        return (
          <>
            {fields.map((_, index) => {
              return (
                <FormCardArrayBlock
                  key={index}
                  column={gridColumn}
                  onRemove={() => {
                    remove(index);
                    if (onRemoveCb) onRemoveCb();
                  }}
                  hideClose={fields.length <= 1}
                  wrapperClassName={cn({
                    'i-print-no-value': isNoValue,
                  })}
                >
                  {renderChild(index)}
                </FormCardArrayBlock>
              );
            })}
            <div
              className={cn({
                'visible-hidden': addHidden,
              })}
            >
              <Button
                radius="md"
                size="xs"
                variant="default"
                onClick={() => {
                  if (!addHidden) {
                    push(newObj);
                    if (onAddCb) onAddCb();
                  }
                }}
              >
                {newBtnText}
              </Button>
            </div>
          </>
        );
      }}
    </FieldArray>
  );
};

const SectionFormArray = ({
  values,
  title,
  parentName,
  renderChild,
  newObj,
  newBtnText,
  gridColumn,
  addHidden = false,
  onNoValue = () => false,
  onAddCb = null,
  onRemoveCb = null,
}) => {
  const contentProps = {
    values,
    parentName,
    renderChild,
    newObj,
    newBtnText,
    gridColumn,
    addHidden,
    onAddCb,
    onRemoveCb,
  };
  return (
    <NewReportSection title={title} onNoValue={onNoValue}>
      <SectionFormArrayContent {...contentProps} />
    </NewReportSection>
  );
};

export default SectionFormArray;
