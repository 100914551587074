import range from 'lodash/range';

const currentYear = new Date().getFullYear();
export const LIST_8_YEARS = range(8).map((i) => ({
  value: String(currentYear - i),
  label: String(currentYear - i),
}));
export const RESIDENT_STATUS_OPTIONS = [
  {
    value: 'current',
    label: 'Current',
  },
  {
    value: 'past',
    label: 'Past',
  },
];
export const NOTICE_STATUS_OPTIONS = [
  {
    value: 'Printed',
    label: 'Printed',
  },
  {
    value: 'Not Viewed',
    label: 'Not Viewed',
  },
];
export const CERTIFICATION_UNIT_OPTIONS = [
  {
    value: 'AD4',
    label: 'AD4',
  },
  {
    value: 'HD4',
    label: 'HD4',
  },
  {
    value: 'BD4',
    label: 'BD4',
  },
  {
    value: '4D4',
    label: '4D4',
  },
  {
    value: '5D4',
    label: '5D4',
  },
  {
    value: '6D4',
    label: '6D4',
  },
  {
    value: '7D4',
    label: '7D4',
  },
  {
    value: '8D4',
    label: '8D4',
  },
  {
    value: '9D4',
    label: '9D4',
  },
  {
    value: '7D5',
    label: '7D5',
  },
  {
    value: 'AD1',
    label: 'AD1',
  },
  {
    value: 'AD2',
    label: 'AD2',
  },
  {
    value: 'AD3',
    label: 'AD3',
  },
  {
    value: 'AD8',
    label: 'AD8',
  },
  {
    value: 'AD9',
    label: 'AD9',
  },
];
export const IN_OUT_FILTER_OPTIONS = [
  { value: 'Corporate', label: 'Corporate' },
  { value: 'NWRECC Properties', label: 'NWRECC Properties' },
  { value: 'TPMC Properties', label: 'TPMC Properties' },
];
export const IN_OUT_STATUS_OPTIONS = [
  { value: 'In office', label: 'In office' },
  { value: 'Working remote', label: 'Working remote' },
  { value: 'In a meeting', label: 'In a meeting' },
  { value: 'Out sick', label: 'Out sick' },
  { value: 'PTO', label: 'PTO' },
  { value: 'Out of office', label: 'Out of office' },
];
export const REPORT_STATUS_VALUES = {
  IN_PROGRESS: 'In Progress',
  SUBMIT_FOR_REVIEW: 'Submitted for Review',
  CALIM_FILED: 'Claim Filed',
  PENDING_INFO: 'Pending Info',
  CLOSED: 'Closed',
  CLAIM_CLOSED: 'Claim Closed',
};
export const REPORT_STATUS_COLOR = {
  [REPORT_STATUS_VALUES.IN_PROGRESS]: 'green',
  [REPORT_STATUS_VALUES.CLOSED]: 'gray',
  [REPORT_STATUS_VALUES.CLAIM_CLOSED]: 'gray',
  [REPORT_STATUS_VALUES.SUBMIT_FOR_REVIEW]: 'yellow',
  [REPORT_STATUS_VALUES.CALIM_FILED]: 'yellow',
  [REPORT_STATUS_VALUES.PENDING_INFO]: 'yellow',
};
export const OPTION_ALL_VALUE = 'all';
export const NO_PROPERTY_VALUE = 'no-property-option-value';
export const NA_VALUE = 'n/a-option-value';
export const NOT_STARTED_LABEL = 'Not Started';
export const STATUS_OPTIONS = [
  {
    value: OPTION_ALL_VALUE,
    label: 'All Status',
  },
  {
    value: 'active',
    label: 'Active',
  },
  {
    value: 'deactivated',
    label: 'Deactivated',
  },
];
export const ORGANIZATION_OPTIONS_TRIM = [
  {
    value: 'CCA',
    label: 'CCA',
  },
  {
    value: 'FMC',
    label: 'FMC',
  },
  {
    value: 'NWRECC',
    label: 'NWRECC',
  },
  {
    value: 'TPMC',
    label: 'TPMC',
  },
  {
    value: 'Guest',
    label: 'Guest',
  },
];
export const ORGANIZATION_OPTIONS = [
  {
    value: OPTION_ALL_VALUE,
    label: 'All Organizations',
  },
  ...ORGANIZATION_OPTIONS_TRIM,
];
export const DEFAULT_ROLE_OPTIONS = {
  value: OPTION_ALL_VALUE,
  label: 'All User Roles',
};
export const DEFAULT_ALL_OPTION = {
  value: OPTION_ALL_VALUE,
  label: 'Select All',
};
export const NO_PROPERTY_OPTION = {
  value: NO_PROPERTY_VALUE,
  label: 'No Property',
};
export const NA_OPTION = {
  value: NA_VALUE,
  label: 'N/A',
};
export const LANGUAGE_OPTIONS = [
  { value: 'english', label: 'English' },
  { value: 'spanish', label: 'Spanish' },
];
export const TIMEZONE_OPTIONS = [
  {
    value: 'America/Chicago',
    label: 'Chicago (GMT-6)',
  },
  {
    value: 'America/Denver',
    label: 'Denver (GMT-7)',
  },
  {
    value: 'America/Phoenix',
    label: 'Phoenix (GMT-7)',
  },
  {
    value: 'Los Angeles (GMT-8)',
    label: 'Los Angeles (GMT-8)',
    momentvalue: 'America/Los_Angeles',
  },
  {
    value: 'America/Anchorage',
    label: 'Anchorage (GMT-9)',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'Honolulu (GMT-10)',
  },
];
export const REPORT_TYPE_OPTIONS = [
  {
    value: 'Property',
    label: 'Property',
  },
  {
    value: 'Liability',
    label: 'Liability',
  },
  {
    value: 'Employee Injury',
    label: 'Employee Injury',
  },
  {
    value: 'Informational',
    label: 'Informational',
  },
];
export const REPORT_STATUS_OPTIONS = [
  {
    value: REPORT_STATUS_VALUES.IN_PROGRESS,
    label: REPORT_STATUS_VALUES.IN_PROGRESS,
  },
  {
    value: REPORT_STATUS_VALUES.SUBMIT_FOR_REVIEW,
    label: REPORT_STATUS_VALUES.SUBMIT_FOR_REVIEW,
  },
  {
    value: REPORT_STATUS_VALUES.CALIM_FILED,
    label: REPORT_STATUS_VALUES.CALIM_FILED,
  },
  {
    value: REPORT_STATUS_VALUES.PENDING_INFO,
    label: REPORT_STATUS_VALUES.PENDING_INFO,
  },
  {
    value: REPORT_STATUS_VALUES.CLOSED,
    label: REPORT_STATUS_VALUES.CLOSED,
  },
  {
    value: REPORT_STATUS_VALUES.CLAIM_CLOSED,
    label: REPORT_STATUS_VALUES.CLAIM_CLOSED,
  },
];
export const REPORT_ROLE_OPTIONS = [
  {
    value: 'Resident',
    label: 'Resident',
  },
  {
    value: 'Employee',
    label: 'Employee',
  },
  {
    value: 'Guest',
    label: 'Guest',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
export const NOTICE_OPTIONS = [
  {
    value: '120',
    label: '120 Day',
  },
  {
    value: '90',
    label: '90 Day',
  },
  {
    value: '60',
    label: '60 Day',
  },
  {
    value: '30',
    label: '30 Day',
  },
];
export const WEATHER_CONDITION_OPTIONS = [
  {
    value: 'Sunny',
    label: 'Sunny',
  },
  {
    value: 'Cloudy',
    label: 'Cloudy',
  },
  {
    value: 'Rainy',
    label: 'Rainy',
  },
  {
    value: 'Thunderstorm',
    label: 'Thunderstorm',
  },
  {
    value: 'Windy',
    label: 'Windy',
  },
  {
    value: 'Foggy',
    label: 'Foggy',
  },
  {
    value: 'Snow',
    label: 'Snow',
  },
  {
    value: 'Ice',
    label: 'Ice',
  },
];
export const NOON_OPTIONS = [
  {
    value: 'am',
    label: 'AM',
  },
  {
    value: 'pm',
    label: 'PM',
  },
];
export const DUE_BY_VALUES = {
  START_DATE: 'Based off of Workflow Start Date',
  SPECIFIC_DATE: 'Based off of Specific Date',
};
export const DUE_BY_OPTIONS = [
  {
    value: DUE_BY_VALUES.START_DATE,
    label: DUE_BY_VALUES.START_DATE,
  },
  {
    value: DUE_BY_VALUES.SPECIFIC_DATE,
    label: DUE_BY_VALUES.SPECIFIC_DATE,
  },
];
export const FREQUENCY_VALUES = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly',
  NA: 'NA',
};
export const FREQUENCY_OPTIONS = [
  {
    value: FREQUENCY_VALUES.DAILY,
    label: FREQUENCY_VALUES.DAILY,
  },
  {
    value: FREQUENCY_VALUES.WEEKLY,
    label: FREQUENCY_VALUES.WEEKLY,
  },
  {
    value: FREQUENCY_VALUES.MONTHLY,
    label: FREQUENCY_VALUES.MONTHLY,
  },
  {
    value: FREQUENCY_VALUES.YEARLY,
    label: FREQUENCY_VALUES.YEARLY,
  },
  {
    value: FREQUENCY_VALUES.NA,
    label: 'N/A',
  },
];
const TIME_OPTIONS = [];
range(24).map((i) => {
  const t = i > 12 ? i - 12 : i;
  const isPM = i >= 12;
  const aText = isPM ? 'PM' : 'AM';
  const tHour = t === 0 ? 12 : t;
  TIME_OPTIONS.push({
    value: `${tHour}:00 ${aText}`,
    label: `${tHour}:00 ${aText}`,
  });
  TIME_OPTIONS.push({
    value: `${tHour}:30 ${aText}`,
    label: `${tHour}:30 ${aText}`,
  });
  return true;
});

export { TIME_OPTIONS };
