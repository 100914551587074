import cn from 'classnames';
import dayjs from 'dayjs';
import { Card, FormCardContent } from 'src/components';
import {
  DatePicker,
  FormikDropdown,
  FormikInput,
  FormikSelect,
} from 'src/components/FormFields';
import {
  DEFAULT_ALL_OPTION,
  DUE_BY_OPTIONS,
  DUE_BY_VALUES,
  FREQUENCY_OPTIONS,
  FREQUENCY_VALUES,
} from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import classes from './FlowInformation.module.scss';

const selectedDateStyle = {
  backgroundColor: '#5F98A9',
  color: 'white',
};

const FlowInformation = ({ className = '', formProps, isWorkflow = false }) => {
  const { values, setFieldValue, errors, touched } = formProps;
  const {
    certificationTypeOptions,
    certificationProgramOptions,
    certificationPermissionOptions,
    certificationLocationOptions,
    workflowTypesBuilderOptions,
  } = useOptions();

  const locationDropdown = (
    <FormikDropdown
      items={[DEFAULT_ALL_OPTION, ...certificationLocationOptions]}
      placeholder={'Select'}
      label="Location"
      name={`locations`}
      displaySelectedAll
      placeholderGray
      errorOverlay
      required
      radius="sm"
      hasNormalSub
      hasAll
    />
  );
  const permissionDropdown = (
    <FormikDropdown
      items={[DEFAULT_ALL_OPTION, ...certificationPermissionOptions]}
      placeholder={'Select'}
      label="Permissions"
      name={`permissions`}
      displaySelectedAll
      placeholderGray
      errorOverlay
      required
      radius="sm"
    />
  );
  const nameField = (
    <FormikInput
      name="name_of_certification"
      label={`Name of ${isWorkflow ? 'Workflow' : 'Certification'}`}
      placeholder={`Name of ${isWorkflow ? 'Workflow' : 'Certification'}`}
      type="text"
      errorOverlay
      required
    />
  );
  return (
    <Card
      className={cn(classes.wrapper, className)}
      contentGap={16}
      notPaddingContentTop
      contentPaddingBottom={0}
      title="Flow Information"
      overflowInit
    >
      {isWorkflow ? (
        <div className={classes.workflowNameTypeWrapper}>
          {nameField}
          <FormikDropdown
            items={workflowTypesBuilderOptions.map((obj) => ({
              value: obj.label,
              label: obj.label,
            }))}
            placeholder={'Select or Create an Option'}
            label="Type"
            name={`workflow_type`}
            placeholderGray
            errorOverlay
            required
            radius="sm"
            newEntity="workflow_type"
            newFullWidth
            newEntityObj={{
              placeholder: 'New Type',
              button: 'Add Type',
            }}
            isSingleSelected
            infoTooltip={
              <>
                This Type field can be utilized to place additional information
                onto the
                <br />
                table view for this specific Workflow.
              </>
            }
          />
        </div>
      ) : (
        <FormCardContent column={1}>{nameField}</FormCardContent>
      )}

      <FormCardContent column={3}>
        {isWorkflow ? (
          <>
            {permissionDropdown}
            {locationDropdown}
            <FormikSelect
              data={DUE_BY_OPTIONS}
              name="due_by"
              label="Due By"
              placeholder="Select an Option"
              maxDropdownHeight={300}
              errorOverlay
              required
            />
            {!!values.due_by && (
              <>
                {values.due_by === DUE_BY_VALUES.START_DATE ? (
                  <FormikInput
                    name="due_in_how_many_days"
                    label="Due in how many days after start date?"
                    placeholder="Input Number"
                    type="number"
                    errorOverlay
                    required
                  />
                ) : (
                  <>
                    <FormikSelect
                      data={FREQUENCY_OPTIONS}
                      name="frequency"
                      label="Frequency"
                      placeholder="Select an Frequency"
                      maxDropdownHeight={300}
                      errorOverlay
                      required
                    />
                    <DatePicker
                      placeholder="Select a Date"
                      label="Recurring Date"
                      name="recurring_date"
                      value={values.recurring_date}
                      error={
                        touched.recurring_date && !!errors.recurring_date
                          ? errors.recurring_date
                          : ''
                      }
                      required
                      onChange={(tDate) => {
                        setFieldValue('recurring_date', tDate);
                      }}
                      dayStyle={(date: Date) => {
                        const selectedDate = values.recurring_date
                          ? dayjs(values.recurring_date)
                          : '';
                        if (
                          !!selectedDate &&
                          !!values.frequency &&
                          dayjs(date).isAfter(selectedDate)
                        ) {
                          const selectedDateName = selectedDate.format('dddd');
                          const selectedDateNumber = selectedDate.format('DD');
                          const selectedMonthNumber = selectedDate.format('MM');
                          const isMatchedDaily =
                            values.frequency === FREQUENCY_VALUES.DAILY;
                          const isMatchedWeekly =
                            values.frequency === FREQUENCY_VALUES.WEEKLY &&
                            selectedDateName === dayjs(date).format('dddd');
                          const isMatchedMonthly =
                            values.frequency === FREQUENCY_VALUES.MONTHLY &&
                            selectedDateNumber === dayjs(date).format('DD');
                          const isMatchedYearly =
                            values.frequency === FREQUENCY_VALUES.YEARLY &&
                            selectedDateNumber === dayjs(date).format('DD') &&
                            selectedMonthNumber === dayjs(date).format('MM');
                          if (
                            isMatchedDaily ||
                            isMatchedWeekly ||
                            isMatchedMonthly ||
                            isMatchedYearly
                          ) {
                            return selectedDateStyle;
                          }
                        }
                        return null;
                      }}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <FormikDropdown
              items={[DEFAULT_ALL_OPTION, ...certificationTypeOptions]}
              placeholder={'Select'}
              label="Certification Type"
              name={`certification_type`}
              displaySelectedAll
              placeholderGray
              errorOverlay
              required
              radius="sm"
            />
            <FormikDropdown
              items={[DEFAULT_ALL_OPTION, ...certificationProgramOptions]}
              placeholder={'Select'}
              label="Program Type"
              name={`program_type`}
              displaySelectedAll
              placeholderGray
              errorOverlay
              required
              radius="sm"
            />
            {locationDropdown}
            {permissionDropdown}
          </>
        )}
      </FormCardContent>
    </Card>
  );
};

export default FlowInformation;
