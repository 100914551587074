import cn from 'classnames';
import { Card } from 'src/components';
import { FakeInput, FormikInput } from 'src/components/FormFields';
import classes from './ChangePassword.module.scss';

interface Props {
  className?: string;
}

const ChangePassword = ({ className }: Props) => {
  return (
    <Card
      title="Change My Password"
      className={cn(classes.wrapper, className)}
      gapSize="md"
      mainContentHasForm
    >
      <FakeInput />
      <FormikInput
        name="currentPassword"
        label="Current Password"
        placeholder="Current Password"
        type="password"
        autoComplete="password"
      />
      <FormikInput
        name="newPassword"
        label="New Password"
        placeholder="New Password"
        type="password"
        autoComplete="new-password"
      />
      <FormikInput
        name="confirmPassword"
        label="Confirm Password"
        placeholder="Confirm Password"
        type="password"
        errorOverlay
        errorBg
        autoComplete="confirm-password"
      />
    </Card>
  );
};

export default ChangePassword;
