import cn from 'classnames';
import classes from './Line.module.scss';

interface Props {
  className?: string;
}

const Line = ({ className }: Props) => {
  return <div className={cn(classes.wrapper, className)}></div>;
};

export default Line;
