import { REPORT_STATUS_VALUES } from 'src/helpers/constants/options';
import { NewReportPermission } from 'src/types/permissions';

export const otherPermission: NewReportPermission = {
  editClosedReport: false,
  availableStatuses: [
    REPORT_STATUS_VALUES.IN_PROGRESS,
    REPORT_STATUS_VALUES.SUBMIT_FOR_REVIEW,
    REPORT_STATUS_VALUES.CLOSED,
  ],
};
