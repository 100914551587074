import {
  ColorScheme,
  ColorSchemeProvider,
  MantineProvider,
} from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { ModalsProvider } from '@mantine/modals';
import { NotificationsProvider } from '@mantine/notifications';
import get from 'lodash/get';
import moment from 'moment-timezone';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router';
import { Navigate } from 'react-router-dom';
import { Button, LoadingOverlay } from 'src/components';
import {
  BETA_DISABLED,
  CANT_BUILD_CERTIFICATE_ROLES,
  LANGUAGES_MAPPING,
} from 'src/helpers/constants';
import { useCollapse } from 'src/helpers/hooks';
import socket from 'src/helpers/socket';
import { getMe } from 'src/store/actions/auth';
import { getPropertyFilters } from 'src/store/actions/property';
import {
  getAllFolders,
  getAllUsers,
  getCertBuilderDropdown,
  getCertificationTypes,
  getDepartments,
  getProperties,
  getRoles,
  getSupervisors,
  getTags,
  getTimezones,
  getWorkflowTypes,
  getWorkflowTypesBuilder,
} from 'src/store/actions/shared';
import { useAppDispatch, useReducerData } from 'src/store/hooks';
import classes from './App.module.scss';
import GlobalStyle from './GlobalStyle';
import AuthLayout from './layout/Auth';
import MainLayout from './layout/Main';
import PublicLayout from './layout/Public';
import { RouteOtherSettings, routes } from './routes';
import themeCustom from './theme/themeCustom';

let calledMe = 0;

const Loader = () => {
  return <LoadingOverlay visible />;
};

const ContextModal = ({ context, id, innerProps }) => (
  <>
    <div>{innerProps.modalBody}</div>
    <Button fullWidth mt="md" onClick={() => context.closeModal(id)}>
      Close
    </Button>
  </>
);
const modals = {
  contextModal: ContextModal,
  /* ...other modals */
};
declare module '@mantine/modals' {
  export interface MantineModalsOverride {
    modals: typeof modals;
  }
}

const App = () => {
  const { i18n } = useTranslation();
  const { setCollapse } = useCollapse();
  const dispatch = useAppDispatch();
  const [callMeLoading, setCallMeLoading] = useState(false);
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });
  const toggleColorScheme = (value: ColorScheme) => {
    setColorScheme(value || colorScheme === 'dark' ? 'light' : 'dark');
  };
  const authToken = useReducerData('auth', 'login.data.token', '');
  const isLoggedIn = !!authToken;
  const loadMetadata = () => {
    setCollapse(false);
    dispatch(getRoles());
    dispatch(getSupervisors());
    dispatch(getAllUsers({ exclude_roles: ['Guest'] }));
    dispatch(getProperties());
    dispatch(getTags());
    dispatch(getDepartments());
    dispatch(getAllFolders());
    dispatch(getPropertyFilters());
    if (!BETA_DISABLED) {
      dispatch(getCertificationTypes());
      dispatch(getWorkflowTypes());
      dispatch(getWorkflowTypesBuilder());
    }
  };
  const loadMetadata1 = (tRole) => {
    if (!CANT_BUILD_CERTIFICATE_ROLES.includes(tRole) && !BETA_DISABLED) {
      dispatch(getCertBuilderDropdown('certificate'));
    }
  };
  useEffect(() => {
    calledMe = 0;
    dispatch(getTimezones());
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const renderRoutes = () => {
    const renderRoute = (
      Component: React.FC,
      layout: string,
      otherSettings?: RouteOtherSettings
    ) => {
      if (Component) {
        switch (layout) {
          case 'private': {
            if (isLoggedIn) {
              return (
                <MainLayout {...otherSettings}>
                  <Component />
                </MainLayout>
              );
            }
            return <Navigate to="/login" replace />;
          }
          case 'auth': {
            return (
              <AuthLayout {...otherSettings}>
                <Component />
              </AuthLayout>
            );
          }
          default:
            return (
              <PublicLayout {...otherSettings}>
                <Component />
              </PublicLayout>
            );
        }
      }
      return null;
    };

    return routes.map(({ name, path, component, layout, ...rest }) => (
      <Route
        key={name}
        path={path}
        element={renderRoute(component, layout, rest)}
      />
    ));
  };

  if (isLoggedIn && calledMe === 0 && !callMeLoading) {
    setCallMeLoading(true);
    dispatch(
      getMe((meData) => {
        const tUserRole = get(meData, 'user.roles[0].name', '');
        loadMetadata1(tUserRole);
        socket.start(meData.user, authToken);
        const tLanguage = meData?.user?.language;
        const tTimezone = meData?.user?.timezone;
        if (LANGUAGES_MAPPING[tLanguage]) {
          i18n.changeLanguage(LANGUAGES_MAPPING[tLanguage]);
        }
        if (tTimezone) {
          moment.tz.setDefault(tTimezone);
        }
        calledMe = 1;
        setCallMeLoading(false);
      })
    );
    loadMetadata();
  }
  if (callMeLoading) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <ColorSchemeProvider
        colorScheme={colorScheme}
        toggleColorScheme={toggleColorScheme}
      >
        <MantineProvider
          withGlobalStyles
          withNormalizeCSS
          theme={{
            colorScheme: colorScheme,
            ...(themeCustom as any),
          }}
        >
          <ModalsProvider modals={modals}>
            <NotificationsProvider autoClose={5000} position="top-right">
              <GlobalStyle />
              <div className={classes.app}>
                <Routes>{renderRoutes()}</Routes>
              </div>
            </NotificationsProvider>
          </ModalsProvider>
        </MantineProvider>
      </ColorSchemeProvider>
    </Suspense>
  );
};

export default App;
