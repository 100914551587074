import { Button, Menu as ManMenu, MenuProps } from '@mantine/core';
import cn from 'classnames';
import React from 'react';
import { MenuItem } from 'src/types/Item';
import classes from './Menu.module.scss';

export type OMenuProps = MenuProps & {
  className?: string;
  items: MenuItem[];
  menuStyles?: 'profile' | 'actionDropdown';
  onClick?: (e) => void;
  isEmpty?: boolean;
};

const Menu = ({
  className = '',
  children,
  items,
  menuStyles,
  onClick,
  isEmpty,
  ...other
}: OMenuProps) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        classes[`dropdownStyle_${menuStyles}`],
        {
          [classes.isEmpty]: isEmpty,
        },
        className
      )}
      onClick={onClick}
    >
      <ManMenu {...other}>
        <ManMenu.Target>
          <Button className={classes.button}>{children}</Button>
        </ManMenu.Target>
        <ManMenu.Dropdown
          className={cn(classes.dropdown, classes[`dropdown_${menuStyles}`], {
            [classes.isEmpty]: isEmpty,
          })}
        >
          {items.map((item: MenuItem, i: number) => {
            return (
              <ManMenu.Item
                key={i}
                onClick={item.onClick}
                className={cn({
                  'is-red': item.isRed,
                })}
              >
                {item.label}
              </ManMenu.Item>
            );
          })}
        </ManMenu.Dropdown>
      </ManMenu>
    </div>
  );
};

export default Menu;
