import { NA_VALUE, OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import { cleanPayloadValue } from 'src/utils/form';

export const getInitialValues = () => {
  return {
    schema_id: '',
    property_id: '',
    property_unit_id: '',
  };
};
export const getWorkflowBR = (values) => {
  const returnObj = {} as any;
  Object.keys(values).map((key) => {
    const parseKey = '';
    returnObj[key] = cleanPayloadValue(values[key], parseKey);
    if (key === 'property_unit_id' && returnObj?.[key]?.length > 0) {
      returnObj[key] = returnObj[key].filter(
        (v) => v !== OPTION_ALL_VALUE && v !== NA_VALUE
      );
    }
    return true;
  });
  return returnObj;
};
