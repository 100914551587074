import { createTypes } from './createTypes';

export default createTypes(
  'GET_WORKFLOW_PENDING',
  'ADD_WORKFLOW',
  'GET_WORKFLOW_BUILDER_LIST',
  'GET_WORKFLOW_BUILDER_DETAIL',
  'DELETE_WORKFLOW_BUILDER',
  'GET_WORKFLOWS'
);
