import cn from 'classnames';
import classes from './Card.module.scss';

const CardTitle = ({
  title,
  required = false,
  contentRight = null,
  className = '',
}) => {
  return (
    <div
      className={cn(classes.titleWrapper, 'i-card-title-wrapper', className)}
    >
      <div className={classes.title}>
        {title}
        {required && (
          <span className={cn(classes.required, 'i-card-required')}>*</span>
        )}
      </div>
      {!!contentRight && (
        <div className={classes.contentRight}>{contentRight}</div>
      )}
    </div>
  );
};

export default CardTitle;
