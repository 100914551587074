import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_SET_REPORT_DETAIL',
  'GET_REPORTS',
  'GET_REPORT_DETAIL',
  'ADD_UPDATE_REPORT',
  'UPLOAD_REPORT_FILES',
  'DELETE_REPORT_FILES'
);
