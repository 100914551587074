import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { PATTERN } from 'src/helpers/constants';
import { TIME_ZONE_PARSER } from 'src/helpers/constants/timezone';

export const getMomentDate = (val) => {
  if (!val) return '';
  return moment(val);
};
export const getBEDate = (val) => {
  if (!val) return '';
  return moment(val).format('YYYY-MM-DD');
};
export const formatBEDate = (val, isSecondFormat = false) => {
  if (!val) return '';
  const valArr = val.split('/');
  return `${valArr[2]}-${valArr[isSecondFormat ? 0 : 1]}-${
    valArr[isSecondFormat ? 1 : 0]
  }`;
};
export const getMomentDateFormat = (val, formatStr) => {
  if (!val || !formatStr) return '';
  return moment(val).format(formatStr);
};
export const isValidTime = (val) => {
  if (!val) return false;
  return PATTERN.TIME.test(val);
};
export const isValidDate = (val) => {
  if (!val) return false;
  return moment(val).isValid();
};
export const getMomentZeroTime = (val) => {
  if (!val) return '';
  return val.clone().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
};
const getDateCompare = (val, key = '') => {
  if (!val) return '';
  if (!key) return val.format('DD/MM/YYYY');
  if (key === 'day') return parseInt(val.format('DD'), 10);
  if (key === 'month') return parseInt(val.format('MM'), 10);
  if (key === 'year') return parseInt(val.format('YYYY'), 10);
};
export const getStatusUpdateDate = (val) => {
  if (!val) return '';
  const todayMoment = moment();
  const thisDayMoment = getMomentDate(val);
  const isToday = getDateCompare(todayMoment) === getDateCompare(thisDayMoment);
  const isYesterday =
    getDateCompare(todayMoment, 'year') ===
      getDateCompare(thisDayMoment, 'year') &&
    getDateCompare(todayMoment, 'month') ===
      getDateCompare(thisDayMoment, 'month') &&
    getDateCompare(todayMoment, 'day') -
      getDateCompare(thisDayMoment, 'day') ===
      1;
  const returnVal = getMomentDateFormat(
    val,
    `${isToday ? '[Today]' : isYesterday ? '[Yesterday]' : 'ddd'} [at] hh:mm A`
  );

  return returnVal;
};
export const getDPDate = (val, isSort = false) => {
  return getMomentDateFormat(val, isSort ? 'M/D/YY' : 'MM/DD/YYYY');
};
export const getTPTime = (val) => {
  if (!val || typeof val !== 'string') return '';
  const valArr = val.split(':');
  return `${valArr[0]}:${valArr[1]}`;
};
export const getCurrentTimezone = () => {
  const tTimeZone = momentTimezone.tz.guess();
  return TIME_ZONE_PARSER[tTimeZone] || tTimeZone;
};
