import cn from 'classnames';
import { FieldArray } from 'formik';
import find from 'lodash/find';
import { useRef } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import { Button, Card } from 'src/components';
import { DND_TYPE } from 'src/helpers/constants/dnd';
import { getNewCertificationSection } from 'src/utils/certification';
import { reorder } from 'src/utils/dnd';
import SectionList from '../SectionList';
import classes from './FlowBuilder.module.scss';

interface Props {
  className?: string;
  formProps: any;
  onDeleteExistingSection: (id: number, cb?: any) => void;
}

const FlowBuilder = ({
  className,
  formProps,
  onDeleteExistingSection,
}: Props) => {
  const subHelperRef = useRef();
  const { values, touched, errors, setFieldValue } = formProps;
  const flowBuilder = values.flowBuilder;
  const hasNotice =
    flowBuilder.children.filter((s) => s.sectionType === 'notice').length > 0;

  const onDragEnd = (parentHelper) => (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    // DnD first section
    if (
      result.type === DND_TYPE.FIRST &&
      (!hasNotice || (result.destination.index !== 0 && hasNotice))
    ) {
      parentHelper.move(result.source.index, result.destination.index);
      return;
    }
    // DnD sub section
    if (result.type === DND_TYPE.SECOND) {
      if (result.source.droppableId === result.destination.droppableId) {
        const thisSubItems =
          find(flowBuilder.children, { id: result.source.droppableId })
            ?.children || [];
        const newSubItems = reorder(
          thisSubItems,
          result.source.index,
          result.destination.index
        );
        setFieldValue('flowBuilder', {
          ...flowBuilder,
          children: (flowBuilder.children || []).map((child) => {
            if (child.id === result.source.droppableId) {
              child.children = newSubItems;
            }
            return child;
          }),
        });
      }
    }
  };
  return (
    <Card
      className={cn(classes.wrapper, className)}
      contentGap={16}
      notPaddingContentTop
      contentPaddingBottom={0}
      title="Flow Builder"
      error={
        touched.flowBuilder?.children &&
        typeof errors.flowBuilder?.children === 'string'
          ? errors.flowBuilder?.children
          : ''
      }
    >
      <FieldArray name="flowBuilder.children">
        {(parentHelper) => {
          return (
            <>
              <div className={classes.buttons}>
                {!hasNotice && (
                  <Button
                    variant={'default'}
                    onClick={() => {
                      parentHelper.insert(
                        0,
                        getNewCertificationSection(
                          flowBuilder.children.length,
                          'notice'
                        )
                      );
                    }}
                    radius="md"
                    textSize="md"
                    size="xs"
                    leftIcon={<PlusSvg />}
                    className={classes.addBtn}
                  >
                    Add Notices Section
                  </Button>
                )}
                <Button
                  variant={'default'}
                  onClick={() => {
                    parentHelper.push(
                      getNewCertificationSection(
                        flowBuilder.children.length,
                        'section'
                      )
                    );
                  }}
                  radius="md"
                  textSize="md"
                  size="xs"
                  leftIcon={<PlusSvg />}
                  className={classes.addBtn}
                >
                  Add Section
                </Button>
              </div>
              <div>
                <DragDropContext onDragEnd={onDragEnd(parentHelper)}>
                  <SectionList
                    flowBuilder={flowBuilder}
                    subHelperRef={subHelperRef}
                    formProps={formProps}
                    onDeleteSection={(index) => {
                      parentHelper.remove(index);
                    }}
                    onDeleteExistingSection={(tId, cb) => {
                      onDeleteExistingSection(tId, cb);
                    }}
                  />
                </DragDropContext>
              </div>
            </>
          );
        }}
      </FieldArray>
    </Card>
  );
};

export default FlowBuilder;
