import cn from 'classnames';
import { ReactComponent as ArrowSvg } from 'src/assets/icons/Arrow.svg';
import { Icon } from 'src/components';
import classes from './Icon.module.scss';

const Arrow = ({
  className = '',
  onClick = undefined,
  direction,
  ...other
}) => {
  return (
    <Icon className={className} onClick={onClick}>
      <ArrowSvg
        {...other}
        className={cn({
          [classes.iconArrowRight]: direction === 'right',
          [classes.iconArrowLeft]: direction === 'left',
          [classes.iconArrowDown]: direction === 'down',
          [classes.iconArrowTop]: direction === 'top',
        })}
      />
    </Icon>
  );
};

export default Arrow;
