import * as Yup from 'yup';
import { DUE_BY_VALUES } from 'src/helpers/constants/options';

export const NOTICE_REQUIRED_FIELDS = ['section_name', 'section_options'];
export const SECTION_REQUIRED_FIELDS = ['section_name', 'section_options'];
export const BUILD_CERTIFICATE_NAME_MAPPING = {
  name_of_certification: 'name',
  program_type: 'programs',
  locations: 'properties',
  certification_type: 'types',
  status_options: 'statuses',
  permissions: 'roles',
  is_active: 'active',
};
export const BUILD_WORKFLOW_NAME_MAPPING = {
  name_of_certification: 'name',
  locations: 'properties',
  workflow_type: 'workflow_type',
  status_options: 'statuses',
  permissions: 'roles',
  is_active: 'active',
  due_by: 'due_by',
  due_in_how_many_days: 'due_in_how_many_days',
  frequency: 'frequency',
  recurring_date: 'recurring_date',
};
export const ASSOCIATED_FILE_KEY = 'associated_file';
export const DATE_INPUT_KEY = 'check_schedule';
export const DATE_INPUT_SPLIT_KEY = '-_-';
export const DROPDOWN_KEY = 'check_dropdown';
export const ASSIGN_STATUS_KEY = 'assign_status';
export const ASSIGN_STATUS_TYPE = 'status';
export const NOTICE_FIELD_MAPPING = {
  section_name: {
    label: 'Name',
    type: 'label',
  },
  section_options: {
    label: 'Notice Options',
    type: 'dropdown',
  },
  check_upload_file: {
    label: 'Upload File',
    type: 'boolean',
  },
  check_schedule: {
    label: 'Scheduled Interview Date Input',
    type: 'boolean',
  },
  notice_information: {
    label: 'Tooltip',
    type: 'tooltip',
  },
};
export const SECTION_FIELD_MAPPING = {
  section_name: {
    label: 'Name',
    type: 'label',
  },
  [ASSOCIATED_FILE_KEY]: {
    label: 'Associated File',
    type: 'file',
  },
  section_options: {
    label: 'Assigned To',
    type: 'dropdown',
  },
  check_upload_file: {
    label: 'Upload File',
    type: 'boolean',
  },
  [DATE_INPUT_KEY]: {
    label: 'Date Input',
    type: 'date',
  },
  [DROPDOWN_KEY]: {
    label: 'Dropdown',
    type: 'dropdown',
  },
  notice_information: {
    label: 'Tooltip',
    type: 'tooltip',
  },
  assign_status: {
    label: 'Assign Status',
    type: ASSIGN_STATUS_TYPE,
  },
};
export const yupSchema = Yup.object().shape({
  name_of_certification: Yup.string().required('This field is required!'),
  certification_type: Yup.mixed().required('This field is required!'),
  program_type: Yup.mixed().required('This field is required!'),
  locations: Yup.mixed().required('This field is required!'),
  permissions: Yup.mixed().required('This field is required!'),
  status_options: Yup.mixed().required('This field is required!'),
  flowBuilder: Yup.object().shape({
    children: Yup.array()
      .min(1, 'Need at least one section')
      .of(
        Yup.object().shape({
          section_name: Yup.string().required('This field is required!'),
          section_options: Yup.mixed().required('This field is required!'),
        })
      ),
  }),
});
export const yupSchemaWorkflow = Yup.object().shape({
  name_of_certification: Yup.string().required('This field is required!'),
  locations: Yup.mixed().required('This field is required!'),
  permissions: Yup.mixed().required('This field is required!'),
  workflow_type: Yup.mixed().required('This field is required!'),
  due_by: Yup.mixed().required('This field is required!'),
  due_in_how_many_days: Yup.string()
    .nullable()
    .when('due_by', (due_by) => {
      return due_by !== DUE_BY_VALUES.START_DATE
        ? Yup.string().nullable()
        : Yup.string().nullable().required('This field is required!');
    }),
  frequency: Yup.mixed()
    .nullable()
    .when('due_by', (due_by) => {
      return due_by === DUE_BY_VALUES.START_DATE
        ? Yup.mixed().nullable()
        : Yup.mixed().nullable().required('This field is required!');
    }),
  recurring_date: Yup.string()
    .nullable()
    .when('due_by', (due_by) => {
      return due_by === DUE_BY_VALUES.START_DATE
        ? Yup.string().nullable()
        : Yup.string().nullable().required('This field is required!');
    }),
  status_options: Yup.mixed().required('This field is required!'),
  flowBuilder: Yup.object().shape({
    children: Yup.array()
      .min(1, 'Need at least one section')
      .of(
        Yup.object().shape({
          section_name: Yup.string().required('This field is required!'),
          section_options: Yup.mixed().required('This field is required!'),
        })
      ),
  }),
});
