import cn from 'classnames';
import { Form, Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { Button, LoadingOverlay, Tabs } from 'src/components';
import AssignedProperties from '../AssignedProperties';
import UserEdit from '../UserEdit';
import classes from './User.module.scss';
import { TABS } from './constants';
import { convertPayload, getInitialValues, schema } from './utils';

interface Props {
  title: string;
  className?: string;
  onCancel: () => void;
  onSubmit: (values, id) => void;
  loading?: boolean;
  editLoading?: boolean;
  dataLoading?: boolean;
  editObj: any;
  drawerId?: string;
}

const User = ({
  title,
  className,
  onCancel,
  onSubmit,
  loading,
  editObj,
  editLoading,
  dataLoading,
  drawerId,
}: Props) => {
  const isEditing = !isEmpty(editObj);
  const submitLoading = isEditing ? editLoading : loading;
  const [activeTab, setActiveTab] = useState(TABS.DETAILS);
  const renderForm = (formProps) => {
    const { errors } = formProps;
    const tabs = [
      {
        key: TABS.DETAILS,
        title: TABS.DETAILS,
        content: (
          <>
            <UserEdit
              editObj={editObj}
              formProps={formProps}
              drawerId={drawerId}
            />
          </>
        ),
      },
      {
        key: TABS.PROPERTIES,
        title: TABS.PROPERTIES,
        content: (
          <AssignedProperties formProps={formProps} drawerId={drawerId} />
        ),
      },
    ];

    return (
      <Form className={classes.form}>
        <LoadingOverlay visible={dataLoading} className={classes.loading} />
        <Tabs value={activeTab} onTabChange={setActiveTab} items={tabs} />
        <div className={classes.footer}>
          <Button
            variant="outline"
            radius="sm"
            textWeight="xl"
            onClick={onCancel}
            disabled={submitLoading}
          >
            Cancel
          </Button>
          <Button
            radius="sm"
            textWeight="xl"
            type="submit"
            loading={submitLoading}
            onClick={() => {
              if (!isEmpty(errors) && activeTab !== TABS.DETAILS) {
                setActiveTab(TABS.DETAILS);
              }
            }}
          >
            {isEditing ? 'Save Changes' : '+ Invite User'}
          </Button>
        </div>
      </Form>
    );
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <h2>{title}</h2>
      <Formik
        initialValues={getInitialValues(editObj)}
        validateOnMount
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          onSubmit(convertPayload(values), editObj.id);
        }}
      >
        {renderForm}
      </Formik>
    </div>
  );
};

export default User;
