import { Popover as ManPopover, PopoverProps } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import cn from 'classnames';
import styled from 'styled-components';
import FilePopover from './FilePopover';
import classes from './Popover.module.scss';

type Props = PopoverProps & {
  children: React.ReactNode;
  content: React.ReactNode | string;
  dropdownClassName?: string;
  customEvent?: boolean;
  customOpened?: boolean;
  customOffset?: number;
};

const Popover = ({
  children,
  content,
  dropdownClassName,
  customEvent,
  customOpened,
  customOffset,
  ...other
}: Props) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <ManPopover
      position="bottom"
      shadow="md"
      opened={customEvent ? customOpened : opened}
      withArrow={false}
      withinPortal={true}
      {...other}
    >
      <ManPopover.Target>
        {customEvent ? (
          children
        ) : (
          <span
            onMouseEnter={open}
            onMouseLeave={close}
            className={classes.childrenWrapper}
          >
            {children}
          </span>
        )}
      </ManPopover.Target>
      <StyledDropdown
        sx={{ pointerEvents: customEvent ? undefined : 'none' }}
        className={cn(
          classes.dropdown,
          {
            [classes.customEvent]: customEvent,
          },
          dropdownClassName
        )}
        offset={customOffset}
      >
        <>{content}</>
      </StyledDropdown>
    </ManPopover>
  );
};

const StyledDropdown = styled(ManPopover.Dropdown)`
  left: ${({ offset }) => `${offset || undefined}px`} !important;
`;

export { FilePopover };
export default Popover;
