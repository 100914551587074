import * as certificationActions from 'src/store/actions/certification';
import * as sharedActions from 'src/store/actions/shared';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const certificateListStore = useReducerData(
    'certification',
    'certificateList',
    {}
  );
  const certificateListMeta = certificateListStore.data || {};
  return {
    certificateListMeta,
    certificateList: certificateListMeta.data || [],
    certificateListLoading: certificateListStore.loading,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...certificationActions,
    ...sharedActions,
  });
};
