import { ButtonProps, Button as ManButton } from '@mantine/core';
import cn from 'classnames';
import React from 'react';
import { SizeType } from 'src/types';
import classes from './Button.module.scss';

type Props = ButtonProps & {
  className?: string;
  onClick?: any;
  nonStyle?: boolean;
  lessCircle?: boolean;
  textSize?: SizeType;
  textWeight?: SizeType;
  isOutline?: boolean;
};

const Button = ({
  className = '',
  nonStyle,
  radius = 'md',
  textSize,
  textWeight,
  isOutline,
  ...other
}: Props) => {
  return (
    <ManButton
      type="button"
      {...other}
      radius={radius}
      className={cn(
        classes.wrapper,
        {
          [classes.nonStyle]: nonStyle,
          [classes.isOutline]: isOutline,
        },
        classes[`text_${textSize}`],
        classes[`weight_${textWeight}`],
        className
      )}
    />
  );
};

export default Button;
