import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_UPDATE_INOUT_USER',
  'GET_PM_USERS',
  'GET_OM_USERS',
  'UPDATE_USER_STATUS',
  'SWITCH_USER_ACTIVE',
  'SWITCH_USER_DETAIL_ACTIVE'
);
