export const defaultObj = {
  loading: false,
  data: {},
  error: '',
};
export const defaultWithIDObj = {
  loading: {},
  data: {},
  error: '',
};
export const defaultArray = {
  loading: false,
  data: [],
  error: '',
};
