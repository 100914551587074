import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import { Input } from 'src/components/FormFields';
import {
  MainContent,
  PageContent,
  PageContentHeader,
  PageContentTitle,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { BETA_DISABLED } from 'src/helpers/constants';
import { useCurrentRole, useInfiniteScroll } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { confirmModalFn } from 'src/utils/modal';
import classes from './Workflows.module.scss';
import OtherItem from './components/OtherItem';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';

let page = 1;

const Workflows = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const role = useCurrentRole();
  const permission = getPermission(role);
  const { getWorkflowBuilderList, deleteWorkflowBuilder, getWorkflowTypes } =
    useActions();
  const { workflowBuilderList, workflowBuilderMeta } = useIndexData();
  const [isLazyLoading, setIsLazyLoading] = useState(false);

  const reloadWorkflowList = (tPage, cb = null) => {
    if (!BETA_DISABLED) {
      getWorkflowBuilderList(
        {
          page: tPage,
          isMore: tPage > 1,
          limit: 50,
        },
        cb
      );
    }
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    reloadWorkflowList(page, () => {
      setIsLazyLoading(false);
    });
  };
  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      workflowBuilderMeta &&
      workflowBuilderMeta.current_page < workflowBuilderMeta.last_page,
  });

  useEffect(() => {
    page = 1;
    reloadWorkflowList(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Workflows',
          },
        ]}
      />
      <MainContent>
        <PageHeader title="Workflows" className={classes.pageHeader}>
          <Input
            radius="md"
            value={search}
            onChange={(e) => {
              const val = e.target.value;
              setSearch(val);
            }}
            placeholder="Search"
            isSearch
          />
        </PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          {!BETA_DISABLED && (
            <>
              <div className={classes.workflowActions}>
                {permission.canBuildWorkflow && (
                  <Button
                    onClick={() => {
                      navigate('/workflow-builder');
                    }}
                    radius="md"
                    textSize="xl"
                  >
                    Build New Workflow
                  </Button>
                )}
                <Button
                  onClick={() => {
                    navigate('/new-workflow');
                  }}
                  radius="md"
                  textSize="xl"
                >
                  Start New Workflow
                </Button>
              </div>
              <div className={classes.workflows}>
                {workflowBuilderList.map((workflowBuilder) => {
                  const isActive = !!workflowBuilder.is_active;
                  const canEdit = !!workflowBuilder.can_edit;
                  return (
                    <OtherItem
                      key={workflowBuilder.id}
                      title={workflowBuilder.name}
                      onClick={() => {
                        navigate(
                          `/workflow-list-builder/${workflowBuilder.id}`
                        );
                      }}
                      isInactive={!isActive}
                      actions={
                        !permission.canActionWorkflowRow ||
                        (!canEdit && !isActive)
                          ? []
                          : [
                              ...(canEdit
                                ? [
                                    {
                                      label: 'Edit',
                                      onClick: () => {
                                        navigate(
                                          `/workflow/${workflowBuilder.id}`
                                        );
                                      },
                                    },
                                  ]
                                : []),
                              ...(isActive
                                ? [
                                    {
                                      label: 'Delete',
                                      isRed: true,
                                      onClick: () => {
                                        confirmModalFn({
                                          children: (
                                            <>
                                              Are you sure you want to delete
                                              the Workflow "
                                              {workflowBuilder.name}
                                              "?
                                            </>
                                          ),
                                          onCancel: () => {},
                                          onConfirm: () => {
                                            deleteWorkflowBuilder(
                                              workflowBuilder.id,
                                              actionCb(() => {
                                                getWorkflowTypes();
                                                page = 1;
                                                reloadWorkflowList(page);
                                              })
                                            );
                                          },
                                          labels: {
                                            confirm: 'Confirm',
                                            cancel: 'Cancel',
                                          },
                                          color: 'red',
                                          centered: true,
                                        });
                                      },
                                    },
                                  ]
                                : []),
                            ]
                      }
                    />
                  );
                })}
              </div>
            </>
          )}
          <PageContentHeader
            className={BETA_DISABLED ? '' : classes.otherHeader}
          >
            <PageContentTitle>Other</PageContentTitle>
          </PageContentHeader>
          <OtherItem
            title="Incident Reporting"
            onClick={() => {
              navigate('/incident-reporting');
            }}
          />
        </PageContent>
      </MainContent>
    </>
  );
};

export default Workflows;
