import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button, Modal } from 'src/components';
import classes from './AddNoticeModal.module.scss';

const AddNoticeModal = ({
  className = '',
  opened,
  onClose,
  options,
  onSubmit,
  loading,
}) => {
  const [selectedNotice, setSelectedNotice] = useState('');

  useEffect(() => {
    if (opened) {
      setSelectedNotice('');
    }
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Add a Notice"
      className={cn(classes.modalWrapper, className)}
      centered
    >
      <div className={classes.content}>
        <ul>
          {(options || []).map((option, index) => {
            return (
              <li
                key={index}
                className={cn({
                  [classes.active]: selectedNotice === option,
                })}
              >
                <span
                  onClick={() => {
                    setSelectedNotice(option);
                  }}
                >
                  {option}
                </span>
              </li>
            );
          })}
        </ul>
        <Button
          type="submit"
          radius="md"
          textWeight="xl"
          loading={loading}
          disabled={!selectedNotice}
          fullWidth
          onClick={() => {
            onSubmit(selectedNotice);
          }}
        >
          Add
        </Button>
      </div>
    </Modal>
  );
};

export default AddNoticeModal;
