import { Pagination as ManPagination, PaginationProps } from '@mantine/core';
import cn from 'classnames';
import classes from './Pagination.module.scss';

type IProps = PaginationProps & {
  className?: string;
};

const Pagination = ({ className, ...other }: IProps) => {
  return (
    <ManPagination {...other} className={cn(classes.wrapper, className)} />
  );
};
export default Pagination;
