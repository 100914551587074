import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Card } from 'src/components';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { actionCb } from 'src/utils/action';
import classes from './UploadNotice.module.scss';
import Footer from './components/Footer';
import NoticeFile from './components/NoticeFile';
import { useActions, useIndexData } from './selectorData';
import { getInitialValues, getNoticeBR } from './utils';

const UploadNotice = () => {
  const navigate = useNavigate();
  const { addNotice } = useActions();
  const { addNoticeLoading } = useIndexData();
  const schema = Yup.object().shape({
    property_id: Yup.string().required('This field is required!'),
    title: Yup.string().required('This field is required!'),
    notice_file: Yup.mixed().required('This field is required!'),
  });
  const formRef = useRef(null);

  const renderForm = () => {
    return (
      <Form className={classes.form} onChange={() => {}}>
        <PageContent>
          <Card
            contentGap={16}
            notPaddingContentTop
            contentPaddingBottom={0}
            form
            className={classes.formCard}
          >
            <NoticeFile />
            <Footer loading={addNoticeLoading} />
          </Card>
        </PageContent>
      </Form>
    );
  };
  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Certifications',
            link: '/certifications',
          },
          {
            label: 'All Notices',
            link: '/notices',
          },
          {
            label: 'Upload Notices',
          },
        ]}
      />
      <MainContent flex contentFullHeight className={classes.mainContent}>
        <PageHeader title={'Upload Notices'} />
        <Formik
          initialValues={getInitialValues()}
          validateOnMount
          validationSchema={schema}
          innerRef={formRef}
          enableReinitialize
          onSubmit={(values: any) => {
            addNotice(
              getNoticeBR(values),
              actionCb(
                () => {
                  navigate('/notices');
                },
                null,
                'Upload notice error'
              )
            );
          }}
        >
          {renderForm}
        </Formik>
      </MainContent>
    </>
  );
};

export default UploadNotice;
