import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from 'src/pages/NewReport/components/Section';

const MailingAddress = ({ isDisabled = false }) => {
  return (
    <NewReportSection title="Mailing Address">
      <FormCardContent column={4}>
        <FormikInput
          name="mailing_address"
          label="Mailing Address (if applicable) "
          placeholder="Address"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="mailing_city"
          label="City"
          placeholder="City"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="mailing_state"
          label="State"
          placeholder="State"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="mailing_zipcode"
          label="Zip Code"
          placeholder="00000"
          inputType="mask"
          mask="99999"
          autoComplete="mailing_zipcode"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default MailingAddress;
