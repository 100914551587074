import cn from 'classnames';
import { useState } from 'react';
import { ReactComponent as File1Svg } from 'src/assets/icons/File1.svg';
import { Button } from 'src/components';
import { COMPLETE_CERT_STATUS_VALUE } from 'src/helpers/constants/certification';
import ContentBlock from '../ContentBlock';
import UploadFileBtn from '../UploadFileBtn';
import classes from './DocumentBlock.module.scss';

const DocumentBlock = ({
  onView,
  onFileChange,
  onComplete = null,
  completeLoading = false,
  currentStatusName = '',
  isWorkflow = false,
  isPreview = false,
}) => {
  const [loading, setLoading] = useState(false);
  return (
    <ContentBlock
      className={cn(classes.wrapper, {
        [classes.hasComplete]: !!onComplete,
      })}
    >
      <UploadFileBtn
        title="Upload Full File"
        onChange={(tFile) => {
          onFileChange(tFile, setLoading);
        }}
        loading={loading}
      />
      <Button
        variant="default"
        radius="md"
        onClick={onView}
        leftIcon={<File1Svg />}
        size="sm"
        className={classes.viewAllBtn}
      >
        View All Uploaded Docs
      </Button>
      {!!onComplete && !isPreview && (
        <Button
          radius="md"
          onClick={onComplete}
          size="sm"
          loading={completeLoading}
        >
          {currentStatusName === COMPLETE_CERT_STATUS_VALUE
            ? 'Resume'
            : 'Complete'}{' '}
          {isWorkflow ? 'Workflow' : 'Certification'}
        </Button>
      )}
    </ContentBlock>
  );
};

export default DocumentBlock;
