import cn from 'classnames';
import { FieldArray, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Button } from 'src/components';
import {
  FormikDropdown,
  FormikInput,
  Radio,
  RadioGroup,
  Upload,
  UploadResultRow,
  UploadResultWrapper,
} from 'src/components/FormFields';
import { LOCATION_FIELD_SUFFIX } from 'src/helpers/constants';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { confirmModalFn } from 'src/utils/modal';
import { notification } from 'src/utils/notification';
import DocumentModal from '../DocumentModal';
import classes from './AddFileModal.module.scss';
import { STEPS } from './constants';
import { useActions, useIndexData } from './selectorData';
import { getFileBR, getFileCheckBR, schema } from './utils';

let uplaodFileConfirm = 'replace';
const MODAL_ID = 'add-file-modal';
const MODAL_PADDING = 50;

const AddFileModal = ({
  className = '',
  isOpened,
  onClose,
  onSubmit,
  loading = false,
  folder = null,
}) => {
  const { checkDuplicateFiles } = useActions();
  const { checkDuplicateLoading } = useIndexData();
  const { propertyOptions, tagOptions, permissionOptions, allFolderOptions } =
    useOptions();
  const [step, setStep] = useState(STEPS.UPLOAD);
  const isActionStep = step === STEPS.ACTION;
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFilesLength = uploadedFiles?.length;
  const parentName = 'files_upload';
  const resetState = () => {
    setStep(STEPS.UPLOAD);
    setUploadedFiles([]);
    uplaodFileConfirm = 'replace';
  };

  const renderForm = (formProps) => {
    const { setFieldValue, handleSubmit, values } = formProps;
    return (
      <Form>
        {!uploadedFilesLength ? (
          <Upload
            multiple={true}
            buttonText="Upload file from Computer"
            className={classes.uploadBtn}
            buttonProps={{
              color: 'green',
            }}
            valueButtonHidden
            onChange={(rFiles) => {
              if (rFiles?.length > 4) {
                notification({
                  title: 'Only 4 files can be uploaded manually at a time',
                  color: 'red',
                });
              } else {
                const folderId = folder?.id;
                const folderName = folder?.name;
                const tFiles = rFiles || [];
                setUploadedFiles(tFiles);
                setFieldValue(
                  parentName,
                  tFiles.map((f) => ({
                    file: f,
                    formNumber: '',
                    location: folderName || '',
                    [`location${LOCATION_FIELD_SUFFIX}`]: String(
                      folderId || ''
                    ),
                    property: '',
                    permissions: '',
                    tags: '',
                  }))
                );
              }
            }}
            hideRemoveValue
            uploadedAsInput
          />
        ) : (
          <>
            <FieldArray name={parentName}>
              {() => {
                return (
                  <>
                    <UploadResultWrapper
                      className={className}
                      rowHasChildren={isActionStep}
                    >
                      {uploadedFiles.map((v, index) => {
                        return (
                          <UploadResultRow
                            key={index}
                            name={v.name}
                            fileObj={v}
                            uploadedAsInput
                            hideRemoveValue
                            className={classes.uploadResultRow}
                          >
                            {isActionStep && (
                              <>
                                <FormikInput
                                  name={`${parentName}.${index}.formNumber`}
                                  label="Form Number"
                                  placeholder="Form Number"
                                  type="text"
                                  radius="md"
                                  errorOverlay
                                />
                                <FormikDropdown
                                  items={allFolderOptions}
                                  placeholder={'File Location'}
                                  label="File Location"
                                  name={`${parentName}.${index}.location`}
                                  displaySelectedAll
                                  placeholderGray
                                  errorOverlay
                                  radius="md"
                                  isFileLocation
                                  position="bottom"
                                  insideModalId={MODAL_ID}
                                  modalPadding={MODAL_PADDING}
                                />
                                <FormikDropdown
                                  items={[
                                    DEFAULT_ALL_OPTION,
                                    ...propertyOptions,
                                  ]}
                                  placeholder={'Select Property'}
                                  label="Property"
                                  name={`${parentName}.${index}.property`}
                                  displaySelectedAll
                                  placeholderGray
                                  errorOverlay
                                  radius="md"
                                  insideModalId={MODAL_ID}
                                  modalPadding={MODAL_PADDING}
                                />
                                <FormikDropdown
                                  items={[
                                    DEFAULT_ALL_OPTION,
                                    ...permissionOptions,
                                  ]}
                                  placeholder={'Select Permissions'}
                                  label="Permissions to Edit"
                                  name={`${parentName}.${index}.permissions_to_edit`}
                                  displaySelectedAll
                                  placeholderGray
                                  errorOverlay
                                  radius="md"
                                  insideModalId={MODAL_ID}
                                  modalPadding={MODAL_PADDING}
                                />
                                <FormikDropdown
                                  items={[
                                    DEFAULT_ALL_OPTION,
                                    ...permissionOptions,
                                  ]}
                                  placeholder={'Select Permissions'}
                                  label="Permissions to View"
                                  name={`${parentName}.${index}.permissions_to_view`}
                                  displaySelectedAll
                                  placeholderGray
                                  errorOverlay
                                  radius="md"
                                  insideModalId={MODAL_ID}
                                  modalPadding={MODAL_PADDING}
                                />
                                <FormikDropdown
                                  items={[
                                    DEFAULT_ALL_OPTION,
                                    ...permissionOptions,
                                  ]}
                                  placeholder={'Select Permissions'}
                                  label="Permissions to Delete"
                                  name={`${parentName}.${index}.permissions_to_delete`}
                                  displaySelectedAll
                                  placeholderGray
                                  errorOverlay
                                  radius="md"
                                  insideModalId={MODAL_ID}
                                  modalPadding={MODAL_PADDING}
                                />
                                <FormikDropdown
                                  items={[DEFAULT_ALL_OPTION, ...tagOptions]}
                                  placeholder={'Select Tags'}
                                  label="Tags"
                                  name={`${parentName}.${index}.tags`}
                                  displaySelectedAll
                                  placeholderGray
                                  errorOverlay
                                  radius="md"
                                  newEntity="tag"
                                  insideModalId={MODAL_ID}
                                  modalPadding={MODAL_PADDING}
                                />
                              </>
                            )}
                          </UploadResultRow>
                        );
                      })}
                    </UploadResultWrapper>
                  </>
                );
              }}
            </FieldArray>
            <Button
              radius="md"
              textSize="xl"
              fullWidth
              type="button"
              loading={checkDuplicateLoading || loading}
              onClick={() => {
                switch (step) {
                  case STEPS.UPLOAD:
                    setStep(STEPS.ACTION);
                    break;
                  case STEPS.ACTION:
                    handleSubmit(values);
                    break;
                  default:
                    break;
                }
              }}
            >
              {isActionStep ? 'Upload' : 'Next'}
            </Button>
          </>
        )}
      </Form>
    );
  };

  useEffect(() => {
    if (isOpened) {
      resetState();
    }
  }, [isOpened]);

  return (
    <DocumentModal
      isOpened={isOpened}
      onClose={onClose}
      title={
        uploadedFilesLength
          ? `(${uploadedFilesLength}) Files Uploaded`
          : 'Upload file(s)'
      }
      size={uploadedFilesLength ? 540 : 280}
      className={cn(classes.modalWrapper, className)}
      scrollable={!!uploadedFilesLength}
      closeOnClickOutside={false}
      closeOnEscape={false}
      id={MODAL_ID}
    >
      <Formik
        initialValues={{
          [parentName]: [],
        }}
        validateOnMount
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          const checkFileBR = getFileCheckBR(values);
          const normalBR = getFileBR(values);
          if (checkFileBR.length > 0) {
            checkDuplicateFiles(
              { documents: checkFileBR },
              actionCb((res) => {
                if (res.length) {
                  confirmModalFn({
                    children: (
                      <>
                        "{res.join(', ')}" already exists in this location. You
                        can replace the existing file or keep both files.
                        Replacing the file will overwrite it's existing contents
                        <RadioGroup
                          onChange={(val) => {
                            uplaodFileConfirm = val;
                          }}
                          defaultValue="replace"
                        >
                          <Radio
                            value="replace"
                            label="Replace the existing file"
                          />
                          <Radio value="keep_both" label="Keep both files" />
                        </RadioGroup>
                      </>
                    ),
                    onCancel: () => {},
                    onConfirm: () => {
                      const fileAfterCheckedBR = getFileBR(
                        values,
                        res,
                        uplaodFileConfirm
                      );
                      onSubmit(fileAfterCheckedBR);
                    },
                    labels: {
                      confirm: 'Upload',
                      cancel: 'Cancel',
                    },
                    color: 'red',
                    centered: true,
                    title: 'Upload Options',
                    isConfirm: true,
                  });
                } else {
                  onSubmit(normalBR);
                }
              })
            );
          } else {
            onSubmit(normalBR);
          }
        }}
      >
        {renderForm}
      </Formik>
    </DocumentModal>
  );
};

export default AddFileModal;
