import cn from 'classnames';
import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { PageContent } from 'src/components/Page';
import EmergencyContacts from './EmergencyContacts';
import Footer from './Footer';
import GuardianPOA from './GuardianPOA';
import MailingAddress from './MailingAddress';
import Members from './Members';
import Payee from './Payee';
import Pets from './Pets';
import classes from './ResidentInfo.module.scss';
import VehicleInformation from './VehicleInformation';
import { schema } from './constants';
import { getInitialValues } from './utils';

const ResidentInfo = ({ className = '' }) => {
  const formRef = useRef(null);
  const renderForm = (formProps) => {
    const { dirty, isValid } = formProps;
    return (
      <Form className={classes.form} onChange={() => {}}>
        <PageContent className={classes.mainContent} inputGray>
          <div className={classes.group}>
            <Members />
            <Pets />
            <MailingAddress />
            <EmergencyContacts />
            <GuardianPOA />
            <Payee />
            <VehicleInformation />
          </div>
          <Footer
            disabled={!dirty || !isValid}
            loading={false}
            onCancel={() => {}}
          />
        </PageContent>
      </Form>
    );
  };

  return (
    <div className={cn(classes.wrapper, className)}>
      <Formik
        initialValues={getInitialValues()}
        validateOnMount
        validationSchema={schema}
        innerRef={formRef}
        enableReinitialize
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSubmit={(values) => {}}
      >
        {renderForm}
      </Formik>
    </div>
  );
};

export default ResidentInfo;
