import { Reducer } from 'redux';

import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/dashboard';

const initialState = {
  dashboard: {
    ...defaultObj,
  },
  readAlert: {
    ...defaultObj,
  },
};

const DashboardReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.GET_DASHBOARD:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          dashboard: {
            ...prevState.dashboard,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          dashboard: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          dashboard: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.READ_ALERT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          readAlert: {
            ...prevState.readAlert,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          readAlert: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          readAlert: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default DashboardReducer;
