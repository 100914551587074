import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'src/components';
import {
  ButtonBar,
  MainContent,
  PageContent,
  PageContentHeader,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { BETA_DISABLED } from 'src/helpers/constants';
import { useCurrentRole, useInfiniteScroll } from 'src/helpers/hooks';
import OtherItem from 'src/pages/Workflows/components/OtherItem';
import { actionCb } from 'src/utils/action';
// import { actionCb } from 'src/utils/action';
import { confirmModalFn } from 'src/utils/modal';
// import { notification } from 'src/utils/notification';
import classes from './Certifications.module.scss';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';

let page = 1;

const Certifications = () => {
  const { getCertificateList, deleteCertificate, getCertificationTypes } =
    useActions();
  const { certificateList, certificateListMeta } = useIndexData();
  const navigate = useNavigate();
  const role = useCurrentRole();
  const permission = getPermission(role);
  const [isLazyLoading, setIsLazyLoading] = useState(false);

  const reloadCertificateList = (tPage, cb = null) => {
    if (!BETA_DISABLED) {
      getCertificateList(
        {
          page: tPage,
          isMore: tPage > 1,
          limit: 50,
        },
        cb
      );
    }
  };

  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    reloadCertificateList(page, () => {
      setIsLazyLoading(false);
    });
  };
  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      certificateListMeta &&
      certificateListMeta.current_page < certificateListMeta.last_page,
  });

  useEffect(() => {
    page = 1;
    reloadCertificateList(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Certifications',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader
          title="Certifications"
          className={classes.pageHeader}
        ></PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          <PageContentHeader className={classes.pageContentHeader}>
            <div>
              {permission.canBuildCertificate && !BETA_DISABLED && (
                <Button
                  onClick={() => {
                    navigate('/certification-builder');
                  }}
                  radius="md"
                  textSize="xl"
                >
                  Build New Certification
                </Button>
              )}
            </div>
            <ButtonBar mobileFullWidth>
              {permission.canUploadNotice && (
                <Button
                  onClick={() => {
                    navigate('/upload-notice');
                  }}
                  radius="md"
                  textSize="xl"
                >
                  Upload Notices
                </Button>
              )}
              {!BETA_DISABLED && (
                <Button
                  onClick={() => {
                    navigate('/new-certification');
                  }}
                  radius="md"
                  textSize="xl"
                >
                  Start New Certification
                </Button>
              )}
            </ButtonBar>
          </PageContentHeader>
          {!BETA_DISABLED && (
            <OtherItem
              title="View All Certifications"
              onClick={() => {
                navigate('/certifications-list');
              }}
            />
          )}
          {/*
            viewRow: able to see certification row
            actionRow: able to see three dot 
           */}
          {permission.canViewNotice && (
            <OtherItem
              title="Recertification Notices"
              onClick={() => {
                navigate('/notices');
              }}
            />
          )}
          {certificateList.map((certificate) => {
            const isActive = !!certificate.is_active;
            const canEdit = !!certificate.can_edit;
            return (
              <OtherItem
                key={certificate.id}
                title={certificate.name}
                onClick={() => {
                  navigate(`/certifications-list-builder/${certificate.id}`);
                }}
                isInactive={!isActive}
                actions={
                  !permission.canActionCertificationRow ||
                  (!canEdit && !isActive)
                    ? []
                    : [
                        ...(canEdit
                          ? [
                              {
                                label: 'Edit',
                                onClick: () => {
                                  navigate(`/certificate/${certificate.id}`);
                                },
                              },
                            ]
                          : []),
                        ...(isActive
                          ? [
                              {
                                label: 'Delete',
                                isRed: true,
                                onClick: () => {
                                  confirmModalFn({
                                    children: (
                                      <>
                                        Are you sure you want to delete the
                                        Certification "{certificate.name}"?
                                      </>
                                    ),
                                    onCancel: () => {},
                                    onConfirm: () => {
                                      deleteCertificate(
                                        certificate.id,
                                        actionCb(() => {
                                          getCertificationTypes();
                                          page = 1;
                                          reloadCertificateList(page);
                                        })
                                      );
                                    },
                                    labels: {
                                      confirm: 'Confirm',
                                      cancel: 'Cancel',
                                    },
                                    color: 'red',
                                    centered: true,
                                  });
                                },
                              },
                            ]
                          : []),
                      ]
                }
              />
            );
          })}
        </PageContent>
      </MainContent>
    </>
  );
};

export default Certifications;
