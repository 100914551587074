import { FormCardContent } from 'src/components';
import {
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from 'src/components/FormFields';
import { WEATHER_CONDITION_OPTIONS } from 'src/helpers/constants/options';
import NewReportSection from '../Section';

const WeatherConditions = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="Weather Conditions"
      onNoValue={() => {
        return (
          !values.weather_condition &&
          !values.weather_temperature &&
          !values.weather_additional_notes
        );
      }}
    >
      <FormCardContent column={3}>
        <FormikSelect
          data={WEATHER_CONDITION_OPTIONS}
          name="weather_condition"
          label="Conditions"
          placeholder="Select"
          maxDropdownHeight={310}
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormCardContent column={2} inside>
          <FormikInput
            name="weather_temperature"
            label="Temperature"
            placeholder="0"
            type="number"
            errorOverlay
            disabledOverlay={isDisabled}
            rightSection="°F"
            onChangeCb={onValuesChange}
          />
        </FormCardContent>
      </FormCardContent>
      <FormCardContent column={1}>
        <FormikTextarea
          name="weather_additional_notes"
          placeholder="Additional Notes"
          maxLength={300}
          disabledOverlay={isDisabled}
          label="Additional Notes"
          onChangeCb={onValuesChange}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default WeatherConditions;
