import { Drawer } from '@mantine/core';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as ArrowSvg } from 'src/assets/icons/Arrow.svg';
import { ReactComponent as BellSvg } from 'src/assets/icons/Bell.svg';
import { ReactComponent as MenuCollapseSvg } from 'src/assets/icons/MenuCollapse.svg';
import { ReactComponent as MenuExpandSvg } from 'src/assets/icons/MenuExpand.svg';
import { ReactComponent as SearchSvg } from 'src/assets/icons/Search.svg';
import { Avatar, Badge, Icon, Menu } from 'src/components';
import { Input } from 'src/components/FormFields';
import { Logo } from 'src/components/Page';
import { BETA_DISABLED, STATUS } from 'src/helpers/constants';
import { useMedia } from 'src/helpers/hooks';
import { Menu as MainMenu } from 'src/layout/Main';
import { intialName } from 'src/utils/text';
import Breadcrumb, { BreadcrumbItem } from '../Breadcrumb';
import classes from './TopHeader.module.scss';
import { useActions, useIndexData } from './selectorData';

interface Props {
  className?: string;
  breadcrumb: BreadcrumbItem[];
}

const ProfileMenu = ({ isMobile = false }) => {
  const isTablet = useMedia('(max-width: 768px)');
  const navigate = useNavigate();
  const { logOut } = useActions();
  const { profile } = useIndexData();

  return (
    <Menu
      position={isMobile ? 'top' : 'bottom-end'}
      menuStyles="profile"
      offset={8}
      items={[
        {
          label: 'My Profile',
          onClick: () => {
            navigate('/my-profile');
          },
        },
        {
          label: 'Logout',
          onClick: () => {
            logOut();
          },
        },
      ]}
      className={cn({
        [classes.profileMenuMobile]: isMobile,
      })}
    >
      <Avatar
        avtSrc={profile.profile_image_path}
        radius="xl"
        isStatus={isTablet}
        isInactive={!profile.is_active}
        className={cn({
          [classes.profileAvatarMobile]: isTablet,
        })}
        statusHasBorder
      >
        {intialName(profile.first_name, profile.last_name)}
      </Avatar>
      {isMobile && (
        <div className={classes.profileMobileInfo}>
          <span>{[profile.first_name, profile.last_name].join(' ')}</span>
          <ArrowSvg />
        </div>
      )}
    </Menu>
  );
};

const TopHeader = ({ className, breadcrumb }: Props) => {
  const { profile } = useIndexData();
  const { switchUserActive } = useActions();
  const isTablet = useMedia('(max-width: 768px)');
  const [status, setStatus] = useState(
    profile.is_active ? STATUS.ACTIVE : STATUS.INACTIVE
  );
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setStatus(profile.is_active ? STATUS.ACTIVE : STATUS.INACTIVE);
  }, [profile.is_active]);

  return (
    <>
      <div className={cn(classes.wrapper, 'i-top-header', className)}>
        <Breadcrumb className={classes.breadcrumb} items={breadcrumb} />
        <div className={classes.right}>
          {!isTablet ? (
            <>
              <Badge
                isStatus
                text={status.value}
                onClick={() => {
                  const isCurrentActive = status.value === STATUS.ACTIVE.value;
                  setStatus(isCurrentActive ? STATUS.INACTIVE : STATUS.ACTIVE);
                  switchUserActive({
                    is_active: isCurrentActive ? 0 : 1,
                  });
                }}
              >
                {status.text}
              </Badge>
              <Icon disabled={BETA_DISABLED}>
                <SearchSvg />
              </Icon>
              <Icon disabled={BETA_DISABLED}>
                <BellSvg />
              </Icon>
              <ProfileMenu />
            </>
          ) : (
            <Icon
              onClick={() => {
                setOpened(true);
              }}
            >
              <MenuCollapseSvg />
            </Icon>
          )}
        </div>
      </div>
      {isTablet && (
        <Drawer
          opened={opened}
          size={303}
          onClose={() => {
            setOpened(false);
          }}
          position="right"
          withCloseButton={false}
          trapFocus={false}
        >
          <div className={classes.mobileMenuTop}>
            <Icon
              onClick={() => {
                setOpened(false);
              }}
              className={classes.actionIcon}
            >
              <MenuExpandSvg />
            </Icon>
            <Icon>
              <BellSvg />
            </Icon>
            <Logo className={classes.logo} />
          </div>
          <div className={classes.mobileMenuMiddle}>
            <Input radius="md" placeholder="Search" isSearch size="md" />
            <MainMenu collapse={false} className={classes.nav} />
            <ProfileMenu isMobile />
          </div>
        </Drawer>
      )}
    </>
  );
};

export default TopHeader;
