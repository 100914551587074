import { syncSetCollapse } from 'src/store/actions/shared';
import { useAppDispatch, useReducerData } from 'src/store/hooks';

const useCollapse = () => {
  const dispatch = useAppDispatch();
  const collapse = useReducerData('shared', 'collapse', false);
  const setCollapse = (val: boolean) => {
    dispatch(syncSetCollapse(val));
  };

  return { collapse, setCollapse };
};

export default useCollapse;
