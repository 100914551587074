import { Form, Formik } from 'formik';
import moment from 'moment-timezone';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { Button } from 'src/components';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { actionCb } from 'src/utils/action';
import classes from './MyProfile.module.scss';
import BasicInfo from './components/BasicInfo';
import ChangePassword from './components/ChangePassword';
import ContactInfo from './components/ContactInfo';
import Language from './components/Language';
// import Preferences from './components/Preferences';
import { useActions, useIndexData } from './selectorData';
import { getProfileBR } from './utils';

const MyProfile = () => {
  const { t } = useTranslation(['myProfile', 'common']);
  const formRef = useRef(null);
  const { profile, updateProfileLoading } = useIndexData();
  const { updateProfile } = useActions();
  const schema = Yup.object().shape({
    newPassword: Yup.string().nullable(),
    confirmPassword: Yup.string().when('newPassword', (newPassword) => {
      return (
        newPassword
          ? Yup.string().required('Please make sure your passwords match')
          : Yup.string().nullable()
      ).oneOf(
        [Yup.ref('newPassword'), null],
        'Please make sure your passwords match'
      );
    }),
  });

  const renderForm = (formProps) => {
    const { dirty, isValid, setFieldValue, values } = formProps;
    return (
      <Form className={classes.form} onChange={() => {}}>
        <PageContent className={classes.mainContent}>
          <div className={classes.group}>
            <div>
              <BasicInfo
                firstName={profile.first_name}
                lastName={profile.last_name}
                email={profile.email}
                title={profile.job_title}
                onAvatarChange={(tFile, src) => {
                  setFieldValue('avatar', tFile);
                  setFieldValue('avatarSrc', src);
                }}
                avatarSrc={values.avatarSrc}
              />
              <ContactInfo />
            </div>
            <div>
              <Language />
              <ChangePassword />
            </div>
          </div>
          {/* <div>
            <Preferences />
          </div> */}
          <div className={classes.footer}>
            <Button variant="outline" radius="sm">
              Cancel
            </Button>
            <Button
              type="submit"
              radius="sm"
              disabled={!dirty || !isValid}
              loading={updateProfileLoading}
            >
              Save Changes
            </Button>
          </div>
        </PageContent>
      </Form>
    );
  };
  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: t('myProfile.title'),
          },
        ]}
      />
      <MainContent>
        <PageHeader title={t('myProfile.title')} />
        <Formik
          initialValues={{
            language: profile.language || 'en',
            timeZone: profile.timezone || 'Phoenix (GMT-7)',
            officePhone: profile.office_phone || '',
            cellPhone: profile.cell_phone || '',
            fax: profile.fax_number || '',
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
            timeCard: !!profile.timecard,
            limit: !!profile.in_out_limits,
            avatarSrc: profile.profile_image_path || '',
          }}
          validateOnMount
          validationSchema={schema}
          innerRef={formRef}
          // enableReinitialize
          onSubmit={async (values) => {
            const br = getProfileBR(values, profile);
            await updateProfile(
              br,
              actionCb(
                () => {
                  if (values.timeZone) {
                    moment.tz.setDefault(values.timeZone);
                  }
                },
                null,
                'Update profile failed!'
              )
            );
            if (formRef && formRef.current) {
              formRef.current.resetForm({ values });
            }
          }}
        >
          {renderForm}
        </Formik>
      </MainContent>
    </>
  );
};

export default MyProfile;
