import cn from 'classnames';
import { ReactComponent as ArrowCircleSvg } from 'src/assets/icons/ArrowCircle.svg';
import { Icon } from 'src/components';
import classes from './Icon.module.scss';

const ArrowCircle = ({
  className = '',
  onClick = undefined,
  direction,
  ...other
}) => {
  return (
    <Icon className={className} onClick={onClick}>
      <ArrowCircleSvg
        {...other}
        className={cn({
          [classes.iconArrowCircleRight]: direction === 'right',
          [classes.iconArrowCircleLeft]: direction === 'left',
          [classes.iconArrowCircleDown]: direction === 'down',
          [classes.iconArrowCircleTop]: direction === 'top',
        })}
      />
    </Icon>
  );
};

export default ArrowCircle;
