import { Request } from './request';

export const apiGetWorkflowPending = async () => {
  return Request.call({
    url: `/workflow/pending`,
    method: 'GET',
  });
};
export const apiAddWorkflow = async (data) => {
  return Request.call({
    url: `/workflows`,
    method: 'POST',
    data,
  });
};
export const apiGetWorkflowBuilderList = async (params) => {
  return Request.call({
    url: `/workflow/builder`,
    method: 'GET',
    params,
  });
};
export const apiGetWorkflowBuilderDetail = async (id) => {
  return Request.call({
    url: `/workflow/builder/${id}`,
    method: 'GET',
  });
};
export const apiDeleteWorkflowBuilder = async (id) => {
  return Request.call({
    url: `/workflow/builder/${id}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiGetWorkflows = async (params = {}) => {
  return Request.call({
    url: '/workflows',
    method: 'GET',
    params,
  });
};
