import { Reducer } from 'redux';

import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/notice';

const initialState = {
  notices: {
    ...defaultArray,
  },
  addNotice: {
    ...defaultObj,
  },
  updateNoticeStatus: {
    ...defaultObj,
  },
};

const NoticeReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_NOTICE_STATUS: {
      return {
        ...state,
        notices: {
          ...state.notices,
          data: state.notices.data.map((notice) => {
            if (notice.id === payload.id) {
              return {
                ...notice,
                status: payload.status,
              };
            }
            return notice;
          }),
        },
      };
    }
    case Types.GET_NOTICES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          notices: {
            ...prevState.notices,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          notices: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          notices: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.ADD_NOTICE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addNotice: {
            ...prevState.addNotice,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addNotice: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addNotice: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_NOTICE_STATUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateNoticeStatus: {
            ...prevState.updateNoticeStatus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateNoticeStatus: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateNoticeStatus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default NoticeReducer;
