import { Request } from './request';

export const apiGetReports = async (data = {}) => {
  return Request.call({
    url: '/incident/list',
    method: 'POST',
    data,
  });
};
export const apiGetReportDetail = async (id) => {
  return Request.call({
    url: `/incident/${id}`,
    method: 'GET',
  });
};
export const apiAddUpdateReport = async (data = {}) => {
  return Request.call({
    url: '/incident',
    method: 'POST',
    data,
  });
};
export const apiUploadReportFiles = async (data = {}) => {
  return Request.call({
    url: '/incident/file',
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiDeleteReportFiles = async (data = {}) => {
  return Request.call({
    url: '/incident/delete_files',
    method: 'DELETE',
    data,
  });
};
export const apiDownloadReport = async (reportId: number) => {
  return Request.call({
    url: `/incident/${reportId}/download_report`,
    method: 'GET',
    responseType: 'blob',
  });
};
