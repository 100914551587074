import { Textarea as ManTextarea, TextareaProps } from '@mantine/core';
import cn from 'classnames';
import classes from './Textarea.module.scss';

export type OTextareaProps = TextareaProps & {
  className?: string;
  disabledOverlay?: boolean;
};

const Textarea = ({ className, disabledOverlay, ...other }: OTextareaProps) => {
  return (
    <>
      <ManTextarea
        {...other}
        disabled={other.disabled || disabledOverlay}
        className={cn(
          classes.wrapper,
          {
            [classes.disabledOverlay]: disabledOverlay,
            'i-print-no-value': !other.value,
          },
          className
        )}
      />
      <span className="i-print-value">{other.value}</span>
    </>
  );
};

export default Textarea;
