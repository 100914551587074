import cn from 'classnames';
import classes from './UserList.module.scss';
import { CardHeader } from './index';

interface Props {
  widths: string[];
  minWidths?: string[];
  headers: CardHeader[];
  styles?: Record<string, unknown>[];
  className?: string;
}

const UserListHeader = ({ headers, widths, styles, className }: Props) => {
  return (
    <div className={cn(classes.header, className)}>
      {headers.map((header: CardHeader, index: number) => {
        return (
          <div
            key={header.key}
            style={{
              ...(styles?.[index] || {}),
              width: widths[index],
              minWidth: String(header.minWidth),
            }}
          >
            {header.label}
          </div>
        );
      })}
    </div>
  );
};

export default UserListHeader;
