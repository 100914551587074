import { trimText } from 'src/utils/text';
import { RECENT_DELETED_FOLDER } from './constants';

export const filterFolders = (folders) => {
  return [...folders, RECENT_DELETED_FOLDER];
};
export const getFolderBR = (name, id?: number) => {
  const returnBR = {
    name: trimText(name),
  } as any;
  if (id) {
    returnBR.directory_id = id;
  }
  return returnBR;
};
