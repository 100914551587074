import { defaultArray, defaultObj } from 'src/store/constants';

export const initialCertificationState = {
  certificateList: {
    ...defaultObj,
  },
  certificateDetail: {
    ...defaultObj,
    previousCertId: undefined,
  },
  certifications: {
    ...defaultObj,
    previousCertId: undefined,
  },
  certificationFields: {
    ...defaultObj,
  },
  certificationPending: {
    ...defaultObj,
  },
  certificateNote: {
    ...defaultArray,
  },
  addCertification: {
    ...defaultObj,
  },
  updateCertification: {
    ...defaultObj,
  },
  buildCertificate: {
    ...defaultObj,
  },
  deleteCertificate: {
    ...defaultObj,
  },
  deleteCertificateFile: {
    ...defaultObj,
  },
  saveCertificateFields: {
    ...defaultObj,
  },
  addCertificateNote: {
    ...defaultObj,
  },
  uploadCertificateFullFile: {
    ...defaultObj,
  },
  deleteCertificateFullFile: {
    ...defaultObj,
  },
  deleteCertificateSection: {
    ...defaultObj,
  },
};
