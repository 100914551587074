import cn from 'classnames';
import { ReactComponent as CalendarTimeSvg } from 'src/assets/icons/CalendarTime.svg';
import { ReactComponent as MailSvg } from 'src/assets/icons/Mail.svg';
import { ReactComponent as PhoneSvg } from 'src/assets/icons/Phone.svg';
import { CalendarView, Popover } from 'src/components';
import { ScheduleType } from 'src/types/schedule';
import { getFormattedPhone } from 'src/utils/phone';
import classes from './InfoIcons.module.scss';

interface Props {
  email?: string;
  phone?: string;
  schedules: ScheduleType[];
  hideSchedule?: boolean;
  className?: string;
  reverse?: boolean;
  phoneContent?: React.ReactNode;
}

const InfoIcons = ({
  email,
  phone,
  schedules,
  hideSchedule,
  className,
  reverse,
  phoneContent,
}: Props) => {
  return (
    <span
      className={cn(
        classes.wrapper,
        {
          [classes.reverse]: reverse,
        },
        className
      )}
    >
      {!!email && (
        <Popover
          content={<b>{email}</b>}
          position="bottom-start"
          dropdownClassName={classes.dropdown}
        >
          <MailSvg />
        </Popover>
      )}
      {(!!phone || !!phoneContent) && (
        <Popover
          content={phoneContent || <b>{getFormattedPhone(phone)}</b>}
          position="bottom-start"
          dropdownClassName={classes.dropdown}
        >
          <PhoneSvg />
        </Popover>
      )}
      {!hideSchedule && (
        <Popover
          content={<CalendarView data={schedules} />}
          position="bottom-start"
          dropdownClassName={classes.dropdown}
        >
          <CalendarTimeSvg />
        </Popover>
      )}
    </span>
  );
};

export default InfoIcons;
