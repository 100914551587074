import isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { ArrowCircle, Modal, Note } from 'src/components';
import { Input } from 'src/components/FormFields';
import { CertNote } from 'src/types/certification';
import { getDPDate } from 'src/utils/date';
import classes from './NoteModal.module.scss';

interface Props {
  opened: boolean;
  onClose: () => void;
  onSubmit: (val: string, cb: () => void) => void;
  notes: CertNote[];
  preObj?: any;
}

const NoteModal = ({ opened, onClose, onSubmit, notes, preObj }: Props) => {
  const inputRef = useRef(null);
  const [note, setNote] = useState('');

  useEffect(() => {
    if (opened) {
      if (!isEmpty(preObj)) {
        const preSectionName =
          (preObj?.fields || []).find((obj) => obj.label === 'Name')?.value ||
          '';
        setNote(
          preSectionName ? `${preSectionName} requires your attention ` : ''
        );
      } else {
        setNote('');
      }
      setTimeout(() => {
        if (inputRef && inputRef.current) {
          inputRef.current.focus();
        }
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title="Notes"
      size={558}
      footer={
        <Input
          innerRef={inputRef}
          radius="md"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
          onKeyPress={(e) => {
            const val = e.target.value;
            if (e.code === 'Enter' && !!val && !!val.trim()) {
              onSubmit(val, () => {
                setNote('');
              });
            }
          }}
          placeholder="Leave a note"
          icon={
            <ArrowCircle
              direction="top"
              onClick={() => {
                onSubmit(note, () => {
                  setNote('');
                });
              }}
            />
          }
          className={classes.noteInput}
        />
      }
      fullHeight
      withCloseButton={false}
      trapFocus={false}
    >
      <div className={classes.notes}>
        {notes.map((note) => {
          return (
            <Note
              key={note.id}
              firstName={note.user?.first_name}
              lastName={note.user?.last_name}
              dateStr={getDPDate(note.created_at, true)}
              content={note.note}
              avatarSrc={note.user?.profile_image_path}
            />
          );
        })}
      </div>
    </Modal>
  );
};

export default NoteModal;
