import { Radio as ManRadio, RadioProps } from '@mantine/core';
import cn from 'classnames';
import classes from './Radio.module.scss';

export type Props = RadioProps & {
  className?: string;
};

const RadioGroup = ManRadio.Group;

const Radio = ({ className, ...other }: Props) => {
  return <ManRadio {...other} className={cn(classes.wrapper, className)} />;
};

export { RadioGroup };
export default Radio;
