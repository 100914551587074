import * as dashboardActions from 'src/store/actions/dashboard';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    myTasksData: useReducerData('dashboard', 'dashboard.data.my_tasks', []),
  };
};
export const useLoadingData = () => {
  return {
    dashboardLoading: useReducerData('dashboard', 'dashboard.loading', false),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...dashboardActions,
  });
};
