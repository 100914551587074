import cn from 'classnames';
import { FormCardContent } from 'src/components';
import { FormikInput, FormikSelect } from 'src/components/FormFields';
import { NOON_OPTIONS } from 'src/helpers/constants/options';
import NewReportSection from '../Section';
import classes from './IncidentFilledBy.module.scss';

const IncidentFilledBy = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="This report has been filled out by:"
      onNoValue={() => {
        return (
          !values.filled_name &&
          !values.filled_phone_numbner &&
          !values.filled_date &&
          !values.filled_title &&
          !values.notified_area_manager &&
          !values.notified_date &&
          !values.notified_time &&
          !values.notified_time_noon
        );
      }}
    >
      <FormCardContent
        column={4}
        className={cn({
          'i-print-no-value':
            !values.filled_name &&
            !values.filled_phone_numbner &&
            !values.filled_date &&
            !values.filled_title,
        })}
      >
        <FormikInput
          name="filled_name"
          label="Name"
          placeholder="Name"
          type="text"
          autoComplete="filled-by-name"
          errorOverlay
          required
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="filled_phone_numbner"
          label="Phone Number"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="filled-by-phone"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="filled_date"
          label="Date"
          placeholder="00/00/0000"
          inputType="mask"
          mask="99/99/9999"
          autoComplete="filled-date"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="filled_title"
          label="Title"
          placeholder="Title"
          type="text"
          autoComplete="filled-title"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
      </FormCardContent>
      <FormCardContent
        column={4}
        className={cn({
          'i-print-no-value':
            values.notified_area_manager &&
            !values.notified_date &&
            !values.notified_time &&
            !values.notified_time_noon,
        })}
      >
        <FormikInput
          name="notified_area_manager"
          label="Area Manager Notified"
          placeholder="Area Manager Notified"
          type="text"
          autoComplete="areamanager-notified"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="notified_date"
          label="Date Notified"
          placeholder="00/00/0000"
          inputType="mask"
          mask="99/99/9999"
          autoComplete="date"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="notified_time"
          label="Time Notified"
          placeholder="00:00"
          inputType="mask"
          mask="99:99"
          autoComplete="date"
          errorOverlay
          disabledOverlay={isDisabled}
          printValue={`${values.notified_time} ${(
            values.notified_time_noon || ''
          ).toUpperCase()}`}
          onChangeCb={onValuesChange}
        />
        <FormikSelect
          data={NOON_OPTIONS}
          name="notified_time_noon"
          label=" "
          placeholder="Select"
          maxDropdownHeight={300}
          errorOverlay
          disabledOverlay={isDisabled}
          className={classes.noonDropdown}
          onChangeCb={onValuesChange}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default IncidentFilledBy;
