/* eslint-disable no-console */
import cn from 'classnames';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Request } from 'src/api/request';
import { Button } from 'src/components';
import { syncSetToken } from 'src/store/actions/auth';
import { useAppDispatch } from 'src/store/hooks';
import { getUrlParamValueByKey } from 'src/utils/routes';
import classes from './ButtonSocialLogin.module.scss';

interface Props {
  children: React.ReactNode;
  social: string;
  className?: string;
  leftIcon?: React.ReactNode;
  isSignUp?: boolean;
}

const ButtonSocialLogin = ({
  children,
  social,
  isSignUp,
  className,
  ...rest
}: Props) => {
  const CALLBACK_CALL = false;
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const getLoginSocialURL = async () => {
    return Request.call({
      url: `/login/${social}`,
      method: 'GET',
    });
  };
  const handleClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await getLoginSocialURL();
    setLoading(false);
    const url = get(resp, 'data.url') || '';
    const rUrl = url;
    if (rUrl) window.location.href = rUrl;
  };
  const getLoginSocialCallback = async () => {
    const url = `/login/${social}/callback${location.search}${
      isSignUp ? '?isSignUp=true' : ''
    }`;
    return Request.call({
      url,
      method: 'GET',
    });
  };

  useEffect(() => {
    async function fetchData() {
      if (!CALLBACK_CALL) {
        const token = String(getUrlParamValueByKey(location.search, 'token'));
        if (token) {
          dispatch(syncSetToken(token));
        } else {
          navigate('/login');
        }
      } else {
        setLoading(true);
        const resp = await getLoginSocialCallback();
        setLoading(false);
        if (resp.status === 1) {
          if (get(resp, 'data.id')) {
            console.log('LOGIN SUCCESSSFULLY');
            // await dispatch(doLoginSocial(resp));
            // const redirectL =
            //   sessionStorage.getItem("redirect_URL") || "/admin/my-documents";
            // dispatch(socialLogin(resp.status));
            // history.push(redirectL);
            // sessionStorage.removeItem("redirect_URL");
          } else {
            const email = get(resp, 'social_data.email');
            const name = get(resp, 'social_data.name');
            console.log('REGISTER WITH EMAIL AND NAME', `${email} ${name}`);
            // const message = get(resp, "message");
            // dispatch(socialLogin(resp.status));
            // history.push(
            //   `/auth/register?email=${email}&name=${name}&message=${message}`
            // );
          }
        } else {
          if (resp.errorStatus === 423) {
            // const userId = get(resp, "data.id");
            // dispatch(socialLogin(resp.errorStatus));
            // history.push({
            //   pathname: `/auth/invitations/invalid`,
            //   state: {
            //     redirectTo: ``,
            //     btnText: "Create Your Company",
            //     userId: userId,
            //     description:
            //       "You can create your own Company Account here to get started.",
            //   },
            // });
            console.log(
              'LOGIN FAILED, ERROR: You can create your own Company Account here to get started.'
            );
          } else if (resp.errorStatus === 404) {
            // dispatch({
            //   type: Types.LOGIN_FAILURE,
            //   status: resp.status,
            //   message: resp.message,
            // });
            // dispatch(socialLogin(resp.errorStatus));
            // return history.push("/auth/login");
            console.log('LOGIN FAILED, ERROR: LOGIN_FAILURE');
          } else {
            // dispatch({
            //   type: Types.LOGIN_DEACTIVATED,
            //   user: resp.data,
            // });
            // return history.push("/auth/login");
            console.log('LOGIN FAILED, ERROR: LOGIN_DEACTIVATED');
          }
        }
      }
    }
    if ((location.search || '').includes(`success=true`)) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  return (
    <Button
      variant="outline"
      {...rest}
      loading={loading}
      onClick={handleClick}
      className={cn(classes.wrapper, className)}
    >
      {children}
    </Button>
  );
};

export default ButtonSocialLogin;
