export const intialName = (firstName, lastName) => {
  if (!firstName && !lastName) return 'N/A';
  // If name has space, first word is firstName, second is lastName. Otherwise we will display first and second letter of name
  const name = firstName || lastName;
  return (
    !!firstName && !!lastName
      ? `${firstName[0]}${lastName[0]}`
      : `${name[0]}${name[1]}`
  ).toUpperCase();
};
export const displayName = (firstName, lastName) => {
  return [firstName, lastName].filter((v) => !!v).join(' ');
};
export const displayRoles = (roles) => {
  return (roles || [])
    .map((r) => r.name)
    .filter((v) => !!v)
    .join(', ');
};

export const trimText = (val) => {
  if (typeof val !== 'string' || !val) return '';
  return val.trim();
};
