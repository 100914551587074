import { LoadingOverlay } from 'src/components';
import classes from './Home.module.scss';
import { useLoadingData } from './selectorData';

const Loading = () => {
  const { dashboardLoading } = useLoadingData();
  return (
    <LoadingOverlay
      visible={dashboardLoading}
      className={classes.loading}
      fixed
    />
  );
};

export default Loading;
