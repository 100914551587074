import cn from 'classnames';
import { Arrow } from 'src/components';
import { TASK_TYPE_MAPPING } from 'src/helpers/constants';
import { getDPDate } from 'src/utils/date';
import classes from './Tasks.module.scss';

type TaskItem = {
  id: number;
  is_read?: boolean;
  type: string;
  title: string | React.ReactNode;
  property: Record<string, unknown>;
  source: Record<string, unknown>;
  updated_at: string;
};
type Props = {
  className?: string;
  items: TaskItem[];
  onClick?: (item?: TaskItem) => void;
};

export const Circle = ({ className = '', hidden = false, style = {} }) => {
  return (
    <span
      className={cn(
        classes.circle,
        {
          [classes.hidden]: hidden,
        },
        className
      )}
      style={style}
    ></span>
  );
};
const TaskText = ({ children }) => {
  return <span className={classes.taskText}>{children}</span>;
};

const Tasks = ({ className, items, onClick }: Props) => {
  return (
    <ul className={cn(classes.wrapper, className)}>
      {items.map((item) => {
        const typeDisplayed = TASK_TYPE_MAPPING[item.type] || item.type;
        const titleArr = [
          item.property?.name,
          item.source?.type,
          getDPDate(item.updated_at),
        ];
        return (
          <li
            key={item.id}
            onClick={() => {
              if (onClick) onClick(item);
            }}
          >
            <Circle hidden={item.is_read} />
            <TaskText>
              <b>{typeDisplayed}</b> • {titleArr.join(' • ')}
            </TaskText>
            <Arrow direction="right" className={classes.arrowRight} />
          </li>
        );
      })}
    </ul>
  );
};

export default Tasks;
