import i18n from 'i18next';
import login_en from 'src/pages/Auth/Login/translations/en.json';
import login_es from 'src/pages/Auth/Login/translations/es.json';
import myProfile_en from 'src/pages/MyProfile/translations/en.json';
import myProfile_es from 'src/pages/MyProfile/translations/es.json';
import newReport_en from 'src/pages/NewReport/translations/en.json';
import newReport_es from 'src/pages/NewReport/translations/es.json';
import common_en from 'src/translations/en.json';
import common_es from 'src/translations/es.json';

// eslint-disable-next-line import/no-named-as-default-member
i18n.init({
  fallbackLng: 'en',
  // debug: true,
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources: {
    en: {
      login: login_en,
      myProfile: myProfile_en,
      common: common_en,
      newReport: newReport_en,
    },
    es: {
      login: login_es,
      myProfile: myProfile_es,
      common: common_es,
      newReport: newReport_es,
    },
  },
});

export default i18n;
