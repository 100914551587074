import { useReducerData } from 'src/store/hooks';
import { filterOptions } from 'src/utils/options';
import { makeRoleOption, makeUserOption } from 'src/utils/role';
import { displayName } from 'src/utils/text';

const buildLocations = (arr, parentId?: string, parentName?: string) => {
  return (arr || []).map((item) => {
    const tIdPath = [parentId || '', item.id].filter((val) => !!val).join('/');
    const tNamePath = [parentName || '', item.name]
      .filter((val) => !!val)
      .join(' > ');
    return {
      value: item.id,
      label: item.name,
      subs: buildLocations(
        item.descendants || item.children,
        tIdPath,
        tNamePath
      ),
      obj: item,
      idPath: tIdPath,
      namePath: tNamePath,
    };
  });
};
const useOptions = () => {
  const allFolders = useReducerData('shared', 'allFolders.data.data', []);
  const roles = useReducerData('shared', 'roles.data', []);
  const supervisors = useReducerData('shared', 'supervisors.data', []);
  const allUsers = useReducerData('shared', 'allUsers.data', []);
  const properties = useReducerData('shared', 'properties.data', []);
  const certificationTypes = useReducerData(
    'shared',
    'certBuilderDropdown.data.types',
    []
  );
  const certificationStatuses = useReducerData(
    'shared',
    'certBuilderDropdown.data.statuses',
    []
  );
  const certificationPrograms = useReducerData(
    'shared',
    'certBuilderDropdown.data.programs',
    []
  );
  const certificationPermissions = useReducerData(
    'shared',
    'certBuilderDropdown.data.permissions',
    []
  );
  const certificationNotices = useReducerData(
    'shared',
    'certBuilderDropdown.data.notice_options',
    []
  );
  const certificationLocations = useReducerData(
    'shared',
    'certBuilderDropdown.data.locations',
    []
  );
  const tags = useReducerData('shared', 'tags.data', []);
  const pLocations = useReducerData(
    'property',
    'propertyFilters.data.locations',
    []
  );
  const pProperties = useReducerData(
    'property',
    'propertyFilters.data.properties',
    []
  );
  const timezones = useReducerData('shared', 'timezones.data', []);
  const certificateTypes = useReducerData(
    'shared',
    'certificationTypes.data',
    []
  );
  const workflowTypes = useReducerData('shared', 'workflowTypes.data', []);
  const workflowTypesBuilder = useReducerData(
    'shared',
    'workflowTypesBuilder.data',
    []
  );
  const departments = useReducerData('shared', 'departments.data', []);
  const certificateTypeOptions = certificateTypes.map((type) => ({
    value: type.id,
    label: type.name,
  }));
  const workflowTypeOptions = workflowTypes.map((type) => ({
    value: type.id,
    label: type.name,
  }));
  const workflowTypesBuilderOptions = workflowTypesBuilder.map((type) => ({
    value: type.id,
    label: type.name,
  }));
  // TODO: roles need to be updated
  const tRoles = [];
  const roleOptionsWithoutGroup = [];
  const roleOptionsWithGroup = [];
  const roleDropdownOptionsWithGroup = [];
  const tRoleKeys = Object.keys(roles);
  tRoleKeys.forEach((key: string) => {
    const tRoleArr = roles[key];
    tRoleArr.map((role, index: number) => {
      const tRoleObj = {
        value: role.name,
        label: role.name,
        obj: role,
        group: key,
      };
      roleOptionsWithGroup.push(tRoleObj);
      roleOptionsWithoutGroup.push({
        value: tRoleObj.value,
        label: tRoleObj.label,
        obj: tRoleObj.obj,
      });
      if (index === 0) {
        roleDropdownOptionsWithGroup.push({
          isLabel: true,
          label: key,
          value: `${key}_label`,
        });
      }
      roleDropdownOptionsWithGroup.push(tRoleObj);
      tRoles.push(role);
    });
  });
  const supervisorOptions = supervisors.map((user) => ({
    value: user.id,
    label: displayName(user.first_name, user.last_name),
  }));
  const propertyOptions = properties.map((p) => ({
    value: p.id,
    label: p.name,
    obj: p,
  }));
  const certificationTypeOptions = certificationTypes.map((type) => ({
    value: type.id,
    label: type.name,
  }));
  const certificationStatusOptions = certificationStatuses.map((status) => ({
    value: status.id,
    label: status.name,
  }));
  const certificationProgramOptions = certificationPrograms.map((program) => ({
    value: program.id,
    label: program.name,
  }));
  const certificationPermissionOptions = certificationPermissions.map(
    (permission) => ({
      value: permission,
      label: permission,
    })
  );
  const certificationNoticeOptions = certificationNotices.map((notice) => ({
    value: notice,
    label: notice,
  }));
  const certificationLocationOptions = [];
  Object.keys(certificationLocations).forEach((key) => {
    certificationLocationOptions.push({
      value: key,
      label: key,
    });
    certificationLocations[key].forEach((location) => {
      certificationLocationOptions.push({
        value: location.id,
        label: location.name,
        parent: key,
      });
    });
  });
  const pLocationOptions = pLocations.map((location) => ({
    value: location,
    label: location,
  }));
  const pPropertyOptions = pProperties.map((property) => ({
    value: property.id,
    label: property.name,
  }));
  const tagOptions = tags.map((tag) => ({
    value: tag.id,
    label: tag.name,
  }));
  const departmentOptions = departments.map((department) => ({
    value: department.id,
    label: department.name,
  }));
  const allUserOptions = allUsers.map((user) => ({
    value: user.id,
    label: displayName(user.first_name, user.last_name),
  }));
  const permissionOptions = [
    {
      isLabel: true,
      label: 'Roles',
    },
    ...filterOptions(
      tRoles.map((r) => makeRoleOption(r)),
      'label',
      ['Guest']
    ),
    {
      isLabel: true,
      label: 'Users',
    },
    ...allUsers.map((u) => makeUserOption(u)),
  ];
  const allFolderOptions = buildLocations(allFolders);
  const timezoneOptions = timezones.map((t) => t);
  return {
    roleOptionsWithGroup,
    roleOptionsWithoutGroup,
    roleDropdownOptionsWithGroup,
    supervisorOptions,
    propertyOptions,
    certificationTypeOptions,
    certificateTypeOptions,
    certificationStatusOptions,
    certificationProgramOptions,
    certificationPermissionOptions,
    certificationNoticeOptions,
    certificationLocationOptions,
    pLocationOptions,
    pPropertyOptions,
    tagOptions,
    departmentOptions,
    allUserOptions,
    permissionOptions,
    allFolderOptions,
    timezoneOptions,
    workflowTypeOptions,
    workflowTypesBuilderOptions,
  };
};

export default useOptions;
