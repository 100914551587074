import cn from 'classnames';
import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { ReactComponent as BellSvg } from 'src/assets/icons/Bell.svg';
import { ReactComponent as DownloadSvg } from 'src/assets/icons/Download.svg';
import { ReactComponent as File1Svg } from 'src/assets/icons/File1.svg';
import { Button } from 'src/components';
import { Input, Select } from 'src/components/FormFields';
import { getFieldByKey } from 'src/pages/ResidentDetail/utils';
import ContentBlock from '../ContentBlock';
import UploadFileBtn from '../UploadFileBtn';
import classes from './SectionBlock.module.scss';
import { getAllSubFiles } from './utils';

const ToggleContentBlock = ({
  dateInputs = [],
  dropdown = [],
  values = {},
  onChange = null,
}) => {
  const dropdownLabel = dropdown?.[0];
  const dropdownOptions = dropdown.filter((_, i) => i !== 0);
  return (
    <div className={classes.toggleContent}>
      <ul>
        {dateInputs.map((dInput, index) => {
          const inputName = `date_${index}`;
          return (
            <li key={index}>
              <span>{dInput.value}</span>
              <Input
                inputType="mask"
                mask="99/99/9999"
                placeholder="00/00/0000"
                autoComplete="date"
                value={values?.[inputName] || ''}
                onChange={(e) => {
                  onChange(inputName, e.target.value);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  onChange(inputName, val, true);
                }}
                radius="md"
                size="xs"
                layout="horizontal"
              />
            </li>
          );
        })}
        {!!dropdownLabel && (
          <li>
            <span>{dropdownLabel}</span>
            <Select
              data={dropdownOptions.map((v) => ({
                value: v,
                label: v,
              }))}
              size="xs"
              radius="md"
              className={classes.dropdownSelect}
              value={values?.['dropdown']}
              placeholder="Select"
              onChange={(val) => {
                onChange('dropdown', val);
              }}
            />
          </li>
        )}
      </ul>
    </div>
  );
};

const SectionBlock = ({
  fields,
  values,
  onFieldChange,
  onViewFile,
  displaySub = false,
  subs = [],
  isSub = false,
  isPreview = false,
  getPreviewSubValues = null,
  allPreviewSubFiles = null,
  checkboxObj = null,
  checks = null,
  onCheckChanged = null,
  titlePrimary = false,
  borderColor = '',
  onBell = null,
  subBorder = false,
  isWorkflow = false,
}) => {
  const schemaFieldIdKey = isWorkflow
    ? 'schema_field_id'
    : 'certificate_schema_field_id';
  const subFiles = isPreview
    ? allPreviewSubFiles || []
    : getAllSubFiles(subs, values);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateDropdownValue, setDateDropdownValue] = useState({} as any);
  const title = getFieldByKey(fields, 'Name', 'value');
  const tooltip = getFieldByKey(fields, 'Tooltip', 'value');
  const associatedFile = getFieldByKey(fields, 'Associated File', 'value');
  const dropdownInputId = getFieldByKey(fields, 'Dropdown', 'id');
  const dropdownInputValues = getFieldByKey(fields, 'Dropdown', 'value') || [];
  const dateInputFields = (fields || []).filter((f) =>
    f.label.includes('Date Input')
  );
  const dropdownInputFields = (fields || []).filter((f) =>
    f.label.includes('Dropdown')
  );
  const hasToggleContent =
    dropdownInputValues.length > 0 || dateInputFields.length > 0;
  const canUploadFile = !!getFieldByKey(fields, 'Upload File', 'value');
  const canUploadFileId = getFieldByKey(fields, 'Upload File', 'id');
  const dropdownValueObj = getFieldByKey(
    values,
    dropdownInputId,
    '',
    schemaFieldIdKey,
    true
  );
  const uploadFileValueObj = getFieldByKey(
    values,
    canUploadFileId,
    '',
    schemaFieldIdKey,
    true
  );
  const dropdownInputValueId = dropdownValueObj?.id;
  const existingFiles = [...(uploadFileValueObj?.files || []), ...subFiles];

  useEffect(() => {
    const newDateDropdownValue = {};
    dateInputFields.forEach((obj, index) => {
      newDateDropdownValue[`date_${index}`] =
        find(values || [], {
          [schemaFieldIdKey]: obj.id,
        })?.value || '';
    });
    dropdownInputFields.forEach((obj) => {
      newDateDropdownValue['dropdown'] =
        find(values || [], {
          [schemaFieldIdKey]: obj.id,
        })?.value || null;
    });
    setDateDropdownValue(newDateDropdownValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentBlock
        title={title}
        tooltip={tooltip}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        dontToggle={!hasToggleContent}
        toggleContent={
          hasToggleContent ? (
            <ToggleContentBlock
              dateInputs={dateInputFields}
              dropdown={dropdownInputValues}
              values={dateDropdownValue}
              onChange={(key, value, isBlur) => {
                setDateDropdownValue({
                  ...dateDropdownValue,
                  [key]: value,
                });
                if (key === 'dropdown') {
                  onFieldChange({
                    value: value,
                    fieldId: dropdownInputId,
                    valueId: dropdownInputValueId,
                  });
                } else if (isBlur) {
                  if (value) {
                    const tIndex = Number(key.split('_')[1]);
                    const foundValue = find(values || [], {
                      [schemaFieldIdKey]: dateInputFields[tIndex]?.id,
                    });
                    onFieldChange({
                      value: value,
                      fieldId: dateInputFields[tIndex]?.id,
                      valueId: foundValue?.id,
                    });
                  }
                }
              }}
            />
          ) : null
        }
        // toggleContentIsChildren
        titlePrimary={titlePrimary}
        alwaysShowHA
        className={cn(classes.wrapper, {
          [classes.isSub]: isSub,
        })}
        borderColor={borderColor}
        checkboxObj={checkboxObj}
        headerAdditional={
          <div className={classes.headerAdditional}>
            {associatedFile ? (
              <Button
                variant="default"
                radius="md"
                onClick={() => {
                  const win = window.open(
                    typeof associatedFile === 'string'
                      ? associatedFile
                      : associatedFile.file,
                    '_blank'
                  );
                  win.focus();
                }}
                leftIcon={<DownloadSvg />}
                size="sm"
                color="green"
              >
                Download
              </Button>
            ) : (
              <div></div>
            )}
            {!!canUploadFile && (
              <>
                <Button
                  variant="default"
                  radius="md"
                  onClick={() => {
                    if (existingFiles.length > 0) {
                      onViewFile(existingFiles);
                    }
                  }}
                  leftIcon={<File1Svg />}
                  size="sm"
                >
                  {existingFiles.length} Doc
                  {existingFiles.length === 1 ? '' : 's'}
                </Button>
                <UploadFileBtn
                  title="Upload File"
                  onChange={(tFiles) => {
                    onFieldChange(
                      {
                        value: canUploadFile ? 1 : 0,
                        fieldId: canUploadFileId,
                        valueId: uploadFileValueObj?.id,
                        fileValues: tFiles,
                      },
                      { setUploadLoading }
                    );
                  }}
                  loading={uploadLoading}
                  multiple
                />
              </>
            )}
            <BellSvg
              className={cn(classes.bellIcon, {
                [classes.bellParentHasSub]: subs?.length > 0,
              })}
              onClick={onBell}
            />
          </div>
        }
      />
      {!!displaySub && (
        <>
          {subs.map((s) => {
            return (
              <SectionBlock
                fields={s.fields}
                borderColor={
                  !!subBorder && !s.is_completed && !checks[s.id] ? 'red' : ''
                }
                values={
                  isPreview && getPreviewSubValues
                    ? getPreviewSubValues(s.id)
                    : values
                }
                onFieldChange={(tValues, tSetting) => {
                  onFieldChange(tValues, tSetting, s);
                }}
                onViewFile={(tFiles) => {
                  onViewFile(tFiles, s.id);
                }}
                displaySub={false}
                key={s.id}
                isSub
                checkboxObj={{
                  checked: checks[s.id],
                  onChange: (val) => {
                    if (onCheckChanged) onCheckChanged(val, s.id);
                  },
                }}
                onBell={() => {
                  onBell(s.id);
                }}
              />
            );
          })}
        </>
      )}
    </>
  );
};

export default SectionBlock;
