import { Field } from 'formik';
import get from 'lodash/get';
import { useState } from 'react';
import { SSN_FIELD_SUFFIX } from 'src/helpers/constants';
import Input, { OInputProps } from '../Input';

type Props = OInputProps & {
  forceError?: string | boolean;
  name: string;
  type?: string;
  errorBg?: boolean;
  onChangeCb?: any;
};

const FormikInput = (props: Props) => {
  const {
    name,
    forceError = false,
    errorBg,
    isSSN,
    onChangeCb,
    ...otherProps
  } = props;
  const [inputType, setInputType] = useState(isSSN ? '' : props.inputType);

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { touched, errors, setFieldValue, values } = form;
        const value = field.value;
        const errorMessage = get(errors, field.name);
        const tTouched = get(touched, field.name);
        const thisError =
          (tTouched && !!errorMessage ? errorMessage : '') || forceError;
        const ssnMaskName = `${name}${SSN_FIELD_SUFFIX}`;
        const ssnMaskValue = values[ssnMaskName];
        const addProps = {} as any;
        if (isSSN) {
          addProps.id = name;
          addProps.focusName = name;
          addProps.placeholder = '000-00-0000';
          addProps.mask = '999-99-9999';
        }
        return (
          <Input
            {...otherProps}
            {...field}
            onChange={(e) => {
              setFieldValue(name, e.target.value);
              if (onChangeCb) onChangeCb(e.target.value);
            }}
            value={(isSSN && inputType === '' ? ssnMaskValue : value) || ''}
            error={thisError}
            errorBg={errorBg ? thisError : !!forceError}
            inputType={inputType}
            onSSNFocus={() => {
              setInputType('mask');
            }}
            onBlur={(e) => {
              // Check and update mask for SSN when End user onBlur
              // And keep actual value of SSN to display when End user focus back
              if (isSSN) {
                const val = e.target.value;
                if (val?.length === 11) {
                  setInputType('');
                  const valArr = val.split('-');
                  setFieldValue(ssnMaskName, `***-**-${valArr[2]}`);
                }
              }
              field.onBlur(e);
            }}
            isSSN={isSSN}
            {...addProps}
          />
        );
      }}
    </Field>
  );
};

export default FormikInput;
