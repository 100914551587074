import { Button, Card } from 'src/components';
import { Checkbox } from 'src/components/FormFields';
import classes from './Footer.module.scss';

const Footer = ({
  disabled,
  loading = false,
  onCancel,
  isDisabled = false,
  onDownload,
  downloadLoading = false,
  values,
  setFieldValue,
  onValuesChange,
}) => {
  return (
    <Card className={classes.wrapper} gapSize="sm" contentGap={20}>
      <div className={classes.info}>
        <p>
          Please keep copies of all documentation (this report, statements, and
          other reports) for your records as you may be asked to recall
          information or provide documentation in the future.
        </p>
        <p>
          Please keep in mind that the sooner we are able to forward all
          information and reports to our insurance company, the sooner they can
          begin to mitigate the extent of the claim.
        </p>
        <p>
          Suggested documentation includes photos, videos, recorded or written
          statements.
        </p>
        <div className={classes.uploadCheckboxes}>
          <span>
            Please Confirm the following items have been uploaded or are not
            necessary before submitting the Incident Report:
          </span>
          <div>
            <Checkbox
              checked={values.photos_required}
              onChange={(e) => {
                setFieldValue('photos_required', e.target.checked);
                onValuesChange();
              }}
              pointer
              radius="xs"
              className={classes.checkbox}
              label="Photos"
              disabled={isDisabled}
            />
            <Checkbox
              checked={values.witness_required}
              onChange={(e) => {
                setFieldValue('witness_required', e.target.checked);
                onValuesChange();
              }}
              pointer
              radius="xs"
              className={classes.checkbox}
              label="Witness Statements"
              disabled={isDisabled}
            />
            <Checkbox
              checked={values.related_documents_required}
              onChange={(e) => {
                setFieldValue('related_documents_required', e.target.checked);
                onValuesChange();
              }}
              pointer
              radius="xs"
              className={classes.checkbox}
              label="Other Related Documents"
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          radius="md"
          loading={downloadLoading}
          textWeight="xl"
          className={classes.downloadBtn}
          onClick={onDownload}
        >
          Download Report
        </Button>
        <Button
          variant="default"
          textWeight="xl"
          radius="md"
          onClick={onCancel}
          disabled={loading}
        >
          {isDisabled ? 'Back' : 'Cancel'}
        </Button>
        {!isDisabled && (
          <Button
            type="submit"
            radius="md"
            disabled={disabled}
            loading={loading}
            textWeight="xl"
          >
            Save Incident Report
          </Button>
        )}
      </div>
    </Card>
  );
};

export default Footer;
