import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_SET_CERTIFICATION_FIELDS',
  'GET_CERTIFICATE_LIST',
  'GET_CERTIFICATE_DETAIL',
  'GET_CERTIFICATIONS',
  'GET_CERTIFICATION_FIELDS',
  'GET_CERTIFICATION_PENDING',
  'GET_CERTIFICATE_NOTE',
  'ADD_CERTIFICATION',
  'UPDATE_CERTIFICATION',
  'BUILD_CERTIFICATE',
  'DELETE_CERTIFICATE',
  'DELETE_CERTIFICATE_FILE',
  'SAVE_CERTIFICATE_FIELDS',
  'ADD_CERTIFICATE_NOTE',
  'UPLOAD_CERTIFICATE_FULL_FILE',
  'DELETE_CERTIFICATE_FULL_FILE',
  'DELETE_CERTIFICATE_SECTION',
  'MARK_CERTIFICCATE_SECTION_COMPLETED',
  'MARK_CERTIFICCATE_COMPLETED'
);
