import { getCurrentTimezone } from 'src/utils/date';

export const getInitialValues = (data) => {
  return {
    preferred_name: '',
    language: 'english',
    timezone: getCurrentTimezone(),
    email: data?.email || '',
    office_phone: '',
    password: '',
    confirmPassword: '',
  };
};
