import { Tabs as ManTabs, TabsProps } from '@mantine/core';
import cn from 'classnames';
import classes from './Tabs.module.scss';

export type TabType = {
  key: string;
  title: string | React.ReactNode;
  content: string | React.ReactNode;
};

type Props = Omit<TabsProps, 'children'> & {
  className?: string;
  items: TabType[];
};

const Tabs = ({ className = '', items, ...other }: Props) => {
  return (
    <ManTabs {...other} className={cn(classes.wrapper, className)}>
      <ManTabs.List>
        {items.map((tab: TabType) => {
          return (
            <ManTabs.Tab value={tab.key} key={tab.key}>
              {tab.title}
            </ManTabs.Tab>
          );
        })}
      </ManTabs.List>
      {items.map((tab: TabType) => {
        return (
          <ManTabs.Panel value={tab.key} pt="xs" key={tab.key}>
            {tab.content}
          </ManTabs.Panel>
        );
      })}
    </ManTabs>
  );
};

export default Tabs;
