import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';

const useInfiniteScroll = ({
  elementId = '',
  threshold = 250,
  debounceTime = 500,
  onLoadMore,
  shouldLoadMore = true,
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = useCallback(
    debounce(async () => {
      const element = elementId
        ? document.getElementById(elementId)
        : document.body;
      const scrollTop = elementId ? element.scrollTop : window.pageYOffset;
      if (
        element.offsetHeight + scrollTop < element.scrollHeight - threshold ||
        scrollTop === 0
      ) {
        return;
      }
      // call load more
      if (onLoadMore && shouldLoadMore) onLoadMore();
    }, debounceTime),
    [elementId, debounceTime, onLoadMore, shouldLoadMore]
  );

  // observe scroll
  useEffect(() => {
    const element = elementId ? document.getElementById(elementId) : document;
    if (element) {
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll, elementId]);
};

export default useInfiniteScroll;
