import { LoadingOverlay } from 'src/components';
import classes from './Documents.module.scss';
import { useLoadingData } from './selectorData';

const Loading = () => {
  const {
    documentsLoading,
    folderDocumentsLoading,
    rdDocumentsLoading,
    documentDetailLoading,
    moveDocumentLoading,
  } = useLoadingData();
  return (
    <LoadingOverlay
      visible={
        documentsLoading ||
        folderDocumentsLoading ||
        rdDocumentsLoading ||
        documentDetailLoading ||
        moveDocumentLoading
      }
      className={classes.loading}
      fixed
    />
  );
};

export default Loading;
