import find from 'lodash/find';
import { ASSIGN_STATUS_TYPE } from 'src/pages/BuildCertificate/constants';

const getSectionFieldsData = (obj) => {
  const tDropdownOptions = (obj?.check_dropdown?.checklist || [])
    .filter((obj) => !!obj.checked)
    .map((obj) => obj.value);
  const sectionFields = [
    {
      id: 1,
      label: 'Name',
      value: obj.section_name,
      type: 'label',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    },
    {
      id: 2,
      label: 'Associated File',
      value: null,
      type: 'file',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    },
    {
      id: 3,
      label: 'Assigned To',
      value: obj.section_options,
      type: 'dropdown',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    },
    {
      id: 4,
      label: 'Upload File',
      value: obj.check_upload_file ? 1 : 0,
      type: 'boolean',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    },
    {
      id: 5,
      label: 'Tooltip',
      value: obj.notice_information,
      type: 'tooltip',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    },
    {
      id: 6,
      label: 'Assign Status',
      value: obj.assign_status,
      type: ASSIGN_STATUS_TYPE,
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    },
  ];
  (obj?.check_schedule || []).forEach((val, tIndex) => {
    sectionFields.push({
      id: sectionFields.length,
      label: `Date Input ${tIndex + 1}`,
      value: val,
      type: 'date',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    });
  });
  if (obj?.check_dropdown?.value && tDropdownOptions.length > 0) {
    sectionFields.push({
      id: sectionFields.length,
      label: 'Dropdown',
      value: [obj.check_dropdown.value, ...tDropdownOptions],
      type: 'dropdown',
      can_upload_file: 0,
      can_use_input: 0,
      dropdown_values: [],
    });
  }
  return sectionFields;
};

export const extractCertData = (defaultData) => {
  const defaultNoticeSections = defaultData?.flowBuilder?.children || [];
  const tNotice = find(defaultNoticeSections, { sectionType: 'notice' }) || {};
  const tSections = defaultNoticeSections.filter(
    (obj) => obj.sectionType === 'section'
  );
  const defaultNotice = {
    ...tNotice,
    fields: [
      {
        id: 1,
        label: 'Name',
        value: tNotice.section_name,
        type: 'label',
        can_upload_file: 0,
        can_use_input: 0,
        dropdown_values: [],
      },
      {
        id: 2,
        label: 'Notice Options',
        value: tNotice.section_options,
        type: 'dropdown',
        can_upload_file: tNotice.check_upload_file,
        can_use_input: 1,
        dropdown_values: [],
      },
      {
        id: 3,
        label: 'Upload File',
        type: 'file-input',
        can_upload_file: tNotice.check_upload_file,
        can_use_input: 0,
        dropdown_values: [],
        value: tNotice.check_upload_file ? 1 : 0,
      },
      {
        id: 4,
        label: 'Scheduled Interview Date Input',
        type: 'date',
        can_upload_file: tNotice.check_schedule,
        can_use_input: 1,
        dropdown_values: [],
        value: tNotice.check_schedule ? 1 : 0,
      },
      {
        id: 5,
        label: 'Tooltip',
        value: tNotice.notice_information,
        type: 'tooltip',
      },
    ],
  };
  const defaultSections = tSections.map((obj) => {
    const sectionFields = getSectionFieldsData(obj);
    return {
      ...obj,
      fields: sectionFields,
      childs: (obj.children || []).map((cObj) => {
        const subSectionfields = getSectionFieldsData(cObj);
        return {
          ...cObj,
          fields: subSectionfields,
        };
      }),
    };
  });

  return { defaultNotice, defaultSections };
};
