import cn from 'classnames';
import styled from 'styled-components';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/CloseCircle.svg';
import Icon from '../Icon';
import classes from './Card.module.scss';

interface FormCardContentProps {
  className?: string;
  column: number;
  children: React.ReactNode;
  inside?: boolean;
}

export const FormCardContent = ({
  className = '',
  children,
  column,
  inside,
}: FormCardContentProps) => {
  return (
    <StyledFormCardContent
      className={cn(classes.formCardContent, className)}
      column={column}
      inside={inside}
    >
      {children}
    </StyledFormCardContent>
  );
};

interface FormCardArrayBlockProps {
  className?: string;
  wrapperClassName?: string;
  column: number;
  children: React.ReactNode;
  onRemove: () => void;
  hideClose?: boolean;
}

export const FormCardArrayBlock = ({
  className = '',
  wrapperClassName = '',
  children,
  column,
  onRemove,
  hideClose,
}: FormCardArrayBlockProps) => {
  return (
    <div className={cn(classes.formCardArrayBlock, wrapperClassName)}>
      <FormCardContent
        className={cn(
          classes.formCardContent,
          {
            [classes.displayClose]: !hideClose,
          },
          className
        )}
        column={column}
      >
        {children}
      </FormCardContent>
      {hideClose ? (
        <div className={classes.hiddenIcon} />
      ) : (
        <Icon onClick={onRemove} className={classes.removeIcon}>
          <CloseCircleSvg />
          <span className={classes.removeIconText}>Remove</span>
        </Icon>
      )}
    </div>
  );
};

const StyledFormCardContent = styled.div`
  grid-template-columns: repeat(${({ column }) => column}, 1fr);
  gap: ${({ column }) => (column === 1 ? '0px' : '16px')};
`;
