import cn from 'classnames';
import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from '../Section';

const InsuranceClaim = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="Insurance Claim Information"
      onNoValue={() => {
        return (
          !values.insurance_name &&
          !values.insurance_phone &&
          !values.insurance_email &&
          !values.insurance_number
        );
      }}
    >
      <FormCardContent
        column={3}
        className={cn({
          'i-print-no-value':
            !values.insurance_name &&
            !values.insurance_phone &&
            !values.insurance_email,
        })}
      >
        <FormikInput
          name="insurance_name"
          label="Adjusters Name"
          placeholder="Full Name"
          type="text"
          autoComplete="insurance_name"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="insurance_phone"
          label="Adjusters Phone Number"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="insurance_phone"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
        <FormikInput
          name="insurance_email"
          label="Adjusters Email"
          placeholder="example@address.com"
          type="email"
          autoComplete="insurance_email"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
      </FormCardContent>
      <FormCardContent
        column={3}
        className={cn({
          'i-print-no-value': !values.insurance_number,
        })}
      >
        <FormikInput
          name="insurance_number"
          label="Insurance Claim Number"
          placeholder="0000000000"
          inputType="mask"
          mask="9999999999"
          autoComplete="insurance_number"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default InsuranceClaim;
