import { openConfirmModal, openContextModal } from '@mantine/modals';
import cn from 'classnames';
import React from 'react';
import classes from './modal.module.scss';

export const contextModalFn = (settings) => {
  openContextModal({
    ...settings,
  });
};

export const confirmModalFn = (settings) => {
  const normalCancelProps = {
    variant: 'subtle',
    color: 'red',
  };
  const {
    color,
    confirmProps,
    cancelProps,
    className,
    withCloseButton,
    isConfirm,
    ...other
  } = settings;
  openConfirmModal({
    title: settings?.title || 'Confirmation',
    children: <>Content</>,
    labels: { confirm: settings?.confirmText || 'Save', cancel: 'Cancel' },
    cancelProps: {
      ...(color === 'red'
        ? {
            variant: 'default',
            radius: 'md',
          }
        : normalCancelProps),
      ...cancelProps,
    },
    confirmProps: {
      ...(color === 'red'
        ? {
            radius: 'md',
          }
        : {}),
      ...(confirmProps || {}),
      color,
    },
    onCancel: settings?.onCancel,
    onConfirm: settings?.onConfirm,
    className: cn(
      {
        [classes.confirmRed]: color === 'red',
        [classes.confirmPrimary]: isConfirm,
      },
      className
    ),
    withCloseButton: color === 'red' ? false : withCloseButton,
    ...(other || {}),
  });
};
