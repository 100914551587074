import cn from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import multiHousingSvg from 'src/assets/images/multiHousing.svg';
import treeGirlSvg from 'src/assets/images/treeGirl.svg';
import { Logo } from 'src/components';
import classes from './Auth.module.scss';

interface Props {
  children: React.ReactNode;
  isAuthInvite?: boolean;
}

const AuthLayout: React.FC<Props> = ({ children, isAuthInvite }) => {
  const { i18n } = useTranslation('login');
  const hideLanguageSwitch = true;

  return (
    <div
      className={cn(classes.wrapper, {
        [classes.isAuthInvite]: isAuthInvite,
      })}
    >
      {!hideLanguageSwitch && (
        <ul>
          <li
            onClick={() => {
              i18n.changeLanguage('en');
            }}
          >
            EN
          </li>
          <li
            onClick={() => {
              i18n.changeLanguage('fr');
            }}
          >
            FR
          </li>
        </ul>
      )}
      <div className={classes.inner}>
        <Logo className={classes.logo} />
        {children}
      </div>
      <div className={classes.additionalImage}>
        <img src={treeGirlSvg} alt="tree-girl" />
        <img src={multiHousingSvg} alt="housing" />
      </div>
    </div>
  );
};

export default AuthLayout;
