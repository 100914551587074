import cn from 'classnames';
import { useEffect, useState } from 'react';
import { ReactComponent as FileSvg } from 'src/assets/icons/File.svg';
import { ReactComponent as Folder1Svg } from 'src/assets/icons/Folder1.svg';
import { Arrow, Collapse, FilePopover } from 'src/components';
import { useCurrentUser, useIsAdminAndExecutive } from 'src/helpers/hooks';
import { TDocument } from 'src/types/document';
import { getDPDate } from 'src/utils/date';
import { formatBytes, getIsFileOwner } from 'src/utils/file';
import classes from './FileBlock.module.scss';

interface Props {
  className?: string;
  onDelete?: any;
  onDetail?: (data, isFolder?: boolean) => void;
  onDuplicate?: (data) => void;
  data: TDocument;
  isExpandAll?: boolean;
  disabled?: boolean;
  onDocumentMoved?: (sourceId: number, targetId: number) => void;
  folderId?: number;
}

const FileBlock = ({
  className,
  onDelete,
  onDetail,
  onDuplicate,
  data,
  isExpandAll,
  disabled,
  onDocumentMoved,
  folderId,
}: Props) => {
  const currentUser = useCurrentUser();
  const isAdminAndExecutive = useIsAdminAndExecutive();
  const userIsOwner = getIsFileOwner(data, currentUser.id);
  const [opened, setOpened] = useState(false);
  const [isExpand, setIsExpand] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const isFolder = data?.is_directory;
  const hasSubs = !!(data.descendants || data.children)?.length;

  useEffect(() => {
    setIsExpand(isExpandAll);
  }, [isExpandAll]);

  return (
    <div
      draggable="true"
      className={classes.fileBlockWrapper}
      onDrop={(event) => {
        const dragObjStr = event.dataTransfer.getData('dragObj');
        const tDragObj = dragObjStr ? JSON.parse(dragObjStr) : {};
        const isDocumentSameDeep =
          (tDragObj.is_directory &&
            data.is_directory &&
            tDragObj.directory_id === data.id) ||
          (tDragObj.is_directory &&
            !data.is_directory &&
            (tDragObj.directory_id === data.directory_id ||
              tDragObj.id === data.directory_id)) ||
          (!tDragObj.is_directory &&
            data.is_directory &&
            tDragObj.directory_id === data.id) ||
          (!tDragObj.is_directory &&
            !data.is_directory &&
            tDragObj.directory_id === data.directory_id);

        if (!isDocumentSameDeep) {
          const sourceDocumentId = tDragObj.id;
          const targetDocumentId = data.is_directory
            ? data.id
            : data.directory_id;
          if (onDocumentMoved)
            onDocumentMoved(sourceDocumentId, targetDocumentId);
        }
        event.preventDefault();
        event.stopPropagation();
        return;
      }}
      onDragOver={(ev) => {
        ev.preventDefault();
      }}
      onDragStart={(event) => {
        // Handle drag layer
        const tEl = event.target as any;
        const crt = tEl.cloneNode(true);
        crt.style.width = '600px';
        crt.style.display = '-99999px';
        crt.style.position = 'fixed';
        crt.style.zIndex = '-1';
        if (crt.children[0]?.children?.[2]) {
          crt.children[0].children[2].style['display'] = 'none';
        }
        if (crt.children[1]) {
          crt.children[1].style['display'] = 'none';
        }

        document.body.appendChild(crt);
        event.dataTransfer.setDragImage(crt, 0, 0);

        event.dataTransfer.setData(
          'dragObj',
          JSON.stringify({
            ...data,
            root_directory_id: folderId,
          })
        );
        event.stopPropagation();
      }}
    >
      <FilePopover
        opened={opened}
        setOpened={setOpened}
        offsetX={offsetX}
        dropdownOffset={-20}
        items={[
          ...(isFolder && !!data?.can_view
            ? [
                {
                  label: 'View Details',
                  onClick: () => {
                    if (onDetail) onDetail(data, true);
                  },
                },
              ]
            : []),
          ...(isFolder && !!data?.can_edit
            ? [
                {
                  label: 'Duplicate Folder',
                  onClick: () => {
                    if (onDuplicate) onDuplicate(data);
                  },
                },
              ]
            : []),
          ...(userIsOwner || isAdminAndExecutive || !!data?.can_delete
            ? [
                {
                  label: `Delete this ${isFolder ? 'folder' : 'file'}`,
                  onClick: () => {
                    onDelete(data);
                  },
                },
              ]
            : []),
        ]}
      >
        <div
          className={cn(
            classes.wrapper,
            {
              [classes.isFolder]: isFolder,
              [classes.isDisabled]: disabled,
            },
            className
          )}
          onContextMenu={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (!disabled) {
              setOffsetX(e.screenX);
              setOpened(true);
            }
          }}
          onClick={
            disabled
              ? undefined
              : isFolder
              ? () => {
                  setIsExpand(!isExpand);
                }
              : () => {
                  onDetail && onDetail(data);
                }
          }
        >
          {isFolder ? <Folder1Svg /> : <FileSvg />}
          <span className={classes.title}>{data.name}</span>
          {!isFolder ? (
            <div className={classes.info}>
              <span>{data.form_number}</span>
              <span className={classes.fileSize}>{formatBytes(data.size)}</span>
              <span>{getDPDate(data.created_at)}</span>
            </div>
          ) : (
            hasSubs && (
              <span className={classes.arrowIcon}>
                <Arrow direction={isExpand && 'down'} onClick={() => {}} />
              </span>
            )
          )}
        </div>
      </FilePopover>
      {hasSubs && (
        <Collapse in={isExpand} className={classes.collapse}>
          {(data.descendants || data.children).map((sub) => {
            return (
              <FileBlock
                onDelete={onDelete}
                onDetail={onDetail}
                data={sub}
                key={sub.id}
                disabled={disabled}
                onDocumentMoved={onDocumentMoved}
                isExpandAll={isExpandAll}
                folderId={folderId}
                onDuplicate={onDuplicate}
              />
            );
          })}
        </Collapse>
      )}
    </div>
  );
};

export default FileBlock;
