import findIndex from 'lodash/findIndex';
import * as Yup from 'yup';
import { LOCATION_FIELD_SUFFIX } from 'src/helpers/constants';
import {
  getDisplayLocationFromPath,
  getDocumentPayloadData,
  getLocationIdPath,
} from 'src/utils/file';
import { makeRoleOption, makeUserOption } from 'src/utils/role';

export const schema = Yup.object().shape({
  // formNumber: Yup.string().required('This field is required!'),
  location: Yup.mixed().required('This field is required!'),
  // property: Yup.mixed().required('This field is required!'),
  // permissions: Yup.mixed().required('This field is required!'),
  // tags: Yup.mixed().required('This field is required!'),
});
export const schemaFolder = Yup.object().shape({});
const getPermissions = (roles, users) => {
  return !!roles.length || !!users.length
    ? [
        ...roles.map((r) => makeRoleOption(r).value),
        ...users.map((u) => makeUserOption(u).value),
      ]
    : null;
};
export const getInitialValues = (data, availableProperties) => {
  const roles = data?.roles || [];
  const users = data?.users || [];
  const rolesEdit = data?.roles_can_edit || [];
  const usersEdit = data?.users_can_edit || [];
  const rolesDelete = data?.roles_can_delete || [];
  const usersDelete = data?.users_can_delete || [];
  const rolesView = data?.roles_can_view || [];
  const usersView = data?.users_can_view || [];
  const properties = data?.properties || [];
  const tProperties = properties.filter(
    (p) => findIndex(availableProperties, { value: p.id }) >= 0
  );
  const tags = data?.tags || [];
  return {
    formNumber: data?.form_number || '',
    notes: data?.notes || '',
    location: getDisplayLocationFromPath(data?.v_path) || null,
    [`location${LOCATION_FIELD_SUFFIX}`]:
      getLocationIdPath(data?.ancestors?.[0] || {}) ||
      String(data?.directory_id),
    property: tProperties.map((p) => p.id),
    permissions: getPermissions(roles, users),
    permissions_to_edit: getPermissions(rolesEdit, usersEdit),
    permissions_to_view: getPermissions(rolesView, usersView),
    permissions_to_delete: getPermissions(rolesDelete, usersDelete),
    tags: tags.map((t) => t.id),
  };
};

export const getFileEditedBR = (values, isFolder) => {
  const {
    tags,
    properties,
    locationId,
    // permissionUsers,
    // permissionRoles,
    permissionUsersDelete,
    permissionRolesDelete,
    permissionUsersEdit,
    permissionRolesEdit,
    permissionUsersView,
    permissionRolesView,
  } = getDocumentPayloadData(values);
  return {
    directory_id: locationId,
    form_number: values.formNumber,
    notes: values.notes,
    properties: properties,
    tags: tags,
    isFolder,
    // users: permissionUsers,
    // roles: permissionRoles,
    users_can_delete: permissionUsersDelete,
    roles_can_delete: permissionRolesDelete,
    users_can_edit: permissionUsersEdit,
    roles_can_edit: permissionRolesEdit,
    users_can_view: permissionUsersView,
    roles_can_view: permissionRolesView,
    folderName: values.folderName,
  };
};
