import { Request } from './request';

export const apiGetSchedules = async (params = {}) => {
  return Request.call({
    url: '/schedules',
    method: 'GET',
    params,
  });
};
export const apiUpdateSchedule = async (data = {}) => {
  return Request.call({
    url: '/schedules',
    method: 'POST',
    data,
  });
};
export const apiActiveSchedule = async (data = {}) => {
  return Request.call({
    url: '/schedules/activate',
    method: 'PUT',
    data,
  });
};
