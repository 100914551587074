import cn from 'classnames';
import classes from './MenuItems.module.scss';

const MenuItems = ({ items, iconColor = false, className = '' }) => {
  return (
    <ul
      className={cn(
        classes.menuItems,
        {
          [classes.iconColor]: iconColor,
        },
        className
      )}
    >
      {items.map((item, i) => {
        return (
          <li
            key={i}
            className={cn({
              [classes.active]: item.active,
            })}
          >
            <span
              onClick={item.onClick}
              className={cn({
                [classes.nonCursor]: !item.onClick,
              })}
            >
              {item.icon}
              <span>{item.label}</span>
            </span>
            {!!item.subs?.length && (
              <ul>
                {item.subs.map((subItem, sIndex) => {
                  return (
                    <li
                      key={sIndex}
                      className={cn({
                        [classes.active]: subItem.active,
                      })}
                    >
                      <span
                        onClick={subItem.onClick}
                        className={cn({
                          [classes.nonCursor]: !subItem.onClick,
                        })}
                      >
                        {subItem.icon}
                        <span>{subItem.label}</span>
                      </span>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default MenuItems;
