import { STATUS } from 'src/helpers/constants';
import {
  NO_PROPERTY_VALUE,
  OPTION_ALL_VALUE,
} from 'src/helpers/constants/options';

export const getStatusBR = (data) => {
  return {
    is_active: data.activeStatus === STATUS.ACTIVE.text ? 1 : 0,
    status: data.status,
    note: data.note || '',
  };
};
export const getFilterParams = (keyword, settings) => {
  const params: Record<string, unknown> = {
    keyword: String(keyword || ''),
  };
  if (settings?.properties && settings.properties?.length) {
    params.properties = settings.properties.filter(
      (v) => v !== NO_PROPERTY_VALUE && v !== OPTION_ALL_VALUE
    );
    // No Property
    if (settings.properties.includes(NO_PROPERTY_VALUE)) {
      params.no_property = true;
    }
  }
  if (settings?.roles && settings.roles?.length) {
    params.roles = settings.roles.filter((v) => v !== OPTION_ALL_VALUE);
  }
  if (settings?.departments && settings.departments?.length) {
    if (!settings.departments.includes(OPTION_ALL_VALUE)) {
      params.departments = settings.departments.map((v) => Number(v));
    }
  }
  if (settings?.workStatus && settings.workStatus?.length) {
    params.work_status = settings.workStatus;
  }
  return params;
};
