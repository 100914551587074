import { Reducer } from 'redux';
import { handleData } from '../middlewares/handleData';
import Types from '../types/certification';
import { initialCertificationState } from './initialStates/certification';

const CertificationReducer: Reducer = (
  state = initialCertificationState,
  action
) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_SET_CERTIFICATION_FIELDS:
      return {
        ...state,
        certificationFields: {
          ...state.certificationFields,
          data: payload,
        },
      };
    case Types.GET_CERTIFICATE_LIST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificateList: {
            ...prevState.certificateList,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificateList: {
            loading: false,
            data: {
              ...payload,
              data: meta?.payload?.isMore
                ? [...(state.certificateList.data?.data || []), ...payload.data]
                : [...payload.data],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificateList: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_CERTIFICATE_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificateDetail: {
            ...prevState.certificateDetail,
            loading: true,
            data:
              meta?.payload?.certId !==
              prevState.certificateDetail.previousCertId
                ? {}
                : { ...prevState.certificateDetail.data },
            previousCertId: meta?.payload?.certId,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificateDetail: {
            loading: false,
            data: payload,
            error: '',
            previousCertId: meta?.payload?.certId,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificateDetail: {
            loading: false,
            data: {},
            error: payload,
            previousCertId: meta?.payload?.certId,
          },
        }),
      });
    case Types.GET_CERTIFICATIONS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certifications: {
            ...prevState.certifications,
            loading: true,
            data:
              !meta?.payload?.isMore &&
              meta?.payload?.certificate_schema_id !==
                prevState.certifications.previousCertId
                ? {}
                : { ...prevState.certifications.data },
            previousCertId: meta?.payload?.certificate_schema_id,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certifications: {
            loading: false,
            data: {
              ...payload,
              data: meta?.payload?.isMore
                ? [...(state.certifications.data?.data || []), ...payload.data]
                : [...payload.data],
            },
            error: '',
            previousCertId: meta?.payload?.certificate_schema_id,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certifications: {
            loading: false,
            data: {},
            error: payload,
            previousCertId: meta?.payload?.certificate_schema_id,
          },
        }),
      });
    case Types.GET_CERTIFICATION_FIELDS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificationFields: {
            ...prevState.certificationFields,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificationFields: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificationFields: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_CERTIFICATION_PENDING:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificationPending: {
            ...prevState.certificationPending,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificationPending: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificationPending: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_CERTIFICATE_NOTE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificateNote: {
            ...prevState.certificateNote,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificateNote: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificateNote: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.ADD_CERTIFICATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addCertification: {
            ...prevState.addCertification,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addCertification: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addCertification: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_CERTIFICATION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateCertification: {
            ...prevState.updateCertification,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateCertification: {
            loading: false,
            data: payload,
            error: '',
          },
          certificationFields: {
            ...state.certificationFields,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateCertification: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.BUILD_CERTIFICATE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          buildCertificate: {
            ...prevState.buildCertificate,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          buildCertificate: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          buildCertificate: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_CERTIFICATE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteCertificate: {
            ...prevState.deleteCertificate,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteCertificate: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteCertificate: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_CERTIFICATE_FILE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteCertificateFile: {
            ...prevState.deleteCertificateFile,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteCertificateFile: {
            loading: false,
            data: payload,
            error: '',
          },
          certificationFields: {
            ...state.certificationFields,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteCertificateFile: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.SAVE_CERTIFICATE_FIELDS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          saveCertificateFields: {
            ...prevState.saveCertificateFields,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          saveCertificateFields: {
            loading: false,
            data: payload,
            error: '',
          },
          certificationFields: {
            ...state.certificationFields,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          saveCertificateFields: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.ADD_CERTIFICATE_NOTE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          addCertificateNote: {
            ...prevState.addCertificateNote,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          addCertificateNote: {
            loading: false,
            data: payload,
            error: '',
          },
          certificateNote: {
            ...state.certificateNote,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          addCertificateNote: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPLOAD_CERTIFICATE_FULL_FILE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadCertificateFullFile: {
            ...prevState.uploadCertificateFullFile,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadCertificateFullFile: {
            loading: false,
            data: payload,
            error: '',
          },
          certificationFields: {
            ...state.certificationFields,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadCertificateFullFile: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_CERTIFICATE_FULL_FILE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteCertificateFullFile: {
            ...prevState.deleteCertificateFullFile,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteCertificateFullFile: {
            loading: false,
            data: payload,
            error: '',
          },
          certificationFields: {
            ...state.certificationFields,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteCertificateFullFile: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_CERTIFICATE_SECTION:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteCertificateSection: {
            ...prevState.deleteCertificateSection,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteCertificateSection: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteCertificateSection: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default CertificationReducer;
