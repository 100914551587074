const FakeInput = () => {
  return (
    <input
      name="username"
      style={{
        position: 'absolute',
        left: '-9999px',
      }}
    />
  );
};

export default FakeInput;
