import { Request } from './request';

export interface LoginPayload {
  email: string;
  password: string;
}

export const apiGetMe = async () => {
  return Request.call({
    url: '/profile',
    method: 'GET',
  });
};
export const apiLogin = async (data: LoginPayload) => {
  return Request.call({
    url: '/login',
    method: 'POST',
    data,
  });
};
export const apiUpdateProfile = async (data) => {
  return Request.call({
    url: '/profile/update?_method=PUT',
    method: 'POST',
    data,
    multipart: true,
  });
};
