import { useNavigate } from 'react-router-dom';
import { ReactComponent as BellSvg } from 'src/assets/icons/Bell.svg';
// import { ReactComponent as Folder1Svg } from 'src/assets/icons/Folder1.svg';
import { ReactComponent as StackSvg } from 'src/assets/icons/Stack.svg';
// import { ReactComponent as VersionsSvg } from 'src/assets/icons/Versions.svg';
import { Badge } from 'src/components';
import { Select } from 'src/components/FormFields';
import { LIST_8_YEARS } from 'src/helpers/constants/options';
import InfoIcons from 'src/pages/InOutBoard/components/InfoIcons';
import { displayName } from 'src/utils/text';
import classes from './ResidentDetail.module.scss';
import MenuItems from './components/MenuItems';
import { SUB_MENU_KEY } from './constants';
import { useIndexData } from './selectorData';

const Left = ({
  isCertDetail,
  setting,
  handleSettingChange,
  menuOpened,
  onOpenChanged,
  isWorkflow,
  params = null,
  loadCertData,
}) => {
  const certKey = isWorkflow ? 'workflow' : 'certificate';
  const navigate = useNavigate();
  const { certFields } = useIndexData();
  const pendingCerts =
    certFields?.[isWorkflow ? 'pending_workflows' : 'pending_certification'] ||
    [];
  const completedCerts =
    certFields?.[
      isWorkflow ? 'completed_workflows' : 'completed_certification'
    ] || [];
  const residentInfo = certFields?.[certKey]?.unit?.resident || {};
  const hideSomeThings = true;
  const goToOtherItem = (tCertId, cItemId) => {
    navigate(
      `/${
        isWorkflow ? 'workflow-list-builder' : 'certifications-list-builder'
      }/${tCertId}/${cItemId}`
    );
  };

  return (
    <div className={classes.left}>
      <div className={classes.topInfo}>
        <h3>{displayName(residentInfo.first_name, residentInfo.last_name)}</h3>
        <p>
          <span>Unit: {certFields?.[certKey]?.unit?.unit_id}</span>
          <span>{certFields?.[certKey]?.property?.name}</span>
        </p>
        <InfoIcons
          className={classes.infoIcons}
          email={residentInfo.email}
          phone={''}
          hideSchedule
          schedules={[]}
          reverse
        />
      </div>
      {!hideSomeThings && (
        <MenuItems
          items={[
            {
              label: 'Notifications',
              icon: (
                <Badge circle alert top count={7}>
                  <BellSvg />
                </Badge>
              ),
            },
          ]}
          className={classes.notificationMenuItems}
        />
      )}
      <MenuItems
        className={classes.menuItems}
        items={[
          {
            label: `Pending ${isWorkflow ? 'Workflows' : 'Certs'}`,
            onClick:
              pendingCerts.length === 0
                ? undefined
                : () => {
                    onOpenChanged(
                      SUB_MENU_KEY.PENDING_CERTS,
                      !menuOpened[SUB_MENU_KEY.PENDING_CERTS]
                    );
                  },
            active: menuOpened[SUB_MENU_KEY.PENDING_CERTS],
            icon: <StackSvg />,
            subs:
              menuOpened[SUB_MENU_KEY.PENDING_CERTS] && isCertDetail
                ? pendingCerts.map((c) => {
                    const itemId = isWorkflow
                      ? c.workflow_id
                      : c.certification_id;
                    return {
                      label: c.name,
                      onClick:
                        String(params?.certId) === String(c.id) &&
                        String(params?.certificationId) === String(itemId)
                          ? undefined
                          : () => {
                              goToOtherItem(c.id, itemId);
                            },
                    };
                  })
                : [],
          },
          {
            label: `Completed ${isWorkflow ? 'Workflows' : 'Certs'}`,
            onClick:
              completedCerts.length === 0
                ? undefined
                : () => {
                    onOpenChanged(
                      SUB_MENU_KEY.COMPLETED_CERTS,
                      !menuOpened[SUB_MENU_KEY.COMPLETED_CERTS]
                    );
                  },
            active: menuOpened[SUB_MENU_KEY.COMPLETED_CERTS],
            icon: <StackSvg />,
            subs:
              menuOpened[SUB_MENU_KEY.COMPLETED_CERTS] && isCertDetail
                ? completedCerts.map((c) => {
                    const itemId = isWorkflow
                      ? c.workflow_id
                      : c.certification_id;
                    return {
                      label: c.name,
                      onClick:
                        String(params?.certId) === String(c.id) &&
                        String(params?.certificationId) === String(itemId)
                          ? undefined
                          : () => {
                              goToOtherItem(c.id, itemId);
                            },
                    };
                  })
                : [],
          },
          // {
          //   label: 'Pending Workflows',
          //   icon: <VersionsSvg />,
          //   active: menuOpened[SUB_MENU_KEY.PENDING_WORKFLOWS],
          //   onClick: () => {
          //     onOpenChanged(
          //       SUB_MENU_KEY.PENDING_WORKFLOWS,
          //       !menuOpened[SUB_MENU_KEY.PENDING_WORKFLOWS]
          //     );
          //   },
          //   subs:
          //     menuOpened[SUB_MENU_KEY.PENDING_WORKFLOWS] && isCertDetail
          //       ? [
          //           {
          //             label: 'Home Self Cert',
          //           },
          //           {
          //             label: 'HUD Initial Cert',
          //           },
          //           {
          //             label: 'HUD IR',
          //           },
          //           {
          //             label: 'Gross Rent',
          //           },
          //         ]
          //       : [],
          // },
          // {
          //   label: 'Resident Files',
          //   icon: <Folder1Svg />,
          // },
        ]}
      />
      <div className={classes.dropdown}>
        <Select
          data={LIST_8_YEARS}
          radius="md"
          value={setting.detailYear || '2024'}
          onChange={(value) => {
            handleSettingChange('detailYear', value);
            loadCertData(value);
          }}
          maxDropdownHeight={400}
        />
      </div>
    </div>
  );
};

export default Left;
