import Pusher from 'pusher-js';
import { SOCKET_CONFIG } from 'src/helpers/constants';
import { store } from 'src/store';
import { syncUpdateMeData } from 'src/store/actions/auth';

class Socket {
  pusher;

  start(user, token) {
    this.stop();
    if (!token) return;

    // init Pusher
    this.pusher = new Pusher(SOCKET_CONFIG.ECHO_APP_ID, {
      cluster: SOCKET_CONFIG.ECHO_CLUSTER,
      disableStats: false,
      forceTLS: false,
      wsPort: SOCKET_CONFIG.ECHO_SERVER_PORT
        ? Number(SOCKET_CONFIG.ECHO_SERVER_PORT)
        : 443,
      wsHost: SOCKET_CONFIG.ECHO_SERVER_HOST,
      authEndpoint: SOCKET_CONFIG.ECHO_AUTH_URL,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
        },
      },
    });

    this.pusher
      .subscribe(`private-user.${user.id}`)
      .bind('user-schedule', (data) => {
        store.dispatch(
          syncUpdateMeData(
            {
              is_active: data.is_active,
            },
            true
          )
        );
      });
  }

  stop() {
    // this method used to unregister all subscription on socket

    if (this.pusher) {
      this.pusher.disconnect();
    }
  }
}

export default new Socket();
