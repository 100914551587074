import * as certificationActions from 'src/store/actions/certification';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    addCertificationLoading: useReducerData(
      'certification',
      'addCertification.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...certificationActions,
  });
};
