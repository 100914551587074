import * as documentActions from 'src/store/actions/document';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    checkDuplicateLoading: useReducerData(
      'document',
      'checkDuplicateFiles.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...documentActions,
  });
};
