import { Upload } from 'src/components/FormFields';

const UploadFileBtn = ({
  title,
  onChange = null,
  loading = false,
  multiple = false,
}) => {
  return (
    <Upload
      multiple={multiple}
      buttonText={title}
      buttonProps={{
        color: 'green',
        size: 'sm',
        loading,
      }}
      valueButtonHidden
      onChange={onChange}
      hideRemoveValue
      uploadedAsInput
    />
  );
};

export default UploadFileBtn;
