import cn from 'classnames';
import get from 'lodash/get';
import { Card, FormCardContent } from 'src/components';
import { FormikDropdown } from 'src/components/FormFields';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import classes from './StatusOptions.module.scss';

const StatusOptions = ({ className = '', formProps, isWorkflow = false }) => {
  const { values, setFieldValue } = formProps;
  const { certificationStatusOptions } = useOptions();
  const tOptions = [DEFAULT_ALL_OPTION, ...certificationStatusOptions].map(
    (option) => {
      if (option.label === 'Not Started') {
        option.always = true;
      }
      return option;
    }
  );
  return (
    <Card
      className={cn(classes.wrapper, className)}
      contentGap={16}
      notPaddingContentTop
      contentPaddingBottom={0}
      title={`${isWorkflow ? 'Workflow' : 'Certification'} Status Options`}
      overflowInit
    >
      <FormCardContent column={1}>
        <FormikDropdown
          items={tOptions}
          placeholder={'Select'}
          label="Status Option"
          name={`status_options`}
          displaySelectedAll
          placeholderGray
          errorOverlay
          required
          radius="sm"
          newEntity="status"
          newFullWidth
          newEntityObj={{
            placeholder: 'New Status',
            button: 'Add Status',
            additionalPayload: {
              schema_type: isWorkflow ? 'workflow' : 'certificate',
            },
          }}
          onChangeCb={() => {
            const tChildren = values?.flowBuilder?.children || [];
            tChildren.forEach((_c, index) => {
              const asKey = `flowBuilder.children.${index}.assign_status`;
              if (get(values, asKey)) setFieldValue(asKey, []);
            });
          }}
        />
      </FormCardContent>
    </Card>
  );
};

export default StatusOptions;
