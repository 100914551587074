import cn from 'classnames';
import { Badge } from 'src/components';
import classes from './NoteBlock.module.scss';

const NoteBlock = ({ className = '', onClick = null, count }) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          pointer: !!onClick,
        },
        className
      )}
      onClick={onClick}
    >
      <span className={classes.text}>Notes</span>
      <Badge circle alert>
        {count}
      </Badge>
    </div>
  );
};

export default NoteBlock;
