import {
  FormikInput,
  FormikSelect,
  FormikUpload,
} from 'src/components/FormFields';
import { useOptions } from 'src/helpers/hooks';

const NoticeFile = () => {
  const { propertyOptions } = useOptions();

  return (
    <>
      <h3>Upload Notices File</h3>
      <FormikSelect
        data={propertyOptions}
        name="property_id"
        label="Property"
        placeholder="Select"
        maxDropdownHeight={300}
      />
      <FormikInput
        name="title"
        label="Upload Title"
        placeholder="Upload Title"
        autoComplete="upload-title"
      />
      <FormikUpload name="notice_file" valueButtonHidden />
    </>
  );
};

export default NoticeFile;
