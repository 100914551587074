import { Field } from 'formik';
import get from 'lodash/get';
import Select, { OSelectProps } from '../Select';

type Props = OSelectProps & {
  forceError?: string | boolean;
  name: string;
  type?: string;
  onChangeCb?: (val) => void;
  customOnChange?: (val) => void;
};

const FormikSelect = (props: Props) => {
  const {
    name,
    forceError = false,
    onChangeCb,
    customOnChange,
    ...otherProps
  } = props;

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { touched, errors, setFieldValue } = form;
        const value = field.value;
        const errorMessage = get(errors, field.name);
        return (
          <Select
            {...otherProps}
            {...field}
            onChange={(val) => {
              if (customOnChange) {
                customOnChange(val);
              } else {
                setFieldValue(name, val);
                if (onChangeCb) onChangeCb(val);
              }
            }}
            value={value}
            error={
              (get(touched, field.name) && !!errorMessage
                ? errorMessage
                : '') || forceError
            }
            errorBg={!!forceError}
          />
        );
      }}
    </Field>
  );
};

export default FormikSelect;
