import { AlertProps, Alert as ManAlert } from '@mantine/core';
import cn from 'classnames';
import classes from './Alert.module.scss';

type Props = AlertProps & {
  className?: string;
};

const Alert = ({ className = '', ...other }: Props) => {
  return <ManAlert {...other} className={cn(classes.wrapper, className)} />;
};

export default Alert;
