import { Button } from 'src/components';
import { Select } from 'src/components/FormFields';
import { FilterBar } from 'src/components/Page';
import {
  DEFAULT_ROLE_OPTIONS,
  ORGANIZATION_OPTIONS,
  STATUS_OPTIONS,
} from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import classes from './UserManagement.module.scss';

const TopFilterBar = ({ setting, handleSettingChange, setOpened }) => {
  const { roleOptionsWithoutGroup } = useOptions();
  const roleOptions = [DEFAULT_ROLE_OPTIONS, ...roleOptionsWithoutGroup];

  return (
    <>
      <FilterBar className={classes.filterBar}>
        <Select
          data={roleOptions}
          radius="md"
          value={String(setting.role)}
          onChange={(value) => {
            handleSettingChange('role', value);
          }}
          maxDropdownHeight={400}
          className={classes.roleDropdown}
        />
        <Select
          data={ORGANIZATION_OPTIONS}
          radius="md"
          value={String(setting.organization)}
          onChange={(value) => {
            handleSettingChange('organization', value);
          }}
        />
        <Select
          data={STATUS_OPTIONS}
          radius="md"
          value={String(setting.status)}
          onChange={(value) => {
            handleSettingChange('status', value);
          }}
        />
      </FilterBar>
      <Button
        onClick={() => {
          setOpened(true);
        }}
        radius="md"
        textSize="xl"
      >
        + Invite User
      </Button>
    </>
  );
};

export default TopFilterBar;
