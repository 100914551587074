import { createTypes } from './createTypes';

export default createTypes(
  'GET_ME',
  'LOGIN',
  'UPDATE_PROFILE',
  'LOG_OUT',
  'SYNC_SET_TOKEN',
  'SYNC_SET_ME_DATA'
);
