import React from 'react';
import { MAIN_TABS, SUB_TABS } from './constants';

export const mainTabs = [
  {
    key: MAIN_TABS.COPORATE.KEY,
    title: MAIN_TABS.COPORATE.LABEL,
    content: <></>,
  },
  {
    key: MAIN_TABS.PROPERTY.KEY,
    title: MAIN_TABS.PROPERTY.LABEL,
    content: <></>,
  },
];

export const subTabs = [
  {
    key: SUB_TABS.BOARD.KEY,
    title: SUB_TABS.BOARD.LABEL,
    content: <></>,
  },
  {
    key: SUB_TABS.STATUS.KEY,
    title: SUB_TABS.STATUS.LABEL,
    content: <></>,
  },
];
