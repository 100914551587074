import * as authActions from 'src/store/actions/auth';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const profileStore = useReducerData('auth', 'meData', {});
  return {
    meLoading: profileStore.loading,
    profile: profileStore.data,
    updateProfileLoading: useReducerData(
      'auth',
      'updateProfile.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
  });
};
