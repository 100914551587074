import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Modal } from 'src/components';
import YourStatus from '../YourStatus';
import classes from './UpdateStatusModal.module.scss';

const UpdateStatusModal = ({
  className = '',
  opened,
  onClose,
  userObj = null,
  onSubmit = null,
  submitLoading = false,
}) => {
  const [setting, setSetting] = useState({
    status: null,
    note: '',
    activeStatus: '',
  });
  useEffect(() => {
    if (opened) {
      setSetting({
        status: userObj?.status || null,
        note: userObj?.note || '',
        activeStatus: userObj?.is_active ? 'Active' : 'Inactive',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title=""
      className={cn(classes.modalWrapper, className)}
      centered
    >
      <YourStatus
        setting={setting}
        onChange={(key, val) => {
          setSetting({
            ...setting,
            [key]: val,
          });
        }}
        onUpdate={() => {
          if (onSubmit) onSubmit(setting);
        }}
        profile={userObj}
        loading={submitLoading}
        onCancel={onClose}
        inModal
      />
    </Modal>
  );
};

export default UpdateStatusModal;
