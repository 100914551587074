import { Form, Formik } from 'formik';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { apiDownloadReport } from 'src/api';
import { Button } from 'src/components';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { REPORT_STATUS_VALUES } from 'src/helpers/constants/options';
import { useCurrentPath, useCurrentRole } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { downloadFileFromObj } from 'src/utils/file';
import { confirmModalFn } from 'src/utils/modal';
import { notification } from 'src/utils/notification';
import { printPage } from 'src/utils/print';
import Loading from './Loading';
import classes from './NewReport.module.scss';
import AdditionalInformation from './components/AdditionalInformation';
import AssistanceProvided from './components/AssistanceProvided';
import Authorities from './components/Authorities';
import CauseIncident from './components/CauseIncident';
import DetailedDescription from './components/DetailedDescription';
import Footer from './components/Footer';
import IncidentFilledBy from './components/IncidentFilledBy';
import Information from './components/Information';
import InsuranceClaim from './components/InsuranceClaim';
import InvolvedParties from './components/InvolvedParties';
import PhotoDocumentUpload from './components/PhotoDocumentUpload';
import StepBeingTaken from './components/StepBeingTaken';
import SummaryOfIncident from './components/SummaryOfIncident';
import TreatmentApplicable from './components/TreatmentApplicable';
import UninvolvedWitnesses from './components/UninvolvedWitnesses';
import WeatherConditions from './components/WeatherConditions';
import { UPLOAD_NAME_MAPPING, schema } from './constants';
import getPermission from './permissions';
import { useActions, useIndexData, useLoadingData } from './selectorData';
import {
  getDetailTitle,
  getInitialValues,
  getPhotoDocumentRequired,
  getReportBR,
  getReportFilesBR,
} from './utils';

const applyDebounce = debounce((val, cb) => {
  cb(val);
}, 300);
let afterSaveTrigger = true;

const NewReport = () => {
  const [downloading, setDownloading] = useState(false);
  const [autoSaveId, setAutoSaveId] = useState<string | number>('');
  const [uploading, setUploading] = useState({});
  const role = useCurrentRole();
  const permission = getPermission(role);
  const currentPath = useCurrentPath();
  const params = useParams();
  const reportId = params.id ? parseInt(params.id, 10) : 0;
  const isDetail = currentPath === '/incident-reporting/:id';
  const isReportAutoSave = currentPath === '/new-report-auto-save/:id';
  const navigate = useNavigate();
  const { reportDetailLoading } = useLoadingData();
  const { addUpdateReportLoading, reportDetail, uploadReportFilesLoading } =
    useIndexData();
  const title = getDetailTitle(reportDetail);
  const {
    addUpdateReport,
    uploadReportFiles,
    getReportDetail,
    syncSetReportDetail,
  } = useActions();
  const { t } = useTranslation(['newReport', 'common']);
  const formRef = useRef(null);

  const loadReport = (tId, cb?: any) => {
    getReportDetail(tId, cb);
  };
  const addSubmitCb = () => {
    if (!isDetail) {
      navigate('/incident-reporting');
    } else {
      loadReport(reportId, () => {
        formRef.current.resetForm();
        notification({
          title: 'Saved report successfully!',
        });
      });
    }
  };
  const handleFilesChanged = (tFiles, type, cb) => {
    setUploading({
      ...uploading,
      [type]: true,
    });
    const fileBR = getReportFilesBR(tFiles, type);
    uploadReportFiles(
      fileBR,
      actionCb(
        (res) => {
          cb(res);
          setUploading({
            ...uploading,
            [type]: false,
          });
        },
        () => {
          setUploading({
            ...uploading,
            [type]: false,
          });
        },
        'Upload files error'
      )
    );
  };
  const showWarningModal = (cb, cancelCb = null) => {
    confirmModalFn({
      children: (
        <>
          Are you sure you want to close this incident report?
          <br />
          <br />
          Once you close this report, you will no longer be able to edit any
          information or change the status. You will still be able to view the
          information and print.
        </>
      ),
      onCancel: () => {
        if (cancelCb) cancelCb();
      },
      onConfirm: () => {
        cb();
      },
    });
  };
  const autoSaveFn = (id, cb?: any) => {
    const tValues = formRef && formRef.current ? formRef.current.values : {};
    if (!tValues.status) {
      tValues.status = REPORT_STATUS_VALUES.IN_PROGRESS;
    }
    const tBR = getReportBR(tValues, id);
    tBR.auto_save = true;
    addUpdateReport(tBR, actionCb(cb));
  };
  const handleValuesChange = (
    previousValue?: string,
    tKey?: string,
    value?: string
  ) => {
    applyDebounce('', () => {
      if (!afterSaveTrigger || (afterSaveTrigger && !addUpdateReportLoading)) {
        const valueChangeFn = () => {
          autoSaveFn(
            autoSaveId || (isDetail || isReportAutoSave ? reportId : ''),
            (res) => {
              if (afterSaveTrigger) {
                afterSaveTrigger = false;
                setAutoSaveId(res.id);
                navigate(`/new-report-auto-save/${res.id}`);
                autoSaveFn(res.id, null);
              }
            }
          );
        };
        if (
          [
            REPORT_STATUS_VALUES.CLOSED,
            REPORT_STATUS_VALUES.CLAIM_CLOSED,
          ].includes(value) &&
          !!previousValue &&
          tKey === 'status'
        ) {
          showWarningModal(() => {
            formRef.current.setFieldValue(tKey, value);
            setTimeout(() => {
              valueChangeFn();
            }, 0);
          });
        } else {
          if (tKey === 'status') {
            formRef.current.setFieldValue(tKey, value);
          }
          setTimeout(() => {
            valueChangeFn();
          }, 0);
        }
      }
    });
  };

  const renderForm = (formProps) => {
    const { dirty, isValid, values, setFieldValue } = formProps;
    const isDisabled =
      isDetail &&
      [REPORT_STATUS_VALUES.CLOSED, REPORT_STATUS_VALUES.CLAIM_CLOSED].includes(
        values.status
      ) &&
      !permission.editClosedReport;
    const commonProps = {
      isDisabled,
      values,
      onValuesChange: handleValuesChange,
    };
    const submitLoading = addUpdateReportLoading || uploadReportFilesLoading;
    const photoDocumentRequired = getPhotoDocumentRequired(values);
    const submitDisabled =
      !dirty ||
      !isValid ||
      photoDocumentRequired.photos ||
      photoDocumentRequired.witness ||
      photoDocumentRequired.related_documents;
    return (
      <Form className={classes.form}>
        <PageContent className={classes.mainContent}>
          <div className={classes.group}>
            <Information
              {...commonProps}
              submitLoading={submitLoading}
              submitDisabled={submitDisabled}
              reportDetail={isDetail ? reportDetail : null}
              permission={permission}
            />
            <SummaryOfIncident {...commonProps} />
            <WeatherConditions {...commonProps} />
            <InvolvedParties {...commonProps} />
            <Authorities {...commonProps} />
            <DetailedDescription {...commonProps} />
            <CauseIncident {...commonProps} />
            <AssistanceProvided {...commonProps} />
            <StepBeingTaken {...commonProps} />
            <UninvolvedWitnesses {...commonProps} />
            <TreatmentApplicable {...commonProps} />
            <IncidentFilledBy {...commonProps} />
            <AdditionalInformation {...commonProps} />
            <PhotoDocumentUpload
              {...commonProps}
              setFieldValue={setFieldValue}
              uploading={uploading}
              requiredChecks={photoDocumentRequired}
              onFilesChanged={(tFiles, type) => {
                handleFilesChanged(tFiles, type, (res) => {
                  const tKey = UPLOAD_NAME_MAPPING[type];
                  setFieldValue(tKey, [...(values[tKey] || []), ...res]);
                  handleValuesChange();
                });
              }}
              onFilesRemoved={(tIndex, type) => {
                const tKey = UPLOAD_NAME_MAPPING[type];
                const tFieldFiles = values[tKey] || [];
                setFieldValue(
                  tKey,
                  tFieldFiles.filter((_, rIndex) => rIndex !== tIndex)
                );
                handleValuesChange();
              }}
            />
            {isDetail && <InsuranceClaim {...commonProps} />}
          </div>
          <Footer
            {...commonProps}
            setFieldValue={setFieldValue}
            downloadLoading={downloading}
            disabled={submitDisabled}
            loading={submitLoading || (isDetail && reportDetailLoading)}
            onCancel={() => {
              if (isDetail && dirty) {
                confirmModalFn({
                  children: (
                    <>
                      Are you sure you want to cancel?
                      <br />
                      <br />
                      Any changes to this incident report will not be saved
                    </>
                  ),
                  onCancel: () => {
                    navigate('/incident-reporting');
                  },
                  onConfirm: () => {},
                });
              } else {
                navigate('/incident-reporting');
              }
            }}
            onDownload={async () => {
              setDownloading(true);
              const downloadRes = await apiDownloadReport(reportId);
              if (downloadRes?.data) {
                const today = moment();
                downloadFileFromObj(
                  downloadRes.data,
                  `incident_report_${reportId}_${today.format(
                    'YYYY'
                  )}_${today.format('MM')}_${today.format('DD')}.zip`
                );
              } else {
                notification({
                  title: 'Download failed!',
                  color: 'red',
                });
              }
              setDownloading(false);
            }}
          />
        </PageContent>
      </Form>
    );
  };

  useEffect(() => {
    syncSetReportDetail({});
    afterSaveTrigger = !isDetail && !isReportAutoSave;
    setDownloading(false);
    if ((isDetail || isReportAutoSave) && !!reportId) {
      loadReport(reportId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading />
      <TopHeader
        breadcrumb={[
          {
            label: 'Workflows',
            link: '/workflows',
          },
          {
            label: 'Incident Reports',
            link: '/incident-reporting',
          },
          {
            label: isDetail ? title : t('newReport.title'),
          },
        ]}
      />
      <MainContent
        className={classes.mainContentWrapper}
        id="report-main-content"
      >
        <PageHeader title={isDetail ? title : t('newReport.new')}>
          <Button
            variant="default"
            textWeight="xl"
            radius="md"
            onClick={() => {
              printPage();
            }}
          >
            Print Report
          </Button>
        </PageHeader>
        <Formik
          initialValues={getInitialValues(
            isDetail || isReportAutoSave ? reportDetail : {}
          )}
          validateOnMount
          validationSchema={schema}
          innerRef={formRef}
          enableReinitialize
          onSubmit={async (values: any) => {
            addUpdateReport(
              getReportBR(values, isDetail || isReportAutoSave ? reportId : ''),
              actionCb(
                () => {
                  addSubmitCb();
                },
                null,
                'Create Report error'
              )
            );
          }}
        >
          {renderForm}
        </Formik>
      </MainContent>
    </>
  );
};

export default NewReport;
