import { Dispatch } from 'redux';
import {
  apiCreateDepartment,
  apiCreateStatus,
  apiCreateTag,
  apiCreateWorkflowType,
  apiGetCertBuilderDropdown,
  apiGetCertificationStatuses,
  apiGetCertificationTypes,
  apiGetDepartments,
  apiGetDocuments,
  apiGetProperties,
  apiGetRoles,
  apiGetTags,
  apiGetTimezones,
  apiGetUsers,
  apiGetWorkflowTypes,
  apiGetWorkflowTypesBuilder,
} from 'src/api';
import Types from '../types/shared';

export const syncSetCollapse = (payload: boolean) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_SET_COLLAPSE,
    payload,
  });
};

export const getRoles = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_ROLES,
    asyncCall: () => {
      return apiGetRoles();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getAllUsers =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_ALL_USERS,
      asyncCall: () => {
        return apiGetUsers({
          page: 1,
          limit: 1000,
          status: 'active',
          ...params,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getSupervisors = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_SUPERVISORS,
    asyncCall: () => {
      return apiGetUsers({
        page: 1,
        limit: 5000,
      });
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getProperties = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_PROPERTIES,
    asyncCall: () => {
      return apiGetProperties();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getCertificationTypes = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_CERTIFICATION_TYPES,
    asyncCall: () => {
      return apiGetCertificationTypes();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getWorkflowTypes = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WORKFLOW_TYPES,
    asyncCall: () => {
      return apiGetWorkflowTypes();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getWorkflowTypesBuilder = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WORKFLOW_TYPES_BUILDER,
    asyncCall: () => {
      return apiGetWorkflowTypesBuilder();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getCertificationStatuses = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_CERTIFICATION_STATUSES,
    asyncCall: () => {
      return apiGetCertificationStatuses();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getCertBuilderDropdown =
  (key: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_CERT_BUILDER_DROPDOWN,
      asyncCall: () => {
        return apiGetCertBuilderDropdown(key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getTags = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_TAGS,
    asyncCall: () => {
      return apiGetTags();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getAllFolders =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_ALL_FOLDERS,
      asyncCall: () => {
        return apiGetDocuments({
          ...params,
          folders_only: true,
          expand_view: true,
          limit: 1000,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getTimezones =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_TIMEZONES,
      asyncCall: () => {
        return apiGetTimezones({
          ...params,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getDepartments =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_DEPARTMENTS,
      asyncCall: () => {
        return apiGetDepartments({
          ...params,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };

// POST
export const createTag = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_TAG,
    asyncCall: () => {
      return apiCreateTag(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const createDepartment = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_DEPARTMENT,
    asyncCall: () => {
      return apiCreateDepartment(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const createStatus = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_STATUS,
    asyncCall: () => {
      return apiCreateStatus(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const createWorflowType = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_WORKFLOW_TYPE,
    asyncCall: () => {
      return apiCreateWorkflowType(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
