import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { useCurrentPath } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { formatBEDate, getDPDate } from 'src/utils/date';
import { testDateField } from 'src/utils/form';
import { notification } from 'src/utils/notification';
import Left from './Left';
import Loading from './Loading';
import classes from './ResidentDetail.module.scss';
import PendingCerts from './SubContent/PendingCerts';
import ResidentInfo from './SubContent/ResidentInfo';
import CertDates from './components/CertDates';
import { SUB_MENU_KEY } from './constants';
import { useActions, useIndexData } from './selectorData';
import { SettingsT } from './types';
import { getBreadCrumb } from './utils';

const ResidentDetail = () => {
  const [setting, setSetting] = useState({
    detailYear: String(new Date().getFullYear()),
    status: '',
    effectiveDate: '',
    responseDate: '',
  } as SettingsT);
  const [menuOpened, setMenuOpened] = useState({
    [SUB_MENU_KEY.PENDING_CERTS]: true,
  });
  const currentPath = useCurrentPath();
  const params = useParams();
  const { certFields } = useIndexData();
  const { getCertificationFields, getCertificateNote, updateCertification } =
    useActions();
  const isWorkflowDetailPage =
    currentPath === '/workflow-list-builder/:certId/:certificationId';
  const tCertKey = isWorkflowDetailPage ? 'workflow' : 'certificate';
  const isCertCertificationDetailPage =
    currentPath === '/certifications-list-builder/:certId/:certificationId' ||
    isWorkflowDetailPage;
  const isCertDetail =
    currentPath === '/certifications-list/:id' ||
    isCertCertificationDetailPage ||
    isWorkflowDetailPage;
  const certificationId = params.certificationId
    ? parseInt(params.certificationId, 10)
    : 0;
  const certId = params.certId ? parseInt(params.certId, 10) : 0;
  const tCertificationId = isCertCertificationDetailPage
    ? certificationId
    : params.id
    ? parseInt(params.id, 10)
    : 0;
  const isResidentInfo = currentPath === '/resident-information';
  const handleUpdateCertification = (key, value) => {
    updateCertification(
      tCertificationId,
      {
        [key]: value,
      },
      isWorkflowDetailPage ? 'workflows' : '',
      actionCb(null, null, 'Update Certification Failed!')
    );
  };
  const handleSettingChange = (key, value) => {
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (key === 'status') {
      handleUpdateCertification(`certificate_status_id`, value);
    }
  };
  const handleSaveCertDate = (key, val, title = 'Effective Date') => {
    const tVal = formatBEDate(val, true);
    const isValidDate = testDateField(val);
    if (isValidDate) {
      handleUpdateCertification(key, tVal);
    } else {
      notification({
        title: `Invalid date, please update ${title}!`,
        color: 'red',
      });
    }
  };
  const loadCertData = (tYear?: string) => {
    getCertificationFields(
      tCertificationId,
      tYear || setting.detailYear,
      isWorkflowDetailPage ? 'workflows' : '',
      actionCb((res) => {
        setSetting({
          detailYear: tYear || setting.detailYear,
          status: res[tCertKey]?.status?.id || null,
          effectiveDate: getDPDate(res[tCertKey]?.effective_date),
          responseDate: getDPDate(res[tCertKey]?.response_date),
        });
      })
    );
  };

  useEffect(() => {
    if (isCertDetail && !!tCertificationId) {
      loadCertData();
      getCertificateNote(
        tCertificationId,
        isWorkflowDetailPage ? 'workflows' : ''
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tCertificationId]);

  return (
    <>
      <TopHeader
        breadcrumb={getBreadCrumb({
          isCertDetail,
          certFields,
          certId,
          isCertCertificationDetailPage,
          certKey: tCertKey,
          isWorkflow: isWorkflowDetailPage,
        })}
      />
      <MainContent
        className={cn(classes.mainContent, {
          [classes.isResidentInfo]: isResidentInfo,
        })}
      >
        <Loading />
        <Left
          isCertDetail={isCertDetail}
          setting={setting}
          handleSettingChange={handleSettingChange}
          menuOpened={menuOpened}
          onOpenChanged={(key, val) => {
            setMenuOpened({
              ...menuOpened,
              [key]: val,
            });
          }}
          isWorkflow={isWorkflowDetailPage}
          params={params}
          loadCertData={loadCertData}
        />
        <div className={classes.right}>
          <PageHeader
            title={
              isCertDetail
                ? certFields?.[tCertKey]?.name
                : 'Resident Information'
            }
            className={classes.pageHeader}
          >
            {isCertDetail && (
              <CertDates
                data={certFields}
                dateValues={{
                  effectiveDate: setting.effectiveDate,
                  responseDate: setting.responseDate,
                }}
                onDateChange={(val, key) => {
                  handleSettingChange(key, val);
                }}
                onDateSave={(val, key) => {
                  handleSaveCertDate(
                    key === 'effectiveDate'
                      ? 'effective_date'
                      : 'response_date',
                    val,
                    key === 'effectiveDate' ? 'Effective Date' : 'Response Date'
                  );
                }}
                certKey={tCertKey}
                isWorkflow
              />
            )}
          </PageHeader>
          <PageContent className={classes.pageContent}>
            {isCertDetail ? (
              <PendingCerts
                setting={setting}
                onSettingChange={handleSettingChange}
                certId={tCertificationId}
                loadCertData={loadCertData}
                isWorkflow={isWorkflowDetailPage}
              />
            ) : (
              <ResidentInfo />
            )}
          </PageContent>
        </div>
      </MainContent>
    </>
  );
};

export default ResidentDetail;
