import cn from 'classnames';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import { useEffect, useState } from 'react';
import { Select } from 'src/components/FormFields';
import { REST_FILES_KEY } from 'src/helpers/constants';
import { COMPLETE_CERT_STATUS_VALUE } from 'src/helpers/constants/certification';
import { useCurrentUser } from 'src/helpers/hooks';
import DocumentBlock from 'src/pages/ResidentDetail/components/DocumentBlock';
import FileModal from 'src/pages/ResidentDetail/components/FileModal';
import NoteBlock from 'src/pages/ResidentDetail/components/NoteBlock';
import NoteModal from 'src/pages/ResidentDetail/components/NoteModal';
import NoticeBlock from 'src/pages/ResidentDetail/components/NoticeBlock';
import SectionBlock from 'src/pages/ResidentDetail/components/SectionBlock';
import { actionCb } from 'src/utils/action';
import { getDPDate } from 'src/utils/date';
import { confirmModalFn } from 'src/utils/modal';
import classes from './PendingCerts.module.scss';
import { CERTIFICATE_FULL_FILE_ID } from './constants';
import { useActions, useIndexData } from './selectorData';
import {
  extractPreviewDeletedFile,
  getAllPreviewFiles,
  getAllPreviewSubFiles,
  getAllSubCompleted,
  getCertAllFiles,
  getCertFieldsBR,
  getPreviewNoticeValues,
  getPreviewSectionFiles,
  getPreviewSectionValues,
  getSectionObjFromList,
  getUploadFullFileBR,
} from './utils';

const PendingCerts = ({
  className = '',
  setting,
  onSettingChange,
  certId,
  isPreview = false,
  previewData = null,
  loadCertData = null,
  isWorkflow = false,
}) => {
  const tCertKey = isWorkflow ? 'workflow' : 'certificate';
  const currentUser = useCurrentUser();
  const previewStatusOptions = previewData?.statusOptions || [];
  const {
    certFields: data,
    certNotes: notes,
    addCertificateNoteLoading,
    saveCertificateFieldsLoading,
  } = useIndexData();
  const tNotes = (isPreview ? setting.notes : notes) || [];
  const {
    addCertificateNote,
    saveCertificateFields,
    deleteCertificateFile,
    uploadCertificateFullFile,
    deleteCertificateFullFile,
    markCertificateSectionCompleted,
    markCertificateCompleted,
    syncSetCertificationFields,
  } = useActions();
  const allFiles = getCertAllFiles(data, isWorkflow);
  const [isOpened, setIsOpened] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [bellObj, setBellObj] = useState(null);
  const [isViewFile, setIsViewFile] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [vFiles, setVFiles] = useState([]);
  const [checks, setChecks] = useState({} as any);
  const [tmpCheckParentIds, setTmpCheckParentIds] = useState([]);
  const tSections = data?.fields || [];
  const tStatusList = data?.status || [];
  const currentStatus = data?.[tCertKey]?.status;
  if (
    findIndex(tStatusList, { id: currentStatus?.id }) < 0 &&
    !!currentStatus?.id
  ) {
    tStatusList.push(currentStatus);
  }
  const statusOptions = isPreview
    ? previewStatusOptions
    : tStatusList.map((s) => ({
        label: s.name,
        value: s.id,
      }));
  const noticeData = isPreview
    ? previewData?.notice
    : find(tSections, { type: 'notice' });
  const sectionsData = isPreview
    ? previewData?.sections || []
    : tSections.filter((s) => s.type === 'section');
  const handleViewFile = (tFiles, tObjId = '', tNoticeValue = '') => {
    if (!isPreview) {
      setVFiles(tFiles);
      setIsViewFile(true);
    } else {
      setVFiles(
        tObjId === REST_FILES_KEY
          ? getAllPreviewFiles(setting)
          : getPreviewSectionFiles(setting, tObjId, tNoticeValue, sectionsData)
      );
      setIsViewFile(true);
    }
  };
  const handleDeleteFile = (tFile) => () => {
    if (!isPreview) {
      const isFullFile = tFile?.id === CERTIFICATE_FULL_FILE_ID;
      const newFiles = vFiles.filter((f) => f.id !== tFile.id);
      setVFiles(newFiles);
      if (newFiles.length === 0) {
        setIsViewFile(false);
      }
      if (isFullFile) {
        deleteCertificateFullFile(
          certId,
          isWorkflow ? 'workflows' : '',
          actionCb(null, null, 'Remove File Failed!')
        );
      } else {
        deleteCertificateFile(
          certId,
          tFile.id,
          isWorkflow ? 'workflows' : '',
          actionCb(null, null, 'Remove File Failed!')
        );
      }
    } else {
      const extractedFileObj = extractPreviewDeletedFile(tFile);
      if (extractedFileObj.sectionId === REST_FILES_KEY) {
        const newFiles = setting[REST_FILES_KEY].filter(
          (_, tIndex) => tIndex !== extractedFileObj.index
        );
        onSettingChange(REST_FILES_KEY, newFiles);
      } else if (extractedFileObj.noticeValue) {
        onSettingChange(extractedFileObj.sectionId, {
          ...setting[extractedFileObj.sectionId],
          notices: setting[extractedFileObj.sectionId].notices.map((tObj) => {
            if (tObj.value === extractedFileObj.noticeValue) {
              tObj.files = tObj.files.filter(
                (_, tIndex) => tIndex !== extractedFileObj.index
              );
            }
            return tObj;
          }),
        });
      } else {
        const newFiles = setting[extractedFileObj.sectionId].files.filter(
          (_, tIndex) => tIndex !== extractedFileObj.index
        );
        onSettingChange(extractedFileObj.sectionId, {
          ...setting[extractedFileObj.sectionId],
          files: newFiles,
        });
      }
    }
  };
  const handleAddNote = (val, cb) => {
    if (val) {
      if (!isPreview) {
        if (!addCertificateNoteLoading) {
          addCertificateNote(
            {
              [isWorkflow ? 'workflow_id' : 'certificate_id']: certId,
              note: val,
            },
            isWorkflow ? 'workflows' : '',
            actionCb(cb)
          );
        }
      } else {
        onSettingChange('notes', [
          ...(setting.notes || []),
          {
            id: new Date().getTime(),
            note: val,
            user: currentUser,
            created_at: new Date(),
          },
        ]);
        if (cb) cb();
      }
    }
  };
  const handleUpdateCertFields = (
    values,
    cb = null,
    errCb = null,
    pBR = null
  ) => {
    saveCertificateFields(
      certId,
      pBR || getCertFieldsBR([values]),
      isWorkflow ? 'workflows' : '',
      actionCb(
        () => {
          if (cb) cb();
        },
        errCb,
        'Save Certification Fields Failed!'
      )
    );
  };
  const handleAddNotice = (values, cb) => {
    if (!isPreview) {
      handleUpdateCertFields(values, cb);
    } else {
      const eNoticeSetting = setting?.[noticeData.id] || {};
      onSettingChange(noticeData.id, {
        ...eNoticeSetting,
        notices: [...(eNoticeSetting.notices || []), values],
      });
      if (cb) cb();
    }
  };
  const handleUploadNoticeFile = (values, cb) => (tFiles) => {
    if (!isPreview) {
      cb(values.valueId, true);
      handleUpdateCertFields(
        {
          ...values,
          value: values.noticeValue,
          fileValues: tFiles,
        },
        () => {
          cb(values.valueId, false);
        },
        () => {
          cb(values.valueId, false);
        }
      );
    } else {
      const eNoticeSetting = setting?.[noticeData.id] || {};
      onSettingChange(noticeData.id, {
        ...eNoticeSetting,
        notices: eNoticeSetting.notices.map((obj) => {
          if (obj.value === values.noticeValue) {
            obj.files = [...(obj.files || []), ...tFiles];
          }
          return obj;
        }),
      });
      if (cb) cb();
    }
  };
  const handleUpdateInterviewSchedule = (values, cb, tSetLoading) => {
    if (!isPreview) {
      tSetLoading(true);
      handleUpdateCertFields(
        {
          ...values,
          value: `${values.interview_date} ${values.interview_time}`,
        },
        () => {
          if (cb) cb();
          tSetLoading(false);
        },
        () => {
          tSetLoading(false);
        }
      );
    } else {
      tSetLoading(false);
      onSettingChange(noticeData.id, {
        ...(setting?.[noticeData.id] || {}),
        interviewSchedule: `${values.interview_date} ${values.interview_time}`,
      });
      if (cb) cb();
    }
  };
  const handleViewAllFiles = () => {
    handleViewFile(
      isPreview ? setting[REST_FILES_KEY] : allFiles,
      REST_FILES_KEY
    );
  };
  const handleFieldChange = (values, tSetting?: any, tSection?: any) => {
    if (!isPreview) {
      const { setUploadLoading } = tSetting || {};
      if (setUploadLoading) {
        setUploadLoading(true);
      }
      handleUpdateCertFields(
        values,
        () => {
          if (setUploadLoading) {
            setUploadLoading(false);
          }
        },
        () => {
          if (setUploadLoading) {
            setUploadLoading(false);
          }
        }
      );
    } else {
      if (values?.fileValues?.length > 0) {
        onSettingChange(tSection.id, {
          ...(setting?.[tSection.id] || {}),
          files: [
            ...(setting?.[tSection.id]?.files || []),
            ...values.fileValues,
          ],
        });
      }
    }
  };

  useEffect(() => {
    const existingChecks = { ...checks };
    tSections.forEach((tSection) => {
      if (existingChecks[tSection.id] !== tSection.is_completed) {
        existingChecks[tSection.id] = tSection.is_completed;
      }
      (tSection.childs || []).forEach((tSubSection) => {
        if (existingChecks[tSubSection.id] !== tSubSection.is_completed) {
          existingChecks[tSubSection.id] = tSubSection.is_completed;
        }
      });
    });
    if (!isEqual(existingChecks, checks)) {
      setChecks(existingChecks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tSections]);

  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.top}>
        <NoteBlock
          onClick={() => {
            setIsOpened(true);
          }}
          count={tNotes?.length}
        />
        <Select
          data={statusOptions}
          radius="md"
          value={currentStatus?.id}
          onChange={(value) => {
            onSettingChange('status', value);
          }}
          maxDropdownHeight={400}
          info={
            getDPDate(
              isPreview ? new Date() : data?.[tCertKey]?.status_updated_at,
              true
            ) || '-/-/--'
          }
          className={classes.statusSelect}
          disabled
        />
      </div>
      <div className={classes.content}>
        {!!noticeData?.id && (
          <NoticeBlock
            isWorkflow={isWorkflow}
            fields={noticeData?.fields}
            values={
              isPreview
                ? getPreviewNoticeValues(setting, noticeData.id)
                : data?.values
            }
            onUpdateInterviewDate={handleUpdateInterviewSchedule}
            onViewFile={(tFile, noObj) => {
              handleViewFile(tFile, noticeData.id, noObj.value);
            }}
            onAddNotice={handleAddNotice}
            addNoticeLoading={saveCertificateFieldsLoading}
            onUploadFile={handleUploadNoticeFile}
            checkboxObj={{
              checked: checks[noticeData.id],
              onChange: (val) => {
                setChecks({
                  ...checks,
                  [noticeData.id]: val,
                });
                if (!isPreview) {
                  markCertificateSectionCompleted(
                    certId,
                    {
                      section_id: noticeData.id,
                    },
                    isWorkflow ? 'workflows' : '',
                    actionCb((res) => {
                      syncSetCertificationFields(res);
                    })
                  );
                }
              },
            }}
          />
        )}
        {sectionsData.map((s) => {
          const tSubs = s.childs || [];
          return (
            <SectionBlock
              isWorkflow={isWorkflow}
              titlePrimary={tSubs.length > 0}
              borderColor={tSubs.length > 0 ? 'primary' : ''}
              key={s.id}
              fields={s?.fields}
              values={
                isPreview
                  ? getPreviewSectionValues(setting, s.id)
                  : data?.values
              }
              onFieldChange={(tValues, tSetting, tSectionObj) => {
                handleFieldChange(tValues, tSetting, tSectionObj || s);
              }}
              onViewFile={(tFiles, tSectionObjId) => {
                handleViewFile(tFiles, tSectionObjId || s.id);
              }}
              displaySub
              subs={tSubs}
              isPreview={isPreview}
              getPreviewSubValues={(subId) => {
                return getPreviewSectionValues(setting, subId);
              }}
              allPreviewSubFiles={getAllPreviewSubFiles(setting, s)}
              checkboxObj={{
                checked: checks[s.id],
                onChange: (val) => {
                  const checkParentFn = () => {
                    setChecks({
                      ...checks,
                      [s.id]: val,
                    });
                    if (!isPreview) {
                      markCertificateSectionCompleted(
                        certId,
                        {
                          section_id: s.id,
                        },
                        isWorkflow ? 'workflows' : '',
                        actionCb((res) => {
                          syncSetCertificationFields(res);
                        })
                      );
                    }
                  };
                  if (val) {
                    const allChildCompleted =
                      tSubs.filter(
                        (obj) => !obj.is_completed && !checks[obj.id]
                      ).length === 0;
                    if (!allChildCompleted) {
                      setTmpCheckParentIds([...tmpCheckParentIds, s.id]);
                    } else {
                      checkParentFn();
                    }
                  } else {
                    checkParentFn();
                  }
                },
              }}
              checks={checks}
              onCheckChanged={(val, subId) => {
                const newChecks = {
                  ...checks,
                  [subId]: val,
                };
                setChecks(newChecks);
                const isAllSubCompleted = getAllSubCompleted(tSubs, newChecks);
                if (isAllSubCompleted) {
                  setTmpCheckParentIds(
                    tmpCheckParentIds.filter((v) => v !== s.id)
                  );
                }
                if (!isPreview) {
                  markCertificateSectionCompleted(
                    certId,
                    {
                      section_id: subId,
                    },
                    isWorkflow ? 'workflows' : '',
                    actionCb((res) => {
                      syncSetCertificationFields(res);
                    })
                  );
                }
              }}
              onBell={(tId) => {
                const thisId = typeof tId === 'number' ? tId : s.id;
                const thisSectionObj = getSectionObjFromList(
                  data?.fields || [],
                  thisId
                );
                setBellObj(thisSectionObj);
                setIsOpened(true);
              }}
              subBorder={tmpCheckParentIds.includes(s.id)}
            />
          );
        })}
        <DocumentBlock
          isWorkflow={isWorkflow}
          currentStatusName={data?.[tCertKey]?.status?.name}
          completeLoading={completeLoading}
          onView={handleViewAllFiles}
          isPreview={isPreview}
          onFileChange={(tFile, setLoading) => {
            if (!isPreview) {
              const tBR = getUploadFullFileBR(tFile);
              if (tBR) {
                setLoading(true);
                uploadCertificateFullFile(
                  certId,
                  tBR,
                  isWorkflow ? 'workflows' : '',
                  actionCb(
                    () => {
                      setLoading(false);
                    },
                    () => {
                      setLoading(false);
                    },
                    'Upload Full File Failed!'
                  )
                );
              }
            } else {
              if (tFile) {
                onSettingChange(REST_FILES_KEY, [
                  ...(setting?.[REST_FILES_KEY] || []),
                  tFile,
                ]);
              }
            }
          }}
          onComplete={() => {
            const currentStatus = data?.[tCertKey]?.status?.name;
            const isCurrentStatusCompleted =
              currentStatus === COMPLETE_CERT_STATUS_VALUE;
            confirmModalFn({
              children: isCurrentStatusCompleted ? (
                <>
                  Are you sure you want to resume this{' '}
                  {isWorkflow ? 'workflow' : 'certification'}?
                  <br />
                  <br />
                  This will move the {isWorkflow
                    ? 'workflow'
                    : 'certification'}{' '}
                  back into the pending section and update the status to the
                  last assigned status before being marked as complete.
                </>
              ) : (
                <>
                  Are you sure you want to complete this{' '}
                  {isWorkflow ? 'workflow' : 'certification'}?
                  <br />
                  <br />
                  Currently, not all sections or subsections have been marked as
                  done.
                  <br />
                  Once marked as complete, this{' '}
                  {isWorkflow ? 'workflow' : 'certification'} will no longer be
                  editable. Unless moved back to the pending status by an Admin,
                  Executive, or Compliance Manager
                  <br />
                  <br />
                  Please carefully review each section & subsection to verify it
                  is complete.
                </>
              ),
              onCancel: () => {},
              onConfirm: () => {
                setCompleteLoading(true);
                markCertificateCompleted(
                  certId,
                  isWorkflow ? 'workflows' : '',
                  actionCb(
                    (res) => {
                      setCompleteLoading(false);
                      syncSetCertificationFields(res);
                      if (loadCertData) loadCertData();
                    },
                    () => {
                      setCompleteLoading(false);
                    },
                    `${isCurrentStatusCompleted ? 'Resume' : 'Complete'} ${
                      isWorkflow ? 'workflow' : 'certification'
                    } failed!`
                  )
                );
              },
              labels: {
                confirm: isCurrentStatusCompleted ? 'Resume' : 'Complete',
                cancel: 'Cancel',
              },
              centered: true,
              isConfirm: true,
            });
          }}
        />
      </div>

      <NoteModal
        opened={isOpened}
        onClose={() => {
          setIsOpened(false);
          setBellObj(null);
        }}
        onSubmit={handleAddNote}
        notes={tNotes}
        preObj={bellObj}
      />
      <FileModal
        opened={isViewFile}
        onClose={() => {
          setIsViewFile(false);
        }}
        title="Files Uploaded"
        fileList={vFiles}
        onDelete={handleDeleteFile}
        isPreview={isPreview}
      />
    </div>
  );
};

export default PendingCerts;
