import cn from 'classnames';
import find from 'lodash/find';
import React from 'react';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/CloseCircle.svg';
import { ActionDropdown } from 'src/components';
import { ACTIVE_INACTIVE_KEY, DAYS } from 'src/helpers/constants';
import { MenuItem } from 'src/types/Item';
import { UserType } from 'src/types/user';
import classes from './CardList.module.scss';

type ContentCardType = 'profile' | 'raw' | 'calendar';
const ContentCard = ({
  children,
  type,
  items,
  widths,
  className,
  actions,
  user,
}: {
  children: React.ReactNode;
  type?: ContentCardType;
  items?: any[];
  widths?: string[];
  className?: string;
  actions?: MenuItem[];
  user?: UserType;
}) => {
  return (
    <div
      className={cn(
        classes.contentCard,
        classes[type],
        {
          [classes.hasAction]: actions?.length,
        },
        className
      )}
    >
      {children}
      {(items || []).map((item, i) => {
        return (
          <div key={i} style={{ width: widths[i] }}>
            <span className={classes.mobileLabel}>
              <span>{item.label}</span>
              <span>:</span>
            </span>
            {item.value}
          </div>
        );
      })}
      {actions?.length && (
        <div className={classes.cardAction}>
          {actions?.length > 0 && (
            <ActionDropdown
              items={actions.map((item) => {
                return {
                  ...item,
                  label:
                    item.label === ACTIVE_INACTIVE_KEY
                      ? user.is_active
                        ? 'Set Inactive'
                        : 'Set Active'
                      : item.label,
                  onClick: () => {
                    if (item.onClick) {
                      item.onClick(user);
                    }
                  },
                };
              })}
              className={classes.actionDropdown}
              withinPortal
            />
          )}
        </div>
      )}
    </div>
  );
};
const CalendarCardItem = ({
  children,
  active = false,
  onClick = undefined,
}) => {
  return (
    <li
      className={cn(classes.calendarCardItem, {
        [classes.itemActive]: !!active,
      })}
    >
      {children}
      <span onClick={onClick}>
        <CloseCircleSvg />
      </span>
    </li>
  );
};
const CalendarView = ({ data = [] }) => {
  return (
    <div className={classes.calendarView}>
      <ul>
        {DAYS.map((day) => {
          const foundDay = find(data, { day });
          return (
            <li key={day}>
              <span>{day}:</span>
              <span>
                {foundDay?.is_active ? (
                  <b>
                    {foundDay.in} - {foundDay.out}
                  </b>
                ) : (
                  <></>
                )}
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
const ProfileColumn = ({ children, mobileBackground = false, ...other }) => {
  return (
    <div
      {...other}
      className={cn(classes.profileColumn, {
        [classes.mobileBackground]: mobileBackground,
      })}
    >
      {children}
    </div>
  );
};

export { ProfileColumn, CalendarCardItem, CalendarView };
export default ContentCard;
