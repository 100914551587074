import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  ResponsiveCard,
  Table,
  TableResult,
} from 'src/components';
import { Dropdown, Input } from 'src/components/FormFields';
import {
  FilterBar,
  MainContent,
  PageContent,
  PageContentHeader,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { BETA_DISABLED } from 'src/helpers/constants';
import {
  DEFAULT_ALL_OPTION,
  REPORT_STATUS_COLOR,
} from 'src/helpers/constants/options';
import {
  useCurrentPath,
  useCurrentRole,
  useInfiniteScroll,
  useMedia,
  useOptions,
} from 'src/helpers/hooks';
import { getCardValue } from 'src/utils/card';
import { applySearch } from 'src/utils/search';
import { renderDateColumn } from 'src/utils/table';
import { displayName } from 'src/utils/text';
import classes from './CertificationsList.module.scss';
import Loading from './Loading';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';
import { getCertificationFilters, getColumns } from './utils';

let page = 1;

const CertificationsList = () => {
  const params = useParams();
  const id = Number(params?.id);
  const isCertificationOfCertPage = id > 0;
  const role = useCurrentRole();
  const permission = getPermission(role);
  const isTablet = useMedia('(max-width: 768px)');
  const {
    propertyOptions,
    certificationStatusOptions,
    certificationTypeOptions,
  } = useOptions();
  const navigate = useNavigate();
  const currentPath = useCurrentPath();
  const isWorkflow = currentPath === '/workflow-list-builder/:id';
  const {
    getCertifications,
    getCertificateDetail,
    getWorkflowBuilderDetail,
    getWorkflows,
    getCertBuilderDropdown,
  } = useActions();
  const {
    certifications,
    certificationsMeta,
    certDetail,
    workflowDetail,
    workflowsMeta,
    workflows,
  } = useIndexData();
  const tCertifications = isWorkflow ? workflows : certifications;
  const tCertificationsMeta = isWorkflow ? workflowsMeta : certificationsMeta;
  const tDetails = isWorkflow ? workflowDetail : certDetail;
  const [setting, setSetting] = useState({
    property: [],
    type: [],
    status: [],
  } as Record<string, string[]>);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [search, setSearch] = useState('');
  const reloadListData = (tSetting, tSearch, tPage = 1, cb = () => {}) => {
    const fAppliedFilter = getCertificationFilters(
      tSetting,
      tSearch,
      tPage,
      isWorkflow
    ) as any;
    if (isCertificationOfCertPage) {
      if (isWorkflow) {
        fAppliedFilter.schema_id = id;
      } else {
        fAppliedFilter.certificate_schema_id = id;
      }
    }
    if (isWorkflow) {
      getWorkflows(fAppliedFilter, cb);
    } else {
      getCertifications(fAppliedFilter, cb);
    }
  };
  const handleSettingChange = (key, value, willNotFetch = false) => {
    page = 1;
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (!willNotFetch) {
      reloadListData(newSetting, search);
    }
  };
  const goToDetail = (tCertificationId) => {
    navigate(
      isCertificationOfCertPage
        ? `/${
            isWorkflow ? 'workflow' : 'certifications'
          }-list-builder/${id}/${tCertificationId}`
        : `/certifications-list/${tCertificationId}`
    );
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    reloadListData(setting, search, page, () => {
      setIsLazyLoading(false);
    });
  };
  const loadDropdown = () => {
    if (!BETA_DISABLED) {
      getCertBuilderDropdown(isWorkflow ? 'workflow' : 'certificate');
    }
  };

  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      tCertificationsMeta &&
      tCertificationsMeta.current_page < tCertificationsMeta.last_page,
  });

  useEffect(() => {
    page = 1;
    reloadListData(setting, search);
    if (isCertificationOfCertPage) {
      if (isWorkflow) {
        getWorkflowBuilderDetail(id);
      } else {
        getCertificateDetail(id);
      }
    }
    loadDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: isWorkflow ? 'Workflows' : 'Certifications',
            link: isWorkflow ? '/workflows' : '/certifications',
          },
          {
            label: isCertificationOfCertPage
              ? tDetails.name
              : 'Certifications List',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <Loading />
        <PageHeader
          title={
            isCertificationOfCertPage ? tDetails.name : 'Certifications List'
          }
          className={classes.pageHeader}
        >
          <Input
            radius="md"
            value={search}
            onChange={(e) => {
              const val = e.target.value;
              setSearch(val);
              applySearch(val, (tVal) => {
                page = 1;
                reloadListData(setting, tVal);
              });
            }}
            placeholder="Search"
            isSearch
          />
        </PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          <PageContentHeader tableResult={tCertificationsMeta.total}>
            <FilterBar className={classes.filterBar}>
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...propertyOptions]}
                value={setting.property}
                onChange={(val) => {
                  handleSettingChange('property', val);
                }}
                placeholder={'All Properties'}
                className={classes.propertyDropdown}
              />
              {!isWorkflow && (
                <Dropdown
                  items={[DEFAULT_ALL_OPTION, ...certificationTypeOptions]}
                  value={setting.type}
                  onChange={(val) => {
                    handleSettingChange('type', val);
                  }}
                  placeholder={'Type'}
                  className={classes.typeDropdown}
                />
              )}
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...certificationStatusOptions]}
                value={setting.status}
                onChange={(val) => {
                  handleSettingChange('status', val);
                }}
                placeholder={'Status'}
                className={classes.statusDropdown}
              />
            </FilterBar>
            <Button
              onClick={() => {
                navigate(isWorkflow ? '/new-workflow' : '/new-certification');
              }}
              radius="md"
              textSize="xl"
            >
              Start New {isWorkflow ? 'Workflow' : 'Certification'}
            </Button>
          </PageContentHeader>
          <TableResult
            count={tCertificationsMeta.total}
            className={classes.tableResult}
            mobileShow
            mobileTop={-49}
          />
          {isTablet ? (
            <>
              {tCertifications.map((certification) => {
                return (
                  <ResponsiveCard
                    key={certification.id}
                    items={[
                      {
                        label: 'Alerts',
                        value: (
                          <Badge circle alert size="md">
                            {certification.alerts_count}
                          </Badge>
                        ),
                      },
                      {
                        label: 'Unit',
                        value: getCardValue(
                          certification.unit?.unit_id || 'N/A'
                        ),
                      },
                      {
                        label: 'Property',
                        value: getCardValue(certification.property?.name),
                      },
                      {
                        label: 'Name',
                        value: getCardValue(
                          displayName(
                            certification.residents?.[0]?.first_name,
                            certification.residents?.[0]?.last_name
                          )
                        ),
                      },
                      ...(isWorkflow
                        ? [
                            {
                              label: 'Type',
                              value: getCardValue(certification.workflow_type),
                            },
                          ]
                        : [
                            {
                              label: 'Type',
                              value: getCardValue(
                                (certification.types || [])
                                  .map((v) => v.code)
                                  .filter((v) => !!v)
                                  .join(', ')
                              ),
                            },
                          ]),
                    ]}
                    boxItems={[
                      {
                        label: 'Status',
                        value: 'Due Date',
                      },
                      {
                        label: (
                          <Badge
                            color={
                              REPORT_STATUS_COLOR[certification.status?.name]
                            }
                          >
                            {certification.status?.name}
                          </Badge>
                        ),
                        value: getCardValue(
                          renderDateColumn(certification.effective_date)
                        ),
                      },
                    ]}
                    onView={
                      permission.canViewDetail
                        ? () => {
                            goToDetail(certification.id);
                          }
                        : undefined
                    }
                  />
                );
              })}
            </>
          ) : (
            <Box vertical>
              <Table
                columns={getColumns(isWorkflow)}
                align="left"
                data={tCertifications}
                onView={
                  permission.canViewDetail
                    ? (record) => {
                        goToDetail(record.id);
                      }
                    : undefined
                }
                selection={{}}
                pagination={{}}
              />
            </Box>
          )}
        </PageContent>
      </MainContent>
    </>
  );
};

export default CertificationsList;
