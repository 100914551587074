import get from 'lodash/get';
import isArray from 'lodash/isArray';
import { Avatar, Badge, Button, Circle } from 'src/components';
import { Select } from 'src/components/FormFields';
import { getMomentDateFormat } from 'src/utils/date';
import { getPhoneFromArray } from 'src/utils/phone';
import { displayName, displayRoles, intialName } from 'src/utils/text';

export const renderStandardColumn = (val) => val || '-';
export const renderStandardWithStatusColumn =
  ({ willShow }) =>
  (val, row) => {
    return (
      <>
        {willShow(row) && <Circle style={{ top: 0, marginRight: 8 }} />}
        {val || '-'}
      </>
    );
  };
export const renderNameColumn = (_val, row) => {
  return displayName(row.first_name, row.last_name) || '-';
};
export const renderRoleColumn = (val) => {
  return displayRoles(val) || '-';
};
export const renderDateColumn = (val) => {
  return getMomentDateFormat(val, 'MM/DD/YYYY') || '-';
};
export const renderAvatarColumn = (val) => {
  return (
    <Avatar size="sm" circle weight="md" fontSize="md">
      {intialName(val?.first_name, val?.last_name)}
    </Avatar>
  );
};
export const renderAlertsColumn = (val) => {
  return val ? (
    <Badge circle alert size="md">
      {val}
    </Badge>
  ) : (
    ''
  );
};
export const renderAlertAsStatusColumn = (val) => {
  return val ? <Badge>{val}</Badge> : '';
};
export const renderPrintColumn = (val) => {
  return (
    <Button
      radius="md"
      size="xs"
      variant="default"
      textSize="md"
      onClick={() => {
        if (val) {
          const win = window.open(val, '_target');
          win.focus();
        }
      }}
    >
      Print
    </Button>
  );
};
export const renderDropdownColumn =
  ({ options, onChange }) =>
  (val, record) => {
    return (
      <Select
        size="xs"
        radius="md"
        placeholder="Select"
        data={options}
        value={val}
        onChange={(val) => {
          onChange(val, record);
        }}
      />
    );
  };
export const renderGrantedUsersColumn = (key) => (_, obj) => {
  const tUser = (get(obj, `granted_users.${key}`) || [])[0] || undefined;
  if (tUser) {
    return displayName(tUser.first_name, tUser.last_name);
  }
  return '-';
};
export const renderPhonesColumn =
  (key = 'phone') =>
  (_, obj) => {
    const phones = obj?.[key] || [];
    if (!isArray(phones)) return '-';
    return getPhoneFromArray(phones);
  };
