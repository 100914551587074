import {
  SegmentedControl as ManSegmentedControl,
  SegmentedControlProps,
} from '@mantine/core';
import cn from 'classnames';
import classes from './SegmentedControl.module.scss';

export type OSelectProps = SegmentedControlProps & {
  className?: string;
  isStatus?: boolean;
};

const SegmentedControl = ({
  className = '',
  isStatus,
  ...other
}: OSelectProps) => {
  return (
    <ManSegmentedControl
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.small]: other.size === 'xs',
          [classes.isStatus]: isStatus,
        },
        className
      )}
    />
  );
};

export default SegmentedControl;
