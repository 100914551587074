import formatStringByPattern from 'format-string-by-pattern';
import find from 'lodash/find';

export const getFormattedPhone = (val) => {
  if (!val) return '';
  return formatStringByPattern('999-999-9999', val);
};
export const getPhoneFromArray = (arr) => {
  if (!arr || arr.length === 0) return '';
  const phonePriority = ['Mobile', 'Home', 'Office', 'FAX'];
  let returnVal = '';
  phonePriority.forEach((type) => {
    if (!returnVal) {
      returnVal = find(arr, { type })?.number;
    }
  });
  return getFormattedPhone(returnVal);
};
