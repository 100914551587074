import cn from 'classnames';
import classes from './Box.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode | string;
  vertical?: boolean;
}

const Box = ({ className, children, vertical }: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.vertical]: vertical,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export default Box;
