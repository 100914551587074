import { ROLES } from 'src/helpers/constants';
import { adminPermission } from './admin';
import { otherPermission } from './other';

const permissions = {
  [ROLES.ADMIN]: adminPermission,
  [ROLES.SUPER_ADMIN]: adminPermission,
};

const getPermission = (role) => {
  return permissions[role] || otherPermission;
};

export default getPermission;
