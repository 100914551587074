import get from 'lodash/get';

export const getErrorMessageFromResponse = (err) => {
  const errorData = get(err, 'data.data') || {};
  let errorMessage = get(err, 'data.message') || '';
  const errorKeys = Object.keys(errorData) || [];
  if (errorKeys.length > 0) {
    errorMessage = errorData[errorKeys[0]];
  }
  return errorMessage;
};
export const getIsEmptyEntity = (record: Record<string, unknown>) => {
  return Object.keys(record).filter((key) => !!record[key]).length === 0;
};
export const getIsEmptyEntities = (records: Record<string, unknown>[]) =>
  records.filter((obj) => !getIsEmptyEntity(obj)).length === 0;
