import { Request } from './request';

export const apiGetCertificateList = async (params = {}) => {
  return Request.call({
    url: '/certificate/builder',
    method: 'GET',
    params,
  });
};
export const apiGetCertificateDetail = async (id) => {
  return Request.call({
    url: `/certificate/builder/${id}`,
    method: 'GET',
  });
};
export const apiGetCertifications = async (params = {}) => {
  return Request.call({
    url: '/certifications',
    method: 'GET',
    params,
  });
};
export const apiGetCertificateFields = async (id, year, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/fields/${id}`,
    method: 'GET',
    params: {
      year,
    },
  });
};
export const apiGetCertificateNote = async (id, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/note/${id}`,
    method: 'GET',
  });
};
export const apiGetCertificationPending = async () => {
  return Request.call({
    url: `/certifications/pending`,
    method: 'GET',
  });
};
export const apiAddCertification = async (data = {}) => {
  return Request.call({
    url: '/certifications',
    method: 'POST',
    data,
  });
};
export const apiUpdateCertification = async (id, data = {}, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/${id}`,
    method: 'POST',
    data,
  });
};
export const apiBuildCertificate = async (data = {}, key = '') => {
  return Request.call({
    url: `/${key || 'certificate'}/builder`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiDeleteCertificate = async (id, key = '') => {
  return Request.call({
    url: `/${key || 'certificate'}/builder/${id}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiDeleteCertificateFile = async (id, fileId, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/file/${id}/${fileId}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiSaveCertificateFields = async (id, data, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/fields/${id}`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiAddCertificateNote = async (data, key) => {
  return Request.call({
    url: `/${key || 'certifications'}/note`,
    method: 'POST',
    data,
  });
};
export const apiUploadCertificateFullFile = async (id, data, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/file/${id}`,
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiDeleteCertificateFullFile = async (id, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/file/${id}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiDeleteCertificateSection = async (id, key = '') => {
  return Request.call({
    url: `/${key || 'certificate'}/builder/form/${id}`,
    method: 'DELETE',
    data: {},
  });
};
export const apiMarkCertificateSectionCompleted = async (
  id,
  data,
  key = ''
) => {
  return Request.call({
    url: `/${key || 'certifications'}/section/${id}/completed`,
    method: 'POST',
    data,
  });
};
export const apiMarkCertificateCompleted = async (id, key = '') => {
  return Request.call({
    url: `/${key || 'certifications'}/${id}/mark_completed`,
    method: 'POST',
    data: {},
  });
};
