import {
  LoadingOverlayProps,
  LoadingOverlay as ManLoadingOverlay,
} from '@mantine/core';
import cn from 'classnames';
import classes from './LoadingOverlay.module.scss';

type Props = LoadingOverlayProps & {
  className?: string;
  fixed?: boolean;
};

const LoadingOverlay = ({ className = '', fixed, ...other }: Props) => {
  return (
    <ManLoadingOverlay
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.fixed]: fixed,
        },
        className
      )}
    />
  );
};

export default LoadingOverlay;
