import { FieldArray } from 'formik';
import get from 'lodash/get';
import { useEffect } from 'react';
import {
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  DraggableStyle,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import styled from 'styled-components';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import { Button } from 'src/components';
import {
  NOTICE_REQUIRED_FIELDS,
  SECTION_REQUIRED_FIELDS,
} from 'src/pages/BuildCertificate/constants';
import { CertFlowBuilder, CertificationSection } from 'src/types/certification';
import { getNewCertificationSubSection } from 'src/utils/certification';
import { getSectionIsValid } from 'src/utils/form';
import Section from '../Section';
import classes from './SectionList.module.scss';

const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  user-select: none;
  padding-left: ${({ lv }) => `${lv * 24}px`};
`;
const NestedContainer = styled(Container)``;

const getStyle = (style: DraggableStyle, snapshot: DraggableStateSnapshot) => {
  if (!snapshot.isDropAnimating) {
    return style;
  }
  return {
    ...style,
    transitionDuration: `0.001s`,
  };
};

interface Props {
  flowBuilder: CertFlowBuilder;
  formProps: any;
  subHelperRef: any;
  onDeleteSection: (index: number) => void;
  onDeleteExistingSection: (id: number, cb?: any) => void;
}

let previousItems = {};

const SectionList = ({
  flowBuilder,
  subHelperRef,
  formProps,
  onDeleteSection,
  onDeleteExistingSection,
}: Props) => {
  const { values, setFieldValue } = formProps;
  const handleUpdateSection = (sName, sObj, oVal) => {
    setFieldValue(sName, {
      ...sObj,
      opened: oVal,
      status: 'added',
    });
  };

  const renderList = (
    tFlowBuilder: CertFlowBuilder | CertificationSection,
    level = 0,
    parentName: string,
    parentSectionTitle?: string
  ) => {
    const isSub = level > 0;
    return (
      <Droppable
        droppableId={tFlowBuilder.id}
        type={isSub ? 'second' : tFlowBuilder.type}
        key={tFlowBuilder.id}
      >
        {(
          dropProvided: DroppableProvided,
          dropSnapshot: DroppableStateSnapshot
        ) => (
          <Container
            ref={dropProvided.innerRef}
            isDraggingOver={dropSnapshot.isDraggingOver}
            {...dropProvided.droppableProps}
            lv={level}
          >
            {tFlowBuilder.children.map(
              (item: CertificationSection, index: number) => {
                const isDragDisabled = item.sectionType === 'notice';
                return (
                  <Draggable
                    draggableId={item.id}
                    key={item.id}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(
                      dragProvided: DraggableProvided,
                      dragSnapshot: DraggableStateSnapshot
                    ) => {
                      const tSectionName = `${parentName}.${index}`;
                      const tOpenedName = `${tSectionName}.opened`;
                      const tOpened = item.opened;
                      return (
                        <NestedContainer
                          ref={dragProvided.innerRef}
                          isDragging={
                            isDragDisabled ? false : dragSnapshot.isDragging
                          }
                          {...(isDragDisabled
                            ? {}
                            : dragProvided.draggableProps)}
                          style={
                            isDragDisabled
                              ? {}
                              : getStyle(
                                  dragProvided.draggableProps.style,
                                  dragSnapshot
                                )
                          }
                        >
                          <Section
                            isDragDisabled={isDragDisabled}
                            selectedStatuses={values.status_options}
                            data={item}
                            dragHandleProps={
                              isDragDisabled
                                ? {}
                                : { ...dragProvided.dragHandleProps }
                            }
                            parentName={`${parentName}.${index}`}
                            toggle={() => {
                              if (!tOpened) {
                                previousItems[item.id] = item;
                              }
                              setFieldValue(tOpenedName, !tOpened);
                            }}
                            isSectionValid={getSectionIsValid(
                              (item.sectionType === 'section'
                                ? SECTION_REQUIRED_FIELDS
                                : NOTICE_REQUIRED_FIELDS
                              ).map((f) => `${parentName}.${index}.${f}`),
                              values
                            )}
                            onAddSection={() => {
                              handleUpdateSection(tSectionName, item, !tOpened);
                            }}
                            onCancelSection={(tData) => {
                              const isItemSub = item.type === 'second';
                              if (tData.status === 'new' && !tData.existingId) {
                                if (isItemSub) {
                                  if (subHelperRef && subHelperRef.current) {
                                    subHelperRef.current.remove(index);
                                  }
                                } else {
                                  onDeleteSection(index);
                                }
                              } else {
                                const previousSectionData =
                                  previousItems[tData.id] || tData;
                                handleUpdateSection(
                                  tSectionName,
                                  previousSectionData,
                                  !tOpened
                                );
                              }
                            }}
                            onDeleteSection={(eId, cb) => {
                              const isItemSub = item.type === 'second';
                              if (!eId) {
                                if (isItemSub) {
                                  if (subHelperRef && subHelperRef.current) {
                                    subHelperRef.current.remove(index);
                                  }
                                } else {
                                  onDeleteSection(index);
                                }
                              } else {
                                onDeleteExistingSection(eId, (res) => {
                                  if (res) {
                                    if (isItemSub) {
                                      if (
                                        subHelperRef &&
                                        subHelperRef.current
                                      ) {
                                        subHelperRef.current.remove(index);
                                      }
                                    } else {
                                      onDeleteSection(index);
                                    }
                                  }
                                  if (cb) cb();
                                });
                              }
                            }}
                            parentSectionTitle={parentSectionTitle}
                            onDateInputChanged={(val) => {
                              const tName = `${parentName}.${index}.check_schedule`;
                              const eValue = get(values, tName) || [];
                              setFieldValue(
                                `${parentName}.${index}.check_schedule`,
                                [...eValue, val]
                              );
                            }}
                            onDropdownChanged={(val, cl) => {
                              setFieldValue(
                                `${parentName}.${index}.check_dropdown`,
                                {
                                  value: val,
                                  checklist: cl,
                                }
                              );
                            }}
                          />
                          {!isSub && (
                            <FieldArray
                              name={`flowBuilder.children.${index}.children`}
                            >
                              {(subHelper) => {
                                subHelperRef.current = subHelper;
                                return (
                                  <>
                                    {item.children?.length > 0 && (
                                      <>
                                        {renderList(
                                          item,
                                          level + 1,
                                          `flowBuilder.children.${index}.children`,
                                          item.section_name
                                        )}
                                      </>
                                    )}
                                    {item.sectionType === 'section' &&
                                      item.type === 'first' &&
                                      !tOpened && (
                                        <Button
                                          variant={'default'}
                                          onClick={() => {
                                            subHelper.push(
                                              getNewCertificationSubSection(
                                                item
                                              )
                                            );
                                          }}
                                          radius="md"
                                          textSize="md"
                                          size="xs"
                                          leftIcon={<PlusSvg />}
                                          className={classes.addBtn}
                                        >
                                          Add Subsection
                                        </Button>
                                      )}
                                  </>
                                );
                              }}
                            </FieldArray>
                          )}
                        </NestedContainer>
                      );
                    }}
                  </Draggable>
                );
              }
            )}
            {dropProvided.placeholder}
          </Container>
        )}
      </Droppable>
    );
  };
  useEffect(() => {
    previousItems = {};
  }, []);

  return (
    <div className={classes.wrapper}>
      {renderList(flowBuilder, 0, 'flowBuilder.children')}
    </div>
  );
};

export default SectionList;
