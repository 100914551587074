import cn from 'classnames';
import { Button } from 'src/components';
import classes from './Footer.module.scss';

const Footer = ({ className = '', loading }) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <Button type="submit" radius="md" textWeight="xl" loading={loading}>
        Start Workflow
      </Button>
    </div>
  );
};

export default Footer;
