import { Button, Card } from 'src/components';
import classes from './ResidentInfo.module.scss';

const Footer = ({
  disabled,
  loading = false,
  onCancel,
  isDisabled = false,
}) => {
  return (
    <Card
      className={classes.footer}
      gapSize="sm"
      notPaddingContent
      notMarginContent
    >
      <div className={classes.buttons}>
        <Button
          variant="default"
          textWeight="xl"
          radius="md"
          onClick={onCancel}
          disabled={loading}
        >
          {isDisabled ? 'Back' : 'Cancel'}
        </Button>
        {!isDisabled && (
          <Button
            type="submit"
            radius="md"
            disabled={disabled}
            loading={loading}
            textWeight="xl"
          >
            Save Resident Profile Information
          </Button>
        )}
      </div>
    </Card>
  );
};

export default Footer;
