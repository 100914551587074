import { Reducer } from 'redux';

import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/schedule';

const initialState = {
  schedules: {
    ...defaultArray,
  },
  updateSchedule: {
    ...defaultObj,
  },
  activeSchedule: {
    ...defaultObj,
  },
};

const ScheduleReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_UPDATE_SCHEDULE: {
      return {
        ...state,
        schedules: {
          ...state.schedules,
          data: state.schedules.data.map((schedule) => {
            if (schedule.day === payload.day) {
              return {
                ...schedule,
                ...payload,
              };
            }
            return schedule;
          }),
        },
      };
    }
    case Types.GET_SCHEDULES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          schedules: {
            ...prevState.schedules,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          schedules: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          schedules: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.UPDATE_SCHEDULE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateSchedule: {
            ...prevState.updateSchedule,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateSchedule: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateSchedule: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.ACTIVE_SCHEDULE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          activeSchedule: {
            ...prevState.activeSchedule,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          activeSchedule: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          activeSchedule: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default ScheduleReducer;
