import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from 'src/components';
import { Input } from 'src/components/FormFields';
import DocumentModal from 'src/pages/Documents/components/DocumentModal';
import classes from './DateInputModal.module.scss';

const DateInputModal = ({
  className = '',
  isOpened,
  onClose,
  onSubmit,
  defaultValue = null,
}) => {
  const [val, setVal] = useState('');
  const resetState = () => {
    setVal(defaultValue || '');
  };

  useEffect(() => {
    if (isOpened) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <DocumentModal
      isOpened={isOpened}
      onClose={onClose}
      title={'Add Date Input'}
      size={583}
      className={cn(classes.modalWrapper, className)}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <div className={classes.content}>
        <Input
          radius="sm"
          value={val}
          onChange={(e) => {
            setVal(e.target.value);
          }}
          placeholder="Date Input"
        />
        <Button
          radius="md"
          textSize="xl"
          fullWidth
          type="button"
          onClick={() => {
            if (val && !!val.trim()) {
              onSubmit(val);
            }
          }}
        >
          Apply
        </Button>
      </div>
    </DocumentModal>
  );
};

export default DateInputModal;
