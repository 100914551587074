import cn from 'classnames';
import { ReactComponent as CloseCircleSvg } from 'src/assets/icons/CloseCircle.svg';
import { ReactComponent as FileSvg } from 'src/assets/icons/File.svg';
import { Icon } from 'src/components';
import { downloadFile, downloadFileFromObj } from 'src/utils/file';
import classes from './Upload.module.scss';

export const UploadResultRow = ({
  name,
  fileObj = null,
  uploadedAsInput = false,
  hideRemoveValue = false,
  onRemove = undefined,
  children = undefined,
  className = '',
  loading = false,
}) => {
  return (
    <div
      className={cn(
        classes.resultRow,
        {
          [classes.uploading]: loading,
        },
        className
      )}
    >
      <div className={classes.resultRowContent}>
        <FileSvg />
        <span
          className={cn(classes.uploadName, {
            [classes.uploadedAsInput]: uploadedAsInput,
          })}
          onClick={() => {
            if (fileObj?.download_link || fileObj?.url) {
              downloadFile(fileObj.download_link || fileObj.url, fileObj.name);
            } else {
              downloadFileFromObj(fileObj);
            }
          }}
        >
          {name}
        </span>
        {!hideRemoveValue && (
          <Icon onClick={onRemove} className={classes.removeIcon}>
            <CloseCircleSvg />
          </Icon>
        )}
      </div>
      {!!children && (
        <div className={classes.resultRowMContent}>{children}</div>
      )}
    </div>
  );
};
export const UploadResultWrapper = ({
  children,
  className = '',
  rowHasChildren = false,
}) => {
  return (
    <div
      className={cn(
        classes.result,
        {
          [classes.rowHasChildren]: rowHasChildren,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

const UploadResult = ({
  uploadedFiles,
  uploadedAsInput = false,
  hideRemoveValue = false,
  onRemove = undefined,
  className = '',
  loading = false,
}) => {
  return (
    <UploadResultWrapper className={className}>
      {uploadedFiles.map((v, i) => {
        return (
          <UploadResultRow
            key={i}
            name={v.name}
            fileObj={v}
            uploadedAsInput={uploadedAsInput}
            hideRemoveValue={hideRemoveValue}
            onRemove={() => {
              if (onRemove) onRemove(i);
            }}
            loading={loading}
          />
        );
      })}
    </UploadResultWrapper>
  );
};

export default UploadResult;
