import get from 'lodash/get';
import { Dispatch } from 'redux';
import {
  apiGetUsers,
  apiSwitchUserActive,
  apiSwitchUserDetailActive,
  apiUpdateUserStatus,
} from 'src/api';
import Types from '../types/inout';
import { syncUpdateMeData } from './auth';

export const syncUpdateInoutUser =
  (key, departmentKey, id, data) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_UPDATE_INOUT_USER,
      payload: { userKey: key, departmentKey, id, data },
    });
  };

export const getPMUsers =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { groupByDepartment: get(params, 'group_by_department') },
      type: Types.GET_PM_USERS,
      asyncCall: () => {
        return apiGetUsers({
          ...params,
          department_groups: 'Property Management',
          limit: 1000,
          sort_by: 'status_updated_at',
          status_updated_at: 'desc',
          group_by_department: true,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getOMUsers =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { groupByDepartment: get(params, 'group_by_department') },
      type: Types.GET_OM_USERS,
      asyncCall: () => {
        return apiGetUsers({
          ...params,
          department_groups: 'Corporate Management',
          limit: 1000,
          sort_by: 'status_updated_at',
          status_updated_at: 'desc',
          group_by_department: true,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const updateUserStatus =
  (data = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_USER_STATUS,
      asyncCall: () => {
        return apiUpdateUserStatus(data);
      },
      onSuccess: (dispatch: any, response: any) => {
        dispatch(syncUpdateMeData(data, true));
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const switchUserActive =
  (data = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.SWITCH_USER_ACTIVE,
      asyncCall: () => {
        return apiSwitchUserActive(data);
      },
      onSuccess: (dispatch: any, response: any) => {
        dispatch(syncUpdateMeData(data, true));
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const switchUserDetailActive =
  (userId: number | string, data = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.SWITCH_USER_DETAIL_ACTIVE,
      asyncCall: () => {
        return apiSwitchUserDetailActive(userId, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
