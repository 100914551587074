import { Dispatch } from 'redux';
import {
  apiAssignUserProperty,
  apiGetAllProperties,
  apiGetPropertyFilters,
  apiGetUserProperty,
  apiRemoveUserProperty,
} from 'src/api';
import Types from '../types/property';

export const getAllProperties =
  (data = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = data;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_ALL_PROPERTIES,
      asyncCall: () => {
        return apiGetAllProperties(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getUserProperty = (userId, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_USER_PROPERTY,
    asyncCall: () => {
      return apiGetUserProperty(userId);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getPropertyFilters = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_PROPERTY_FILTERS,
    asyncCall: () => {
      return apiGetPropertyFilters();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const assignUserProperty =
  (userId, data, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ASSIGN_USER_PROPERTY,
      asyncCall: () => {
        return apiAssignUserProperty(userId, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const removeUserProperty =
  (userId, data, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.REMOVE_USER_PROPERTY,
      asyncCall: () => {
        return apiRemoveUserProperty(userId, data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
