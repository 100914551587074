import { DEFAULT_CERTIFICATION_SECTION } from 'src/helpers/constants/certification';
import {
  CertificationSection,
  CertificationSectionType,
} from 'src/types/certification';

export const getNewCertificationSection = (
  tLength: number,
  sectionType: CertificationSectionType
) => {
  return {
    id: `C-S-${tLength + 1}`,
    type: 'first',
    sectionType,
    children: [],
    opened: true,
    status: 'new',
    ...DEFAULT_CERTIFICATION_SECTION,
  } as CertificationSection;
};
export const getNewCertificationSubSection = (
  section: CertificationSection
) => {
  const sChild = section.children || [];
  const newSection: CertificationSection = {
    id: `${section.id}-Sub-${sChild.length + 1}`,
    type: 'second',
    sectionType: 'section',
    children: [],
    opened: true,
    status: 'new',
    ...DEFAULT_CERTIFICATION_SECTION,
  };
  return newSection as CertificationSection;
};
