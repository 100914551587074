import get from 'lodash/get';
import * as Yup from 'yup';
import {
  FIELD_PARSE_KEY,
  PROPERTY_ASSIGNED_AUTOMATICALLY_BY,
  SSN_FIELD_SUFFIX,
  SUPERVISOR_OPTIONAL_BY,
} from 'src/helpers/constants';
import { decodeNormalObj } from 'src/utils/encrypt';
import {
  cleanInitDateValue,
  cleanInitSSNMaskValue,
  cleanInitSSNValue,
  cleanPayloadValue,
  testDateField,
  testFormatField,
  testFormatRequireField,
} from 'src/utils/form';
import {
  DATE_FIELDS,
  DROPDOWN_INT_FIELDS,
  FORMAT_FIELDS,
  USER_ATTR_MAPPING,
} from './constants';

export const convertPayload = (values) => {
  const returnObj = {} as any;
  Object.keys(USER_ATTR_MAPPING).map((key) => {
    const payloadKey = USER_ATTR_MAPPING[key];
    const parseKey = DATE_FIELDS.includes(payloadKey)
      ? FIELD_PARSE_KEY.DATE
      : FORMAT_FIELDS.includes(payloadKey)
      ? FIELD_PARSE_KEY.FORMAT
      : '';
    returnObj[payloadKey] = cleanPayloadValue(values[key], parseKey);
    return true;
  });
  if (PROPERTY_ASSIGNED_AUTOMATICALLY_BY.includes(returnObj.role)) {
    delete returnObj.properties;
  }
  return returnObj;
};
export const getInitialValues = (data) => {
  const tData = decodeNormalObj(data);
  if (tData.ssn) {
    tData.ssn = cleanInitSSNValue(tData.ssn);
  }
  const returnObj = {} as any;
  Object.keys(USER_ATTR_MAPPING).map((key) => {
    const dataKey = USER_ATTR_MAPPING[key];
    returnObj[key] =
      dataKey == USER_ATTR_MAPPING.userRole
        ? get(tData, 'roles[0].name', '')
        : DATE_FIELDS.includes(dataKey)
        ? cleanInitDateValue(tData?.[dataKey])
        : DROPDOWN_INT_FIELDS.includes(dataKey)
        ? Number(tData?.[dataKey]) || ''
        : tData?.[dataKey] || '';
    return true;
  });
  returnObj[`${USER_ATTR_MAPPING.ssn}${SSN_FIELD_SUFFIX}`] =
    cleanInitSSNMaskValue(tData?.ssn);
  return returnObj;
};

export const schema = Yup.object().shape({
  firstName: Yup.string().required('This field is required!'),
  lastName: Yup.string().required('This field is required!'),
  ssn: Yup.string()
    .required('This field is required!')
    .test('Required SSN', 'This field is required!', testFormatRequireField)
    .test(
      'Invalid Social Security Number',
      'Invalid Social Security Number',
      testFormatField
    ),
  ukgId: Yup.string()
    .required('This field is required!')
    .test('Required HRIS ID', 'This field is required!', testFormatRequireField)
    .test('Invalid HRIS ID', 'Invalid HRIS ID', testFormatField),
  hireDate: Yup.string()
    .required('This field is required!')
    .test(
      'Required Hire Date',
      'This field is required!',
      testFormatRequireField
    )
    .test('Invalid Hire Date', 'Invalid Hire Date', testDateField),
  terminationDate: Yup.string()
    .nullable()
    .test(
      'Invalid Termination Date',
      'Invalid Termination Date',
      testDateField
    ),
  userRole: Yup.string().required('This field is required!'),
  jobTitle: Yup.string().required('This field is required!'),
  organization: Yup.string().required('This field is required!'),
  supervisor: Yup.string().when('userRole', (userRole) => {
    return SUPERVISOR_OPTIONAL_BY.includes(userRole)
      ? Yup.string().nullable()
      : Yup.string().required('This field is required!');
  }),
  email: Yup.string()
    .required('This field is required!')
    .email('Invalid email'),
  cellPhone: Yup.string()
    .nullable()
    .test('Invalid Cell Phone', 'Invalid Cell Phone', testFormatField),
  officePhone: Yup.string()
    .nullable()
    .test('Invalid Office Phone', 'Invalid Office Phone', testFormatField),
  faxNumber: Yup.string()
    .nullable()
    .test('Invalid Fax Number', 'Invalid Fax Number', testFormatField),
});
