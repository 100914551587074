import { Popover } from '@mantine/core';
import cn from 'classnames';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as ArrowSvg } from 'src/assets/icons/Arrow.svg';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import Button from 'src/components/Button';
import { Checkbox, Input } from 'src/components/FormFields';
import Icon from 'src/components/Icon';
import Arrow from 'src/components/Icon/Arrow';
import InfoTooltip from 'src/components/InfoTooltip';
import { NA_VALUE, OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import { FloatingPosition, SizeType } from 'src/types';
import { Item } from 'src/types/Item';
import { actionCb } from 'src/utils/action';
import { trimText } from 'src/utils/text';
import classes from './Dropdown.module.scss';
import { useActions } from './selectorData';
import {
  displayValue,
  enhanceHeightOfDropdown,
  findLocationParentObj,
} from './utils';

type OtherValObj = {
  idPath?: string;
  namePath?: string;
  obj?: any;
};

export type ODropdownProps = {
  className?: string;
  dropdownClassName?: string;
  items: Item[];
  value?: string[];
  onChange?: (val: string[], otherObj?: OtherValObj | OtherValObj[]) => void;
  placeholder?: string;
  position?: FloatingPosition;
  label?: string | React.ReactNode;
  error?: string;
  errorBg?: boolean;
  displaySelectedAll?: boolean;
  placeholderGray?: boolean;
  errorOverlay?: boolean;
  radius?: SizeType;
  applyText?: string;
  isFileLocation?: boolean;
  pathValue?: string;
  newEntity?: 'tag' | 'department' | 'status' | 'workflow_type';
  newFullWidth?: boolean;
  newEntityObj?: any;
  required?: boolean;
  hasNormalSub?: boolean;
  hasAll?: boolean;
  disabled?: boolean;
  dropdownId?: string;
  insideModalId?: string;
  modalPadding?: number;
  additionalSpace?: number;
  isSingleSelected?: boolean;
  infoTooltip?: string | React.ReactNode;
};

const Dropdown = ({
  className,
  dropdownClassName,
  items,
  value,
  onChange,
  placeholder,
  position,
  label,
  displaySelectedAll,
  placeholderGray,
  error,
  errorOverlay,
  radius = 'md',
  applyText,
  isFileLocation,
  pathValue,
  newEntity,
  newFullWidth,
  newEntityObj,
  required,
  hasNormalSub,
  hasAll,
  disabled,
  dropdownId,
  insideModalId,
  modalPadding = 0,
  additionalSpace = 0,
  isSingleSelected,
  infoTooltip,
}: ODropdownProps) => {
  const dropdownHeightMinus = newEntity ? 139 : 84;
  const dropdownRef = useRef() as any;
  const { createTag, createDepartment, createStatus, createWorflowType } =
    useActions();
  const newEntityAction = {
    tag: createTag,
    department: createDepartment,
    status: createStatus,
    workflow_type: createWorflowType,
  };
  const [newValue, setNewValue] = useState('');
  const [isNew, setIsNew] = useState(false);
  const [newLoading, setNewLoading] = useState(false);
  const [val, setVal] = useState('');
  const [subItem, setSubItem] = useState({} as any);
  const notSubItem = isEmpty(subItem);
  const [opened, setOpened] = useState(false);
  const [selectedVal, setSelectedVal] = useState([]);
  const itemsNotVal = items.filter((item) => item.value !== OPTION_ALL_VALUE);
  const allItemValues = items
    .filter((item) => !item.isLabel)
    .map((item) => item.value);
  const pathValueArr = (pathValue || '').split('/').map((v) => Number(v));
  const lastSubId = pathValueArr[pathValueArr.length - 1];
  const selectedHasNA = selectedVal?.includes(NA_VALUE);
  const alwaysValues = items
    .filter((item) => item.always)
    .map((item) => item.value);
  const resetNewEntityState = () => {
    setNewValue('');
    setIsNew(false);
    setNewLoading(false);
  };
  const handleCreateEntity = (val) => {
    const newEntityActionFn = newEntityAction[newEntity];
    if (newEntityActionFn) {
      setNewLoading(true);
      newEntityActionFn(
        { name: val, ...(newEntityObj?.additionalPayload || {}) },
        actionCb(
          () => {
            setNewLoading(false);
            resetNewEntityState();
          },
          () => {
            setNewLoading(false);
          },
          `Add new ${newEntity} failed!`
        )
      );
    }
  };

  useEffect(() => {
    if (
      !isEqual(value, selectedVal) &&
      isArray(selectedVal) &&
      !isSingleSelected
    ) {
      setSelectedVal(value);
    }
    if (opened) {
      resetNewEntityState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, opened]);
  useEffect(() => {
    if (opened && isFileLocation) {
      const tSub = findLocationParentObj(items, pathValue);
      setSubItem(tSub);
    }
    if (opened) {
      // update height of dropdown when small screen
      setTimeout(() => {
        enhanceHeightOfDropdown({
          dropdownRef,
          insideModalId,
          dropdownId,
          position,
          dropdownHeightMinus,
          modalPadding,
          additionalSpace,
        });
      }, 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opened]);

  return (
    <Popover
      position={position || 'bottom-start'}
      width="target"
      transition="pop"
      opened={opened}
      onChange={setOpened}
      withinPortal
    >
      <Popover.Target ref={dropdownRef}>
        <div
          className={cn(
            classes.wrapper,
            {
              [classes.placeholderGray]: placeholderGray && value.length === 0,
              [classes.isError]: !!error,
              [classes.errorOverlay]: errorOverlay && !!error,
              [classes.isFileLocation]: isFileLocation,
              [classes.disabled]: disabled,
              [classes.isSingleSelected]: isSingleSelected,
            },
            classes[`radius_${radius}`],
            className
          )}
        >
          {!!label && (
            <label
              className={cn(classes.label, {
                [classes.hasInfoTooltip]: !!infoTooltip,
              })}
            >
              {label}
              {required && <span className={classes.requiredMark}>*</span>}
              {!!infoTooltip && (
                <InfoTooltip tooltip={infoTooltip} position="top-end" />
              )}
            </label>
          )}
          <span
            className={classes.input}
            onClick={
              disabled
                ? undefined
                : () => {
                    setOpened(!opened);
                  }
            }
          >
            <span>
              {displayValue(
                value,
                placeholder || 'placeholder',
                items,
                displaySelectedAll,
                isSingleSelected
              )}
            </span>
            <Icon className={classes.arrow}>
              <ArrowSvg />
            </Icon>
          </span>
          {!!error && <div className={classes.errorMsg}>{error}</div>}
        </div>
      </Popover.Target>
      <Popover.Dropdown
        id={dropdownId}
        className={cn(
          classes.dropdown,
          {
            [classes.dropdownIsFileLocation]: isFileLocation,
            [classes.dropdownHasNewEntity]: newEntity,
            [classes.dropdownHasNormalSub]: hasNormalSub,
            [classes.dropdownHasAll]: hasAll,
            [classes.isSingleSelected]: isSingleSelected,
            [classes.newFullWidth]: newFullWidth,
          },
          dropdownClassName
        )}
      >
        {notSubItem ? (
          <div>
            <Input
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
              size="md"
              placeholder="Search"
              isSearch
            />
          </div>
        ) : (
          <div className={classes.parentLocation}>
            <Arrow
              direction="left"
              onClick={() => {
                if (isFileLocation) {
                  const tSub = findLocationParentObj(items, subItem.idPath);
                  setSubItem(tSub);
                }
              }}
            />
            <span>{subItem.label}</span>
          </div>
        )}
        {!!newEntity && (
          <div className={classes.newEntity}>
            {isNew ? (
              <div
                className={cn(classes.newEntityForm, {
                  [classes.newFullWidth]: newFullWidth,
                })}
              >
                <Input
                  radius="md"
                  placeholder={newEntityObj?.placeholder || 'New Tag'}
                  size="xs"
                  value={newValue}
                  onChange={(e) => {
                    setNewValue(e.target.value);
                  }}
                  onKeyPress={(e) => {
                    if (
                      (e.key === 'Enter' || e.which === 13) &&
                      trimText(e.target.value)
                    ) {
                      handleCreateEntity(e.target.value);
                    }
                  }}
                />
                <Button
                  type="button"
                  size="xs"
                  onClick={() => {
                    if (trimText(newValue)) {
                      handleCreateEntity(newValue);
                    }
                  }}
                  loading={newLoading}
                  className={cn({
                    [classes.neBtnIsLoading]: newLoading,
                  })}
                >
                  <PlusSvg />
                </Button>
              </div>
            ) : (
              <Button
                type="button"
                size="xs"
                variant="default"
                leftIcon={<PlusSvg />}
                textWeight="xl"
                onClick={() => {
                  setIsNew(true);
                }}
              >
                {newEntityObj?.button || 'Add New Tag'}
              </Button>
            )}
          </div>
        )}
        <ul>
          {(notSubItem ? items : subItem.subs)
            .filter(
              (item) =>
                item.isLabel ||
                !val ||
                item.value === OPTION_ALL_VALUE ||
                String(item.label || '')
                  .toLowerCase()
                  .includes(val?.toLowerCase())
            )
            .map((item, index) => {
              const { isLabel } = item;
              const itemHasSub = !!item.subs?.length;
              return (
                <li
                  key={isLabel ? index : String(item.value)}
                  className={cn({
                    [classes.isLabel]: isLabel,
                    [classes.normalSub]: !!item.parent,
                    [classes.isActive]:
                      isSingleSelected && value === item.value,
                  })}
                >
                  {isLabel ? (
                    <span className={classes.optionLabel}>
                      <span>{item.label}</span>
                      <span />
                    </span>
                  ) : (
                    <>
                      {isFileLocation ? (
                        <span
                          className={cn(classes.locationItem, {
                            [classes.isActive]:
                              pathValueArr.includes(item.value) ||
                              lastSubId === item.value,
                          })}
                        >
                          <span
                            onClick={() => {
                              if (onChange)
                                onChange(item.value, {
                                  idPath: item.idPath,
                                  namePath: item.namePath,
                                });
                              setOpened(false);
                            }}
                          >
                            {item.label}
                          </span>
                          {itemHasSub && (
                            <Arrow
                              direction="right"
                              onClick={() => {
                                setSubItem(item);
                              }}
                            />
                          )}
                        </span>
                      ) : (
                        <>
                          {!isSingleSelected ? (
                            <Checkbox
                              label={item.label}
                              disabled={
                                selectedHasNA && item.value !== NA_VALUE
                              }
                              checked={
                                item.always || selectedVal.includes(item.value)
                              }
                              onChange={(e) => {
                                const tChecked = e.target.checked;
                                if (tChecked && item.value === NA_VALUE) {
                                  setSelectedVal([item.value]);
                                } else {
                                  if (item.value === OPTION_ALL_VALUE) {
                                    setSelectedVal(
                                      tChecked
                                        ? allItemValues.filter(
                                            (v) => v !== NA_VALUE
                                          )
                                        : items
                                            .filter((item) => item.always)
                                            .map((item) => item.value)
                                    );
                                  } else {
                                    let newSelectedVal = tChecked
                                      ? [...selectedVal, item.value]
                                      : selectedVal.filter(
                                          (v) =>
                                            !(
                                              v === item.value &&
                                              !alwaysValues.includes(v)
                                            )
                                        );
                                    if (hasNormalSub) {
                                      if (!item.parent) {
                                        const childValues = items
                                          .filter(
                                            (iItem: Item) =>
                                              iItem.parent === item.value
                                          )
                                          .map((iItem) => iItem.value);
                                        if (tChecked) {
                                          newSelectedVal = [
                                            ...newSelectedVal,
                                            ...childValues,
                                          ];
                                        } else {
                                          newSelectedVal =
                                            newSelectedVal.filter(
                                              (v) => !childValues.includes(v)
                                            );
                                        }
                                      }
                                    }
                                    setSelectedVal(
                                      tChecked &&
                                        newSelectedVal.length ===
                                          itemsNotVal.length
                                        ? allItemValues
                                        : newSelectedVal.filter(
                                            (v) => v !== OPTION_ALL_VALUE
                                          )
                                    );
                                  }
                                }
                              }}
                            />
                          ) : (
                            <span
                              className={classes.singleOption}
                              onClick={() => {
                                if (item.value !== value) {
                                  if (onChange) onChange(item.value);
                                }
                                setOpened(false);
                              }}
                            >
                              {item.label}
                            </span>
                          )}
                        </>
                      )}
                    </>
                  )}
                </li>
              );
            })}
        </ul>
        {!isFileLocation && !isSingleSelected && (
          <div>
            <Button
              type="button"
              fullWidth
              onClick={() => {
                if (onChange) onChange(selectedVal);
                setOpened(false);
              }}
            >
              {applyText || 'Apply'}
            </Button>
          </div>
        )}
      </Popover.Dropdown>
    </Popover>
  );
};

export default Dropdown;
