export const DEFAULT_SETTING = {
  filter: 'Corporate',
  roles: [],
  properties: [],
  workStatus: [],
  search: '',
  status: '',
  note: '',
  activeStatus: 'Active',
  departments: [],
};
export const MAIN_TABS = {
  COPORATE: {
    KEY: 'coporate',
    LABEL: 'Corporate Management',
  },
  PROPERTY: {
    KEY: 'property',
    LABEL: 'Property Management',
  },
};
export const SUB_TABS = {
  BOARD: {
    KEY: 'board',
    LABEL: 'In & Out Board',
  },
  STATUS: {
    KEY: 'status',
    LABEL: 'My Status',
  },
};
