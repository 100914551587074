import cn from 'classnames';
import React from 'react';
import classes from './Icon.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  onClick?: (e?: any) => void;
  disabled?: boolean;
}

const Icon = ({ className, children, onClick, disabled }: Props) => {
  return (
    <div
      className={cn(
        'i-icon',
        classes.wrapper,
        {
          pointer: !!onClick,
          disabled,
          'not-allowed': disabled,
        },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Icon;
