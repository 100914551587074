import cn from 'classnames';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import DocumentModal from '../DocumentModal';

import classes from './AddFolderModal.module.scss';

const AddFolderModal = ({
  className = '',
  isOpened,
  onClose,
  onSubmit,
  loading = false,
}) => {
  const schema = Yup.object().shape({});

  const renderForm = () => {
    return (
      <Form>
        <FormikInput
          name="folder"
          type="text"
          label={''}
          placeholder={'Untitled Folder'}
          radius="md"
          data-autofocus
          maxLength={255}
        />
        <Button
          radius="md"
          textSize="xl"
          fullWidth
          type="submit"
          loading={loading}
        >
          Create
        </Button>
      </Form>
    );
  };
  return (
    <DocumentModal
      isOpened={isOpened}
      onClose={onClose}
      title="New Folder"
      size={280}
      className={cn(classes.modalWrapper, className)}
    >
      <Formik
        initialValues={{
          folder: '',
        }}
        validateOnMount
        validationSchema={schema}
        enableReinitialize
        onSubmit={(values) => {
          if (values.folder.trim()) {
            onSubmit(values.folder);
          }
        }}
      >
        {renderForm}
      </Formik>
    </DocumentModal>
  );
};

export default AddFolderModal;
