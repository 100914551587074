import findIndex from 'lodash/findIndex';
import { Button, FormCardContent } from 'src/components';
import { FormikInput, FormikSelect } from 'src/components/FormFields';
import {
  NOON_OPTIONS,
  REPORT_STATUS_OPTIONS,
  REPORT_TYPE_OPTIONS,
} from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import NewReportSection from '../Section';
import classes from './Information.module.scss';

const Information = ({
  isDisabled = false,
  submitLoading,
  values,
  submitDisabled = false,
  reportDetail = null,
  onValuesChange,
  permission,
}) => {
  const { propertyOptions } = useOptions();
  if (
    values.property_id &&
    findIndex(propertyOptions, { value: values.property_id }) < 0 &&
    reportDetail
  ) {
    propertyOptions.push({
      value: values.property_id,
      label: reportDetail.property?.name,
    });
  }
  const statusOptions = REPORT_STATUS_OPTIONS.map((option) => ({
    ...option,
    disabled:
      permission.availableStatuses.length > 0 &&
      !permission.availableStatuses.includes(option.value),
  }));

  return (
    <>
      <div className={classes.statusDropdown}>
        {!isDisabled ? (
          <Button
            type="submit"
            radius="md"
            disabled={submitDisabled}
            loading={submitLoading}
            textWeight="xl"
          >
            Save Incident Report
          </Button>
        ) : (
          <div />
        )}
        <FormikSelect
          data={statusOptions}
          name="status"
          label="Report Status"
          placeholder="Select"
          maxDropdownHeight={300}
          errorOverlay
          disabledOverlay={isDisabled}
          required
          customOnChange={(val) => {
            onValuesChange(values.status, 'status', val);
          }}
        />
      </div>
      <NewReportSection
        title="Information"
        onNoValue={() => {
          return (
            !values.property_id &&
            !values.property_id &&
            !values.manager_on_duty &&
            !values.incident_date &&
            !values.incident_time &&
            !values.noon
          );
        }}
      >
        <FormCardContent column={3}>
          <FormikSelect
            data={propertyOptions}
            name="property_id"
            label="Property"
            required={true}
            placeholder="Select"
            maxDropdownHeight={300}
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikSelect
            data={REPORT_TYPE_OPTIONS}
            name="type"
            label="Incident Type"
            required={true}
            placeholder="Select"
            maxDropdownHeight={300}
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name="manager_on_duty"
            label="Manager On Duty"
            placeholder="Manager On Duty"
            type="text"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name="incident_date"
            label="Date of Incident"
            inputType="mask"
            mask="99/99/9999"
            placeholder="00/00/0000"
            autoComplete="date"
            required
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name="incident_time"
            label="Time of Incident"
            inputType="mask"
            mask="99:99"
            autoComplete="time"
            errorOverlay
            placeholder="00:00"
            disabledOverlay={isDisabled}
            printValue={`${values.incident_time} ${(
              values.noon || ''
            ).toUpperCase()}`}
            onChangeCb={onValuesChange}
          />
          <FormikSelect
            data={NOON_OPTIONS}
            name="noon"
            label=" "
            placeholder="Select"
            maxDropdownHeight={300}
            errorOverlay
            disabledOverlay={isDisabled}
            className={classes.noonDropdown}
            onChangeCb={onValuesChange}
          />
          {/* <FormikSwitch name={'is_active'} label="Active" labelPosition="left" /> */}
        </FormCardContent>
      </NewReportSection>
    </>
  );
};

export default Information;
