import { createTypes } from './createTypes';

export default createTypes(
  'GET_FOLDERS',
  'GET_DOCUMENTS',
  'GET_FOLDER_DOCUMENTS',
  'GET_RECENTLY_DELETED_DOCUMENTS',
  'GET_DOCUMENT_DETAIL',
  'GENRATE_DOCUMENT_URL',
  'CREATE_FOLDER',
  'UPLOAD_FILES',
  'UPDATE_DOCUMENT',
  'DELETE_DOCUMENT',
  'CHECK_DUPLICATE_FILES',
  'MOVE_DOCUMENT',
  'DUPLICATE_FOLDER'
);
