import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useState } from 'react';
import { useCollapse } from 'src/helpers/hooks';
import { confirmModalFn, contextModalFn } from 'src/utils/modal';
import DocumentDrawer from '../DocumentDrawer';
import FileContent from '../FileContent';
import FolderBlock from '../FolderBlock';
import classes from './Sidebar.module.scss';

const DRAWER_ID = 'sidebar-drawer-folder-detail';

const Sidebar = ({
  className = '',
  folders,
  onDelete,
  onDocumentMoved = null,
  onDetail = null,
  editLoading = false,
  onEdit = null,
  onDuplicate = null,
}) => {
  const { collapse } = useCollapse();
  const [editFileObj, setEditFileObj] = useState({} as any);
  const closeUserDrawer = () => {
    setEditFileObj({});
  };
  const handleDetail = (tFile) => {
    if (onDetail)
      onDetail(tFile, (res) => {
        setEditFileObj(res);
      });
  };
  const handleDuplicate = (tFolder) => {
    confirmModalFn({
      children: (
        <>
          Are you sure you want to duplicate {tFolder.name}?
          <br />
          <br />
          Duplicating a folder will create a copy of the selected folder, and
          any sub-folders within it. This will not duplicate files.
          <br />
          <br />
          The new folders will prepend “Copy of” to the folder name.
        </>
      ),
      onCancel: () => {},
      onConfirm: () => {
        if (onDuplicate) onDuplicate(tFolder);
      },
      labels: {
        confirm: 'Duplicate',
        cancel: 'Cancel',
      },
      centered: true,
    });
  };
  const handleDelete = (folder) => {
    const folderHasFiles = false;
    if (folderHasFiles) {
      contextModalFn({
        modal: 'contextModal',
        title: 'Confirmation',
        innerProps: {
          modalBody: 'Please delete all files first',
        },
      });
    } else {
      confirmModalFn({
        children: (
          <>
            Are you sure you want to delete the folder "{folder.name}"? Folders
            must be empty to delete.
          </>
        ),
        onCancel: () => {},
        onConfirm: () => {
          onDelete(folder);
        },
        labels: {
          confirm: 'Confirm',
          cancel: 'Cancel',
        },
        color: 'red',
        centered: true,
      });
    }
  };
  return (
    <div className={cn(classes.wrapper, className)}>
      <h3>Documents</h3>
      <div className={classes.folders}>
        {folders.map((folder) => {
          const { id, name, isMarginTop, icon, isActive } = folder;
          return (
            <FolderBlock
              key={id}
              folderId={id}
              title={name}
              isMarginTop={isMarginTop}
              icon={icon}
              onDelete={() => {
                handleDelete(folder);
              }}
              path={`/documents/${
                typeof id === 'number' ? `folders/${id}` : id
              }`}
              isActive={isActive}
              onDocumentMoved={onDocumentMoved}
              onDetail={() => {
                handleDetail(folder);
              }}
              data={folder}
              onDuplicate={() => {
                handleDuplicate(folder);
              }}
            />
          );
        })}
      </div>
      <DocumentDrawer
        opened={!isEmpty(editFileObj)}
        onClose={() => {
          closeUserDrawer();
        }}
        position="left"
        className={cn(classes.rootFolderDetailsDrawer, {
          [classes.isCollapse]: collapse,
        })}
        id={DRAWER_ID}
      >
        <FileContent
          data={editFileObj}
          onBack={() => {
            closeUserDrawer();
          }}
          onEditFile={(payload, id, cb) => {
            if (onEdit) {
              onEdit(
                omit(
                  {
                    ...payload,
                    name: payload.folderName,
                  },
                  ['roles', 'users', 'folderName']
                ),
                id,
                (res) => {
                  setEditFileObj(res);
                  if (cb) cb();
                }
              );
            }
          }}
          saveLoading={editLoading}
          parentModalId={DRAWER_ID}
        />
      </DocumentDrawer>
    </div>
  );
};

export default Sidebar;
