import { Request } from './request';

export const apiGetNotices = async (data = {}) => {
  return Request.call({
    url: '/notice/list',
    method: 'POST',
    data,
  });
};
export const apiAddNotice = async (data = {}) => {
  return Request.call({
    url: '/notice',
    method: 'POST',
    data,
    multipart: true,
  });
};
export const apiUpdateNoticeStatus = async (data = {}) => {
  return Request.call({
    url: '/notice/status',
    method: 'POST',
    data,
  });
};
