import { Avatar, CardList, ContentCard, ProfileColumn } from 'src/components';
import { MenuItem } from 'src/types/Item';
import type { UserType } from 'src/types/user';
import { getStatusUpdateDate } from 'src/utils/date';
import { getFormattedPhone } from 'src/utils/phone';
import { displayName, intialName } from 'src/utils/text';
import InfoIcons from '../InfoIcons';
import classes from './UserList.module.scss';

export type CardHeader = {
  key: string;
  label: string;
  minWidth?: string;
};
interface Props {
  widths: string[];
  minWidths?: string[];
  users: UserType[];
  headers?: CardHeader[];
  actions?: MenuItem[];
  hideHeader?: boolean;
}

const UsersList = ({
  widths,
  users,
  headers,
  minWidths = [],
  actions,
  hideHeader,
}: Props) => {
  return (
    <CardList
      widths={widths}
      headers={headers}
      data={users}
      renderContentCard={(user: UserType) => {
        return (
          <ContentCard
            items={[
              {
                label: 'Notes',
                value: user.note,
              },
              {
                label: 'Last Updated',
                value: getStatusUpdateDate(user.status_updated_at),
              },
            ]}
            widths={[widths[1], widths[2]]}
            actions={actions}
            user={user}
          >
            <ProfileColumn style={{ width: widths[0], minWidth: minWidths[0] }}>
              <Avatar
                size="md"
                circle
                isStatus
                isInactive={!user.is_active}
                avtSrc={user.profile_image_path}
              >
                {intialName(user.first_name, user.last_name)}
              </Avatar>
              <div>
                <span>
                  {displayName(user.first_name, user.last_name)}
                  <InfoIcons
                    email={user.email}
                    schedules={user.work_schedule}
                    phoneContent={
                      (!!user.cell_phone || !!user.office_phone) && (
                        <div className={classes.phoneWrapper}>
                          {!!user.cell_phone && (
                            <div>
                              <span>Mobile:</span>
                              <b>{getFormattedPhone(user.cell_phone)}</b>
                            </div>
                          )}
                          {!!user.office_phone && (
                            <div>
                              <span>Office:</span>
                              <b>{getFormattedPhone(user.office_phone)}</b>
                            </div>
                          )}
                        </div>
                      )
                    }
                  />
                </span>
                <span>{user.status}</span>
              </div>
            </ProfileColumn>
          </ContentCard>
        );
      }}
      hideHeader={hideHeader}
    />
  );
};

export default UsersList;
