import { FIELD_PARSE_KEY } from 'src/helpers/constants';
import { NA_VALUE, OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import { cleanPayloadValue } from 'src/utils/form';

export const getInitialValues = () => {
  return {
    property_id: '',
    certificate_schema_id: '',
    effective_date: '',
    response_date: '',
    property_unit_id: '',
  };
};
export const getCertificationBR = (values) => {
  const returnObj = {} as any;
  Object.keys(values).map((key) => {
    const parseKey = ['effective_date', 'response_date'].includes(key)
      ? FIELD_PARSE_KEY.DATE
      : '';
    returnObj[key] = cleanPayloadValue(values[key], parseKey);
    if (key === 'property_unit_id' && returnObj?.[key]?.length > 0) {
      returnObj[key] = returnObj[key].filter(
        (v) => v !== OPTION_ALL_VALUE && v !== NA_VALUE
      );
    }
    return true;
  });
  return returnObj;
};
