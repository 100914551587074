import cn from 'classnames';
import React from 'react';
import { ReactComponent as TopLogoSvg } from 'src/assets/images/TopLogo.svg';
import classes from './Logo.module.scss';

interface Props {
  className?: string;
}

const Logo = ({ className }: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <TopLogoSvg />
    </div>
  );
};

export default Logo;
