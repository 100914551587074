export const ENCRYPTE_KEY_OBJ = {
  SSN: 'ssn',
  HIRE_DATE: 'hire_date',
  TERMINATION_DATE: 'termination_date',
  SUPERVISOR_ID: 'supervisor_id',
  HRIS_ID: 'hris_id',
};
export const ENCRYPT_KEYS = [
  ENCRYPTE_KEY_OBJ.SSN,
  ENCRYPTE_KEY_OBJ.HIRE_DATE,
  ENCRYPTE_KEY_OBJ.TERMINATION_DATE,
  ENCRYPTE_KEY_OBJ.SUPERVISOR_ID,
  ENCRYPTE_KEY_OBJ.HRIS_ID,
];
