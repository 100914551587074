import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useState } from 'react';
import { TDocument } from 'src/types/document';
import { confirmModalFn } from 'src/utils/modal';
import DocumentDrawer from '../DocumentDrawer';
import FileBlock from '../FileBlock';
import FileContent from '../FileContent';
import classes from './FilesList.module.scss';

interface Props {
  className?: string;
  recentText?: string;
  data: TDocument[];
  isExpandAll?: boolean;
  onDeleteFile?: (f) => void;
  onDuplicate?: (f) => void;
  onDetail?: (f, cb?: any) => void;
  disabled?: boolean;
  onEditFile?: (payload, id, cb?: any) => void;
  editLoading?: boolean;
  folderId?: number;
  onDocumentMoved?: (sourceId: number, targetId: number) => void;
}

const DRAWER_ID = 'file-list-drawer-detail';

const FilesList = ({
  className = '',
  recentText = '',
  data,
  isExpandAll,
  onDeleteFile,
  disabled,
  onDetail,
  onEditFile,
  editLoading,
  folderId,
  onDocumentMoved,
  onDuplicate,
}: Props) => {
  const [editFileObj, setEditFileObj] = useState({} as any);
  const isDocumentFile = !editFileObj.is_directory;
  const handleDelete = (tFile) => {
    confirmModalFn({
      children: (
        <>
          Are you sure you want to delete the file "{tFile.name}"? File will be
          deleted after 30 days please confirm.
        </>
      ),
      onCancel: () => {},
      onConfirm: () => {
        if (onDeleteFile) onDeleteFile(tFile);
      },
      labels: {
        confirm: 'Confirm',
        cancel: 'Cancel',
      },
      color: 'red',
      centered: true,
    });
  };
  const closeUserDrawer = () => {
    setEditFileObj({});
  };
  const handleDetail = (tFile) => {
    if (onDetail)
      onDetail(tFile, (res) => {
        setEditFileObj(res);
      });
  };
  const handleDuplicate = (tFolder) => {
    confirmModalFn({
      children: (
        <>
          Are you sure you want to duplicate {tFolder.name}?
          <br />
          <br />
          Duplicating a folder will create a copy of the selected folder, and
          any sub-folders within it. This will not duplicate files.
          <br />
          <br />
          The new folders will prepend “Copy of” to the folder name.
        </>
      ),
      onCancel: () => {},
      onConfirm: () => {
        if (onDuplicate) onDuplicate(tFolder);
      },
      labels: {
        confirm: 'Duplicate',
        cancel: 'Cancel',
      },
      centered: true,
    });
  };

  return (
    <>
      <div
        className={cn(classes.wrapper, className)}
        onDrop={(event) => {
          const dragObjStr = event.dataTransfer.getData('dragObj');
          const tDragObj = dragObjStr ? JSON.parse(dragObjStr) : {};
          if (tDragObj.directory_id !== folderId) {
            const sourceDocumentId = tDragObj.id;
            if (onDocumentMoved) onDocumentMoved(sourceDocumentId, folderId);
          }
          event.preventDefault();
          event.stopPropagation();
          return;
        }}
        onDragOver={(ev) => {
          ev.preventDefault();
        }}
      >
        {!!recentText && <h5>{recentText}</h5>}
        <div className={classes.files}>
          {data.map((file) => {
            const { id } = file;
            return (
              <FileBlock
                key={id}
                onDelete={handleDelete}
                onDetail={handleDetail}
                data={file}
                isExpandAll={isExpandAll}
                disabled={disabled}
                onDocumentMoved={onDocumentMoved}
                folderId={folderId}
                onDuplicate={handleDuplicate}
              />
            );
          })}
        </div>
      </div>
      <DocumentDrawer
        opened={!isEmpty(editFileObj)}
        onClose={() => {
          closeUserDrawer();
        }}
        id={DRAWER_ID}
        className={cn({
          [classes.fileDrawer]: isDocumentFile,
        })}
      >
        <FileContent
          data={editFileObj}
          onBack={() => {
            closeUserDrawer();
          }}
          onEditFile={(payload, id, cb) => {
            if (payload.isFolder) {
              onEditFile(
                omit(
                  {
                    ...payload,
                    name: payload.folderName,
                  },
                  ['roles', 'users', 'folderName']
                ),
                id,
                (res) => {
                  setEditFileObj(res);
                  if (cb) cb();
                }
              );
            } else {
              onEditFile(payload, id, (res) => {
                setEditFileObj(res);
                if (cb) cb();
              });
            }
          }}
          saveLoading={editLoading}
          parentModalId={DRAWER_ID}
        />
      </DocumentDrawer>
    </>
  );
};

export default FilesList;
