import { displayName } from 'src/utils/text';

export const makeRoleOption = (role) => {
  return {
    value: `role_${role.name}_${role.id}`,
    label: role.name,
    obj: role,
  };
};
export const makeUserOption = (user) => {
  return {
    value: `user_${user.id}`,
    label: displayName(user.first_name, user.last_name),
    obj: user,
  };
};
