import { Badge } from 'src/components';
import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import {
  renderDateColumn,
  renderNameColumn,
  renderRoleColumn,
  renderStandardColumn,
} from 'src/utils/table';

export const getUserParams = (params) => {
  const returnObj = {
    page: params.page || 1,
    limit: 12,
    role: params.role !== OPTION_ALL_VALUE ? params.role : '',
    status: params.status !== OPTION_ALL_VALUE ? params.status : '',
    keyword: params.search || '',
    organization: params.organization || OPTION_ALL_VALUE,
    include_deactivated: true,
  };
  return returnObj;
};

export const getColumns = ({ onEdit, onDeactivate, onActivate }) => {
  return [
    {
      key: 'roles',
      render: renderRoleColumn,
      title: 'User Role',
    },
    {
      key: 'name',
      render: renderNameColumn,
      title: 'Name',
    },
    {
      key: 'organization',
      render: renderStandardColumn,
      title: 'Organization',
    },
    {
      key: 'email',
      render: renderStandardColumn,
      title: 'Email',
    },
    {
      key: 'deactivated',
      render: (val) => (
        <Badge color={!val ? 'green' : 'red'}>
          {!val ? 'Active' : 'Deactivated'}
        </Badge>
      ),
      title: 'Status',
    },
    {
      key: 'last_login',
      render: renderDateColumn,
      title: 'Last Login',
    },
    {
      key: 'action3',
      getItems: (row) => {
        return !row.deactivated
          ? [
              {
                label: 'Edit',
                onClick: onEdit(row),
              },
              {
                label: 'Deactivate',
                onClick: onDeactivate(row),
                isRed: true,
              },
            ]
          : [
              {
                label: 'Reactivate',
                onClick: onActivate(row),
              },
            ];
      },
      render: () => {},
    },
  ];
};
