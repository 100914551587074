import { Dispatch } from 'redux';
import {
  apiAddUpdateReport,
  apiDeleteReportFiles,
  apiGetReportDetail,
  apiGetReports,
  apiUploadReportFiles,
} from 'src/api';
import Types from '../types/report';

export const syncSetReportDetail = (payload) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_SET_REPORT_DETAIL,
    payload,
  });
};

export const getReports =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_REPORTS,
      asyncCall: () => {
        return apiGetReports(params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getReportDetail = (id, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_REPORT_DETAIL,
    asyncCall: () => {
      return apiGetReportDetail(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const addUpdateReport = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.ADD_UPDATE_REPORT,
    asyncCall: () => {
      return apiAddUpdateReport(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const uploadReportFiles = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPLOAD_REPORT_FILES,
    asyncCall: () => {
      return apiUploadReportFiles(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const deleteReportFiles = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_REPORT_FILES,
    asyncCall: () => {
      return apiDeleteReportFiles(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
