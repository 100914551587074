import { Request } from './request';

export const apiGetUsers = async (params = {}) => {
  return Request.call({
    url: '/users',
    method: 'POST',
    data: params,
  });
};
export const apiGetUser = async (userId: number) => {
  return Request.call({
    url: `/users/${userId}`,
    method: 'GET',
  });
};
export const apiUpdateUserStatus = async (data) => {
  return Request.call({
    url: '/users/status',
    method: 'PUT',
    data,
  });
};
export const apiSwitchUserActive = async (data) => {
  return Request.call({
    url: '/users/switch_active_inactive',
    method: 'PUT',
    data,
  });
};
export const apiInviteUser = async (data) => {
  return Request.call({
    url: '/users/invite',
    method: 'POST',
    data,
  });
};
export const apiVerifyInviteToken = async (token, data) => {
  return Request.call({
    url: `/invites/verify_token/${token}`,
    method: 'POST',
    data,
  });
};
export const apiAcceptInvite = async (data) => {
  return Request.call({
    url: `/invites/accept`,
    method: 'POST',
    data,
  });
};
export const apiUpdateUserActivate = async (userId, key) => {
  return Request.call({
    url: `/users/${userId}/${key}`,
    method: 'PUT',
    data: {},
  });
};
export const apiEditUser = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}`,
    method: 'PUT',
    data,
  });
};
export const apiEditUserStatus = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}/update_status`,
    method: 'PUT',
    data,
  });
};
export const apiUserDismissPopup = async (data) => {
  return Request.call({
    url: `/users/dismiss-popup`,
    method: 'POST',
    data,
  });
};
export const apiSwitchUserDetailActive = async (userId, data) => {
  return Request.call({
    url: `/users/${userId}/switch_active_inactive`,
    method: 'PUT',
    data,
  });
};
