import { CardProps, Card as ManCard } from '@mantine/core';
import cn from 'classnames';
import React from 'react';
import styled from 'styled-components';
import classes from './Card.module.scss';
import CardTitle from './CardTitle';
import { FormCardArrayBlock, FormCardContent } from './FormCard';

type Props = CardProps & {
  className?: string;
  titleClassName?: string;
  children: React.ReactNode;
  title?: string | React.ReactNode;
  contentRight?: string | React.ReactNode;
  titleBorder?: boolean;
  notPaddingContent?: boolean;
  notMarginContent?: boolean;
  notPaddingContentTop?: boolean;
  contentRow?: boolean;
  mainContentHasForm?: boolean;
  paddingBottom?: boolean;
  gapSize?: string;
  required?: boolean;
  contentGap?: number;
  contentPaddingBottom?: number;
  form?: boolean;
  overflowInit?: boolean;
  error?: string;
  isComingSoon?: boolean;
};

const CardSection = ManCard.Section;

const Card = ({
  className,
  children,
  title,
  contentRight,
  titleBorder,
  notPaddingContent,
  notMarginContent,
  notPaddingContentTop,
  gapSize,
  contentRow,
  mainContentHasForm,
  paddingBottom,
  required,
  contentGap = 12,
  contentPaddingBottom = 16,
  form,
  overflowInit,
  error,
  isComingSoon,
  titleClassName,
  ...other
}: Props) => {
  return (
    <ManCard
      withBorder
      radius="md"
      p="xs"
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.titleNotBorder]: !titleBorder,
          [classes.notPaddingContent]: notPaddingContent,
          [classes.notMarginContent]: notMarginContent,
          [classes.notPaddingContentTop]: notPaddingContentTop,
          [classes.contentRow]: contentRow,
          [classes.mainContentHasForm]: mainContentHasForm,
          [classes.paddingBottom]: paddingBottom,
          [classes.form]: form,
          [classes.overflowInit]: overflowInit,
          [classes.error]: !!error,
          [classes.isComingSoon]: isComingSoon,
        },
        classes[`gapSize${gapSize}`],
        className
      )}
    >
      {(!!title || !!contentRight) && (
        <CardSection
          withBorder={titleBorder}
          inheritPadding
          py="xs"
          className={titleClassName}
        >
          <CardTitle
            title={
              <>
                {title}
                {!!error && (
                  <span className={classes.titleErrorText}>{error}</span>
                )}
              </>
            }
            required={required}
            contentRight={contentRight}
          />
        </CardSection>
      )}
      <StyledCardSection
        inheritPadding
        py="xs"
        className={cn(classes.mainContent, 'i-card-main-content')}
        gap={contentGap}
        cpb={contentPaddingBottom}
      >
        {children}
      </StyledCardSection>
    </ManCard>
  );
};

const StyledCardSection = styled(CardSection)`
  gap: ${({ gap }) => `${gap}px`};
  padding-bottom: ${({ cpb }) => `${cpb}px`};
`;

export { CardSection, FormCardContent, FormCardArrayBlock, CardTitle };
export default Card;
