import cn from 'classnames';
import { Popover } from 'src/components';
import classes from './Popover.module.scss';

const FilePopover = ({
  children,
  opened,
  setOpened,
  offsetX = 0,
  dropdownOffset,
  items,
}) => {
  return (
    <Popover
      width={170}
      position="bottom"
      shadow="md"
      onChange={(val) => {
        setOpened(val);
      }}
      content={
        <>
          {items.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => {
                  setOpened(false);
                  if (item.onClick) item.onClick();
                }}
              >
                {item.label}
              </div>
            );
          })}
        </>
      }
      customEvent
      customOpened={opened}
      offset={dropdownOffset}
      customOffset={offsetX}
      dropdownClassName={cn(classes.filePopoverDropdown, {
        hide: items.length <= 0,
      })}
    >
      {children}
    </Popover>
  );
};

export default FilePopover;
