import { CheckboxProps, Checkbox as ManCheckbox } from '@mantine/core';
import cn from 'classnames';
import classes from './Checkbox.module.scss';

export type Props = CheckboxProps & {
  className?: string;
  pointer?: boolean;
  color?: string;
};

const Checkbox = ({
  className,
  pointer,
  radius = 'xs',
  color,
  ...other
}: Props) => {
  return (
    <ManCheckbox
      {...other}
      radius={radius}
      className={cn(
        classes.wrapper,
        {
          [classes.pointer]: pointer,
        },
        classes[`color_${color}`],
        className
      )}
    />
  );
};

export default Checkbox;
