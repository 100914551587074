import { parseStringToNumber } from 'src/utils/number';

export const getProfileBR = (values, profile) => {
  const formData = new FormData();
  if (values.currentPassword && values.newPassword && values.confirmPassword) {
    formData.append('current_password', values.currentPassword);
    formData.append('new_password', values.newPassword);
    formData.append('confirm_password', values.confirmPassword);
  }
  if (values.avatar && typeof values.avatar === 'object') {
    formData.append('profile_image', values.avatar);
  }
  formData.append('first_name', profile.first_name);
  formData.append('last_name', profile.last_name);
  formData.append('title', profile.title);
  formData.append('cell_phone', parseStringToNumber(values.cellPhone) || '');
  formData.append(
    'office_phone',
    parseStringToNumber(values.officePhone) || ''
  );
  formData.append('fax_number', parseStringToNumber(values.fax) || '');
  formData.append('language', values.language || '');
  formData.append('timezone', values.timeZone || '');
  formData.append('timecard', values.timeCard || false);
  formData.append('in_out_limits', values.limit || false);
  return formData;
};
