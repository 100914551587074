import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Badge,
  Box,
  Button,
  ResponsiveCard,
  Table,
  TableResult,
} from 'src/components';
import { Dropdown } from 'src/components/FormFields';
import {
  FilterBar,
  MainContent,
  PageContent,
  PageContentHeader,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import {
  DEFAULT_ALL_OPTION,
  REPORT_STATUS_COLOR,
  REPORT_STATUS_OPTIONS,
  REPORT_TYPE_OPTIONS,
} from 'src/helpers/constants/options';
import { useMedia, useOptions } from 'src/helpers/hooks';
import { getCardValue } from 'src/utils/card';
import { getMomentDateFormat } from 'src/utils/date';
import { cleanFilter } from 'src/utils/filter';
import classes from './IncidentReporting.module.scss';
import { useActions, useIndexData } from './selectorData';
import { getColumns } from './utils';

const IncidentReporting = () => {
  const isTablet = useMedia('(max-width: 768px)');
  const { propertyOptions } = useOptions();
  const navigate = useNavigate();
  const { getReports } = useActions();
  const { reports } = useIndexData();
  const [setting, setSetting] = useState({
    property_id: [],
    type: [],
    status: [],
  } as Record<string, string[]>);
  const reloadReports = (tSetting) => {
    getReports(cleanFilter(tSetting));
  };
  const handleSettingChange = (key, value, willNotFetch = false) => {
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (!willNotFetch) {
      reloadReports(newSetting);
    }
  };
  const goToDetail = (tId) => {
    navigate(`/incident-reporting/${tId}`);
  };

  useEffect(() => {
    reloadReports(setting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Workflows',
            link: '/workflows',
          },
          {
            label: 'Incident Reports',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader
          title="Incident Reports"
          className={classes.pageHeader}
        ></PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          <PageContentHeader tableResult={reports.length}>
            <FilterBar className={classes.filterBar}>
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...propertyOptions]}
                value={setting.property_id}
                onChange={(val) => {
                  handleSettingChange('property_id', val);
                }}
                placeholder={'All Properties'}
                className={classes.propertyDropdown}
              />
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...REPORT_TYPE_OPTIONS]}
                value={setting.type}
                onChange={(val) => {
                  handleSettingChange('type', val);
                }}
                placeholder={'Type'}
                className={classes.typeDropdown}
              />
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...REPORT_STATUS_OPTIONS]}
                value={setting.status}
                onChange={(val) => {
                  handleSettingChange('status', val);
                }}
                placeholder={'Status'}
                className={classes.statusDropdown}
              />
            </FilterBar>
            <Button
              onClick={() => {
                navigate('/new-report');
              }}
              radius="md"
              textSize="xl"
            >
              Add New Report
            </Button>
          </PageContentHeader>
          <TableResult count={reports.length} className={classes.tableResult} />
          {isTablet ? (
            <>
              {reports.map((report) => {
                return (
                  <ResponsiveCard
                    key={report.id}
                    items={[
                      {
                        label: 'Property',
                        value: getCardValue(report.property?.name),
                      },
                      {
                        label: 'Type',
                        value: getCardValue(report.type),
                      },
                      {
                        label: 'Manager On Duty',
                        value: getCardValue(report.manager_on_duty),
                      },
                    ]}
                    boxItems={[
                      {
                        label: 'Status',
                        value: 'Date of Incident',
                      },
                      {
                        label: (
                          <Badge color={REPORT_STATUS_COLOR[report.status]}>
                            {report.status}
                          </Badge>
                        ),
                        value: getCardValue(
                          getMomentDateFormat(
                            report.incident_date,
                            'MM/DD/YYYY'
                          )
                        ),
                      },
                    ]}
                    onView={() => {
                      goToDetail(report?.id);
                    }}
                  />
                );
              })}
            </>
          ) : (
            <Box vertical>
              <Table
                columns={getColumns()}
                align="left"
                data={reports}
                onView={(record) => {
                  goToDetail(record?.id);
                }}
                selection={{}}
                pagination={{}}
              />
            </Box>
          )}
        </PageContent>
      </MainContent>
    </>
  );
};

export default IncidentReporting;
