import { Reducer } from 'redux';
import { getUserGroupByDeparment } from 'src/utils/store';
import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/inout';

const initialState = {
  pmUsers: {
    ...defaultObj,
  },
  omUsers: {
    ...defaultObj,
  },
  updateUserStatus: {
    ...defaultObj,
  },
  switchUserActive: {
    ...defaultObj,
  },
  switchUserDetailActive: {
    ...defaultObj,
  },
};

const InOutReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.SYNC_UPDATE_INOUT_USER:
      return {
        ...state,
        [payload.userKey]: {
          ...state[payload.userKey],
          data: {
            ...state[payload.userKey].data,
            [payload.departmentKey]: state[payload.userKey].data[
              payload.departmentKey
            ].map((user) => {
              if (user.id === payload.id) {
                return {
                  ...user,
                  ...payload.data,
                };
              }
              return user;
            }),
          },
        },
      };
    case Types.GET_PM_USERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          pmUsers: {
            ...prevState.pmUsers,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          pmUsers: {
            loading: false,
            data: meta?.payload?.groupByDepartment
              ? {
                  data: getUserGroupByDeparment(payload),
                }
              : payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          pmUsers: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_OM_USERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          omUsers: {
            ...prevState.omUsers,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          omUsers: {
            loading: false,
            data: meta?.payload?.groupByDepartment
              ? {
                  data: getUserGroupByDeparment(payload),
                }
              : payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          omUsers: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_USER_STATUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateUserStatus: {
            ...prevState.updateUserStatus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateUserStatus: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateUserStatus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.SWITCH_USER_ACTIVE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          switchUserActive: {
            ...prevState.switchUserActive,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          switchUserActive: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          switchUserActive: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.SWITCH_USER_DETAIL_ACTIVE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          switchUserDetailActive: {
            ...prevState.switchUserDetailActive,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          switchUserDetailActive: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          switchUserDetailActive: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default InOutReducer;
