import React from 'react';
import { FormikInput } from 'src/components/FormFields';
import { getIsEmptyEntities } from 'src/utils/object';
import SectionFormArray from '../SectionFormArray';

interface Props {
  values: any;
  isDisabled?: boolean;
  onValuesChange: () => void;
}

const UninvolvedWitnesses = ({ values, isDisabled, onValuesChange }: Props) => {
  return (
    <SectionFormArray
      onAddCb={onValuesChange}
      onRemoveCb={onValuesChange}
      values={values}
      addHidden={isDisabled}
      title="Uninvolved Witnesses"
      parentName="uninvolved_witness"
      onNoValue={() => {
        return getIsEmptyEntities(values.uninvolved_witness);
      }}
      renderChild={(index) => {
        return (
          <>
            <FormikInput
              name={`uninvolved_witness.${index}.name`}
              label="Name"
              placeholder="Name"
              type="text"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormikInput
              name={`uninvolved_witness.${index}.address`}
              label="Address"
              placeholder="Address"
              type="text"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormikInput
              name={`uninvolved_witness.${index}.phone_number`}
              label="Phone Number"
              placeholder="000-000-0000"
              inputType="mask"
              mask="999-999-9999"
              autoComplete="time"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
          </>
        );
      }}
      newObj={{
        name: '',
        address: '',
        phone_number: '',
      }}
      newBtnText="+ Add an Uninvolved Witnesses"
      gridColumn={3}
    />
  );
};

export default UninvolvedWitnesses;
