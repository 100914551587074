import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { getDPDate, getTPTime } from 'src/utils/date';
import { clearnUpValues, getCleanedValue } from 'src/utils/form';
import { renderDateColumn } from 'src/utils/table';
import {
  ADDITIONAL_INFORMATION_NAME_MAPPING,
  INSURANCE_NAME_MAPPING,
  WEATHER_CONDITIONS_NAME_MAPPING,
} from './constants';

export const getInitialValues = (data) => {
  const isDetail = !isEmpty(data);
  const returnObj = {
    property_id: data?.property_id || data?.property?.id || '',
    type: data?.type || '',
    manager_on_duty: data?.manager_on_duty || '',
    incident_date: getDPDate(data?.incident_date),
    incident_time: getTPTime(data?.incident_time?.split(' ')?.[0]),
    noon: data?.incident_time?.split(' ')?.[1]?.toLowerCase() || 'am',
    status: data?.status || '',
    summary: data?.summary || '',
    description: data?.description || '',
    cause: data?.cause || '',
    assitance: data?.assitance || '',
    step_taken: data?.step_taken || '',
    filled_name: data?.filled_name || '',
    additional_information: data?.additional_information || '',
    filled_phone_numbner: data?.filled_phone_numbner || '',
    filled_date: getDPDate(data?.filled_date),
    filled_title: data?.filled_title || '',
    notified_area_manager: data?.notified_area_manager || '',
    notified_date: getDPDate(data?.notified_date),
    notified_time: getTPTime(data?.notified_time?.split(' ')?.[0]),
    notified_time_noon:
      data?.notified_time?.split(' ')?.[1]?.toLowerCase() || 'am',
    date_treated: getDPDate(data?.date_treated),
    involved_party: data?.involved_party?.length
      ? data.involved_party
      : [{ name: '', address: '', phone_number: '', role: '' }],
    authority: data?.authority?.length
      ? data.authority
      : [
          {
            name: '',
            phone_number: '',
            badge_number: '',
            police_report_number: '',
          },
        ],
    uninvolved_witness: data?.uninvolved_witness?.length
      ? data.uninvolved_witness
      : [{ name: '', address: '', phone_number: '' }],
    physician: data?.physician?.length
      ? data.physician
      : [{ name: '', address: '', phone_number: '' }],
    hospital: data?.hospital?.length
      ? data.hospital
      : [{ name: '', address: '', phone_number: '' }],
    // TODO: new fields
    weather_condition: '',
    weather_temperature: '',
    weather_additional_notes: '',
    photos_required: !!data?.photos_confirmed,
    witness_required: !!data?.witness_statements_confirmed,
    related_documents_required: !!data?.other_additional_documents_confirmed,
    photos_additional_information: data?.photos_additional_info || '',
    photos_additional_upload: [],
    photos_additional_upload_ids: [],
    photos_additional_upload_na: !!data?.photos_na,
    witness_additional_information:
      data?.witness_statements_additional_info || '',
    witness_additional_upload: [],
    witness_additional_upload_ids: [],
    witness_additional_upload_na: !!data?.witness_statements_na,
    related_documents_additional_information:
      data?.other_additional_documents_additional_info || '',
    related_documents_additional_upload: [],
    related_documents_additional_upload_ids: [],
    related_documents_additional_upload_na:
      !!data?.other_additional_documents_na,
  } as any;
  if (isDetail) {
    returnObj.insurance_name = data?.insurance?.agent_name || '';
    returnObj.insurance_phone = data?.insurance?.agent_phone_number || '';
    returnObj.insurance_email = data?.insurance?.agent_email || '';
    returnObj.insurance_number = data?.insurance?.claim_number || '';
    returnObj.weather_condition = data?.weather_conditions?.conditions || '';
    returnObj.weather_temperature = data?.weather_conditions?.temperature || '';
    returnObj.weather_additional_notes =
      data?.weather_conditions?.additional_notes || '';
    returnObj.photos_additional_upload = (data?.photos || []).map((f) => ({
      ...f,
      name: f.file_name,
      url: f.file_path,
    }));
    returnObj.photos_additional_upload_ids = (data?.photos || []).map(
      (f) => f.id
    );
    returnObj.witness_additional_upload = (data?.witness_statements || []).map(
      (f) => ({
        ...f,
        name: f.file_name,
        url: f.file_path,
      })
    );
    returnObj.witness_additional_upload_ids = (
      data?.witness_statements || []
    ).map((f) => f.id);
    returnObj.related_documents_additional_upload = (
      data?.other_additional_documents || []
    ).map((f) => ({
      ...f,
      name: f.file_name,
      url: f.file_path,
    }));
    returnObj.related_documents_additional_upload_ids = (
      data?.other_additional_documents || []
    ).map((f) => f.id);
  }
  return returnObj;
};

export const getReportBR = (values, id?: number | string) => {
  const tValues = omit(values, ['additional_upload']);
  const returnObj = clearnUpValues(tValues) as any;
  returnObj.insurance = {};
  returnObj.weather_conditions = {};
  Object.keys(tValues).map((key) => {
    const tValue = tValues[key];
    if (key === 'property_id') {
      returnObj[key] = parseInt(tValue, 10);
    } else if (key === 'incident_time' || key === 'notified_time') {
      if (!!values.incident_time && !!values.noon && key === 'incident_time') {
        returnObj[key] = `${values.incident_time} ${values.noon.toUpperCase()}`;
      } else if (
        !!values.notified_time &&
        !!values.notified_time_noon &&
        key === 'notified_time'
      ) {
        returnObj[key] = `${
          values.notified_time
        } ${values.notified_time_noon.toUpperCase()}`;
      }
    } else if (key.includes('insurance_')) {
      returnObj.insurance[INSURANCE_NAME_MAPPING[key]] = getCleanedValue(
        key,
        tValue
      );
    } else if (key.includes('weather_')) {
      returnObj.weather_conditions[WEATHER_CONDITIONS_NAME_MAPPING[key]] =
        getCleanedValue(key, tValue);
      delete returnObj[key];
    } else if (
      key.includes('photos_additional_') ||
      key.includes('related_documents_additional_') ||
      key.includes('witness_additional_') ||
      [
        'photos_required',
        'witness_required',
        'related_documents_required',
      ].includes(key)
    ) {
      if (ADDITIONAL_INFORMATION_NAME_MAPPING[key]) {
        returnObj[ADDITIONAL_INFORMATION_NAME_MAPPING[key]] = getCleanedValue(
          key,
          tValue
        );
      }
      delete returnObj[key];
    } else if (!!tValue && isArray(tValue)) {
      returnObj[key] = tValue.map((fieldValue) => clearnUpValues(fieldValue));
    }
    return true;
  });
  if (id) {
    returnObj.id = id;
  }
  delete returnObj.noon;
  delete returnObj.notified_time_noon;
  return returnObj;
};
export const getReportFilesBR = (tFiles, type) => {
  const fData = new FormData();
  tFiles.map((file) => {
    fData.append('files[]', file);
  });
  fData.append('type', type);
  return fData;
};
export const getDetailTitle = (detail) => {
  return `${detail.property?.name || ''}'s ${
    detail.type
  } Incident Report - ${renderDateColumn(detail.incident_date)}`;
};
export const getDeletedFileIds = (currentFiles, valueFiles) => {
  const cFileIds = (currentFiles || []).filter((f) => f.id).map((f) => f.id);
  const vFileIds = (valueFiles || []).filter((f) => f.id).map((f) => f.id);
  return cFileIds.filter((id) => !vFileIds.includes(id));
};
export const getPhotoDocumentRequired = (values) => {
  return {
    photos:
      values.photos_required &&
      !values.photos_additional_upload?.length &&
      !values.photos_additional_upload_na,
    witness:
      values.witness_required &&
      !values.witness_additional_upload?.length &&
      !values.witness_additional_upload_na,
    related_documents:
      values.related_documents_required &&
      !values.related_documents_additional_upload?.length &&
      !values.related_documents_additional_upload_na,
  };
};
