import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import {
  DEFAULT_MODAL_PADDING,
  INPUT_HEIGHT,
  MAX_DROPDOWN_HEIGHT,
} from './constants';

const getLabel = (value, items) => {
  return get(find(items, { value }), 'label') || '';
};

export const displayValue = (
  value,
  placeholder,
  items,
  displaySelectedAll,
  isSingleSelected
) => {
  if (!value) return placeholder;
  if (isSingleSelected) {
    return find(items, { value })?.label || '';
  }
  if (typeof value === 'string') return value;
  if (typeof value === 'object') {
    const tValue = value.filter((v) => v !== OPTION_ALL_VALUE);
    return tValue.length === 0 ||
      (value.length === items.length && !displaySelectedAll)
      ? placeholder
      : tValue.length === 1
      ? getLabel(tValue[0], items)
      : `${tValue.length} (Selected)`;
  }
};
const findObjFromArr = (arr, ids) => {
  let foundObj = {};
  let tempArr = [...arr];
  const lastId = ids[ids.length - 1];
  ids.map((id) => {
    if (id === lastId) {
      foundObj = { ...find(tempArr, { value: id }) };
    } else {
      if (findIndex(tempArr, { value: id }) >= 0) {
        tempArr = [...find(tempArr, { value: id }).subs];
      }
    }
  });
  return foundObj;
};
export const findLocationParentObj = (items, idPaths) => {
  const currentNamePathArr = idPaths?.split('/').map((v) => parseInt(v, 10));
  if (currentNamePathArr?.length > 1) {
    currentNamePathArr.pop();
    return findObjFromArr(items, currentNamePathArr);
  }
  return {};
};
export const enhanceHeightOfDropdown = ({
  dropdownRef,
  insideModalId,
  dropdownId,
  position,
  dropdownHeightMinus,
  modalPadding,
  additionalSpace,
}) => {
  if (dropdownRef && dropdownRef.current && !!insideModalId) {
    const elOffsetTop = dropdownRef.current.offsetTop;
    let modalOffsetTop = 0;
    const modalEl = insideModalId ? document.getElementById(insideModalId) : '';
    const isModalIsDrawer =
      !!modalEl && modalEl.classList?.contains('mantine-Drawer-root');
    if (modalEl) {
      if (modalEl.classList?.contains('mantine-Drawer-root')) {
        const modalContentEl = modalEl.querySelectorAll(
          '.mantine-Drawer-drawer'
        )?.[0];
        modalOffsetTop = get(modalContentEl, 'offsetTop') || 0;
      } else {
        const modalContentEl = modalEl.querySelectorAll('.mantine-Modal-modal');
        modalOffsetTop = get(modalContentEl, '[0].offsetTop') || 0;
      }
    }
    const tElOffsetTop = elOffsetTop + modalOffsetTop;
    if (tElOffsetTop < MAX_DROPDOWN_HEIGHT && dropdownId) {
      const dropdownEl = document.getElementById(dropdownId);
      const ulEL = dropdownEl.querySelectorAll('ul')?.[0];
      if (ulEL) {
        if (!position) {
          const previousMaxHeight = MAX_DROPDOWN_HEIGHT - dropdownHeightMinus;
          const newMaxHeight =
            elOffsetTop -
            (isModalIsDrawer ? 0 : 40) -
            (DEFAULT_MODAL_PADDING - modalPadding - modalOffsetTop) -
            additionalSpace;
          const minusMaxHeight = newMaxHeight - previousMaxHeight;
          ulEL.style.maxHeight = `${newMaxHeight}px`;
          const previousTop = Number(
            (dropdownEl.style.top || '').replace('px', '')
          );
          if (!isModalIsDrawer) {
            dropdownEl.style.top = `${previousTop + minusMaxHeight}px`;
          }
        } else if (position === 'bottom') {
          const windowHeight = window.innerHeight;
          const newMaxHeight =
            windowHeight -
            tElOffsetTop -
            INPUT_HEIGHT -
            dropdownHeightMinus -
            additionalSpace;
          if (newMaxHeight < MAX_DROPDOWN_HEIGHT - dropdownHeightMinus) {
            ulEL.style.maxHeight = `${newMaxHeight}px`;
          }
        }
      }
    }
  }
};
