import cn from 'classnames';
import { FormikDropdown } from 'src/components/FormFields';
import { PROPERTY_ASSIGNED_AUTOMATICALLY_BY } from 'src/helpers/constants';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import UserProperty from '../UserProperty';
import classes from './AssignedProperties.module.scss';

const DisableAssignProperty = () => {
  const { propertyOptions } = useOptions();
  return (
    <div className={classes.disableAssignProperty}>
      <span>
        This role is assigned to all properties ({propertyOptions.length})
      </span>
    </div>
  );
};

const AssignedProperties = ({ className = '', formProps, drawerId = '' }) => {
  const { propertyOptions } = useOptions();
  const { values, setFieldValue } = formProps;
  const selectedPropertyIds = values.properties;
  const selectedProperties = propertyOptions.filter(
    (property) =>
      selectedPropertyIds?.length &&
      selectedPropertyIds.includes(property.value)
  );
  const isDisabledAssignProperties =
    PROPERTY_ASSIGNED_AUTOMATICALLY_BY.includes(values.userRole);

  return (
    <div className={cn(classes.wrapper, className)}>
      {isDisabledAssignProperties ? (
        <DisableAssignProperty />
      ) : (
        <>
          <div className={classes.action}>
            <FormikDropdown
              items={[DEFAULT_ALL_OPTION, ...propertyOptions]}
              placeholder={'Select Properties'}
              label="Properties"
              name="properties"
              displaySelectedAll
              placeholderGray
              errorOverlay
              radius="sm"
              applyText="Update"
              insideModalId={drawerId}
              position="bottom"
              modalPadding={135}
              additionalSpace={20}
            />
          </div>
          <div className={classes.result}>
            {selectedProperties.map((sProperty) => {
              return (
                <UserProperty
                  key={sProperty.value}
                  data={sProperty.obj}
                  onRemove={() => {
                    setFieldValue(
                      'properties',
                      selectedPropertyIds.filter((id) => id !== sProperty.value)
                    );
                  }}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default AssignedProperties;
