import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Card } from 'src/components';
import { FormikSelect } from 'src/components/FormFields';
import { LANGUAGES_MAPPING } from 'src/helpers/constants';
import { LANGUAGE_OPTIONS } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import classes from './Language.module.scss';

interface Props {
  className?: string;
}

const Language = ({ className }: Props) => {
  const { timezoneOptions } = useOptions();
  const { i18n } = useTranslation();
  const hideLanguage = true;

  return (
    <Card
      title="Language & Region"
      className={cn(classes.wrapper, className)}
      gapSize="lg"
      contentRow
    >
      {!hideLanguage && (
        <FormikSelect
          data={LANGUAGE_OPTIONS}
          name="language"
          label="Language"
          placeholder="Language"
          onChangeCb={(val) => {
            if (LANGUAGES_MAPPING[val]) {
              i18n.changeLanguage(LANGUAGES_MAPPING[val]);
            }
          }}
        />
      )}
      <FormikSelect
        data={timezoneOptions}
        searchable
        name="timeZone"
        label="Time Zone"
        placeholder="Time Zone"
        maxDropdownHeight={300}
      />
    </Card>
  );
};

export default Language;
