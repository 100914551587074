import cn from 'classnames';
import React from 'react';
import { Button } from 'src/components';
import classes from './ActionButton.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode | string;
}

const ActionButton = ({ className, children }: Props) => {
  return (
    <Button
      type="button"
      size="xs"
      variant="default"
      className={cn(classes.wrapper, className)}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
