export const PATTERN = {
  NAME: /^([a-zA-Z'-.]+\s{0,})*([a-zA-Z'-.]+\s{0,})+$/,
  TIME: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
};
export const MESSAGE = {
  NAME: 'Invalid Name',
};
export const STATUS = {
  ACTIVE: {
    value: 'active',
    text: 'Active',
  },
  INACTIVE: {
    value: 'inactive',
    text: 'Inactive',
  },
};
export const LANGUAGES_MAPPING = {
  english: 'en',
  spanish: 'es',
};
export const DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
export const FIELD_PARSE_KEY = {
  DATE: 'date',
  FORMAT: 'format',
};
export const ROLES = {
  ADMIN: 'Admin',
  SUPER_ADMIN: 'Super Admin',
  COMPLIANCE_SPECIALIST: 'Compliance Specialist',
  PROPERTY_MANAGER: 'Property Manager',
  EXECUTIVE: 'Executive',
  REGIONAL_MANAGER: 'Regional Manager',
  COMPLIANCE_MANAGER: 'Compliance Manager',
  GUEST: 'Guest',
};
export const CANT_BUILD_CERTIFICATE_ROLES = [
  ROLES.COMPLIANCE_SPECIALIST,
  ROLES.PROPERTY_MANAGER,
  ROLES.REGIONAL_MANAGER,
  ROLES.GUEST,
];
export const SUPERVISOR_OPTIONAL_BY = [
  ROLES.ADMIN,
  ROLES.EXECUTIVE,
  ROLES.GUEST,
];
export const PROPERTY_ASSIGNED_AUTOMATICALLY_BY = [
  ROLES.ADMIN,
  ROLES.EXECUTIVE,
  ROLES.COMPLIANCE_MANAGER,
];
export const DEFAULT_SCHEDULE_TIME = {
  IN: '9:00 AM',
  OUT: '5:00 PM',
};
export const BETA_DISABLED = ['production'].includes(
  process.env.REACT_APP_NODE_ENV
);
export const STAGE_PROD = ['production', 'staging'].includes(
  process.env.REACT_APP_NODE_ENV
);
export const BETA_LINKS_ORDERED = BETA_DISABLED ? ['/in-out-board'] : [];
export const BETA_LINKS = BETA_DISABLED
  ? [
      '/',
      '/in-out-board',
      '/notices',
      '/upload-notice',
      '/workflows',
      '/incident-reporting',
      '/incident-reporting/:id',
      '/new-report',
      '/my-properties',
      '/my-residents',
      '/user-management',
      '/my-profile',
    ]
  : [];
export const DEFAULT_URL =
  BETA_LINKS_ORDERED.length > 0 ? BETA_LINKS_ORDERED[0] : '/';
export const SSN_FIELD_SUFFIX = '_ssn_mask';
export const SSN_FIELD_NAMES = ['ssn'];
export const LOCATION_FIELD_SUFFIX = '_idPath';
export const SELECT_FIELD_SUFFIX = '_obj';
export const SOCKET_CONFIG = {
  ECHO_APP_ID: process.env.REACT_APP_ECHO_APP_ID || 'northwest-dev',
  ECHO_CLUSTER: process.env.REACT_APP_ECHO_CLUSTER || 'mt1',
  ECHO_SERVER_PORT: process.env.REACT_APP_ECHO_SERVER_PORT || '443',
  ECHO_SERVER_HOST:
    process.env.REACT_APP_ECHO_SERVER_HOST ||
    'northwest-dev-socket.ventive.app',
  ECHO_AUTH_URL:
    process.env.REACT_APP_ECHO_AUTH_URL ||
    'https://northwest-api.ventive.app/socket/auth',
};
export const TASK_TYPE_MAPPING = {
  NEW_INCIDENT_REPORT: 'Incident Report',
};
export const ACTIVE_INACTIVE_KEY = 'active_inactive';
export const ADDITIONAL_CHECKS_TYPES = {
  DATE_INPUT: 'date-input',
  DROPDOWN: 'dropdown',
};
export const REST_FILES_KEY = 'restFiles';
