import { Select as ManSelect, SelectProps } from '@mantine/core';
import cn from 'classnames';
import find from 'lodash/find';
import get from 'lodash/get';
import { ReactComponent as ArrowSvg } from 'src/assets/icons/Arrow.svg';
import classes from './Select.module.scss';

export type OSelectProps = SelectProps & {
  className?: string;
  errorBg?: boolean;
  dropdownFullWidth?: boolean;
  errorOverlay?: boolean;
  disabledOverlay?: boolean;
  info?: React.ReactNode | string;
};

const Select = ({
  className = '',
  errorBg,
  errorOverlay,
  disabledOverlay,
  info,
  required,
  nothingFound,
  ...other
}: OSelectProps) => {
  return (
    <>
      <ManSelect
        withinPortal
        rightSection={
          other.clearable && !!other.value ? undefined : (
            <ArrowSvg width={20} height={20} className={classes.arrow} />
          )
        }
        styles={{ rightSection: { pointerEvents: 'none' } }}
        {...other}
        labelProps={{ required }}
        icon={info || other.icon}
        nothingFound={nothingFound || 'No option found'}
        disabled={disabledOverlay || other.disabled}
        className={cn(
          classes.wrapper,
          {
            [classes.errorBg]: errorBg,
            [classes.errorOverlay]: errorOverlay,
            [classes.disabledOverlay]: disabledOverlay,
            [classes.info]: !!info,
            'i-print-no-value': !other.value,
          },
          className
        )}
        classNames={{
          dropdown: classes.dropdown,
        }}
        inputContainer={(tChildren) => {
          return (
            <>
              {tChildren}
              <span className="i-print-value">
                {get(find(other.data, { value: other.value }), 'label')}
              </span>
            </>
          );
        }}
      />
    </>
  );
};

export default Select;
