export const parseStringToNumber = (str, isUseParseInt = false) => {
  if (typeof str !== 'string') return str;
  if (str) {
    const newStr = str
      .replace(/ /g, '')
      .replace(/_/g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/\+/g, '')
      .replace(/-/g, '')
      .replace(/%/g, '');
    return isUseParseInt ? parseInt(newStr, 10) : newStr;
  }
  return '';
};
