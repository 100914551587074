import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  ResponsiveCard,
  Table,
  TableResult,
} from 'src/components';
import { Dropdown, Input } from 'src/components/FormFields';
import {
  ButtonBar,
  FilterBar,
  MainContent,
  PageContent,
  PageContentHeader,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { BETA_DISABLED } from 'src/helpers/constants';
import {
  DEFAULT_ALL_OPTION,
  NOTICE_STATUS_OPTIONS,
} from 'src/helpers/constants/options';
import { useCurrentRole, useMedia, useOptions } from 'src/helpers/hooks';
import { getCardValue } from 'src/utils/card';
import { getMomentDateFormat } from 'src/utils/date';
import { applySearch } from 'src/utils/search';
import { renderDropdownColumn, renderPrintColumn } from 'src/utils/table';
import { intialName } from 'src/utils/text';
import classes from './Notices.module.scss';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';
import { getColumns } from './utils';

const Notices = () => {
  const role = useCurrentRole();
  const permission = getPermission(role);
  const isTablet = useMedia('(max-width: 768px)');
  const { propertyOptions } = useOptions();
  const navigate = useNavigate();
  const { notices } = useIndexData();
  const { getNotices, syncUpdateNoticeStatus, updateNoticeStatus } =
    useActions();
  const [setting, setSetting] = useState({
    property: [],
  } as Record<string, string[]>);
  const [search, setSearch] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reloadReports = (tSetting, tSearch) => {
    getNotices({
      property_id: tSetting.property,
      search: tSearch,
    });
  };
  const handleSettingChange = (key, value, willNotFetch = false) => {
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (!willNotFetch) {
      reloadReports(newSetting, search);
    }
  };
  const handleStatusChange = (val, record) => {
    syncUpdateNoticeStatus(record.id, val);
    updateNoticeStatus({
      id: record.id,
      status: val,
    });
  };

  useEffect(() => {
    reloadReports(setting, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Certifications',
            link: '/certifications',
          },
          {
            label: 'All Notices',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader
          title="All Notices"
          className={classes.pageHeader}
          mobileNotWrap
        >
          <Input
            radius="md"
            value={search}
            onChange={(e) => {
              const val = e.target.value;
              setSearch(val);
              applySearch(val, (tVal) => {
                reloadReports(setting, tVal);
              });
            }}
            placeholder="Search"
            isSearch
          />
        </PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          <PageContentHeader>
            <FilterBar className={classes.filterBar}>
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...propertyOptions]}
                value={setting.property}
                onChange={(val) => {
                  handleSettingChange('property', val);
                }}
                placeholder={'All Properties'}
                className={classes.propertyDropdown}
              />
            </FilterBar>
            <ButtonBar mobileFullWidth>
              {permission.canUploadNotice && (
                <Button
                  onClick={() => {
                    navigate('/upload-notice');
                  }}
                  radius="md"
                  textSize="xl"
                >
                  Upload Notices
                </Button>
              )}
              {!BETA_DISABLED && (
                <Button
                  onClick={() => {
                    navigate('/new-certification');
                  }}
                  radius="md"
                  textSize="xl"
                >
                  Start New Certification
                </Button>
              )}
            </ButtonBar>
          </PageContentHeader>
          <TableResult
            count={notices.length}
            className={classes.tableResult}
            mobileShow
          />
          {permission.canViewNotice && (
            <>
              {isTablet ? (
                <>
                  {notices.map((notice) => {
                    return (
                      <ResponsiveCard
                        key={notice.id}
                        items={[
                          {
                            label: 'Uploaded',
                            value: getCardValue(notice.title),
                          },
                          {
                            label: 'Added by',
                            value: getCardValue(
                              intialName(
                                notice.user?.first_name,
                                notice.user?.last_name
                              )
                            ),
                          },
                          {
                            label: 'Property',
                            value: getCardValue(notice.property?.name),
                          },
                          {
                            label: 'Date Uploaded',
                            value: getCardValue(
                              getMomentDateFormat(
                                notice.created_at,
                                'MM/DD/YYYY'
                              )
                            ),
                          },
                        ]}
                        boxItems={[
                          {
                            label: 'Status',
                            value: '',
                          },
                          {
                            label: renderDropdownColumn({
                              options: NOTICE_STATUS_OPTIONS,
                              onChange: handleStatusChange,
                            })(notice.status, notice),
                            value: renderPrintColumn(notice.file_path),
                            align: 'right',
                            hasButton: true,
                            className: classes.cardBoxItems,
                          },
                        ]}
                      />
                    );
                  })}
                </>
              ) : (
                <Box vertical>
                  <Table
                    columns={getColumns({ onStatusChange: handleStatusChange })}
                    align="left"
                    data={notices}
                    onView={undefined}
                    selection={{}}
                    pagination={{}}
                  />
                </Box>
              )}
            </>
          )}
        </PageContent>
      </MainContent>
    </>
  );
};

export default Notices;
