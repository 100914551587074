import cn from 'classnames';
import { ActionDropdown, Arrow } from 'src/components';
import { MenuItem } from 'src/types/Item';
import classes from './OtherItem.module.scss';

interface Props {
  className?: string;
  title: string | React.ReactNode;
  onClick?: (e) => void;
  actions?: MenuItem[];
  isInactive?: boolean;
}

const OtherItem = ({
  className = '',
  title,
  onClick,
  actions,
  isInactive,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isInactive]: isInactive,
        },
        className
      )}
      onClick={isInactive ? undefined : onClick}
    >
      <h3>{title}</h3>
      {isInactive && <span className={classes.inactiveTag}>Inactive</span>}
      {actions?.length > 0 && (
        <ActionDropdown
          items={actions}
          className={classes.actionDropdown}
          withinPortal
        />
      )}
      {!isInactive && <Arrow direction="right" />}
    </div>
  );
};

export default OtherItem;
