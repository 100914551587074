import cn from 'classnames';
import { Card } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import classes from './ContactInfo.module.scss';

interface Props {
  className?: string;
}

const ContactInfo = ({ className }: Props) => {
  return (
    <Card
      title="Contact Information"
      className={cn(classes.wrapper, className)}
      gapSize="md"
      mainContentHasForm
    >
      <FormikInput
        name="officePhone"
        label="Office Phone"
        placeholder="Office Phone"
        inputType="mask"
        mask="999-999-9999"
        autoComplete="office-phone"
      />
      <FormikInput
        name="cellPhone"
        label="Cell Phone"
        placeholder="Cell Phone"
        inputType="mask"
        mask="999-999-9999"
        autoComplete="cell-phone"
      />
      <FormikInput
        name="fax"
        label="Fax Number"
        placeholder="Fax Number"
        inputType="mask"
        mask="999-999-9999"
        autoComplete="fax-number"
      />
    </Card>
  );
};

export default ContactInfo;
