import { FormikInput } from 'src/components/FormFields';

const RelevantDates = ({ formProps }) => {
  const { values } = formProps;
  const didSelectProperty = !!values.property_id;
  return (
    <>
      <h3>Relevant Dates</h3>
      <FormikInput
        name="effective_date"
        label="Effective Date"
        placeholder="Effective Date"
        inputType="mask"
        mask="99/99/9999"
        autoComplete="effective-date"
        errorOverlay
        disabled={!didSelectProperty}
      />
      <FormikInput
        name="response_date"
        label="Response Date (If Applicable)"
        placeholder="Response Date"
        inputType="mask"
        mask="99/99/9999"
        autoComplete="response-date"
        errorOverlay
        disabled={!didSelectProperty}
      />
    </>
  );
};

export default RelevantDates;
