import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_SET_COLLAPSE',
  'GET_ROLES',
  'GET_ALL_USERS',
  'GET_SUPERVISORS',
  'GET_PROPERTIES',
  'GET_CERTIFICATION_TYPES',
  'GET_WORKFLOW_TYPES',
  'GET_WORKFLOW_TYPES_BUILDER',
  'GET_CERTIFICATION_STATUSES',
  'GET_CERT_BUILDER_DROPDOWN',
  'GET_TAGS',
  'GET_ALL_FOLDERS',
  'GET_TIMEZONES',
  'GET_DEPARTMENTS',
  'CREATE_TAG',
  'CREATE_DEPARTMENT',
  'CREATE_STATUS',
  'CREATE_WORKFLOW_TYPE'
);
