import find from 'lodash/find';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'src/components';
import { Input } from 'src/components/FormFields';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { useCurrentPath, useInfiniteScroll } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { applySearch } from 'src/utils/search';
import classes from './Documents.module.scss';
import Loading from './Loading';
import AddFileModal from './components/AddFileModal';
import AddFolderModal from './components/AddFolderModal';
import FilesList from './components/FilesList';
import Sidebar from './components/Sidebar';
import { useActions, useIndexData } from './selectorData';
import { filterFolders, getFolderBR } from './utils';

const TITLE_MAPPING = {
  '/documents': 'Documents',
  '/documents/recently-deleted': 'Recently Deleted',
};

let page = 1;
const DOCUMENT_LIMIT = 50;

const Documents = () => {
  const {
    getFolders,
    getRecentlyDeletedDocuments,
    getDocuments,
    getFolderDocuments,
    createFolder,
    deleteDocument,
    uploadFiles,
    getDocumentDetail,
    updateDocument,
    moveDocument,
    getAllFolders,
    duplicateFolder,
  } = useActions();
  const {
    folders,
    createFolderLoading,
    uploadFilesLoading,
    documents,
    folderDocuments,
    rdDocuments,
    updateDocumentLoading,
    documentMeta,
    folderDocumentMeta,
    rdDocumentMeta,
  } = useIndexData();
  const [search, setSearch] = useState('');
  const [newFiles, setNewFiles] = useState(false);
  const [newFolder, setNewFolder] = useState(false);
  const [isExpandAll, setIsExpandAll] = useState(false);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const folderId = parseInt(String(params?.id || 0), 10);
  const foundFolder = find(folders, { id: folderId });
  const currentPath = useCurrentPath();
  const isRecentlyDeleted = currentPath === '/documents/recently-deleted';
  const isFolderFiles = currentPath === '/documents/folders/:id';
  const isDocuments = currentPath === '/documents';
  const title = TITLE_MAPPING[currentPath] || foundFolder?.name;
  const filderedFolders = filterFolders(folders);
  const tDocumentMeta = isDocuments
    ? documentMeta
    : isRecentlyDeleted
    ? rdDocumentMeta
    : isFolderFiles
    ? folderDocumentMeta
    : {};
  const reloadRecentlyDeletedDocuments = (cb = null) => {
    getRecentlyDeletedDocuments(
      {
        limit: DOCUMENT_LIMIT,
        page,
        isMore: page > 1,
      },
      cb
    );
  };
  const reloadFolderDocuments = (keyword = '', cb = null, keepData = false) => {
    getFolderDocuments(
      folderId,
      {
        limit: DOCUMENT_LIMIT,
        expand_view: true,
        keyword,
        page,
        isMore: page > 1,
        keepData,
      },
      cb
    );
  };
  const reloadDocuments = (keyword = '', cb = null) => {
    getDocuments(
      {
        limit: DOCUMENT_LIMIT,
        keyword,
        page,
        isMore: page > 1,
      },
      cb
    );
  };
  const reloadFileFolders = (keyword = '', keepData = false) => {
    if (isDocuments) {
      reloadDocuments(keyword);
    } else if (isFolderFiles) {
      reloadFolderDocuments(keyword, null, keepData);
    }
  };
  const reloadFolders = () => {
    getFolders({
      limit: 1000,
    });
  };
  const handleCreateFolder = (folderName) => {
    createFolder(
      getFolderBR(folderName, folderId),
      actionCb(
        () => {
          getAllFolders();
          if (isFolderFiles) {
            page = 1;
            reloadFolderDocuments(search);
          } else if (isDocuments) {
            reloadFolders();
          }
          setNewFolder(false);
        },
        null,
        'Create Folder Failed!'
      )
    );
  };
  const handleDeleteFolder = (folder) => {
    deleteDocument(
      folder.id,
      {
        folders_only: 1,
      },
      actionCb(
        () => {
          getAllFolders();
          reloadFolders();
          if (folder.id === folderId) {
            navigate('/documents');
          }
        },
        null,
        'Delete folder failed!'
      )
    );
  };
  const handleDuplicate = (folder, fromFolderDetails = false) => {
    duplicateFolder(
      {
        directory_id: folder.id,
      },
      actionCb(
        () => {
          getAllFolders();
          reloadFolders();
          if (fromFolderDetails) {
            reloadFolderDocuments(search, null, true);
          }
        },
        null,
        'Duplicate folder failed!'
      )
    );
  };
  const handleDeleteFile = (tFile) => {
    deleteDocument(
      tFile.id,
      {
        folders_only: tFile.is_directory ? 1 : 0,
      },
      actionCb(
        () => {
          page = 1;
          reloadFileFolders(search, true);
        },
        null,
        `Delete ${tFile.is_directory ? 'folder' : 'file'} failed!`
      )
    );
  };
  const handleDetail = (tFile, cb) => {
    getDocumentDetail(
      tFile.id,
      {},
      actionCb((res) => {
        if (cb) cb(res);
      })
    );
  };
  const handleEdit = (payload, id, cb) => {
    updateDocument(
      id,
      payload,
      actionCb(
        (res) => {
          reloadFolders();
          cb(res);
        },
        null,
        'Update document failed!'
      )
    );
  };
  const handleDocumentMoved = (sourceId, targetId) => {
    if (sourceId !== targetId && !!sourceId && !!targetId) {
      moveDocument(
        {
          document_id: sourceId,
          directory_id: targetId,
        },
        actionCb(
          () => {
            getAllFolders();
            if (isFolderFiles) {
              page = 1;
              reloadFolderDocuments(search);
            }
          },
          null,
          'Move document failed!'
        )
      );
    }
  };
  const loadMoreDocuments = (tSearch, cb) => {
    if (isDocuments) {
      reloadDocuments(tSearch, cb);
    } else if (isRecentlyDeleted) {
      reloadRecentlyDeletedDocuments(cb);
    } else if (isFolderFiles) {
      reloadFolderDocuments(tSearch, cb);
    }
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    loadMoreDocuments(search, () => {
      setIsLazyLoading(false);
    });
  };
  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      tDocumentMeta &&
      tDocumentMeta.current_page < tDocumentMeta.last_page,
  });

  useEffect(() => {
    page = 1;
    reloadFolders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (folderId) {
      page = 1;
      reloadFolderDocuments();
      setSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folderId]);
  useEffect(() => {
    if (isRecentlyDeleted) {
      page = 1;
      reloadRecentlyDeletedDocuments();
      setSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecentlyDeleted]);
  useEffect(() => {
    if (isDocuments) {
      page = 1;
      reloadDocuments();
      setSearch('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocuments]);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Documents',
            link: !isDocuments ? '/documents' : '',
          },
          ...(isRecentlyDeleted || isFolderFiles
            ? [
                {
                  label: title,
                },
              ]
            : []),
        ]}
      />
      <MainContent className={classes.mainContent}>
        <Sidebar
          className={classes.sidebar}
          folders={filderedFolders.map((folder) => ({
            ...folder,
            isActive:
              folder.id === folderId ||
              (typeof folder.id === 'string' && isRecentlyDeleted),
          }))}
          onDelete={handleDeleteFolder}
          onDocumentMoved={(sourceId, targetId) => {
            handleDocumentMoved(sourceId, targetId);
          }}
          onDetail={handleDetail}
          onEdit={handleEdit}
          editLoading={updateDocumentLoading}
          onDuplicate={(tFolder) => {
            handleDuplicate(tFolder);
          }}
        />
        <div className={classes.rightSide}>
          <PageHeader title={title} className={classes.pageHeader}></PageHeader>
          <PageContent className={classes.pageContent}>
            <Loading />
            {!isRecentlyDeleted && (
              <>
                <Input
                  radius="md"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    applySearch(e.target.value, (tVal) => {
                      page = 1;
                      reloadFileFolders(tVal);
                    });
                  }}
                  placeholder="Search"
                  isSearch
                />
                <div className={classes.buttons}>
                  {!!folderId && (
                    <Button
                      variant="default"
                      onClick={() => {
                        setIsExpandAll(!isExpandAll);
                      }}
                      radius="md"
                      className={classes.collapseBtn}
                    >
                      {isExpandAll ? 'Collapse' : 'Expand'} All Folderes
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setNewFolder(true);
                    }}
                    radius="md"
                    textSize="xl"
                  >
                    Add New Folder
                  </Button>
                  <Button
                    onClick={() => {
                      setNewFiles(true);
                    }}
                    radius="md"
                    textSize="xl"
                  >
                    Upload File
                  </Button>
                </div>
              </>
            )}
            <FilesList
              recentText={isDocuments ? 'Recent' : ''}
              data={
                isRecentlyDeleted
                  ? rdDocuments
                  : isFolderFiles
                  ? folderDocuments
                  : documents
              }
              isExpandAll={isExpandAll}
              onDeleteFile={handleDeleteFile}
              disabled={isRecentlyDeleted}
              onDetail={handleDetail}
              onEditFile={(payload, id, cb) => {
                updateDocument(
                  id,
                  payload,
                  actionCb(
                    (res) => {
                      page = 1;
                      reloadFileFolders(search);
                      cb(res);
                    },
                    null,
                    'Update document failed!'
                  )
                );
              }}
              editLoading={updateDocumentLoading}
              folderId={folderId}
              onDocumentMoved={(sourceId, targetId) => {
                if (isFolderFiles) {
                  handleDocumentMoved(sourceId, targetId);
                }
              }}
              onDuplicate={(tFolder) => {
                handleDuplicate(tFolder, true);
              }}
            />
          </PageContent>
        </div>
      </MainContent>
      <AddFolderModal
        isOpened={newFolder}
        onClose={() => {
          setNewFolder(false);
        }}
        onSubmit={(folderName) => {
          handleCreateFolder(folderName);
        }}
        loading={createFolderLoading}
      />
      <AddFileModal
        folder={foundFolder}
        isOpened={newFiles}
        loading={uploadFilesLoading}
        onClose={() => {
          setNewFiles(false);
        }}
        onSubmit={(payload) => {
          uploadFiles(
            payload,
            actionCb(
              () => {
                page = 1;
                reloadFileFolders(search);
                setNewFiles(false);
              },
              null,
              'Upload files failed!'
            )
          );
        }}
      />
    </>
  );
};

export default Documents;
