import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from 'src/components';
import { REST_FILES_KEY } from 'src/helpers/constants';
import { DUE_BY_VALUES } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import DocumentModal from 'src/pages/Documents/components/DocumentModal';
import PendingCerts from 'src/pages/ResidentDetail/SubContent/PendingCerts';
import { SettingsT } from 'src/pages/ResidentDetail/types';
import classes from './CertPreviewModal.module.scss';
import { extractCertData } from './utils';

const DEFAULT_SETTING: SettingsT = {
  detailYear: String(new Date().getFullYear()),
  status: 1,
  effectiveDate: '',
  responseDate: '',
  notes: [],
  [REST_FILES_KEY]: [],
};

const CertPreviewModal = ({
  className = '',
  isOpened,
  onClose,
  defaultData = null,
  isWorkflow = false,
}) => {
  const { certificationStatusOptions } = useOptions();
  const [setting, setSetting] = useState(DEFAULT_SETTING);
  const defaultStatuses = defaultData?.status_options || [];
  const { defaultNotice, defaultSections } = extractCertData(defaultData);
  const hasFrequency =
    isWorkflow &&
    !!defaultData?.frequency &&
    defaultData?.due_by === DUE_BY_VALUES.SPECIFIC_DATE;
  const resetState = () => {
    setSetting(DEFAULT_SETTING);
  };
  const handleSettingChange = (key, value) => {
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
  };

  useEffect(() => {
    if (isOpened) {
      resetState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpened]);

  return (
    <DocumentModal
      isOpened={isOpened}
      onClose={onClose}
      title={
        defaultData?.name_of_certification ||
        `${isWorkflow ? 'Workflow' : 'Certificate'} Name`
      }
      size={1170}
      className={cn(
        classes.modalWrapper,
        {
          [classes.hasFrequency]: hasFrequency,
        },
        className
      )}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <div className={classes.mainContent}>
        {hasFrequency && (
          <div className={classes.frequency}>
            <div>
              <span>Frequency</span>
              <span>{defaultData.frequency}</span>
            </div>
          </div>
        )}
        <PendingCerts
          setting={setting}
          onSettingChange={handleSettingChange}
          certId={6}
          isPreview
          className={classes.pendingCerts}
          previewData={{
            statusOptions: certificationStatusOptions.filter((obj) =>
              defaultStatuses.includes(obj.value)
            ),
            notice: defaultNotice,
            sections: defaultSections,
          }}
        />
        <div className={classes.footer}>
          <Button
            radius="md"
            textSize="xl"
            type="button"
            onClick={onClose}
            size="md"
          >
            Close
          </Button>
        </div>
      </div>
    </DocumentModal>
  );
};

export default CertPreviewModal;
