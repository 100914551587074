import { FormikInput } from 'src/components/FormFields';
import { getIsEmptyEntities } from 'src/utils/object';
import SectionFormArray from '../SectionFormArray';

const Authorities = ({ values, isDisabled = false, onValuesChange }) => {
  return (
    <SectionFormArray
      onAddCb={onValuesChange}
      onRemoveCb={onValuesChange}
      addHidden={isDisabled}
      values={values}
      title="Authorities"
      parentName="authority"
      onNoValue={() => {
        return getIsEmptyEntities(values.authority);
      }}
      renderChild={(index) => {
        return (
          <>
            <FormikInput
              name={`authority.${index}.name`}
              label="Authority Name"
              placeholder="Authority Name"
              type="text"
              autoComplete="authority-name"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormikInput
              name={`authority.${index}.phone_number`}
              label="Authority Phone Number"
              placeholder="000-000-0000"
              inputType="mask"
              mask="999-999-9999"
              autoComplete="authority-phone"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormikInput
              name={`authority.${index}.badge_number`}
              label="Authority Badge Number"
              placeholder="00000"
              inputType="mask"
              mask="99999"
              autoComplete="authority-badge"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
            <FormikInput
              name={`authority.${index}.police_report_number`}
              label="Police Report Number"
              placeholder="0000000"
              inputType="mask"
              mask="9999999"
              autoComplete="police-reportnumber"
              errorOverlay
              disabledOverlay={isDisabled}
              onChangeCb={onValuesChange}
            />
          </>
        );
      }}
      newObj={{
        name: '',
        phone_number: '',
        badge_number: '',
        police_report_number: '',
      }}
      newBtnText="+ Add an Authority"
      gridColumn={4}
    />
  );
};

export default Authorities;
