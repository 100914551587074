import { Reducer } from 'redux';

import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/document';

const initialState = {
  folders: {
    ...defaultObj,
  },
  documents: {
    ...defaultObj,
  },
  folderDocuments: {
    ...defaultObj,
  },
  recentlyDeletedDocuments: {
    ...defaultObj,
  },
  documentDetail: {
    ...defaultObj,
  },
  generateDocumentUrl: {
    ...defaultObj,
  },
  createFolder: {
    ...defaultObj,
  },
  uploadFiles: {
    ...defaultObj,
  },
  updateDocument: {
    ...defaultObj,
  },
  delteDocument: {
    ...defaultObj,
  },
  checkDuplicateFiles: {
    ...defaultObj,
  },
  moveDocument: {
    ...defaultObj,
  },
  duplicateFolder: {
    ...defaultObj,
  },
};

const DocumentReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_FOLDERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          folders: {
            ...prevState.folders,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          folders: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          folders: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_DOCUMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          documents: {
            ...prevState.documents,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          documents: {
            loading: false,
            data: meta?.payload?.isMore
              ? {
                  ...payload,
                  data: [
                    ...(prevState.documents?.data?.data || []),
                    ...(payload.data || []),
                  ],
                }
              : payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          documents: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_FOLDER_DOCUMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          folderDocuments: {
            ...prevState.folderDocuments,
            loading: true,
            data:
              meta?.payload?.isMore || meta?.payload?.keepData
                ? {
                    ...(prevState.folderDocuments.data || {}),
                  }
                : {},
          },
        }),
        success: (prevState) => ({
          ...prevState,
          folderDocuments: {
            loading: false,
            data: meta?.payload?.isMore
              ? {
                  ...payload,
                  data: [
                    ...(prevState.folderDocuments?.data?.data || []),
                    ...(payload.data || []),
                  ],
                }
              : payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          folderDocuments: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_RECENTLY_DELETED_DOCUMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          recentlyDeletedDocuments: {
            ...prevState.recentlyDeletedDocuments,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          recentlyDeletedDocuments: {
            loading: false,
            data: meta?.payload?.isMore
              ? {
                  ...payload,
                  data: [
                    ...(prevState.recentlyDeletedDocuments?.data?.data || []),
                    ...(payload.data || []),
                  ],
                }
              : payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          recentlyDeletedDocuments: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_DOCUMENT_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          documentDetail: {
            ...prevState.documentDetail,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          documentDetail: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          documentDetail: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GENRATE_DOCUMENT_URL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          generateDocumentUrl: {
            ...prevState.generateDocumentUrl,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          generateDocumentUrl: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          generateDocumentUrl: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_FOLDER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createFolder: {
            ...prevState.createFolder,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createFolder: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createFolder: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPLOAD_FILES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          uploadFiles: {
            ...prevState.uploadFiles,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          uploadFiles: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          uploadFiles: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.UPDATE_DOCUMENT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          updateDocument: {
            ...prevState.updateDocument,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          updateDocument: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          updateDocument: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DELETE_DOCUMENT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          delteDocument: {
            ...prevState.delteDocument,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          delteDocument: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          delteDocument: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CHECK_DUPLICATE_FILES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          checkDuplicateFiles: {
            ...prevState.checkDuplicateFiles,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          checkDuplicateFiles: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          checkDuplicateFiles: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.MOVE_DOCUMENT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          moveDocument: {
            ...prevState.moveDocument,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          moveDocument: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          moveDocument: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.DUPLICATE_FOLDER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          duplicateFolder: {
            ...prevState.duplicateFolder,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          duplicateFolder: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          duplicateFolder: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default DocumentReducer;
