import { Table as ManTable, TableProps } from '@mantine/core';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { ReactComponent as EllipsisSvg } from 'src/assets/icons/Ellipsis.svg';
import { Align } from 'src/types';
import Checkbox from '../FormFields/Checkbox';
import Menu from '../Menu';
import Pagination from '../Pagination';
import classes from './Table.module.scss';

export type ColumnType = {
  key: string;
  title?: string | React.ReactNode;
  width?: string | number;
  minWidth?: string | number;
  render: any;
  renderAction?: any;
  getItems?: any;
  align?: any;
  titleAlign?: any;
  className?: string;
};
export type RowType = Record<string, string | number | React.ReactNode>;

type Props = TableProps & {
  className?: string;
  columns: ColumnType[];
  data: RowType[];
  hideColumn?: boolean;
  onView?: (val) => void;
  pagination?: any;
  align?: Align;
  columnAlign?: Align;
  selection?: any;
  idKey?: string;
  hoverColor?: boolean;
};

const Table = ({
  className = '',
  columns,
  data,
  hideColumn,
  onView,
  pagination,
  align,
  selection,
  idKey,
  hoverColor,
  ...other
}: Props) => {
  const thisIdKey = idKey || 'id';
  const hasSelection = !isEmpty(selection);
  const selectedRows = selection.rows || [];
  const hasRowChecked = selectedRows.length > 0;
  const selectedIds = selectedRows.map((r) => r[thisIdKey]);
  const isCheckedAll =
    selectedRows.length > 0 && selectedRows.length === data.length;
  const handleChange = (row: any) => () => {
    if (row === true) {
      selection.onChange(isCheckedAll ? [] : data);
    } else {
      selection.onChange(
        selectedIds.includes(row[thisIdKey])
          ? selectedRows.filter((r) => r[thisIdKey] !== row[thisIdKey])
          : [...selectedRows, row]
      );
    }
  };
  return (
    <>
      <ManTable
        {...other}
        className={cn(
          classes.wrapper,
          classes[`align_${align}`],
          {
            [classes.hoverColor]: hoverColor,
          },
          className
        )}
      >
        <colgroup>
          {hasSelection && <col style={{ width: '60px' }} />}
          {columns.map((column: ColumnType) => {
            return (
              <col
                key={column.key}
                style={{ width: column.width, minWidth: column.minWidth }}
              />
            );
          })}
        </colgroup>
        <thead>
          {!hideColumn && (
            <tr>
              {hasSelection && (
                <th className={classes.selectedRow}>
                  <Checkbox
                    checked={isCheckedAll}
                    onChange={handleChange(true)}
                    indeterminate={hasRowChecked && !isCheckedAll}
                  />
                </th>
              )}
              {columns.map((column: ColumnType) => {
                return (
                  <th
                    key={column.key}
                    className={cn(classes[`titleAlign${column.titleAlign}`])}
                  >
                    {column.title}
                  </th>
                );
              })}
            </tr>
          )}
        </thead>
        <tbody>
          {data.map((row: RowType, index: number) => {
            return (
              <tr
                key={index}
                onClick={() => {
                  if (onView) onView(row);
                }}
                className={cn({
                  pointer: !!onView,
                })}
              >
                {hasSelection && (
                  <td className={classes.selectedRow}>
                    <Checkbox
                      checked={selectedIds.includes(row[thisIdKey])}
                      onChange={handleChange(row)}
                    />
                  </td>
                )}
                {columns.map((column: ColumnType) => {
                  const thisCellData = row[column.key];
                  const a3Actions = column.getItems ? column.getItems(row) : [];
                  return (
                    <td key={column.key} style={{ textAlign: column.align }}>
                      <div
                        className={cn(
                          classes[`columnAlign${column.align}`],
                          column.className
                        )}
                      >
                        {column.key === 'action3' ? (
                          <Menu
                            position="bottom-end"
                            menuStyles="actionDropdown"
                            items={a3Actions}
                            className={cn({
                              [classes.hideMenu]: a3Actions.length === 0,
                            })}
                          >
                            <span className={classes.action3}>
                              <EllipsisSvg onClick={() => {}} />
                            </span>
                          </Menu>
                        ) : column.key === 'action' ? (
                          <span
                            className={cn(classes.action, {
                              [classes.actionAlwaysShow]: !!column.renderAction,
                            })}
                          >
                            {column.renderAction ? (
                              column.renderAction()
                            ) : (
                              <>
                                View{' '}
                                <span className={classes.arrow}>&#62;</span>
                              </>
                            )}
                          </span>
                        ) : (
                          column.render(thisCellData, row)
                        )}
                      </div>
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </ManTable>
      {!isEmpty(pagination) && (
        <Pagination
          total={pagination?.totalPage || 0}
          onChange={pagination?.onChange}
          siblings={1}
          boundaries={1}
          withEdges
          page={pagination?.page || 1}
          className={classes.pagination}
          size="sm"
        />
      )}
    </>
  );
};

export default Table;
