import * as certificationActions from 'src/store/actions/certification';
import * as workflowActions from 'src/store/actions/workflow';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  return {
    certFields: useReducerData('certification', 'certificationFields.data', {}),
    certNotes: useReducerData('certification', 'certificateNote.data', []),
    addCertificateNoteLoading: useReducerData(
      'certification',
      'addCertificateNote.loading',
      false
    ),
    saveCertificateFieldsLoading: useReducerData(
      'certification',
      'saveCertificateFields.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...certificationActions,
    ...workflowActions,
  });
};
