import get from 'lodash/get';
import { LOCATION_FIELD_SUFFIX } from 'src/helpers/constants';
import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';

export const getImageDataURI = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};
export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
export const getDisplayLocationFromPath = (val, fileName?: string) => {
  if (!val || typeof val !== 'string') return '';
  const valArr = val.split('/');
  return valArr.length === 1
    ? valArr[0]
    : valArr.length > 1
    ? `${valArr.filter((_, i) => i < valArr.length - 1).join(' > ')}${
        fileName ? ` > ${fileName}` : ''
      }`
    : '';
};
const getAncestorChildIds = (obj, arr) => {
  if (obj.id) {
    arr.push(obj.id);
  }
  if (obj?.children?.length) {
    getAncestorChildIds(obj.children[0], arr);
  }
};
export const getLocationIdPath = (obj) => {
  const arr = [];
  getAncestorChildIds(obj, arr);
  return arr.join('/');
};
const getDocumentFDName = (
  key,
  keyAsArray = false,
  index?: number,
  hasIndex = false
) => {
  return `${hasIndex ? `documents[${index}][${key}]` : key}${
    keyAsArray ? `[]` : ''
  }`;
};
export const setFileObjFormData = (f, formData, otherSetting?: any) => {
  const { index, confirmMethod, duplicateFileNames, hideFile } =
    otherSetting || {};
  const tConfirmMethod = confirmMethod || 'replace';
  const tDuplicateFileNames = duplicateFileNames || [];
  const hasIndex = index >= 0;
  const { formNumber } = f;
  const property = f.property || [];
  const tags = f.tags || [];
  const permissionsToEdit = f.permissions_to_edit || [];
  const permissionsToView = f.permissions_to_view || [];
  const permissionsToDelete = f.permissions_to_delete || [];
  const locationIdPath = get(f, `location${LOCATION_FIELD_SUFFIX}`) || '';
  const locationIds = locationIdPath.split('/');
  const locationId = locationIds[locationIds.length - 1];
  formData.append(
    getDocumentFDName('directory_id', false, index, hasIndex),
    locationId
  );
  formData.append(
    getDocumentFDName('form_number', false, index, hasIndex),
    formNumber || ''
  );
  if (!hideFile) {
    formData.append(
      getDocumentFDName('name', false, index, hasIndex),
      f.file?.name || ''
    );
    formData.append(getDocumentFDName('file', false, index, hasIndex), f.file);
    formData.append(
      getDocumentFDName('duplicate', false, index, hasIndex),
      tDuplicateFileNames.includes(f.file?.name) ? tConfirmMethod : 'replace'
    );
  }
  property.forEach((p) => {
    if (p !== OPTION_ALL_VALUE) {
      formData.append(
        getDocumentFDName('properties', true, index, hasIndex),
        String(p)
      );
    }
  });
  tags.forEach((tag) => {
    if (tag !== OPTION_ALL_VALUE) {
      formData.append(
        getDocumentFDName('tags', true, index, hasIndex),
        String(tag)
      );
    }
  });
  permissionsToEdit.forEach((permission) => {
    if (permission !== OPTION_ALL_VALUE) {
      const pArr = permission.split('_');
      formData.append(
        getDocumentFDName(`${pArr[0]}s_can_edit`, true, index, hasIndex),
        pArr[pArr.length - 1]
      );
    }
  });
  permissionsToView.forEach((permission) => {
    if (permission !== OPTION_ALL_VALUE) {
      const pArr = permission.split('_');
      formData.append(
        getDocumentFDName(`${pArr[0]}s_can_view`, true, index, hasIndex),
        pArr[pArr.length - 1]
      );
    }
  });
  permissionsToDelete.forEach((permission) => {
    if (permission !== OPTION_ALL_VALUE) {
      const pArr = permission.split('_');
      formData.append(
        getDocumentFDName(`${pArr[0]}s_can_delete`, true, index, hasIndex),
        pArr[pArr.length - 1]
      );
    }
  });
};
const getUserRoleFromPermission = (permissions) => {
  if (!permissions || permissions.length === 0) {
    return { permissionUsers: [], permissionRoles: [] };
  }
  const permissionUsers = permissions
    .filter((p) => p.includes('user_'))
    .map((user) => {
      const userArr = user.split('_');
      return Number(userArr[userArr.length - 1]);
    });
  const permissionRoles = permissions
    .filter((p) => p.includes('role_'))
    .map((role) => {
      const roleArr = role.split('_');
      return Number(roleArr[roleArr.length - 1]);
    });
  return { permissionUsers, permissionRoles };
};
export const getDocumentPayloadData = (values) => {
  const tags = (values.tags || []).filter((v) => v !== OPTION_ALL_VALUE);
  const properties = (values.property || []).filter(
    (v) => v !== OPTION_ALL_VALUE
  );
  const permissions = (values.permissions || []).filter(
    (v) => v !== OPTION_ALL_VALUE
  );
  const permissionsToDelete = (values.permissions_to_delete || []).filter(
    (v) => v !== OPTION_ALL_VALUE
  );
  const permissionsToEdit = (values.permissions_to_edit || []).filter(
    (v) => v !== OPTION_ALL_VALUE
  );
  const permissionsToView = (values.permissions_to_view || []).filter(
    (v) => v !== OPTION_ALL_VALUE
  );
  const locationIdPath = get(values, `location${LOCATION_FIELD_SUFFIX}`) || '';
  const locationIds = locationIdPath.split('/');
  const locationId = Number(locationIds[locationIds.length - 1]);
  const { permissionUsers, permissionRoles } =
    getUserRoleFromPermission(permissions);
  const {
    permissionUsers: permissionUsersDelete,
    permissionRoles: permissionRolesDelete,
  } = getUserRoleFromPermission(permissionsToDelete);
  const {
    permissionUsers: permissionUsersEdit,
    permissionRoles: permissionRolesEdit,
  } = getUserRoleFromPermission(permissionsToEdit);
  const {
    permissionUsers: permissionUsersView,
    permissionRoles: permissionRolesView,
  } = getUserRoleFromPermission(permissionsToView);
  return {
    tags,
    properties,
    locationId,
    permissionUsers,
    permissionRoles,
    permissionUsersDelete,
    permissionRolesDelete,
    permissionUsersEdit,
    permissionRolesEdit,
    permissionUsersView,
    permissionRolesView,
  };
};
export const downloadFile = (url, fileName) => {
  const element = document.createElement('a');
  element.setAttribute('href', url);
  element.setAttribute('target', '_blank');
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
export const getIsFileOwner = (fData, userId) => fData?.owner?.id === userId;
export const downloadFileFromObj = (fileObj, fileName?: string) => {
  const url = window.URL.createObjectURL(new Blob([fileObj]));
  downloadFile(url, fileName || fileObj.name);
};
