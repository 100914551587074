import { InputProps, Input as ManInput } from '@mantine/core';
import cn from 'classnames';
import InputMask from 'react-input-mask';
import { ReactComponent as SearchSvg } from 'src/assets/icons/Search.svg';
import { SizeType } from 'src/types';
import classes from './Input.module.scss';

export type OInputProps = InputProps & {
  className?: string;
  wrapperClassName?: string;
  error?: string;
  label?: string | React.ReactNode;
  placeholder?: string;
  errorBg?: boolean;
  value?: any;
  onChange?: any;
  icon?: React.ReactNode;
  inputType?: string;
  mask?: string;
  maskChar?: string;
  errorOverlay?: boolean;
  isSearch?: boolean;
  autoComplete?: string;
  disabledOverlay?: boolean;
  onKeyPress?: any;
  isSSN?: boolean;
  onSSNFocus?: any;
  focusName?: string;
  textSize?: SizeType;
  maxLength?: number;
  layout?: 'horizontal' | undefined;
  onBlur?: (e: any) => void;
  printValue?: string;
  innerRef?: any;
};

const Input = ({
  className,
  error,
  label,
  errorBg,
  icon,
  inputType,
  mask,
  maskChar,
  errorOverlay,
  isSearch,
  required,
  disabledOverlay,
  onKeyPress,
  isSSN,
  onSSNFocus,
  focusName,
  textSize,
  maxLength,
  layout,
  wrapperClassName,
  printValue,
  innerRef,
  ...other
}: OInputProps) => {
  const addProps = {} as any;
  if (inputType === 'mask') {
    addProps.component = InputMask;
    addProps.mask = mask;
    addProps.maskChar = maskChar;
  }
  return (
    <ManInput.Wrapper
      error={error}
      label={label}
      className={cn(
        classes.inputWrapper,
        {
          [classes.error]: !!error,
          [classes.errorBg]: errorBg,
          [classes.hasIcon]: !!icon || isSearch,
          [classes.errorOverlay]: errorOverlay,
          [classes.disabledOverlay]: disabledOverlay,
          [classes.isSearch]: isSearch,
          'i-print-no-value': !(printValue || other.value),
        },
        classes[`layout_${layout}`],
        wrapperClassName
      )}
      required={required}
    >
      <ManInput
        {...addProps}
        {...other}
        disabled={other.disabled || disabledOverlay}
        className={cn(classes.wrapper, classes[`text_${textSize}`], className)}
        onKeyPress={onKeyPress}
        onFocus={() => {
          if (isSSN && onSSNFocus) {
            onSSNFocus();
            setTimeout(() => {
              document.getElementById(focusName).focus();
            }, 0);
          }
        }}
        maxLength={maxLength}
        ref={innerRef}
      />
      {(!!icon || isSearch) && (
        <span className={classes.icon}>{isSearch ? <SearchSvg /> : icon}</span>
      )}
      <span className={'i-print-value'}>{printValue || other.value}</span>
    </ManInput.Wrapper>
  );
};

export default Input;
