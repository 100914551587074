import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import {
  renderAlertAsStatusColumn,
  renderNameColumn,
  renderPhonesColumn,
  renderStandardColumn,
} from 'src/utils/table';

export const getColumns = () => {
  return [
    {
      key: 'name',
      render: renderNameColumn,
      title: 'Name',
      width: 300,
    },
    {
      key: 'unit',
      render: (val) => val?.name,
      title: 'Unit',
      width: 150,
    },
    {
      key: 'phone',
      render: renderPhonesColumn(),
      title: 'Phone Number',
      width: 200,
    },
    {
      key: 'email',
      render: renderStandardColumn,
      title: 'Email Address',
    },
    {
      key: 'pending_workflows',
      render: renderAlertAsStatusColumn,
      title: 'Pending Workflows',
      width: 200,
      align: 'center',
      titleAlign: 'center',
    },
    {
      key: 'pending_certs',
      render: renderAlertAsStatusColumn,
      title: 'Pending Certs',
      width: 200,
      align: 'center',
      titleAlign: 'center',
    },
  ];
};
export const getFilters = (settings, search, page = 1) => {
  return {
    properties: settings.properties.filter((val) => val !== OPTION_ALL_VALUE),
    limit: 30,
    page,
    isMore: page > 1,
    search,
  };
};
