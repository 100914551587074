import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from 'src/pages/NewReport/components/Section';

const GuardianPOA = ({ isDisabled = false }) => {
  return (
    <NewReportSection title="Guardian/POA">
      <FormCardContent column={4}>
        <FormikInput
          name="poa_first_last_name"
          label="First and Last Name"
          placeholder="First and Last Name"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="poa_address"
          label="Address"
          placeholder="Address"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="poa_phone"
          label="Phone Number"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="poa_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="poa_type"
          label="Type"
          placeholder="Type"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default GuardianPOA;
