import get from 'lodash/get';
import queryString from 'query-string';

export const getAllUrlParams = (search = '') => {
  const searchUrl = search;
  return queryString.parse(searchUrl.slice(1)) || {};
};

export const getUrlParamValueByKey = (
  search = '',
  key = '',
  isGetAll?: boolean
) => {
  const values = getAllUrlParams(search);
  if (isGetAll) {
    return values;
  }
  return get(values, key, '');
};
