import { combineReducers } from 'redux';
import authTypes from '../types/auth';
import AuthReducer from './auth';
import CertificationReducer from './certification';
import DashboardReducer from './dashboard';
import DocumentReducer from './document';
import InOutReducer from './inout';
import NoticeReducer from './notice';
import PropertyReducer from './property';
import ReportReducer from './report';
import ResidentReducer from './resident';
import ScheduleReducer from './schedule';
import SharedReducer from './shared';
import UserReducer from './user';
import WorkflowReducer from './workflow';

const reducers = {
  auth: AuthReducer,
  inout: InOutReducer,
  schedule: ScheduleReducer,
  user: UserReducer,
  shared: SharedReducer,
  report: ReportReducer,
  certification: CertificationReducer,
  notice: NoticeReducer,
  property: PropertyReducer,
  document: DocumentReducer,
  resident: ResidentReducer,
  dashboard: DashboardReducer,
  workflow: WorkflowReducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

const rootReducer = (state, action) => {
  if (action.type === authTypes.LOG_OUT) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export type ReducerName = keyof typeof reducers;

export default rootReducer;
