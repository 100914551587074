import { FormikTextarea } from 'src/components/FormFields';
import NewReportSection from '../Section';

const StepBeingTaken = ({ isDisabled = false, values, onValuesChange }) => {
  return (
    <NewReportSection
      title="Steps Being Taken to Prevent Reoccurrence"
      onNoValue={() => {
        return !values.step_taken;
      }}
    >
      <FormikTextarea
        name="step_taken"
        placeholder="Assistance Provided by Property Staff"
        maxLength={300}
        disabledOverlay={isDisabled}
        onChangeCb={onValuesChange}
      ></FormikTextarea>
    </NewReportSection>
  );
};

export default StepBeingTaken;
