import find from 'lodash/find';
import { FormikDropdown, FormikSelect } from 'src/components/FormFields';
import { DEFAULT_ALL_OPTION, NA_OPTION } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import { makeOptions } from 'src/utils/options';

const ResidentInformation = ({ formProps }) => {
  const { propertyOptions, certificateTypeOptions } = useOptions();
  const { values, setFieldValue } = formProps;
  const didSelectProperty = !!values.property_id;
  const unitOptions = makeOptions(
    find(propertyOptions, { value: values.property_id })?.obj?.units || [],
    'id',
    'unit_id'
  );
  const tUnitOptions =
    unitOptions.length > 0 ? [DEFAULT_ALL_OPTION, ...unitOptions] : [];

  return (
    <>
      <h3>Resident Information</h3>
      <FormikSelect
        data={propertyOptions}
        name="property_id"
        label="Property"
        placeholder="Select"
        maxDropdownHeight={300}
        errorOverlay
        searchable
        onChangeCb={() => {
          setFieldValue('property_unit_id', []);
        }}
        nothingFound="No property found"
      />
      <FormikDropdown
        items={[...tUnitOptions, NA_OPTION]}
        placeholder={'Select'}
        label="Unit Number (s)"
        name="property_unit_id"
        displaySelectedAll
        placeholderGray
        errorOverlay
        radius="sm"
        disabled={!didSelectProperty}
      />
      <FormikSelect
        data={!values.property_id ? [] : certificateTypeOptions}
        name="certificate_schema_id"
        label={`Certification Type${
          !values.property_id ? ' *Please select a property' : ''
        }`}
        placeholder="Select"
        maxDropdownHeight={300}
        errorOverlay
        disabled={!didSelectProperty}
      />
    </>
  );
};

export default ResidentInformation;
