import findIndex from 'lodash/findIndex';
import { Avatar, Badge } from 'src/components';
import { getDPDate } from 'src/utils/date';
import { formatBytes } from 'src/utils/file';
import { getDisplayPermission } from 'src/utils/permission';
import { intialName } from 'src/utils/text';

export const getFileInfos = ({ data, availableProperties }) => {
  const owner = data?.owner || {};
  const isFolder = !!data?.is_directory;
  const tags = data.tags || [];
  const properties = data.properties || [];
  const tProperties = properties.filter(
    (p) => findIndex(availableProperties, { value: p.id }) >= 0
  );
  // const permissionUsers = data?.users || [];
  // const permissionRoles = data?.roles || [];
  const permissionUsersView = data?.users_can_view || [];
  const permissionRolesView = data?.roles_can_view || [];
  const permissionUsersEdit = data?.users_can_edit || [];
  const permissionRolesEdit = data?.roles_can_edit || [];
  const permissionUsersDelete = data?.users_can_delete || [];
  const permissionRolesDelete = data?.roles_can_delete || [];
  const fileInfos = [
    ...(isFolder
      ? []
      : [
          {
            label: 'Form Number',
            content: <>{data?.form_number}</>,
          },
          {
            label: 'Type',
            content: <>{data?.mime_type}</>,
          },
        ]),
    {
      label: 'Date Modified',
      content: <>{getDPDate(data?.updated_at)}</>,
    },
    ...(isFolder
      ? []
      : [
          {
            label: 'Size',
            content: <>{formatBytes(data?.size)}</>,
          },
        ]),
    {
      label: 'Owner',
      content: (
        <>
          <Avatar avtSrc={owner.profile_image_path} radius="xl" statusHasBorder>
            {intialName(owner.first_name, owner.last_name)}
          </Avatar>
        </>
      ),
    },
    ...[
      {
        label: 'Permissions to Edit',
        content:
          permissionUsersEdit.length > 0 || permissionRolesEdit.length > 0 ? (
            <>
              {getDisplayPermission(
                permissionUsersEdit,
                permissionRolesEdit
              ).join(', ')}
            </>
          ) : (
            '-'
          ),
      },
      {
        label: 'Permissions to View',
        content:
          permissionUsersView.length > 0 || permissionRolesView.length > 0 ? (
            <>
              {getDisplayPermission(
                permissionUsersView,
                permissionRolesView
              ).join(', ')}
            </>
          ) : (
            '-'
          ),
      },
      {
        label: 'Permissions to Delete',
        content:
          permissionUsersDelete.length > 0 ||
          permissionRolesDelete.length > 0 ? (
            <>
              {getDisplayPermission(
                permissionUsersDelete,
                permissionRolesDelete
              ).join(', ')}
            </>
          ) : (
            '-'
          ),
      },
    ],
    {
      label: 'Tags',
      content:
        tags.length > 0
          ? tags.map((t) => (
              <Badge key={t.id} radius="md">
                {t.name}
              </Badge>
            ))
          : '-',
    },
    {
      label: 'Properties',
      content:
        tProperties.length > 0
          ? tProperties.map((p) => (
              <Badge key={p.id} radius="md" color={'green'}>
                {p.name}
              </Badge>
            ))
          : '-',
    },
    {
      label: 'Notes',
      content: <>{data?.notes}</>,
    },
  ];
  return fileInfos;
};
