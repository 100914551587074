import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Badge, Line } from 'src/components';
import {
  FormikDropdown,
  FormikInput,
  FormikSelect,
} from 'src/components/FormFields';
import { SUPERVISOR_OPTIONAL_BY } from 'src/helpers/constants';
import { ORGANIZATION_OPTIONS_TRIM } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import { renderDateColumn } from 'src/utils/table';
import classes from './UserEdit.module.scss';

const UserEdit = ({ className = '', editObj, formProps, drawerId = '' }) => {
  const { values } = formProps;
  const isEditing = !isEmpty(editObj);
  const { roleOptionsWithGroup, supervisorOptions, departmentOptions } =
    useOptions();
  const isSupervisorOptional = SUPERVISOR_OPTIONAL_BY.includes(values.userRole);
  return (
    <div className={cn(classes.wrapper, className)}>
      {isEditing && (
        <div className={classes.userInfo}>
          <div>
            <span>Last Login</span>
            <span>{renderDateColumn(editObj?.last_login)}</span>
          </div>
          <div>
            <Badge color={'green'}>Active</Badge>
          </div>
        </div>
      )}
      <div className={classes.fields}>
        <FormikInput
          name="firstName"
          label={'First Name'}
          placeholder={'First Name'}
          required
        />
        <FormikInput
          name="lastName"
          label={'Last Name'}
          placeholder={'Last Name'}
          required
        />
      </div>
      <div className={classes.fields}>
        <FormikInput
          name="preferredName"
          label={'Preferred Name'}
          placeholder={'Preferred Name'}
        />
        <FormikInput
          name="ssn"
          label={'Social Security Number'}
          required
          isSSN
        />
      </div>
      <div className={classes.fields}>
        <FormikInput
          name="ukgId"
          label={'HRIS ID'}
          placeholder={'000000'}
          inputType="mask"
          mask="999999"
          required
        />
        <div className={classes.fields}>
          <FormikInput
            name="hireDate"
            label={'Hire Date'}
            placeholder={'00/00/0000'}
            inputType="mask"
            mask="99/99/9999"
            required
          />
          <FormikInput
            name="terminationDate"
            label={'Termination Date'}
            placeholder={'00/00/0000'}
            inputType="mask"
            mask="99/99/9999"
          />
        </div>
      </div>
      <Line />
      <div className={classes.fields}>
        <FormikSelect
          data={roleOptionsWithGroup}
          name="userRole"
          label={'User Role'}
          placeholder="Select One"
          required
        />
        <FormikInput
          name="jobTitle"
          label={'Job Title'}
          placeholder={'Job Title'}
          required
        />
      </div>
      <div className={classes.fields}>
        <FormikSelect
          data={ORGANIZATION_OPTIONS_TRIM}
          name="organization"
          label={'Organization'}
          placeholder="Select One"
          required
        />
        <FormikSelect
          data={supervisorOptions}
          searchable
          name="supervisor"
          label={'Supervisor'}
          placeholder="Select One"
          required={!isSupervisorOptional}
        />
      </div>
      <div className={classes.fields}>
        <FormikDropdown
          items={departmentOptions}
          placeholder={'Select One'}
          label="Department"
          name="department"
          placeholderGray
          errorOverlay
          radius="md"
          newEntity="department"
          insideModalId={drawerId}
          position="top-start"
          isSingleSelected
          newEntityObj={{
            placeholder: 'New department',
            button: 'Add Department',
          }}
        />
      </div>
      <Line />
      <div className={classes.fields}>
        <FormikInput
          name="email"
          label={'Email'}
          placeholder={'example@address.com'}
          required
        />
        <FormikInput
          name="cellPhone"
          label="Cell Phone"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="cell-phone"
        />
      </div>
      <div className={classes.fields}>
        <FormikInput
          name="officePhone"
          label="Office Phone"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="office-phone"
        />
        <FormikInput
          name="faxNumber"
          label="Fax Number"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="fax-number"
        />
      </div>
    </div>
  );
};

export default UserEdit;
