import cn from 'classnames';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BuildingSvg } from 'src/assets/icons/Building.svg';
import { ReactComponent as ClockSvg } from 'src/assets/icons/Clock.svg';
import { ReactComponent as FolderSvg } from 'src/assets/icons/Folder.svg';
import { ReactComponent as HelpSvg } from 'src/assets/icons/Help.svg';
import { ReactComponent as HomeSvg } from 'src/assets/icons/Home.svg';
import { ReactComponent as LinkSvg } from 'src/assets/icons/Link.svg';
import { ReactComponent as MenuCollapseSvg } from 'src/assets/icons/MenuCollapse.svg';
import { ReactComponent as MenuExpandSvg } from 'src/assets/icons/MenuExpand.svg';
import { ReactComponent as StackSvg } from 'src/assets/icons/Stack.svg';
import { ReactComponent as UserManagementSvg } from 'src/assets/icons/UserManagement.svg';
import { ReactComponent as UsersSvg } from 'src/assets/icons/Users.svg';
import { ReactComponent as VersionsSvg } from 'src/assets/icons/Versions.svg';
import { Icon } from 'src/components';
import { Logo, NavItem, Navigation } from 'src/components/Page';
import {
  BETA_LINKS,
  BETA_LINKS_ORDERED,
  DEFAULT_URL,
} from 'src/helpers/constants';
import { NAV_COLLAPSE_URLS } from 'src/helpers/constants/urls';
import {
  useCollapse,
  useCurrentPath,
  useCurrentRole,
  useMedia,
} from 'src/helpers/hooks';
import classes from './Main.module.scss';
import getPermission from './permissions';
import { filterNavs, sortMenus } from './utils';

interface Props {
  children: React.ReactNode;
}

const Information = () => {
  return (
    <div className={classes.information}>
      <span>Unit 214 | John Johnson</span>
      <span>File HUD Packet Extension 231</span>
      <span>Search: Northwest region...</span>
    </div>
  );
};

export const Menu = ({ collapse, className = '' }) => {
  const isMobile = useMedia('(max-width: 480px)');
  const hideInfo = true;
  // Navigation multiple languages at common module
  const { t } = useTranslation(['common']);
  const role = useCurrentRole();
  const permission = getPermission(role);
  const permissionUrls = permission.urls || [];
  const permissionExcludeUrls = permission.excludeUrls || [];

  const menus: NavItem[] = [
    {
      id: 'home',
      icon: <HomeSvg />,
      label: t('navigation.home', { ns: 'common' }),
      link: '/',
      tooltip: t('navigation.home', { ns: 'common' }),
    },
    {
      id: 'in-out-board',
      icon: <ClockSvg />,
      label: t('navigation.inoutBoard', { ns: 'common' }),
      link: '/in-out-board',
      tooltip: t('navigation.inoutBoard', { ns: 'common' }),
    },
    {
      id: 'certifications',
      icon: <StackSvg />,
      label: t('navigation.certifications', { ns: 'common' }),
      link: '/certifications',
      tooltip: t('navigation.certifications', { ns: 'common' }),
      activeLinks: [
        '/certifications-list',
        '/certification-builder',
        '/new-certification',
        '/notices',
        '/upload-notice',
      ],
    },
    {
      id: 'workflows',
      icon: <VersionsSvg />,
      label: t('navigation.workflows', { ns: 'common' }),
      link: '/workflows',
      tooltip: t('navigation.workflows', { ns: 'common' }),
      activeLinks: [
        '/incident-reporting',
        '/new-report',
        '/incident-reporting/:id',
      ],
    },
    {
      id: 'my-properties',
      icon: <BuildingSvg />,
      iconFill: true,
      label: t('navigation.myProperties', { ns: 'common' }),
      link: '/my-properties',
      tooltip: t('navigation.myProperties', { ns: 'common' }),
    },
    {
      id: 'my-residents',
      icon: <UsersSvg />,
      iconFill: true,
      label: t('navigation.myResidents', { ns: 'common' }),
      link: '/my-residents',
      tooltip: t('navigation.myResidents', { ns: 'common' }),
    },
    {
      id: 'user-management',
      icon: <UserManagementSvg />,
      label: t('navigation.userManagement', { ns: 'common' }),
      link: '/user-management',
      tooltip: t('navigation.userManagement', { ns: 'common' }),
      mobileHide: true,
    },
    {
      id: 'documents',
      icon: <FolderSvg />,
      label: t('navigation.documents', { ns: 'common' }),
      link: '/documents',
      tooltip: t('navigation.documents', { ns: 'common' }),
      activeLinks: ['/documents/recently-deleted', '/documents/folders/:id'],
    },
  ];
  const menus1: NavItem[] = [
    {
      id: 'resource-tool',
      label: t('navigation.resourceTool', { ns: 'common' }),
    },
    {
      id: 'help',
      icon: <HelpSvg />,
      label: t('navigation.help', { ns: 'common' }),
      link: '/help',
      tooltip: t('navigation.help', { ns: 'common' }),
    },
    {
      id: 'resource-links',
      icon: <LinkSvg />,
      label: t('navigation.resourceLinks', { ns: 'common' }),
      link: '/resource-links',
      tooltip: t('navigation.resourceLinks', { ns: 'common' }),
      notAvailable: true,
    },
  ];
  const menus2: NavItem[] = [
    {
      id: 'recents',
      label: t('navigation.recents', { ns: 'common' }),
      additionalInfo: <Information />,
    },
  ];
  return (
    <div
      className={cn(
        classes.nav,
        {
          [classes.hideInfo]: hideInfo,
        },
        className
      )}
    >
      <Navigation
        items={filterNavs(
          sortMenus(menus, BETA_LINKS_ORDERED, BETA_LINKS),
          permissionUrls,
          permissionExcludeUrls,
          isMobile
        )}
        collapse={collapse}
      />
      {!hideInfo && (
        <Navigation
          items={filterNavs(menus2, permissionUrls, permissionExcludeUrls)}
          className={classes.middleNav}
          collapse={collapse}
        />
      )}
      <Navigation
        items={filterNavs(menus1, permissionUrls, permissionExcludeUrls)}
        collapse={collapse}
      />
    </div>
  );
};

const MainLayout: React.FC<Props> = ({ children }) => {
  const isSmallerDesktop = useMedia('(max-width: 1024px)');
  const isTablet = useMedia('(max-width: 768px)');
  const { collapse, setCollapse } = useCollapse();
  const role = useCurrentRole();
  const permission = getPermission(role);
  const currentPath = useCurrentPath();

  useEffect(() => {
    if (isSmallerDesktop) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallerDesktop]);
  useEffect(() => {
    if (NAV_COLLAPSE_URLS.includes(currentPath)) {
      setCollapse(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);
  useEffect(() => {
    const { urls, excludeUrls } = permission;
    if (BETA_LINKS.length > 0) {
      if (!BETA_LINKS.includes(currentPath)) {
        window.location.href = DEFAULT_URL;
      }
    } else {
      if (!urls) {
        window.location.href = DEFAULT_URL;
      } else {
        if (
          (!urls.includes(currentPath) && urls.length !== 0) ||
          (excludeUrls || []).includes(currentPath)
        ) {
          window.location.href = DEFAULT_URL;
        }
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={cn(classes.wrapper, {
        [classes.collapse]: collapse,
      })}
      id="main-wrapper"
    >
      {!isTablet && (
        <div className={classes.left}>
          <div className={classes.logoBox}>
            <Logo className={classes.logo} />
            <Icon
              onClick={() => {
                setCollapse(!collapse);
              }}
            >
              {collapse ? <MenuCollapseSvg /> : <MenuExpandSvg />}
            </Icon>
          </div>
          <Menu collapse={collapse} />
        </div>
      )}
      <div className={classes.right}>{children}</div>
    </div>
  );
};

export default MainLayout;
