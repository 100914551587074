import cn from 'classnames';
import React from 'react';
import classes from './Modal.module.scss';
import Modal from './index';

type Props = {
  className?: string;
  isOpened: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const AlertModal = ({ className, children, isOpened, onClose }: Props) => {
  return (
    <Modal
      opened={isOpened}
      onClose={onClose}
      size={870}
      withCloseButton={false}
      trapFocus={false}
      className={cn(classes.alertModal, className)}
      withinPortal={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
    >
      <div className={classes.alertContent}>{children}</div>
    </Modal>
  );
};

export default AlertModal;
