import { FormikTextarea } from 'src/components/FormFields';
import NewReportSection from '../Section';

const DetailedDescription = ({
  isDisabled = false,
  values,
  onValuesChange,
}) => {
  return (
    <NewReportSection
      title="Detailed Description of Incident"
      onNoValue={() => {
        return !values.description;
      }}
    >
      <FormikTextarea
        name="description"
        placeholder="Description of Incident"
        maxLength={300}
        disabledOverlay={isDisabled}
        onChangeCb={onValuesChange}
      ></FormikTextarea>
    </NewReportSection>
  );
};

export default DetailedDescription;
