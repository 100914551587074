import * as authActions from 'src/store/actions/auth';
import * as inoutActions from 'src/store/actions/inout';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const profileStore = useReducerData('auth', 'meData', {});
  return {
    profile: profileStore.data,
  };
};

export const useActions = () => {
  return useStoreActions({
    ...authActions,
    ...inoutActions,
  });
};
