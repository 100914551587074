import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Filter, Tabs, Tasks } from 'src/components';
import { Dropdown } from 'src/components/FormFields';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import classes from './Home.module.scss';
import Loading from './Loading';
import ActionButton from './components/ActionButton';
import HorizontalList from './components/HorizontalList';
import VerticalList from './components/VerticalList';
import { tabs } from './constants';
import { useActions, useIndexData } from './selectorData';

const Home = () => {
  const navigate = useNavigate();
  const { getDashboard, readAlert } = useActions();
  const { myTasksData } = useIndexData();
  const isTasks = true;
  const hideFilter = true;
  const [activeTab, setActiveTab] = useState('Upcoming');
  const [property, setProperty] = useState([]);

  useEffect(() => {
    getDashboard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Home',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader title="Home">
          {!hideFilter && (
            <>
              <Dropdown
                dropdownClassName={classes.propertyDropdown}
                items={[
                  {
                    value: OPTION_ALL_VALUE,
                    label: 'Select All',
                  },
                  {
                    value: 'West Estates',
                    label: 'West Estates',
                  },
                  {
                    value: 'North Appartments',
                    label: 'North Appartments',
                  },
                  {
                    value: 'Hawthorn',
                    label: 'Hawthorn',
                  },
                  {
                    value: 'Tapestry Townhomes',
                    label: 'Tapestry Townhomes',
                  },
                ]}
                value={property}
                onChange={(val) => {
                  setProperty(val);
                }}
                placeholder="Property"
                position="bottom-end"
              />
              <Filter className={classes.filter} />
            </>
          )}
        </PageHeader>
        <PageContent className={classes.contentInner}>
          <Loading />
          <div className={cn(classes.col, classes.left)}>
            <Card
              title="My Tasks"
              // contentRight={<ActionButton>View All</ActionButton>}
              notPaddingContent
              paddingBottom
              className={classes.taskSection}
            >
              {isTasks ? (
                <div className={classes.tasks}>
                  <Tasks
                    items={myTasksData}
                    onClick={(item) => {
                      readAlert(Number(item.id));
                      navigate(`/incident-reporting/${item.source?.id}`);
                    }}
                  />
                </div>
              ) : (
                <Tabs
                  value={activeTab}
                  onTabChange={setActiveTab}
                  items={tabs}
                />
              )}
            </Card>
            <Card
              title="Maintenance & Inspections"
              titleBorder
              contentRight={<ActionButton>View More</ActionButton>}
              isComingSoon
            >
              <HorizontalList>
                <li>
                  <span>In Progress</span>
                  <span>187</span>
                </li>
                <li>
                  <span>On time</span>
                  <span>160</span>
                </li>
                <li>
                  <span>Due soon</span>
                  <span>28</span>
                </li>
                <li>
                  <span>Late</span>
                  <span>12</span>
                </li>
              </HorizontalList>
            </Card>
          </div>
          <div className={cn(classes.col, classes.right)}>
            <div className={classes.group}>
              <Card title="Occupancy" titleBorder isComingSoon>
                <VerticalList>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                </VerticalList>
                <div>
                  <ActionButton>View More</ActionButton>
                </div>
              </Card>
              <Card title="Rent & Payables" titleBorder isComingSoon>
                <VerticalList>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                  <li>
                    <span>Vacant</span>
                    <span>24</span>
                  </li>
                </VerticalList>
                <div>
                  <ActionButton>View More</ActionButton>
                </div>
              </Card>
            </div>
            <Card
              title="Applications & Move-ins"
              titleBorder
              contentRight={<ActionButton>View More</ActionButton>}
              isComingSoon
            >
              <HorizontalList>
                <li>
                  <span>In Progress</span>
                  <span>187</span>
                </li>
                <li>
                  <span>On time</span>
                  <span>160</span>
                </li>
                <li>
                  <span>Due soon</span>
                  <span>28</span>
                </li>
                <li>
                  <span>Late</span>
                  <span>12</span>
                </li>
              </HorizontalList>
            </Card>
            <Card
              title="Recertifications"
              titleBorder
              contentRight={<ActionButton>View More</ActionButton>}
              isComingSoon
            >
              <HorizontalList>
                <li>
                  <span>In Progress</span>
                  <span>187</span>
                </li>
                <li>
                  <span>On time</span>
                  <span>160</span>
                </li>
                <li>
                  <span>Due soon</span>
                  <span>28</span>
                </li>
                <li>
                  <span>Late</span>
                  <span>12</span>
                </li>
              </HorizontalList>
            </Card>
            <Card
              title="Move-Outs"
              titleBorder
              contentRight={<ActionButton>View More</ActionButton>}
              isComingSoon
            >
              <HorizontalList>
                <li>
                  <span>In Progress</span>
                  <span>187</span>
                </li>
                <li>
                  <span>On time</span>
                  <span>160</span>
                </li>
                <li>
                  <span>Due soon</span>
                  <span>28</span>
                </li>
                <li>
                  <span>Late</span>
                  <span>12</span>
                </li>
              </HorizontalList>
            </Card>
          </div>
        </PageContent>
      </MainContent>
    </>
  );
};

export default Home;
