import * as propertyActions from 'src/store/actions/property';
import * as userActions from 'src/store/actions/user';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const usersStore = useReducerData('user', 'users', {});
  return {
    usersData: usersStore.data || {},
    ivniteUserLoading: useReducerData('user', 'inviteUser.loading', false),
    editUserLoading: useReducerData('user', 'editUser.loading', false),
    getUserLoading: useReducerData('user', 'getUser.loading', false),
    getAssignedPropertiesLoading: useReducerData(
      'property',
      'userProperty.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...userActions,
    ...propertyActions,
  });
};
