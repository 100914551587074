import { Global } from '@mantine/core';

const GlobalStyle = () => {
  return (
    <Global
      styles={(theme) => ({
        '*, *::before, *::after': {
          boxSizing: 'border-box',
        },
        body: {
          color: theme.colors.primaryBlack[0],
          fontSize: 14,
        },
        h1: {
          fontWeight: 500,
          fontSize: 32,
          lineHeight: '155%',
          color: theme.colors.primaryBlack[0],
          margin: '0 0 16px 0',
        },
      })}
    />
  );
};

export default GlobalStyle;
