import { LoaderProps, Loader as ManLoader } from '@mantine/core';
import cn from 'classnames';
import classes from './Loader.module.scss';

type Props = LoaderProps & {
  className?: string;
};

const Loader = ({ className = '', ...other }: Props) => {
  return <ManLoader {...other} className={cn(classes.wrapper, className)} />;
};

export default Loader;
