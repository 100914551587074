import cn from 'classnames';
import { Avatar, Button, CardList, ContentCard } from 'src/components';
import { SegmentedControl, Select, Textarea } from 'src/components/FormFields';
import { STATUS } from 'src/helpers/constants';
import { IN_OUT_STATUS_OPTIONS } from 'src/helpers/constants/options';
import { getStatusUpdateDate } from 'src/utils/date';
import { displayName, intialName } from 'src/utils/text';
import classes from './YourStatus.module.scss';

const YourStatus = ({
  setting,
  onChange,
  onUpdate,
  profile,
  loading = false,
  onCancel = null,
  inModal = false,
}) => {
  const hasUpdated = !!profile?.status_updated_at;

  return (
    <CardList
      singleHeader
      headers={
        inModal
          ? null
          : [
              {
                key: 'property',
                label: 'Your Status',
              },
            ]
      }
      data={[
        {
          id: 1,
        },
      ]}
      renderContentCard={() => {
        return (
          <ContentCard
            type="profile"
            className={cn(classes.content, {
              [classes.inModal]: inModal,
            })}
          >
            <div>
              <Avatar
                size="md"
                circle
                isStatus
                isInactive={setting.activeStatus !== STATUS.ACTIVE.text}
                avtSrc={profile?.profile_image_path}
              >
                {intialName(profile?.first_name, profile?.last_name)}
              </Avatar>
              <span>
                {displayName(profile?.first_name, profile?.last_name)}
              </span>
            </div>
            <div>
              <Select
                data={IN_OUT_STATUS_OPTIONS}
                radius="md"
                label="Status"
                value={setting.status}
                onChange={(val) => {
                  onChange('status', val);
                }}
                placeholder="Status"
              />
            </div>
            <div>
              <Textarea
                radius="md"
                label="Note"
                autosize
                minRows={1}
                maxRows={4}
                value={setting.note || ''}
                onChange={(e) => {
                  onChange('note', e.target.value);
                }}
                placeholder="Note"
              />
            </div>
            <div>
              <SegmentedControl
                data={['Active', 'Inactive']}
                radius="md"
                size="xs"
                isStatus
                value={setting.activeStatus}
                onChange={(val) => {
                  onChange('activeStatus', val);
                }}
                color={
                  setting.activeStatus === STATUS.ACTIVE.text ? 'green' : 'red'
                }
              />
            </div>
            <div
              className={cn(classes.bottom, {
                [classes.fullWidth]: !hasUpdated && !inModal,
                [classes.hasCancel]: !!onCancel,
              })}
            >
              {hasUpdated && !onCancel && (
                <span>
                  Updated: {getStatusUpdateDate(profile.status_updated_at)}
                </span>
              )}
              {!!onCancel && (
                <Button
                  variant="white"
                  radius="sm"
                  textWeight="xl"
                  onClick={onCancel}
                  color="red"
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>
              )}
              <Button onClick={onUpdate} loading={loading}>
                Update
              </Button>
            </div>
          </ContentCard>
        );
      }}
    />
  );
};

export default YourStatus;
