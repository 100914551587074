import cn from 'classnames';
import React from 'react';
import { Card } from 'src/components';

interface Props {
  title: string;
  children: React.ReactNode;
  required?: boolean;
  className?: string;
  onNoValue?: () => boolean;
  onTitleNoValue?: () => boolean;
}

const NewReportSection = ({
  title,
  children,
  required,
  className,
  onNoValue,
  onTitleNoValue,
}: Props) => {
  return (
    <Card
      title={title}
      contentGap={16}
      notPaddingContentTop
      contentPaddingBottom={0}
      required={required}
      className={cn(className, {
        'i-print-no-value': !!onNoValue && onNoValue(),
      })}
      titleClassName={cn({
        'i-print-no-value': !!onTitleNoValue && onTitleNoValue(),
      })}
    >
      {children}
    </Card>
  );
};

export default NewReportSection;
