import { Request } from './request';

export const apiGetAllProperties = async (data = {}) => {
  return Request.call({
    url: '/property',
    method: 'POST',
    data,
  });
};
export const apiGetUserProperty = async (userId) => {
  return Request.call({
    url: `/property/access/${userId}`,
    method: 'GET',
    params: {},
  });
};
export const apiGetPropertyFilters = async () => {
  return Request.call({
    url: `/property/filters`,
    method: 'GET',
    params: {},
  });
};
export const apiAssignUserProperty = async (userId, data = {}) => {
  return Request.call({
    url: `/property/access/${userId}`,
    method: 'POST',
    data,
  });
};
export const apiRemoveUserProperty = async (userId, data = {}) => {
  return Request.call({
    url: `/property/access/${userId}`,
    method: 'DELETE',
    data,
  });
};
