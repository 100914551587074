import { Drawer, DrawerPosition } from '@mantine/core';
import cn from 'classnames';
import classes from './DocumentDrawer.module.scss';

interface Props {
  opened: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  position?: DrawerPosition;
  id?: string;
}

const DocumentDrawer = ({
  className,
  opened,
  onClose,
  children,
  position = 'right',
  id,
}: Props) => {
  return (
    <Drawer
      opened={opened}
      size={662}
      onClose={onClose}
      position={position}
      withCloseButton={false}
      className={cn(classes.wrapper, className)}
      overlayOpacity={0}
      id={id}
    >
      {children}
    </Drawer>
  );
};

export default DocumentDrawer;
