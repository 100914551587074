import { useState } from 'react';
import { ReactComponent as File1Svg } from 'src/assets/icons/File1.svg';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import { Button } from 'src/components';
import { Input } from 'src/components/FormFields';
import { CERT_FIELD_NAME } from 'src/pages/ResidentDetail/constants';
import { getFieldByKey } from 'src/pages/ResidentDetail/utils';
import { getDPDate } from 'src/utils/date';
import AddNoticeModal from '../AddNoticeModal';
import ContentBlock from '../ContentBlock';
import UploadFileBtn from '../UploadFileBtn';
import classes from './NoticeBlock.module.scss';

const FORM_NAME = {
  DATE: 'interview_date',
  TIME: 'interview_time',
};

const NoticeBlock = ({
  fields,
  values,
  onUpdateInterviewDate,
  onViewFile,
  onAddNotice,
  addNoticeLoading,
  onUploadFile,
  checkboxObj = null,
  isWorkflow = false,
}) => {
  const schemaFieldIdKey = isWorkflow
    ? 'schema_field_id'
    : 'certificate_schema_field_id';
  const [uploadNFLoading, setUploadNFLoading] = useState({});
  const [updateISLoading, setUpdateISLoading] = useState(false);
  const [isAddNotice, setIsAddNotice] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formValues, setFormValues] = useState({
    [FORM_NAME.DATE]: '',
    [FORM_NAME.TIME]: '',
  });
  const title = getFieldByKey(fields, 'Name', 'value');
  const tooltip = getFieldByKey(fields, 'Tooltip', 'value');
  const noticeOptionId = getFieldByKey(fields, 'Notice Options', 'id');
  const noticeOptionUploadFile =
    getFieldByKey(fields, 'Notice Options', 'can_upload_file') || false;
  const noticeOptionObjs = (values || []).filter(
    (v) => v[schemaFieldIdKey] === noticeOptionId
  );
  const noticeOptionExistingValues = noticeOptionObjs.map((n) => n.value);
  const noticeOptionValues = (
    getFieldByKey(fields, 'Notice Options', 'value') || []
  ).filter((v) => !noticeOptionExistingValues.includes(v));
  const scheduledInterviewId = getFieldByKey(
    fields,
    'Scheduled Interview Date Input',
    'id'
  );
  const scheduledInterviewTrigger = getFieldByKey(
    fields,
    'Scheduled Interview Date Input',
    'value'
  );
  const scheduledInterviewValue = getFieldByKey(
    values,
    scheduledInterviewId,
    'value',
    schemaFieldIdKey
  );
  const scheduledInterviewValueId = getFieldByKey(
    values,
    scheduledInterviewId,
    'id',
    schemaFieldIdKey
  );
  const toggleUploadLoading = (id, val) => {
    setUploadNFLoading({
      ...uploadNFLoading,
      [id]: val,
    });
  };

  return (
    <>
      <ContentBlock
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        title={title}
        tooltip={tooltip}
        checkboxObj={checkboxObj}
        toggleContent={
          <div className={classes.noticeOptions}>
            {noticeOptionObjs.map((noObj) => {
              const noFiles = noObj.files || [];
              return (
                <ContentBlock
                  key={noObj.id}
                  title={`${noObj?.value} Notice`}
                  dontToggle
                  headerAdditional={
                    <div className={classes.noticeOptionHeaderAdditional}>
                      <span>
                        {getDPDate(noObj.updated_at || noObj.created_at, true)}
                      </span>
                      {noFiles.length > 0 && (
                        <Button
                          variant="default"
                          radius="md"
                          onClick={() => {
                            onViewFile(noFiles, noObj);
                          }}
                          leftIcon={<File1Svg />}
                          size="sm"
                        >
                          View File
                        </Button>
                      )}
                      {!!noticeOptionUploadFile && (
                        <UploadFileBtn
                          title="Upload File"
                          onChange={onUploadFile(
                            {
                              valueId: noObj.id,
                              fieldId: noticeOptionId,
                              noticeValue: noObj.value,
                            },
                            toggleUploadLoading
                          )}
                          loading={uploadNFLoading?.[noObj.id]}
                          multiple
                        />
                      )}
                    </div>
                  }
                />
              );
            })}
            <div className={classes.addNotice}>
              {noticeOptionValues.length > 0 ? (
                <Button
                  variant="default"
                  radius="md"
                  onClick={() => {
                    setIsAddNotice(true);
                  }}
                  leftIcon={<PlusSvg />}
                  size="sm"
                >
                  Add Notice
                </Button>
              ) : (
                <div></div>
              )}
              {!!scheduledInterviewTrigger && (
                <div className={classes.addInterview}>
                  <Input
                    label="Interview Date:"
                    inputType="mask"
                    mask="99/99/9999"
                    placeholder="00/00/0000"
                    autoComplete="date"
                    value={formValues[FORM_NAME.DATE]}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        [FORM_NAME.DATE]: e.target.value,
                      })
                    }
                    layout="horizontal"
                    size="xs"
                    radius="md"
                  />
                  <Input
                    label="Time:"
                    inputType="mask"
                    mask="99:99"
                    autoComplete="time"
                    placeholder="00:00"
                    value={formValues[FORM_NAME.TIME]}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        [FORM_NAME.TIME]: e.target.value,
                      })
                    }
                    layout="horizontal"
                    size="xs"
                    radius="md"
                  />
                  <Button
                    radius="md"
                    onClick={() => {
                      onUpdateInterviewDate(
                        {
                          ...formValues,
                          fieldId: scheduledInterviewId,
                          valueId: scheduledInterviewValueId,
                          fieldName: CERT_FIELD_NAME.NOTICE_SCHEDULE_INTERVIEW,
                        },
                        () => {
                          setFormValues({
                            [FORM_NAME.DATE]: '',
                            [FORM_NAME.TIME]: '',
                          });
                          setIsOpen(false);
                        },
                        setUpdateISLoading
                      );
                    }}
                    size="sm"
                    loading={updateISLoading}
                  >
                    Add
                  </Button>
                </div>
              )}
            </div>
          </div>
        }
        headerAdditional={
          scheduledInterviewValue ? (
            <div className={classes.scheduledInterview}>
              <span>Scheduled Interview Date:</span>
              <span>{scheduledInterviewValue}</span>
            </div>
          ) : undefined
        }
      />
      <AddNoticeModal
        opened={isAddNotice}
        onClose={() => {
          setIsAddNotice(false);
        }}
        options={noticeOptionValues}
        onSubmit={(val) => {
          onAddNotice(
            {
              fieldId: noticeOptionId,
              value: val,
            },
            () => {
              setIsAddNotice(false);
            }
          );
        }}
        loading={addNoticeLoading}
      />
    </>
  );
};

export default NoticeBlock;
