import cn from 'classnames';
import { Modal } from 'src/components';
import classes from './DocumentModal.module.scss';

const DocumentModal = ({
  className = '',
  isOpened,
  onClose,
  size,
  title,
  children,
  ...other
}) => {
  return (
    <Modal
      {...other}
      opened={isOpened}
      onClose={onClose}
      title={title}
      size={size}
      className={cn(classes.modalWrapper, className)}
      centered
    >
      {children}
    </Modal>
  );
};

export default DocumentModal;
