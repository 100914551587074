import { Form, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import {
  BETA_DISABLED,
  CANT_BUILD_CERTIFICATE_ROLES,
} from 'src/helpers/constants';
import { useCurrentPath, useOptions } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import classes from './BuildCertificate.module.scss';
import Loading from './Loading';
import FlowBuilder from './components/FlowBuilder';
import FlowInformation from './components/FlowInformation';
import Footer from './components/Footer';
import StatusOptions from './components/StatusOptions';
import { yupSchema, yupSchemaWorkflow } from './constants';
import { useActions, useIndexData } from './selectorData';
import {
  getAnySectionIsOpened,
  getBuildCertificateBR,
  getInitialValues,
} from './utils';

const BuildCertificate = () => {
  const {
    certificationTypeOptions,
    certificationProgramOptions,
    certificationPermissionOptions,
    certificationLocationOptions,
    certificationStatusOptions,
  } = useOptions();
  const params = useParams();
  const certificateId = params.id ? parseInt(params.id, 10) : '';
  const currentPath = useCurrentPath();
  const isEditCertificate = currentPath === '/certificate/:id';
  const isWorkflow =
    currentPath === '/workflow-builder' || currentPath === '/workflow/:id';
  const isEditWorkflow = currentPath === '/workflow/:id';
  const tIsEditCertificate = isEditCertificate || isEditWorkflow;
  const navigate = useNavigate();
  const formRef = useRef(null);
  const {
    buildCertificate,
    getCertificateDetail,
    getWorkflowBuilderDetail,
    getCertificationTypes,
    deleteCertificateSection,
    getWorkflowTypes,
    getCertBuilderDropdown,
  } = useActions();
  const { buildCertificateLoading, certDetail, workflowDetail, currentUser } =
    useIndexData();
  const tCertDetail = isWorkflow ? workflowDetail : certDetail;

  const reloadCertiicateDetail = (tId) => {
    getCertificateDetail(tId);
  };
  const reloaWorkflowBuilderDetail = (tId) => {
    getWorkflowBuilderDetail(tId);
  };
  const handleDeleteExistingSection = (tId, cb) => {
    deleteCertificateSection(tId, isWorkflow ? 'workflow' : '', cb);
  };
  const getPageTitle = () => {
    if (isWorkflow) {
      if (isEditWorkflow) {
        return `Edit ${tCertDetail.name || '[Worlflow name]'}`;
      }
      return 'New Workflow';
    }
    if (isEditCertificate) {
      return `Edit ${tCertDetail.name || '[Certificate name]'}`;
    }
    return 'New Certification Flow';
  };
  const getBreadcrumb = () => {
    if (isWorkflow) {
      return [
        {
          label: 'Workflows',
          link: '/workflows',
        },
        {
          label: isEditWorkflow
            ? tCertDetail.name || '[Certificate name]'
            : 'Workflow Builder',
        },
      ];
    }
    return [
      {
        label: 'Certifications',
        link: '/certifications',
      },
      {
        label: isEditCertificate
          ? tCertDetail.name || '[Certificate name]'
          : 'Certification Builder',
      },
    ];
  };

  const renderForm = (formProps) => {
    const anySectionIsOpened = getAnySectionIsOpened(formProps.values);
    return (
      <Form className={classes.form} onChange={() => {}}>
        <PageContent vertical paddingTop="sm" gap="sm">
          <StatusOptions formProps={formProps} isWorkflow={isWorkflow} />
          <FlowInformation formProps={formProps} isWorkflow={isWorkflow} />
          <FlowBuilder
            formProps={formProps}
            onDeleteExistingSection={handleDeleteExistingSection}
          />
          <Footer
            isSubmitDisabled={anySectionIsOpened}
            loading={buildCertificateLoading}
            isEditCertificate={isEditCertificate}
            formProps={formProps}
            isWorkflow={isWorkflow}
          />
        </PageContent>
      </Form>
    );
  };

  const loadDropdown = () => {
    const tRole = currentUser?.roles?.[0]?.name;
    if (!CANT_BUILD_CERTIFICATE_ROLES.includes(tRole) && !BETA_DISABLED) {
      getCertBuilderDropdown(isWorkflow ? 'workflow' : 'certificate');
    }
  };

  useEffect(() => {
    if (isEditCertificate) {
      reloadCertiicateDetail(certificateId);
    }
    if (isEditWorkflow) {
      reloaWorkflowBuilderDetail(certificateId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [certificateId]);
  useEffect(() => {
    loadDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader breadcrumb={getBreadcrumb()} />
      <MainContent flex contentFullHeight className={classes.mainContent}>
        <Loading />
        <PageHeader title={getPageTitle()} />
        <Formik
          initialValues={getInitialValues(
            tIsEditCertificate ? tCertDetail : {},
            {
              permissionOptions: certificationPermissionOptions,
              typeOptions: certificationTypeOptions,
              programOptions: certificationProgramOptions,
              locationOptions: certificationLocationOptions,
              statusOptions: certificationStatusOptions,
              isWorkflow,
            }
          )}
          validateOnMount
          validationSchema={isWorkflow ? yupSchemaWorkflow : yupSchema}
          innerRef={formRef}
          enableReinitialize
          onSubmit={(values) => {
            const br = getBuildCertificateBR(
              values,
              tIsEditCertificate ? certificateId : '',
              { isWorkflow }
            );
            buildCertificate(
              br,
              actionCb(
                () => {
                  if (isWorkflow) {
                    getWorkflowTypes();
                  } else {
                    getCertificationTypes();
                  }
                  navigate(isWorkflow ? '/workflows' : '/certifications');
                },
                null,
                `${isWorkflow ? 'Workflow' : 'Certificate'} Builder failed!`
              ),
              isWorkflow ? 'workflow' : ''
            );
          }}
        >
          {renderForm}
        </Formik>
      </MainContent>
    </>
  );
};

export default BuildCertificate;
