import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from 'src/pages/NewReport/components/Section';

const Payee = ({ isDisabled = false }) => {
  return (
    <NewReportSection title="Payee">
      <FormCardContent column={4}>
        <FormikInput
          name="payee_first_last_name"
          label="First and Last Name"
          placeholder="First and Last Name"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="payee_address"
          label="Address"
          placeholder="Address"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="payee_phone"
          label="Phone Number"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="payee_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="payee_email"
          label="Email Address"
          placeholder="Email Address"
          type="email"
          autoComplete="payee_email"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default Payee;
