import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { ReactComponent as InfoCircleSvg } from 'src/assets/icons/InfoCircle.svg';
import { Arrow, Collapse, Tooltip } from 'src/components';
import { Checkbox } from 'src/components/FormFields';
import { SectionCheckboxObj } from 'src/types';
import classes from './ContentBlock.module.scss';

interface Props {
  title?: string;
  tooltip?: string;
  dontToggle?: boolean;
  children?: React.ReactNode;
  className?: string;
  toggleContentIsChildren?: boolean;
  onToggle?: (val: boolean) => void;
  toggleContent?: React.ReactNode;
  headerAdditional?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen?: (val: boolean) => void;
  alwaysShowHA?: boolean;
  checkboxObj?: SectionCheckboxObj;
  titlePrimary?: boolean;
  borderColor?: string;
}

const ContentBlock = ({
  title,
  tooltip,
  dontToggle,
  children,
  className,
  toggleContentIsChildren,
  onToggle,
  toggleContent,
  headerAdditional,
  isOpen,
  setIsOpen,
  alwaysShowHA,
  checkboxObj,
  titlePrimary,
  borderColor,
}: Props) => {
  const hasCheckbox = !isEmpty(checkboxObj);
  const disableToggle = !!dontToggle;
  const checked = !!checkboxObj?.checked;
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.titlePrimary]: !checked && titlePrimary,
          [classes.isCompleted]: checked,
        },
        classes[`border_color_${checked ? '' : borderColor}`],
        className
      )}
    >
      {children || (
        <>
          <div className={classes.header}>
            <div className={classes.text}>
              {hasCheckbox && (
                <Checkbox
                  checked={checked}
                  onChange={(e) => {
                    if (checkboxObj?.onChange) {
                      checkboxObj.onChange(e.target.checked);
                    }
                  }}
                  pointer
                  radius="sm"
                  color="primary"
                  className={classes.checkbox}
                />
              )}

              {!!title && <span className={classes.title}>{title}</span>}
              {tooltip ? (
                <Tooltip
                  label={tooltip}
                  color="dark"
                  // withArrow
                  transitionDuration={200}
                  position="top-start"
                >
                  <InfoCircleSvg />
                </Tooltip>
              ) : (
                ''
              )}
            </div>
            {(!isOpen || alwaysShowHA) && headerAdditional}
            {!dontToggle && (
              <Arrow
                direction={isOpen ? 'top' : 'bottom'}
                className={classes.arrow}
                onClick={() => {
                  if (!disableToggle) {
                    if (setIsOpen) setIsOpen(!isOpen);
                    if (onToggle) onToggle(!isOpen);
                  }
                }}
              />
            )}
          </div>
          {!toggleContentIsChildren && !!toggleContent && (
            <Collapse in={isOpen} className={classes.collapse}>
              {toggleContent}
            </Collapse>
          )}
        </>
      )}
    </div>
  );
};

export default ContentBlock;
