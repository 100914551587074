import { Dispatch } from 'redux';
import {
  apiCheckDuplicateFiles,
  apiCreateFolder,
  apiDeleteDocument,
  apiDuplicateFolder,
  apiGenrateDocumentUrl,
  apiGetDocumentDetail,
  apiGetDocuments,
  apiMoveDocument,
  apiUpdateDocument,
  apiUploadFiles,
} from 'src/api';
import Types from '../types/document';

export const getFolders =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_FOLDERS,
      asyncCall: () => {
        return apiGetDocuments({
          ...params,
          folders_only: true,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getDocuments =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_DOCUMENTS,
      asyncCall: () => {
        return apiGetDocuments({
          ...other,
          recent_files: true,
          folders_only: false,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getFolderDocuments =
  (id, params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, keepData, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore, keepData },
      type: Types.GET_FOLDER_DOCUMENTS,
      asyncCall: () => {
        return apiGetDocuments({
          ...other,
          directory_id: id,
          // recent_files: false,
          // folders_only: false,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getRecentlyDeletedDocuments =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_RECENTLY_DELETED_DOCUMENTS,
      asyncCall: () => {
        return apiGetDocuments({
          ...other,
          recently_deleted: true,
          // recent_files: false,
          // folders_only: false,
        });
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getDocumentDetail =
  (id, params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_DOCUMENT_DETAIL,
      asyncCall: () => {
        return apiGetDocumentDetail(id, params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const genrateDocumentUrl =
  (id, params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GENRATE_DOCUMENT_URL,
      asyncCall: () => {
        return apiGenrateDocumentUrl(id, params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const createFolder = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CREATE_FOLDER,
    asyncCall: () => {
      return apiCreateFolder(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const uploadFiles = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPLOAD_FILES,
    asyncCall: () => {
      return apiUploadFiles(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const updateDocument = (id, data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_DOCUMENT,
    asyncCall: () => {
      return apiUpdateDocument(id, data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const deleteDocument = (id, data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_DOCUMENT,
    asyncCall: () => {
      return apiDeleteDocument(id, data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const checkDuplicateFiles = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.CHECK_DUPLICATE_FILES,
    asyncCall: () => {
      return apiCheckDuplicateFiles(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const moveDocument = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.MOVE_DOCUMENT,
    asyncCall: () => {
      return apiMoveDocument(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const duplicateFolder = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DUPLICATE_FOLDER,
    asyncCall: () => {
      return apiDuplicateFolder(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
