import { Field } from 'formik';
import get from 'lodash/get';
import { LOCATION_FIELD_SUFFIX } from 'src/helpers/constants';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import Dropdown, { ODropdownProps } from '../Dropdown';

type Props = ODropdownProps & {
  forceError?: string | boolean;
  name: string;
  onChangeCb?: (val) => void;
};

const FormikDropdown = (props: Props) => {
  const {
    name,
    forceError = false,
    onChangeCb,
    isFileLocation,
    isSingleSelected,
    ...otherProps
  } = props;
  const firstItem = otherProps.items?.[0];
  const isEmptyItems =
    otherProps.items?.length === 1 &&
    firstItem?.value === DEFAULT_ALL_OPTION.value &&
    firstItem?.label === DEFAULT_ALL_OPTION.label;

  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { touched, errors, setFieldValue, values } = form;
        const value = field.value;
        const errorMessage = get(errors, field.name);
        return (
          <Dropdown
            {...otherProps}
            {...field}
            onChange={(val, otherObj) => {
              if (isFileLocation) {
                setFieldValue(
                  `${name}${LOCATION_FIELD_SUFFIX}`,
                  get(otherObj, 'idPath')
                );
                setFieldValue(name, get(otherObj, 'namePath'));
              } else {
                setFieldValue(name, val.length === 0 ? null : val);
              }
              if (onChangeCb) onChangeCb(val);
            }}
            value={value || (isSingleSelected ? '' : [])}
            pathValue={String(
              get(values, `${name}${LOCATION_FIELD_SUFFIX}`) || ''
            )}
            error={
              (get(touched, field.name) && !!errorMessage
                ? errorMessage
                : '') || forceError
            }
            errorBg={!!forceError}
            isFileLocation={isFileLocation}
            isSingleSelected={isSingleSelected}
            dropdownId={name}
            items={isEmptyItems ? [] : otherProps.items}
          />
        );
      }}
    </Field>
  );
};

export default FormikDropdown;
