import { Form, Formik } from 'formik';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Card } from 'src/components';
import {
  MainContent,
  PageContent,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { actionCb } from 'src/utils/action';
import { testDateField, testDateRequired } from 'src/utils/form';
import classes from './NewCertification.module.scss';
import Footer from './components/Footer';
import RelevantDates from './components/RelevantDates';
import ResidentInformation from './components/ResidentInformation';
import { useActions, useIndexData } from './selectorData';
import { getCertificationBR, getInitialValues } from './utils';

const NewCertification = () => {
  const navigate = useNavigate();
  const { addCertification } = useActions();
  const { addCertificationLoading } = useIndexData();

  const schema = Yup.object().shape({
    property_id: Yup.string().required('This field is required!'),
    certificate_schema_id: Yup.string().required('This field is required!'),
    property_unit_id: Yup.array()
      .nullable()
      .min(1, 'This field is required!')
      .required('This field is required!'),
    effective_date: Yup.string()
      .test(
        'required-effective-date',
        'This field is required!',
        testDateRequired
      )
      .test('invalid-effective-date', 'Invalid Date', testDateField),
    response_date: Yup.string().test(
      'invalid-response-date',
      'Invalid Date',
      testDateField
    ),
  });
  const formRef = useRef(null);

  const renderForm = (formProps) => {
    return (
      <Form className={classes.form} onChange={() => {}}>
        <PageContent>
          <Card
            contentGap={16}
            notPaddingContentTop
            contentPaddingBottom={0}
            className={classes.formCard}
            form
          >
            <ResidentInformation formProps={formProps} />
            <RelevantDates formProps={formProps} />
            <Footer loading={addCertificationLoading} />
          </Card>
        </PageContent>
      </Form>
    );
  };
  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Certifications',
            link: '/certifications',
          },
          {
            label: 'Certifications List',
            link: '/certifications-list',
          },
          {
            label: 'Start a Certification',
          },
        ]}
      />
      <MainContent flex contentFullHeight className={classes.mainContent}>
        <PageHeader title={'Start a Certification'} />
        <Formik
          initialValues={getInitialValues()}
          validateOnMount
          validationSchema={schema}
          innerRef={formRef}
          enableReinitialize
          onSubmit={(values) => {
            const br = getCertificationBR(values);
            addCertification(
              br,
              actionCb(
                () => {
                  navigate('/certifications-list');
                },
                null,
                'Start Certification Error!'
              )
            );
          }}
        >
          {renderForm}
        </Formik>
      </MainContent>
    </>
  );
};

export default NewCertification;
