import { Tooltip as ManTooltip, TooltipProps } from '@mantine/core';
import cn from 'classnames';
import classes from './Tooltip.module.scss';

type Props = TooltipProps & {
  className?: string;
};

const Tooltip = ({ className = '', ...other }: Props) => {
  return <ManTooltip {...other} className={cn(classes.wrapper, className)} />;
};

export default Tooltip;
