import get from 'lodash/get';
import { FIELD_PARSE_KEY, PATTERN } from 'src/helpers/constants';
import { getDPDate, isValidDate, isValidTime } from 'src/utils/date';

export const isValidFormatField = (value) => {
  return !(value || '').includes('_');
};
const trimFormatValue = (val) => {
  if (!val) return '';
  return val
    .replace(/\//g, '')
    .replace(/-/g, '')
    .replace(/_/g, '')
    .replace(/:/g, '')
    .replace(/\)/g, '')
    .replace(/\(/g, '');
};
export const testFormatField = (value) => {
  return (
    !trimFormatValue(value) ||
    (!!trimFormatValue(value) && isValidFormatField(value))
  );
};
export const testTimeField = (value) => {
  return (
    !trimFormatValue(value) || (!!trimFormatValue(value) && isValidTime(value))
  );
};
export const testTime12Field = (value) => {
  const valArr = (value || '').split(':');
  const intHour = parseInt(valArr[0], 10);
  return !value || value === '__:__' || (intHour >= 0 && intHour <= 12);
};
export const testDateField = (value) => {
  return (
    !trimFormatValue(value) || (!!trimFormatValue(value) && isValidDate(value))
  );
};
export const testDateRequired = (value) => {
  return !!trimFormatValue(value);
};
export const testFormatRequireField = (value) => {
  return !!trimFormatValue(value);
};
export const testNamePatternField = (value) => {
  return PATTERN.NAME.test(value);
};
export const cleanDateValue = (val) => {
  if (!val) return '';
  const valArr = val.split('/');
  return `${valArr[2]}-${valArr[0]}-${valArr[1]}`;
};
export const cleanPayloadValue = (val, key) => {
  const keyFnMapping = {
    [FIELD_PARSE_KEY.DATE]: cleanDateValue,
    [FIELD_PARSE_KEY.FORMAT]: trimFormatValue,
  };
  return keyFnMapping[key] ? keyFnMapping[key](val) : val;
};
export const cleanInitDateValue = (val) => {
  if (!val || typeof val !== 'string') return '';
  return getDPDate(val);
};
export const cleanInitSSNMaskValue = (val) => {
  if (!val) return '';
  const strVal = String(val);
  if (strVal.length < 9) return '';
  return `***-**-${strVal.substring(5, 9)}`;
};
export const cleanInitSSNValue = (val) => {
  if (!val) return '';
  const strVal = String(val);
  if (strVal.length < 9) {
    let returnStr = '';
    for (let i = 0; i < 9; i++) {
      returnStr += strVal[i] || '_';
    }
    return returnStr;
  }
  return strVal.replace(/ /g, '_').replace(/-/g, '').substring(0, 9);
};
export const getCleanedValue = (key, value) => {
  const parseKey = key.includes('date')
    ? FIELD_PARSE_KEY.DATE
    : key.includes('phone')
    ? FIELD_PARSE_KEY.FORMAT
    : '';
  return cleanPayloadValue(value, parseKey);
};
export const clearnUpValues = (values) => {
  const returnObj = {};
  Object.keys(values).map((key) => {
    const tValue = values[key];
    returnObj[key] = getCleanedValue(key, tValue);
    return true;
  });
  return returnObj;
};
export const getSectionIsValid = (fields, values) => {
  const isInvalid = fields.filter((field) => !get(values, field)).length > 0;
  return !isInvalid;
};
