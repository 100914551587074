import cn from 'classnames';
import { Field } from 'formik';
import get from 'lodash/get';
import React from 'react';
import { ReactComponent as CalendarPlusSvg } from 'src/assets/icons/CalendarPlus.svg';
import { ReactComponent as DropdownPlusSvg } from 'src/assets/icons/DropdownPlus.svg';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import { ADDITIONAL_CHECKS_TYPES } from 'src/helpers/constants';
import classes from './FormikAdditionalChecks.module.scss';

type Props = {
  className?: string;
  fields: any[];
};

const checkTypeMapping = {
  [ADDITIONAL_CHECKS_TYPES.DATE_INPUT]: <CalendarPlusSvg />,
  [ADDITIONAL_CHECKS_TYPES.DROPDOWN]: <DropdownPlusSvg />,
};

const Check = ({ label, onDelete = () => {}, type = '' }) => {
  const tIcon = checkTypeMapping[type] || '';
  return (
    <span>
      {!!tIcon && <span className={classes.checkIcon}>{tIcon}</span>}
      {label}
      <PlusSvg onClick={onDelete} />
    </span>
  );
};

const FormikAdditionalChecks = ({ className, fields }: Props) => {
  return (
    <Field>
      {({
        // field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { setFieldValue, values } = form;
        const fieldsHaveValue =
          fields
            .map((field) => field.displayName || field.name)
            .filter((fName) => !!get(values, fName)).length > 0;
        if (!fieldsHaveValue) return '';
        return (
          <div className={cn(classes.wrapper, className)}>
            {fields.map((field, fIndex) => {
              const tValue = get(values, field.name);
              const tValueId = get(values, field.idName);
              if (field.isStringArray) {
                return (tValue || []).map((v, vIndex) => {
                  const tFieldId = tValueId?.[vIndex];
                  return (
                    <Check
                      key={`${v}_${vIndex}`}
                      type={field.type}
                      label={v}
                      onDelete={() => {
                        setFieldValue(
                          field.name,
                          tValue.filter((_v, rIndex) => rIndex !== vIndex)
                        );
                        setFieldValue(field.deleteName, [
                          ...(get(values, field.deleteName) || []),
                          tFieldId,
                        ]);
                      }}
                    />
                  );
                });
              }

              return tValue ? (
                <Check
                  key={`${field.name}_${fIndex}`}
                  label={get(values, field.displayName || field.name)}
                  onDelete={() => {
                    setFieldValue(field.name, '');
                  }}
                  type={field.type}
                />
              ) : (
                <React.Fragment key={`${field.name}_${fIndex}`} />
              );
            })}
          </div>
        );
      }}
    </Field>
  );
};

export default FormikAdditionalChecks;
