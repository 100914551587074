import cn from 'classnames';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import classes from './Section.module.scss';

export const FormLine = ({ className = '', children, item = 1 }) => {
  return (
    <div
      className={cn(
        classes.formLine,
        {
          [classes.items2]: item === 2,
          [classes.items3]: item === 3,
        },
        className
      )}
    >
      {children}
    </div>
  );
};

export const AdditionalChecks = ({ className = '' }) => {
  const values = [
    {
      id: 1,
      label: 'Date Input: Date Requested',
    },
  ];
  return (
    <div className={cn(classes.additionalChecks, className)}>
      {values.map((val) => {
        return (
          <span key={val.id}>
            {val.label}
            <PlusSvg />
          </span>
        );
      })}
    </div>
  );
};
