import { LoadingOverlay } from 'src/components';
import { useCurrentPath } from 'src/helpers/hooks';
import classes from './BuildCertificate.module.scss';
import { useLoadingData } from './selectorData';

const Loading = () => {
  const currentPath = useCurrentPath();
  const isWorkflow =
    currentPath === '/workflow-builder' || currentPath === '/workflow/:id';
  const { certDetailLoading, workflowDetailLoading } = useLoadingData();
  return (
    <LoadingOverlay
      visible={isWorkflow ? workflowDetailLoading : certDetailLoading}
      className={classes.loading}
      fixed
    />
  );
};

export default Loading;
