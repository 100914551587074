import cn from 'classnames';
import React from 'react';
import classes from './MainContent.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  flex?: boolean;
  contentFullHeight?: boolean;
  id?: string;
}

const MainContent = ({
  className,
  children,
  flex,
  contentFullHeight,
  id,
}: Props) => {
  return (
    <div
      className={cn(
        classes.wrapper,
        'i-main-content',
        {
          [classes.flex]: flex,
          [classes.contentFullHeight]: contentFullHeight,
        },
        className
      )}
      id={id}
    >
      {children}
    </div>
  );
};

export default MainContent;
