import { useEffect, useState } from 'react';
import { Box, ResponsiveCard, Table, TableResult } from 'src/components';
import { Dropdown, Input } from 'src/components/FormFields';
import {
  FilterBar,
  MainContent,
  PageContent,
  PageContentHeader,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import {
  useCurrentRole,
  useInfiniteScroll,
  useMedia,
  useOptions,
} from 'src/helpers/hooks';
import { getCardValue } from 'src/utils/card';
import { applySearch } from 'src/utils/search';
import classes from './MyResidents.module.scss';
import getPermission from './permissions';
import { useActions, useIndexData } from './selectorData';
import { getColumns, getFilters } from './utils';

let page = 1;

const MyResidents = () => {
  const role = useCurrentRole();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const permission = getPermission(role);
  const isTablet = useMedia('(max-width: 768px)');
  const { pPropertyOptions } = useOptions();
  const { getResidents } = useActions();
  const { residents, residentsMeta } = useIndexData();
  const [setting, setSetting] = useState({
    properties: [],
  } as Record<string, string[]>);
  const [isLazyLoading, setIsLazyLoading] = useState(false);
  const [search, setSearch] = useState('');
  const reloadResidents = (tSetting, tSearch, tPage = 1, cb = () => {}) => {
    getResidents(getFilters(tSetting, tSearch, tPage), cb);
  };
  const handleSettingChange = (key, value, willNotFetch = false) => {
    page = 1;
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (!willNotFetch) {
      reloadResidents(newSetting, search);
    }
  };
  const loadMore = () => {
    setIsLazyLoading(true);
    page = page + 1;
    reloadResidents(setting, search, page, () => {
      setIsLazyLoading(false);
    });
  };

  useInfiniteScroll({
    onLoadMore: loadMore,
    shouldLoadMore:
      !isLazyLoading &&
      residentsMeta &&
      residentsMeta.current_page < residentsMeta.last_page,
  });

  useEffect(() => {
    page = 1;
    reloadResidents(setting, search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'My Residents',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader title="My Residents" className={classes.pageHeader}>
          <Input
            radius="md"
            value={search}
            onChange={(e) => {
              const val = e.target.value;
              setSearch(val);
              applySearch(val, (tVal) => {
                page = 1;
                reloadResidents(setting, tVal);
              });
            }}
            placeholder="Search"
            isSearch
          />
        </PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          <PageContentHeader tableResult={residentsMeta.total}>
            <FilterBar className={classes.filterBar}>
              <Dropdown
                items={[DEFAULT_ALL_OPTION, ...pPropertyOptions]}
                value={setting.properties}
                onChange={(val) => {
                  handleSettingChange('properties', val);
                }}
                placeholder={'All Properties'}
                className={classes.propertyDropdown}
              />
            </FilterBar>
          </PageContentHeader>
          <TableResult
            count={residentsMeta.total}
            className={classes.tableResult}
            mobileShow
            mobileTop={-49}
          />
          {isTablet ? (
            <>
              {residents.map((certification) => {
                return (
                  <ResponsiveCard
                    key={certification.id}
                    items={[
                      {
                        label: 'Name',
                        value: getCardValue(''),
                      },
                      {
                        label: 'Unit',
                        value: getCardValue(''),
                      },
                      {
                        label: 'Phone Number',
                        value: getCardValue(''),
                      },
                      {
                        label: 'Pending Workflows',
                        value: getCardValue(''),
                      },
                      {
                        label: 'Pending Certs',
                        value: getCardValue(''),
                      },
                    ]}
                    boxItems={[
                      {
                        label: 'Email Address',
                        value: getCardValue(''),
                      },
                    ]}
                    onView={undefined}
                  />
                );
              })}
            </>
          ) : (
            <Box vertical>
              <Table
                columns={getColumns()}
                align="left"
                data={residents}
                // data={[]}
                onView={undefined}
                selection={{}}
                pagination={{}}
              />
            </Box>
          )}
        </PageContent>
      </MainContent>
    </>
  );
};

export default MyResidents;
