export const getAllSubFiles = (subs, values) => {
  const tSubs = subs || [];
  const tValues = values || [];
  if (!tSubs.length || !tValues.length) return [];
  const sFiles = [];
  const sUploadFileIds = [];
  tSubs.forEach((s) => {
    (s.fields || []).forEach((sF) => {
      if (sF.label === 'Upload File') {
        sUploadFileIds.push(sF.id);
      }
    });
  });
  tValues.forEach((v) => {
    if (sUploadFileIds.includes(v.certificate_schema_field_id)) {
      (v.files || []).forEach((f) => {
        sFiles.push(f);
      });
    }
  });
  return sFiles;
};
