import * as documentActions from 'src/store/actions/document';
import * as sharedActions from 'src/store/actions/shared';
import { useReducerData, useStoreActions } from 'src/store/hooks';

export const useIndexData = () => {
  const folderStore = useReducerData('document', 'folders', {});
  const folderMeta = folderStore.data || {};
  const documentStore = useReducerData('document', 'documents', {});
  const documentMeta = documentStore.data || {};
  const folderDocumentStore = useReducerData('document', 'folderDocuments', {});
  const folderDocumentMeta = folderDocumentStore.data || {};
  const rdDocumentStore = useReducerData(
    'document',
    'recentlyDeletedDocuments',
    {}
  );
  const rdDocumentMeta = rdDocumentStore.data || {};
  return {
    folderMeta,
    folders: folderMeta.data || [],
    foldersLoading: folderStore.loading,
    createFolderLoading: useReducerData(
      'document',
      'createFolder.loading',
      false
    ),
    uploadFilesLoading: useReducerData(
      'document',
      'uploadFiles.loading',
      false
    ),
    documentMeta,
    documents: documentMeta.data || [],
    documentsLoading: documentStore.loading,
    folderDocumentMeta,
    folderDocuments: folderDocumentMeta.data || [],
    folderDocumentsLoading: folderDocumentStore.loading,
    rdDocumentMeta,
    rdDocuments: rdDocumentMeta.data || [],
    rdDocumentsLoading: rdDocumentStore.loading,
    updateDocumentLoading: useReducerData(
      'document',
      'updateDocument.loading',
      false
    ),
  };
};
export const useLoadingData = () => {
  return {
    documentsLoading: useReducerData('document', 'documents.loading', false),
    folderDocumentsLoading: useReducerData(
      'document',
      'folderDocuments.loading',
      false
    ),
    rdDocumentsLoading: useReducerData(
      'document',
      'recentlyDeletedDocuments.loading',
      false
    ),
    documentDetailLoading: useReducerData(
      'document',
      'documentDetail.loading',
      false
    ),
    moveDocumentLoading: useReducerData(
      'document',
      'moveDocument.loading',
      false
    ),
  };
};

export const useActions = () => {
  return useStoreActions({
    ...documentActions,
    ...sharedActions,
  });
};
