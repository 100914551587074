import { Arrow, Badge } from 'src/components';
import { REPORT_STATUS_COLOR } from 'src/helpers/constants/options';
import { renderDateColumn, renderStandardColumn } from 'src/utils/table';

export const getColumns = () => {
  return [
    {
      key: 'property',
      render: (_, record) => {
        return renderStandardColumn(record.property?.name);
      },
      title: 'Property',
    },
    {
      key: 'type',
      render: renderStandardColumn,
      title: 'Type',
    },
    {
      key: 'manager_on_duty',
      render: renderStandardColumn,
      title: 'Manager On Duty',
    },
    {
      key: 'status',
      render: (val) => <Badge color={REPORT_STATUS_COLOR[val]}>{val}</Badge>,
      title: 'Status',
    },
    {
      key: 'incident_date',
      render: renderDateColumn,
      title: 'Date of Incident',
    },
    {
      key: 'action',
      render: () => {},
      renderAction: () => {
        return <Arrow direction="right" />;
      },
    },
  ];
};
