import { Field } from 'formik';
import get from 'lodash/get';
import ButtonCheck, { OButtonCheckProps } from '../ButtonCheck';

type Props = OButtonCheckProps;

const FormikButtonCheck = ({ buttons, ...other }: Props) => {
  return (
    <Field name={name}>
      {({
        // field, // { name, value, onChange, onBlur }
        form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      }) => {
        const { setFieldValue, values } = form;
        return (
          <ButtonCheck
            {...other}
            onChange={(name) => {
              setFieldValue(name, !get(values, name));
            }}
            buttons={buttons.map((btn) => ({
              ...btn,
              checked: btn.disableCheck ? false : !!get(values, btn.name),
            }))}
            values={values}
          />
        );
      }}
    </Field>
  );
};

export default FormikButtonCheck;
