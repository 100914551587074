import cn from 'classnames';
import { displayName, intialName } from 'src/utils/text';
import Avatar from '../Avatar';
import classes from './Note.module.scss';

interface Props {
  className?: string;
  firstName: string;
  lastName: string;
  avatarSrc?: string;
  dateStr?: string;
  content?: React.ReactNode | string;
}

const Note = ({
  className = '',
  firstName,
  lastName,
  avatarSrc,
  dateStr,
  content,
}: Props) => {
  return (
    <div className={cn(classes.wrapper, className)}>
      <div className={classes.top}>
        <Avatar size={30} circle avtSrc={avatarSrc}>
          {intialName(firstName, lastName)}
        </Avatar>
        <div className={classes.info}>
          <span>{displayName(firstName, lastName)}</span>
          <span>{dateStr}</span>
        </div>
      </div>
      <div className={classes.bottom}>{content}</div>
    </div>
  );
};

export default Note;
