import { LayoutPermission } from 'src/types/permissions';

export const otherPermission: LayoutPermission = {
  // Empty is can access to every routes
  urls: [],
  excludeUrls: [
    '/user-management',
    '/notices',
    '/upload-notice',
    '/certifications-list/:id',
    '/resident-information',
    '/my-properties',
    '/documents',
    '/documents/folders/:id',
    '/documents/recently-deleted',
    '/my-residents',
  ],
};
