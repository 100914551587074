import cn from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Tooltip } from 'src/components';
import { STAGE_PROD } from 'src/helpers/constants';
import { useCurrentPath } from 'src/helpers/hooks';
import classes from './Navigation.module.scss';

export type NavItem = {
  id: string;
  icon?: React.ReactNode;
  label: string | React.ReactNode;
  link?: string;
  activeLinks?: string[];
  iconFill?: boolean;
  additionalInfo?: string | React.ReactNode;
  tooltip?: string | React.ReactNode;
  notAvailable?: boolean;
  mobileHide?: boolean;
};

interface Props {
  className?: string;
  items: NavItem[];
  collapse?: boolean;
}

const Navigation = ({ className, items, collapse }: Props) => {
  const currentPath = useCurrentPath();

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.collapse]: collapse,
        },
        className
      )}
    >
      <ul>
        {items.map((item: NavItem) => {
          const notAvailable = STAGE_PROD && item.notAvailable;
          const liItem = (
            <li
              className={cn({
                [classes.isActive]:
                  currentPath === item.link ||
                  (item.activeLinks || []).includes(currentPath),
                [classes.iconFill]: item.iconFill,
                [classes.isLabel]: !item.link,
                [classes.notAvailable]: notAvailable,
              })}
            >
              {item.link ? (
                <Link
                  to={notAvailable ? '/' : item.link}
                  onClick={(e) => {
                    if (notAvailable) {
                      e.preventDefault();
                      e.stopPropagation();
                    }
                  }}
                >
                  <Icon>{item.icon}</Icon>
                  <span>{item.label}</span>
                </Link>
              ) : (
                <>
                  <span>
                    <Icon>{item.icon}</Icon>
                    {item.label}
                  </span>
                  {item.additionalInfo}
                </>
              )}
            </li>
          );
          return (
            <React.Fragment key={item.id}>
              {(collapse && !!item.tooltip) || notAvailable ? (
                <Tooltip
                  label={notAvailable ? 'Coming Soon!' : item.tooltip}
                  color="dark"
                  withArrow
                  position="right"
                  // tooltip animation
                  transition="slide-left"
                  transitionDuration={200}
                >
                  {liItem}
                </Tooltip>
              ) : (
                liItem
              )}
            </React.Fragment>
          );
        })}
      </ul>
    </div>
  );
};

export default Navigation;
