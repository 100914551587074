import { Dispatch } from 'redux';
import { apiActiveSchedule, apiGetSchedules, apiUpdateSchedule } from 'src/api';
import Types from '../types/schedule';

export const syncUpdateSchedule = (payload) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_SCHEDULE,
    payload,
  });
};

export const getSchedules = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_SCHEDULES,
    asyncCall: () => {
      return apiGetSchedules();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const updateSchedule =
  (data = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_SCHEDULE,
      asyncCall: () => {
        return apiUpdateSchedule(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const activeSchedule =
  (data = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ACTIVE_SCHEDULE,
      asyncCall: () => {
        return apiActiveSchedule(data);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
