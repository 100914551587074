import cn from 'classnames';
import { Form, Formik } from 'formik';
import omit from 'lodash/omit';
import { useRef, useState } from 'react';
import { ReactComponent as EditSvg } from 'src/assets/icons/Edit.svg';
import { Arrow, Button, Loader, Tooltip } from 'src/components';
import {
  FormikDropdown,
  FormikInput,
  FormikTextarea,
  Input,
} from 'src/components/FormFields';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import { useCurrentUser, useOptions } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import {
  downloadFile,
  getDisplayLocationFromPath,
  getIsFileOwner,
} from 'src/utils/file';
import classes from './FileContent.module.scss';
import { useActions } from './selectorData';
import { getFileInfos } from './uiUtils';
import {
  getFileEditedBR,
  getInitialValues,
  schema,
  schemaFolder,
} from './utils';

const FileInfo = ({ label, content }) => {
  return (
    <div className={classes.fileInfoBlock}>
      <span>{label}</span>
      <div>{content}</div>
    </div>
  );
};

interface Props {
  className?: string;
  data: any;
  onBack?: () => void;
  onEditFile?: (payload, id, cb?: any) => void;
  saveLoading?: boolean;
  parentModalId?: string;
}

const FileContent = ({
  className,
  data,
  onBack,
  onEditFile,
  saveLoading,
  parentModalId,
}: Props) => {
  const isDocumentFile = !data?.is_directory;
  const currentUser = useCurrentUser();
  const { genrateDocumentUrl } = useActions();
  const userIsOwner = getIsFileOwner(data, currentUser.id);
  const { propertyOptions, tagOptions, permissionOptions, allFolderOptions } =
    useOptions();
  const [generateLoading, setGenerateLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const initialValues = getInitialValues(data, propertyOptions);
  const formRef = useRef(null);
  const isFolder = !!data?.is_directory;
  const fileInfos = getFileInfos({
    data,
    availableProperties: propertyOptions,
  });
  const [folderName, setFolderName] = useState(data?.name || '');
  const renderPermissionDropdown = (name, label, placeholder) => {
    const isDropdownTop = isDocumentFile
      ? ['permissions_to_edit']
      : [
          'permissions_to_edit',
          'permissions_to_view',
          'permissions_to_delete',
        ].includes(name);
    const isSpaceBottom = [
      'permissions_to_view',
      'permissions_to_delete',
    ].includes(name);
    return (
      <FormikDropdown
        items={[DEFAULT_ALL_OPTION, ...permissionOptions]}
        placeholder={placeholder}
        label={label}
        name={name}
        displaySelectedAll
        placeholderGray
        errorOverlay
        radius="md"
        insideModalId={parentModalId}
        position={isDropdownTop ? 'bottom' : undefined}
        additionalSpace={isSpaceBottom ? 20 : 0}
      />
    );
  };
  const renderForm = () => {
    const propertyDropdown = (
      <FormikDropdown
        items={[DEFAULT_ALL_OPTION, ...propertyOptions]}
        placeholder={'Select Property'}
        label="Property"
        name={`property`}
        displaySelectedAll
        placeholderGray
        errorOverlay
        radius="md"
        insideModalId={parentModalId}
        position="bottom"
        additionalSpace={20}
      />
    );
    return (
      <Form className={classes.form}>
        <>
          {!isFolder && (
            <>
              <FormikInput
                name={`formNumber`}
                label="Form Number"
                placeholder="Form Number"
                type="text"
                errorOverlay
                radius="md"
              />
              <FormikDropdown
                items={allFolderOptions}
                placeholder={'File Location'}
                label="File Location"
                name={`location`}
                displaySelectedAll
                placeholderGray
                errorOverlay
                radius="md"
                isFileLocation
                insideModalId={parentModalId}
                position="bottom"
              />
              {propertyDropdown}
              {/* {renderPermissionDropdown(
                'permissions',
                'Permissions',
                'Select Permissions'
              )} */}
            </>
          )}
          {renderPermissionDropdown(
            'permissions_to_edit',
            'Permissions to Edit',
            'Select Permissions'
          )}
          {renderPermissionDropdown(
            'permissions_to_view',
            'Permissions to View',
            'Select Permissions'
          )}
          {renderPermissionDropdown(
            'permissions_to_delete',
            'Permissions to Delete',
            'Select Permissions'
          )}
          <FormikDropdown
            items={[DEFAULT_ALL_OPTION, ...tagOptions]}
            placeholder={'Select Tags'}
            label="Tags"
            name={`tags`}
            displaySelectedAll
            placeholderGray
            errorOverlay
            radius="md"
            newEntity="tag"
            disabled={!userIsOwner}
            insideModalId={parentModalId}
            additionalSpace={40}
          />
          {isFolder && propertyDropdown}
          <FormikTextarea
            name={`notes`}
            label="Notes"
            placeholder="Notes"
            type="text"
            radius="md"
            disabled={!userIsOwner}
          />
        </>
      </Form>
    );
  };

  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.isFolder]: isFolder,
        },
        className
      )}
    >
      <div className={classes.header}>
        <Arrow direction="left" onClick={onBack} />
        <div className={classes.fileHeader}>
          <div
            onClick={() => {
              if (!generateLoading && !isFolder) {
                setGenerateLoading(true);
                genrateDocumentUrl(
                  data.id,
                  {},
                  actionCb(
                    (res) => {
                      setGenerateLoading(false);
                      if (res.url) {
                        downloadFile(res.url, data.name);
                      }
                    },
                    () => {
                      setGenerateLoading(false);
                    },
                    'Generate file url failed!'
                  )
                );
              }
            }}
            className={classes.fileName}
          >
            {isEdit && isFolder ? (
              <Input
                placeholder="FolderName"
                errorOverlay
                radius="md"
                value={folderName}
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
                size="xs"
                textSize="md"
                errorBg={!folderName}
                error={!folderName && ' '}
              />
            ) : (
              <>
                <Tooltip
                  label={data?.name}
                  color="dark"
                  withArrow
                  transitionDuration={200}
                >
                  <span>{data?.name}</span>
                </Tooltip>
                {generateLoading && <Loader size="xs" />}
              </>
            )}
          </div>
          <div>{getDisplayLocationFromPath(data?.v_path, data?.name)}</div>
        </div>
        <div className={classes.buttons}>
          {isEdit && (
            <Button
              variant={'default'}
              onClick={() => {
                setIsEdit(false);
                setFolderName(data?.name || '');
              }}
              radius="md"
              textSize="md"
              textWeight="md"
              size="xs"
              leftIcon={!isEdit && <EditSvg />}
            >
              Cancel
            </Button>
          )}
          {!!data?.can_edit && (
            <Button
              variant={!isEdit ? 'default' : 'filled'}
              onClick={() => {
                if (!isEdit) {
                  setIsEdit(true);
                } else {
                  if (formRef && formRef.current) {
                    formRef.current.handleSubmit(formRef.current.values);
                  }
                }
              }}
              radius="md"
              textSize="md"
              textWeight="md"
              size="xs"
              leftIcon={!isEdit && <EditSvg />}
              loading={isEdit && saveLoading}
            >
              {isEdit ? 'Save' : 'Edit'}
            </Button>
          )}
        </div>
      </div>
      <div className={classes.content}>
        {isEdit ? (
          <Formik
            initialValues={initialValues}
            innerRef={formRef}
            validateOnMount
            validationSchema={isFolder ? schemaFolder : schema}
            enableReinitialize
            onSubmit={(values) => {
              if ((!isFolder || (isFolder && !!folderName)) && onEditFile) {
                onEditFile(
                  omit(
                    getFileEditedBR(
                      {
                        ...values,
                        folderName,
                      },
                      isFolder
                    ),
                    isFolder ? [] : ['folderName']
                  ),
                  data.id,
                  () => {
                    setIsEdit(false);
                  }
                );
              }
            }}
          >
            {renderForm}
          </Formik>
        ) : (
          <>
            {fileInfos.map((info, index) => {
              return <FileInfo {...info} key={index} />;
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default FileContent;
