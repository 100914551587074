import { Modal as ManModal, ModalProps } from '@mantine/core';
import cn from 'classnames';
import React from 'react';
import AlertModal from './Alert';
import classes from './Modal.module.scss';

type Props = ModalProps & {
  className?: string;
  footer?: React.ReactNode;
  fullHeight?: boolean;
  scrollable?: boolean;
};

const Modal = ({
  className,
  children,
  footer,
  fullHeight,
  scrollable,
  ...other
}: Props) => {
  return (
    <ManModal
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.hasFooter]: !!footer,
          [classes.fullHeight]: fullHeight,
          [classes.scrollable]: scrollable,
        },
        className
      )}
    >
      {children}
      {!!footer && <div className={classes.footer}>{footer}</div>}
    </ManModal>
  );
};

export { AlertModal };
export default Modal;
