import { Dispatch } from 'redux';
import { apiAddNotice, apiGetNotices, apiUpdateNoticeStatus } from 'src/api';
import Types from '../types/notice';

export const syncUpdateNoticeStatus = (id, status) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_UPDATE_NOTICE_STATUS,
    payload: { id, status },
  });
};

export const getNotices =
  (params = {}, cb?: any) =>
  (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_NOTICES,
      asyncCall: () => {
        return apiGetNotices(params);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const addNotice = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.ADD_NOTICE,
    asyncCall: () => {
      return apiAddNotice(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const updateNoticeStatus = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_NOTICE_STATUS,
    asyncCall: () => {
      return apiUpdateNoticeStatus(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
