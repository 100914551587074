import { BadgeProps, Badge as ManBadge } from '@mantine/core';
import cn from 'classnames';
import React from 'react';
import { STATUS } from 'src/helpers/constants';
import classes from './Badge.module.scss';

type Props = BadgeProps & {
  className?: string;
  circle?: boolean;
  alert?: boolean;
  isStatus?: boolean;
  text?: string;
  onClick?: (e: any) => void;
  top?: boolean;
  count?: number;
};

const Badge = ({
  className = '',
  circle,
  isStatus,
  color,
  text,
  alert,
  top,
  count,
  children,
  ...other
}: Props) => {
  const thisColor = isStatus
    ? text === STATUS.ACTIVE.value
      ? 'green'
      : 'red'
    : color;
  const badgeEl = (
    <ManBadge
      {...other}
      color={thisColor}
      className={cn(
        classes.wrapper,
        classes[thisColor],
        classes[`size_${other.size}`],
        {
          [classes.circle]: circle,
          [classes.darkRed]: thisColor === 'dark-red',
          [classes.isStatus]: isStatus,
          [classes.cursor]: !!other.onClick,
          [classes.alert]: alert,
        },
        className
      )}
    >
      {top ? count : children}
    </ManBadge>
  );
  if (top) {
    return (
      <span className={classes.badgeWrapper}>
        {children}
        {badgeEl}
      </span>
    );
  }
  return badgeEl;
};

export default Badge;
