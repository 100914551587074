import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from 'src/pages/NewReport/components/Section';

const Pets = ({ isDisabled = false }) => {
  return (
    <NewReportSection title="Pets">
      <FormCardContent column={4}>
        <FormikInput
          name="pet_name"
          label="Pet Name"
          placeholder="Pet Name"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="pet_type"
          label="Type of Animal/Breed"
          placeholder="Type of Animal/Breed"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="pet_age"
          label="Pet Age"
          placeholder="00"
          inputType="mask"
          mask="99"
          autoComplete="pet_age"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="pet_weight"
          label="Pet Weight"
          placeholder="000"
          inputType="mask"
          mask="999"
          autoComplete="pet_weight"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default Pets;
