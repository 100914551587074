import isArray from 'lodash/isArray';
import { Reducer } from 'redux';
import { getLatestWorkflowTypeBuilder } from 'src/utils/store';
import { defaultArray, defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/shared';

const initialState = {
  collapse: false,
  roles: {
    ...defaultArray,
  },
  allUsers: {
    ...defaultArray,
  },
  supervisors: {
    ...defaultArray,
  },
  properties: {
    ...defaultArray,
  },
  certificationTypes: {
    ...defaultArray,
  },
  workflowTypes: {
    ...defaultArray,
  },
  workflowTypesBuilder: {
    ...defaultArray,
  },
  certificationStatuses: {
    ...defaultArray,
  },
  certBuilderDropdown: {
    ...defaultObj,
  },
  tags: {
    ...defaultArray,
  },
  allFolders: {
    ...defaultObj,
  },
  timezones: {
    ...defaultArray,
  },
  departments: {
    ...defaultArray,
  },
  createTag: {
    ...defaultObj,
  },
  createDepartment: {
    ...defaultObj,
  },
  createStatus: {
    ...defaultObj,
  },
  createWorkflow: {
    ...defaultObj,
  },
};

const SharedReducer: Reducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case Types.SYNC_SET_COLLAPSE: {
      return {
        ...state,
        collapse: action.payload,
      };
    }
    case Types.GET_ROLES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          roles: {
            ...prevState.roles,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          roles: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          roles: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_SUPERVISORS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          supervisors: {
            ...prevState.supervisors,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          supervisors: {
            loading: false,
            data: payload?.data || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          supervisors: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_ALL_USERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allUsers: {
            ...prevState.allUsers,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allUsers: {
            loading: false,
            data: payload?.data || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allUsers: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_PROPERTIES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          properties: {
            ...prevState.properties,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          properties: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          properties: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_CERTIFICATION_TYPES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificationTypes: {
            ...prevState.certificationTypes,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificationTypes: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificationTypes: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_WORKFLOW_TYPES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflowTypes: {
            ...prevState.workflowTypes,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflowTypes: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflowTypes: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_WORKFLOW_TYPES_BUILDER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflowTypesBuilder: {
            ...prevState.workflowTypesBuilder,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflowTypesBuilder: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflowTypesBuilder: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_CERTIFICATION_STATUSES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certificationStatuses: {
            ...prevState.certificationStatuses,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certificationStatuses: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certificationStatuses: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_CERT_BUILDER_DROPDOWN:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          certBuilderDropdown: {
            ...prevState.certBuilderDropdown,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          certBuilderDropdown: {
            loading: false,
            data: payload || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          certBuilderDropdown: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_TAGS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          tags: {
            ...prevState.tags,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          tags: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          tags: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_ALL_FOLDERS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          allFolders: {
            ...prevState.allFolders,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          allFolders: {
            loading: false,
            data: payload || {},
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          allFolders: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_TIMEZONES:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          timezones: {
            ...prevState.timezones,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          timezones: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          timezones: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.GET_DEPARTMENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          departments: {
            ...prevState.departments,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          departments: {
            loading: false,
            data: payload || [],
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          departments: {
            loading: false,
            data: [],
            error: payload,
          },
        }),
      });
    case Types.CREATE_TAG:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createTag: {
            ...prevState.createTag,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createTag: {
            loading: false,
            data: payload,
            error: '',
          },
          tags: {
            ...state.tags,
            data: [...state.tags.data, payload],
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createTag: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_DEPARTMENT:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createDepartment: {
            ...prevState.createDepartment,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createDepartment: {
            loading: false,
            data: payload,
            error: '',
          },
          departments: {
            ...state.departments,
            data: isArray(payload)
              ? payload
              : [...state.departments.data, payload],
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createDepartment: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_STATUS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createStatus: {
            ...prevState.createStatus,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          createStatus: {
            loading: false,
            data: payload,
            error: '',
          },
          certBuilderDropdown: {
            ...prevState.certBuilderDropdown,
            data: payload,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          createStatus: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.CREATE_WORKFLOW_TYPE:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          createWorkflow: {
            ...prevState.createWorkflow,
            loading: true,
          },
        }),
        success: (prevState) => {
          return {
            ...prevState,
            createWorkflow: {
              loading: false,
              data: payload,
              error: '',
            },
            workflowTypesBuilder: {
              ...prevState.workflowTypesBuilder,
              data: getLatestWorkflowTypeBuilder(
                prevState.workflowTypesBuilder?.data,
                payload
              ),
            },
          };
        },
        failure: (prevState) => ({
          ...prevState,
          createWorkflow: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default SharedReducer;
