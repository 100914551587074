import { Dispatch } from 'redux';
import {
  apiAddWorkflow,
  apiDeleteWorkflowBuilder,
  apiGetWorkflowBuilderDetail,
  apiGetWorkflowBuilderList,
  apiGetWorkflowPending,
  apiGetWorkflows,
} from 'src/api';
import Types from '../types/workflow';

export const getWorkflowPending = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_WORKFLOW_PENDING,
    asyncCall: () => {
      return apiGetWorkflowPending();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const addWorkflow = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.ADD_WORKFLOW,
    asyncCall: () => {
      return apiAddWorkflow(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getWorkflowBuilderList =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_WORKFLOW_BUILDER_LIST,
      asyncCall: () => {
        return apiGetWorkflowBuilderList(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getWorkflowBuilderDetail =
  (id, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: { certId: id },
      type: Types.GET_WORKFLOW_BUILDER_DETAIL,
      asyncCall: () => {
        return apiGetWorkflowBuilderDetail(id);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const deleteWorkflowBuilder = (id, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_WORKFLOW_BUILDER,
    asyncCall: () => {
      return apiDeleteWorkflowBuilder(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getWorkflows =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore, schema_id: params.schema_id },
      type: Types.GET_WORKFLOWS,
      asyncCall: () => {
        return apiGetWorkflows(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
