import { Button, FileButton, FileButtonProps } from '@mantine/core';
import cn from 'classnames';
import isArray from 'lodash/isArray';
import { ReactComponent as UploadSvg } from 'src/assets/icons/Upload.svg';
import { SizeType } from 'src/types';
import classes from './Upload.module.scss';
import UploadResult, {
  UploadResultRow,
  UploadResultWrapper,
} from './UploadResult';

export type OUploadProps = Omit<
  FileButtonProps,
  'children' | 'onChange' | 'multiple'
> & {
  className?: string;
  value?: any;
  children?: any;
  onChange?: any;
  error?: string;
  multiple?: boolean;
  onRemove?: (index: number) => void;
  valueButtonHidden?: boolean;
  valueHidden?: boolean;
  buttonText?: string | React.ReactNode;
  buttonProps?: any;
  hideRemoveValue?: boolean;
  uploadedAsInput?: boolean;
  label?: string | React.ReactNode;
  required?: boolean;
  size?: SizeType;
  printBtnText?: string;
  loading?: boolean;
  disabled?: boolean;
};

const Upload = ({
  className,
  children,
  value,
  onChange,
  error,
  onRemove,
  valueButtonHidden,
  valueHidden,
  buttonText,
  buttonProps,
  hideRemoveValue,
  uploadedAsInput,
  label,
  required,
  size,
  printBtnText,
  loading,
  disabled,
  ...other
}: OUploadProps) => {
  const tValue = isArray(value) ? value : value ? [value] : [];
  return (
    <div
      className={cn(
        classes.wrapper,
        {
          [classes.error]: error,
          [classes.valueButtonHidden]: valueButtonHidden,
        },
        classes[`size_${size}`],
        className
      )}
    >
      {!!label && (
        <label>
          {label}
          {required && <span className={classes.requiredMark}>*</span>}
        </label>
      )}
      {(!valueButtonHidden || (valueButtonHidden && tValue.length === 0)) && (
        <div>
          <FileButton {...other} onChange={onChange ? onChange : () => {}}>
            {(props) =>
              children ? (
                children
              ) : (
                <>
                  <Button
                    {...props}
                    radius="md"
                    size="xs"
                    variant="default"
                    leftIcon={<UploadSvg />}
                    {...(buttonProps || {})}
                    className={cn(classes.uploadBtn)}
                    loading={loading}
                    disabled={disabled}
                  >
                    {buttonText || 'Upload file'}
                  </Button>
                  <span
                    className={cn('i-print-value', {
                      'i-print-no-value': !tValue.length,
                    })}
                  >
                    {printBtnText || 'Upload file'}
                  </span>
                </>
              )
            }
          </FileButton>
          {!!error && <div className={classes.errorMsg}>{error}</div>}
        </div>
      )}
      {tValue.length > 0 && !valueHidden && (
        <UploadResult
          uploadedFiles={tValue}
          uploadedAsInput={uploadedAsInput}
          hideRemoveValue={hideRemoveValue}
          onRemove={loading || disabled ? undefined : onRemove}
          loading={loading || disabled}
        />
      )}
    </div>
  );
};

export { UploadResultWrapper, UploadResultRow };
export default Upload;
