import Login from 'src/pages/Auth/Login';
import BuildCertificate from 'src/pages/BuildCertificate';
import Certifications from 'src/pages/Certifications';
import CertificationsList from 'src/pages/CertificationsList';
import Documents from 'src/pages/Documents';
import Help from 'src/pages/Help';
import Home from 'src/pages/Home';
import InOutBoard from 'src/pages/InOutBoard';
import IncidentReporting from 'src/pages/IncidentReporting';
import Invite from 'src/pages/Invite';
import MyProfile from 'src/pages/MyProfile';
import MyProperties from 'src/pages/MyProperties';
import MyResidents from 'src/pages/MyResidents';
import NewCertification from 'src/pages/NewCertification';
import NewReport from 'src/pages/NewReport';
import NewWorkflow from 'src/pages/NewWorkflow';
import Notices from 'src/pages/Notices';
import ResidentDetail from 'src/pages/ResidentDetail';
import ResourceLinks from 'src/pages/ResourceLinks';
import UploadNotice from 'src/pages/UploadNotice';
import UserManagement from 'src/pages/UserManagement';
import Workflows from 'src/pages/Workflows';

export interface RouteOtherSettings {
  isAuthInvite?: boolean;
}

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    layout: 'private',
  },
  {
    path: '/resident-information',
    name: 'ResidentDetail',
    component: ResidentDetail,
    layout: 'private',
  },
  {
    path: '/notices',
    name: 'Notices',
    component: Notices,
    layout: 'private',
  },
  {
    path: '/my-residents',
    name: 'MyResidents',
    component: MyResidents,
    layout: 'private',
  },
  {
    path: '/upload-notice',
    name: 'UploadNotice',
    component: UploadNotice,
    layout: 'private',
  },
  {
    path: '/workflow/:id',
    name: 'EditWorkflow',
    component: BuildCertificate,
    layout: 'private',
  },
  {
    path: '/workflow-list-builder/:certId/:certificationId',
    name: 'WorkflowDetail',
    component: ResidentDetail,
    layout: 'private',
  },
  {
    path: '/workflow-list-builder/:id',
    name: 'WorkflowListBuilder',
    component: CertificationsList,
    layout: 'private',
  },
  {
    path: '/workflow-builder',
    name: 'WorkflowBuilder',
    component: BuildCertificate,
    layout: 'private',
  },
  {
    path: '/new-workflow',
    name: 'NewWorkflow',
    component: NewWorkflow,
    layout: 'private',
  },
  {
    path: '/workflows',
    name: 'Workflows',
    component: Workflows,
    layout: 'private',
  },
  {
    path: '/certifications',
    name: 'Certifications',
    component: Certifications,
    layout: 'private',
  },
  {
    path: '/certifications-list-builder/:certId/:certificationId',
    name: 'CertficationDetail',
    component: ResidentDetail,
    layout: 'private',
  },
  {
    path: '/certifications-list/:id',
    name: 'CertficationDetail',
    component: ResidentDetail,
    layout: 'private',
  },
  {
    path: '/certifications-list-builder/:id',
    name: 'CertificationsListBuilder',
    component: CertificationsList,
    layout: 'private',
  },
  {
    path: '/certifications-list',
    name: 'CertificationsList',
    component: CertificationsList,
    layout: 'private',
  },
  {
    path: '/certificate/:id',
    name: 'EditCertificate',
    component: BuildCertificate,
    layout: 'private',
  },
  {
    path: '/certification-builder',
    name: 'BuildCertificate',
    component: BuildCertificate,
    layout: 'private',
  },
  {
    path: '/new-certification',
    name: 'NewCertification',
    component: NewCertification,
    layout: 'private',
  },
  {
    path: '/my-properties',
    name: 'MyProperties',
    component: MyProperties,
    layout: 'private',
  },
  {
    path: '/new-report-auto-save/:id',
    name: 'ReportDetail',
    component: NewReport,
    layout: 'private',
  },
  {
    path: '/incident-reporting/:id',
    name: 'ReportDetail',
    component: NewReport,
    layout: 'private',
  },
  {
    path: '/incident-reporting',
    name: 'IncidentReporting',
    component: IncidentReporting,
    layout: 'private',
  },
  {
    path: '/new-report',
    name: 'NewReport',
    component: NewReport,
    layout: 'private',
  },
  {
    path: '/documents/recently-deleted',
    name: 'RecentlyDeleted',
    component: Documents,
    layout: 'private',
  },
  {
    path: '/documents/folders/:id',
    name: 'Folder',
    component: Documents,
    layout: 'private',
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents,
    layout: 'private',
  },
  {
    path: '/help',
    name: 'Help',
    component: Help,
    layout: 'private',
  },
  {
    path: '/resource-links',
    name: 'ResourceLinks',
    component: ResourceLinks,
    layout: 'private',
  },
  {
    path: '/my-profile',
    name: 'MyProfile',
    component: MyProfile,
    layout: 'private',
  },
  {
    path: '/in-out-board',
    name: 'InOutBoard',
    component: InOutBoard,
    layout: 'private',
  },
  {
    path: '/user-management',
    name: 'UserManagement',
    component: UserManagement,
    layout: 'private',
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    layout: 'auth',
  },
  {
    path: '/invite',
    name: 'Invite',
    component: Invite,
    layout: 'auth',
    isAuthInvite: true,
  },
];
