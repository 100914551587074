import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from 'src/pages/NewReport/components/Section';

const Members = ({ isDisabled = false }) => {
  return (
    <NewReportSection title="Household Members">
      <FormCardContent column={3}>
        <FormikInput
          name="primary_first_last_name"
          label="Primary Member First and Last Name"
          placeholder="First and Last Name"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="lep_requirements"
          label="LEP Requirements"
          placeholder="LEP Requirements"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="email_address"
          label="Email Address"
          placeholder="Email Address"
          type="email"
          autoComplete="email_address"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="home_phone"
          label="Home Phone"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="home_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="cell_phone"
          label="Cell Phone/ Message"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="cell_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="work_phone"
          label="Work Phone"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="work_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
      <FormCardContent column={4}>
        <FormikInput
          name="primary_first_last_name"
          label="Primary Member First and Last Name"
          placeholder="First and Last Name"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="lep_requirements"
          label="LEP Requirements"
          placeholder="LEP Requirements"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="email_address"
          label="Email Address"
          placeholder="Email Address"
          type="email"
          autoComplete="email_address"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="relationship"
          label="Relationship to Household"
          placeholder="Mother/Father/Daughter"
          autoComplete="relationship"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
      <FormCardContent column={3}>
        <FormikInput
          name="home_phone"
          label="Home Phone"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="home_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="cell_phone"
          label="Cell Phone/ Message"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="cell_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="work_phone"
          label="Work Phone"
          placeholder="000-000-0000"
          inputType="mask"
          mask="999-999-9999"
          autoComplete="work_phone"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default Members;
