import { displayName } from 'src/utils/text';

const MIN_PERMISSION_DISPLAYED = 5;

export const getDisplayPermission = (users, roles) => {
  let displayedUserCount = 0;
  const roleNames = (roles || []).map((r) => r.name);
  if (roleNames.length < MIN_PERMISSION_DISPLAYED && users?.length > 0) {
    users.forEach((user) => {
      if (roleNames.length < MIN_PERMISSION_DISPLAYED) {
        roleNames.push(displayName(user.first_name, user.last_name));
        displayedUserCount = displayedUserCount + 1;
      }
    });
  }
  if (users?.length && users.length - displayedUserCount > 0) {
    roleNames.push(`+${users.length - displayedUserCount}`);
  }
  return roleNames;
};
