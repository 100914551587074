import {
  AlertStylesParams,
  AvatarStylesParams,
  ButtonStylesParams,
  InputStylesParams,
  SegmentedControlStylesParams,
  SwitchStylesParams,
  TooltipStylesParams,
} from '@mantine/core';

// Custom global styles for Mantine and it's components here
const colors = {
  primary: ['#5F98A9', '#BCD7DE'],
  black: ['#000000', '#0E121A', '#10181E'],
  white: ['#ffffff'],
  gray: ['#D0D2D9', '#CED4DA', '#9D9EA1', '#F2F2F2', '#959595'],
  red: ['#F93746', '#F9E0E2', '#AA0411', '#DA0819', '#D0021B'],
  blue: ['#EFF6F8'],
  yellow: ['#DD7105', '#FFF3DA', '#FFF0CA', '#C88116'],
  green: ['#497E14', '#E7F8D6', '#7dae4c'],
};
const themeColors = {
  primaryWhite: [colors.white[0]],
  primaryBlack: [colors.black[0]],
  blue: ['#E7F5FF', '#D0EBFF', '#A5D8FF', colors.primary[0]],
  gray: ['#F8F9FA', '#F1F3F5', '#E9ECEF', '#DEE2E6', colors.gray[0]],
};
const BUTTON_COLOR_MAPPING = {
  red: {
    background: colors.red[1],
    color: colors.red[0],
  },
  green_default: {
    borderColor: colors.primary[0],
  },
};
const BUTTON_SIZE_MAPPING = {
  xs: {
    height: 26,
    padding: '0 8px',
  },
  sm: {
    height: 30,
    padding: '0 8px',
  },
};
const BUTTON_VARIANT_MAPPING = {
  default: {
    hoverBackground: colors.blue[0],
    disabledBackground: 'transparent',
    disabledBorderColor: colors.gray[1],
    disabledColor: colors.gray[1],
  },
};
const BADGE_COLOR_MAPPING = {
  green: {
    background: colors.green[1],
    color: colors.green[0],
  },
  gray: {
    background: colors.gray[3],
    color: colors.gray[4],
  },
  red: {
    background: colors.red[1],
    color: colors.red[2],
  },
  yellow: {
    background: colors.yellow[2],
    color: colors.yellow[3],
  },
};
const SEGMENTED_COLOR_MAPPING = {
  red: {
    default: colors.red[1],
    active: colors.red[3],
  },
  green: {
    default: colors.green[1],
    active: colors.green[0],
  },
};
const SWITCH_COLOR_MAPPING = {
  green: {
    background: colors.green[2],
    border: colors.green[2],
  },
};
const AVATAR_SIZE = {
  xl: {
    width: '70px',
    height: '70px',
  },
  sm: {
    width: '28px',
    height: '28px',
  },
};
export const getStyles = (theme) => {
  return {
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    body: {
      color: theme.colors.primaryBlack[0],
      fontSize: 14,
    },
  };
};

export default {
  fontFamily: 'Poppins',
  spacing: { xs: 16, sm: 22, md: 25, lg: 30, xl: 40 },
  primaryShade: 3,
  colors: themeColors,
  components: {
    Alert: {
      styles: (theme, params: AlertStylesParams) => ({
        root: {
          background: params.color === 'red' ? colors.red[0] : undefined,
        },
      }),
    },
    Avatar: {
      styles: (theme, params: AvatarStylesParams) => ({
        root: {
          minWidth: AVATAR_SIZE[params.size]?.width || undefined,
          width: AVATAR_SIZE[params.size]?.width || undefined,
          height: AVATAR_SIZE[params.size]?.width || undefined,
          borderRadius: params.size === 'xl' ? '50%' : undefined,
        },
        placeholder: {
          fontSize: params.size === 'xl' ? '28px' : undefined,
          fontWeight: 500,
        },
      }),
    },
    Badge: {
      styles: (theme, params: ButtonStylesParams) => ({
        root: {
          fontWeight: 500,
          color: BADGE_COLOR_MAPPING[params.color]?.color || undefined,
          background:
            BADGE_COLOR_MAPPING[params.color]?.background || undefined,
          fontSize: '12px',
          padding: '0 8px',
        },
      }),
    },
    Button: {
      styles: (theme, params: ButtonStylesParams) => ({
        root: {
          height: BUTTON_SIZE_MAPPING[params.size]?.height || undefined,
          padding: BUTTON_SIZE_MAPPING[params.size]?.padding || undefined,
          fontWeight: 400,
          color: BUTTON_COLOR_MAPPING[params.color]?.color || undefined,
          borderColor:
            params.variant === 'default'
              ? BUTTON_COLOR_MAPPING[`${params.color}_default`]?.borderColor ||
                colors.gray[1]
              : undefined,
          '&:hover': {
            backgroundColor:
              BUTTON_VARIANT_MAPPING[params.variant]?.hoverBackground ||
              undefined,
          },
          '&:disabled': {
            backgroundColor:
              BUTTON_VARIANT_MAPPING[params.variant]?.disabledBackground ||
              colors.primary[1],
            borderColor:
              BUTTON_VARIANT_MAPPING[params.variant]?.disabledBorderColor ||
              undefined,
            color:
              BUTTON_VARIANT_MAPPING[params.variant]?.disabledColor ||
              undefined,
          },
        },
      }),
    },
    InputWrapper: {
      styles: {
        label: {
          color: colors.black[1],
          lineHeight: '22px',
        },
        error: {
          color: colors.red[0],
        },
        required: {
          color: colors.red[4],
        },
      },
    },
    Input: {
      styles: (theme, params: InputStylesParams) => ({
        input: {
          padding: '7px 8px',
          height: params.size === 'md' ? '40px' : undefined,
          minHeight: params.size === 'md' ? '40px' : undefined,
          fontSize: params.size === 'md' ? '14px' : undefined,
          '&:disabled': {
            background: 'transparent',
          },
        },
      }),
    },
    Checkbox: {
      styles: {
        label: {
          paddingLeft: 10,
          cursor: 'pointer',
          fontWeight: 400,
        },
        // input: {
        //   borderRadius: 2,
        // },
      },
    },
    Tooltip: {
      styles: (theme, params: TooltipStylesParams) => {
        const isWhite = params.color === 'white';
        return {
          tooltip: {
            background: params.color === 'dark' ? '#636363' : undefined,
            whiteSpace: 'pre-wrap',
            color: isWhite ? '#0E1219' : undefined,
            boxShadow: isWhite ? '0px 4px 8px 0px #00000040' : undefined,
            borderRadius: isWhite ? 8 : undefined,
            fontWeight: isWhite ? 400 : undefined,
            lineHeight: isWhite ? '21px' : undefined,
            padding: isWhite ? '8px' : undefined,
          },
        };
      },
    },
    Select: {
      styles: () => ({
        invalid: {
          borderColor: colors.red[0],
        },
      }),
    },
    SegmentedControl: {
      styles: (theme, params: SegmentedControlStylesParams) => ({
        root: {
          padding: params.size === 'xs' ? '2px' : undefined,
          backgroundColor:
            SEGMENTED_COLOR_MAPPING[params.color]?.default || undefined,
        },
        active: {
          backgroundColor:
            SEGMENTED_COLOR_MAPPING[params.color]?.active || undefined,
        },
        labelActive: {
          color: SEGMENTED_COLOR_MAPPING[params.color]?.default || undefined,
          '&:hover': {
            color: SEGMENTED_COLOR_MAPPING[params.color]?.default || undefined,
          },
        },
        label: {
          fontSize: params.size === 'xs' ? '14px' : undefined,
          color: '#C5CBD1',
          '&:hover': {
            color: '#C5CBD1',
          },
        },
      }),
    },
    Switch: {
      styles: (theme, params: SwitchStylesParams) => ({
        trackLabel: {
          fontSize: params.size === 'lg' ? '10px' : undefined,
          fontWeight: 500,
        },
        root: {
          color: colors.gray[2],
        },
        input: {
          '&:checked': {
            '& + label.mantine-Switch-track': {
              backgroundColor:
                SWITCH_COLOR_MAPPING[params.color]?.background || undefined,
              borderColor:
                SWITCH_COLOR_MAPPING[params.color]?.border || undefined,
            },
          },
          '& + label.mantine-Switch-track': {
            cursor: 'pointer',
          },
        },
      }),
    },
    Modal: {
      styles: () => ({
        title: {
          fontSize: '20px',
          fontWeight: 600,
          color: colors.black[2],
        },
        modal: {
          borderRadius: '8px',
        },
        body: {
          fontSize: 14,
          fontWeight: 400,
          color: colors.black[2],
        },
        close: {
          ' > svg': {
            color: colors.black[2],
            ' > path': {
              stroke: colors.black[2],
            },
          },
        },
      }),
    },
    Pagination: {
      styles: () => ({
        item: {
          width: '32px',
          height: '32px',
          padding: 0,
          fontWight: 500,
          color: '#0E121A',
          ' > svg > path': {
            fill: '#0E121A',
          },
          '&[data-active], &:hover': {
            color: colors.primary[0],
            background: 'white',
            borderColor: colors.primary[0],
          },
        },
      }),
    },
  },
};
