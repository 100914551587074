import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

export const filterNavs = (
  menus,
  permissionUrls,
  permissionExcludeUrls,
  isMobile?: boolean
) => {
  return menus.filter(
    (menu) =>
      (permissionUrls.length === 0 || permissionUrls.includes(menu.link)) &&
      !permissionExcludeUrls.includes(menu.link) &&
      !(menu.mobileHide && isMobile)
  );
};
export const sortMenus = (menus, sortedLinks, availableLinks) => {
  const sortedMenus = sortedLinks
    .filter((link) => findIndex(menus, { link }) >= 0)
    .map((link) => find(menus, { link }));
  const normalMenus = menus
    .filter((menu) => !sortedLinks.includes(menu.link))
    .map((menu) => ({
      ...menu,
      notAvailable:
        menu.notAvailable ||
        (!availableLinks.includes(menu.link) && availableLinks?.length > 0),
    }));
  return [...sortedMenus, ...normalMenus];
};
