import { FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import NewReportSection from 'src/pages/NewReport/components/Section';

const VehicleInformation = ({ isDisabled = false }) => {
  return (
    <NewReportSection title="Vehicle Information">
      <FormCardContent column={5}>
        <FormikInput
          name="license_plate_number"
          label="License Plate Number"
          placeholder="000000"
          inputType="mask"
          mask="999999"
          autoComplete="license_plate_number"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="year"
          label="Year"
          placeholder="Year"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="made"
          label="Made"
          placeholder="Made"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="model"
          label="Model"
          placeholder="Model"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
        <FormikInput
          name="color"
          label="Color"
          placeholder="Color"
          type="text"
          errorOverlay
          disabledOverlay={isDisabled}
        />
      </FormCardContent>
    </NewReportSection>
  );
};

export default VehicleInformation;
