import { createTypes } from './createTypes';

export default createTypes(
  'SYNC_UPDATE_USER',
  'GET_USERS',
  'GET_USER',
  'INVITE_USER',
  'VERIFY_INVITE_TOKEN',
  'ACCEPT_INVITE',
  'UPDATE_USER_ACTIVATE',
  'EDIT_USER',
  'EDIT_USER_STATUS',
  'DISMISS_POPUP'
);
