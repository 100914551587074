import isArray from 'lodash/isArray';
import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';

export const cleanFilter = (filters) => {
  const returnObj = {} as any;
  Object.keys(filters).map((key) => {
    if (isArray(filters[key])) {
      returnObj[key] = filters[key].filter((v) => v !== OPTION_ALL_VALUE);
    } else {
      returnObj[key] = filters[key];
    }
    return true;
  });
  return returnObj;
};
