import { Drawer } from '@mantine/core';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Table } from 'src/components';
import { Input } from 'src/components/FormFields';
import {
  MainContent,
  PageContent,
  PageContentHeader,
  PageHeader,
  TopHeader,
} from 'src/components/Page';
import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import { useMedia } from 'src/helpers/hooks';
import { actionCb } from 'src/utils/action';
import { confirmModalFn } from 'src/utils/modal';
import { notification } from 'src/utils/notification';
import { applySearch } from 'src/utils/search';
import { displayName } from 'src/utils/text';
import TopFilterBar from './TopFilterBar';
import classes from './UserManagement.module.scss';
import User from './components/User';
import { useActions, useIndexData } from './selectorData';
import { getColumns, getUserParams } from './utils';

const USER_DRAWER_ID = 'user-details-drawer';

let editObjTemp = {};

const UserManagement = () => {
  const isMobile = useMedia('(max-width: 480px)');
  const navigate = useNavigate();
  const {
    getUsers,
    inviteUser,
    updateUserActivate,
    syncUpdateUser,
    editUser,
    getUserProperty,
    assignUserProperty,
    getUser,
  } = useActions();
  const {
    usersData,
    ivniteUserLoading,
    editUserLoading,
    getAssignedPropertiesLoading,
    getUserLoading,
  } = useIndexData();
  const [setting, setSetting] = useState({
    search: '',
    role: OPTION_ALL_VALUE,
    organization: OPTION_ALL_VALUE,
    status: OPTION_ALL_VALUE,
  } as Record<string, string>);
  const [pagination, setPagination] = useState({
    page: 1,
  });
  const [selectedRows, setSelectedRows] = useState([]);
  const [opened, setOpened] = useState(false);
  const [editObj, setEditObj] = useState({});
  const reloadUsers = (tParams = {}) => {
    getUsers(getUserParams(tParams));
  };
  const handleSettingChange = (key, value, willNotFetch = false) => {
    const newSetting = {
      ...setting,
      [key]: value,
    };
    setSetting(newSetting);
    if (!willNotFetch) {
      setPagination({
        page: 1,
      });
      reloadUsers(newSetting);
    }
  };
  const closeUserDrawer = () => {
    setOpened(false);
    setEditObj({});
    editObjTemp = {};
  };
  const handleEdit = (row) => () => {
    editObjTemp = {};
    getUserProperty(
      row.id,
      actionCb((res) => {
        editObjTemp = {
          ...editObjTemp,
          properties: res.map((p) => p.id),
        };
        setEditObj(editObjTemp);
      })
    );
    getUser(
      row.id,
      actionCb((res) => {
        editObjTemp = {
          ...editObjTemp,
          ...res,
        };
        setEditObj(editObjTemp);
      })
    );
    setOpened(true);
  };
  const handleDeactivate = (row) => () => {
    confirmModalFn({
      children: (
        <>
          Are you sure you want to deactivate{' '}
          {displayName(row.first_name, row.last_name)}?
          <br />
          <br />
          Admins will still be able to retrieve user data, but the user will not
          be able to login or utilize their OneTree account
        </>
      ),
      onConfirm: () => {
        syncUpdateUser(row.id, { deactivated: 1 });
        updateUserActivate(row.id, 'deactivate');
      },
    });
  };
  const handleActivate = (row) => () => {
    confirmModalFn({
      children: (
        <>
          Are you sure you want to reactivate{' '}
          {displayName(row.first_name, row.last_name)}?
          <br />
          <br />
          This will allow the user to login and and utilize their OneTreee
          account
        </>
      ),
      onConfirm: () => {
        syncUpdateUser(row.id, { deactivated: 0 });
        updateUserActivate(row.id, 'reactivate');
      },
    });
  };
  const handleAssignProperties = (pUserId, pProperties) => {
    if (!!pProperties && isArray(pProperties)) {
      assignUserProperty(
        pUserId,
        {
          properties: pProperties.filter((id) => id !== OPTION_ALL_VALUE),
        },
        actionCb(null, null, 'Assign Properties faled!')
      );
    }
  };

  useEffect(() => {
    reloadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (isMobile) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile]);

  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'User Management',
          },
        ]}
      />
      <MainContent className={classes.mainContent}>
        <PageHeader title="User Management" className={classes.pageHeader}>
          <Input
            radius="md"
            value={setting.search}
            onChange={(e) => {
              const val = e.target.value;
              handleSettingChange('search', val, true);
              applySearch(val, (tVal) => {
                setPagination({ page: 1 });
                reloadUsers({
                  ...setting,
                  search: tVal,
                });
              });
            }}
            placeholder="Search"
            isSearch
          />
        </PageHeader>
        <PageContent className={classes.pageContent} paddingTop="sm" vertical>
          <PageContentHeader>
            <TopFilterBar
              setting={setting}
              handleSettingChange={handleSettingChange}
              setOpened={setOpened}
            />
          </PageContentHeader>
          <Box vertical>
            <Table
              columns={getColumns({
                onEdit: handleEdit,
                onActivate: handleActivate,
                onDeactivate: handleDeactivate,
              })}
              align="left"
              data={usersData.data || []}
              selection={{
                rows: selectedRows,
                onChange: (rows) => {
                  setSelectedRows(rows);
                },
              }}
              pagination={{
                totalPage: usersData.last_page,
                onChange: (val) => {
                  if (val !== pagination.page) {
                    setPagination({
                      page: val,
                    });
                    reloadUsers({ ...setting, page: val });
                  }
                },
                ...pagination,
              }}
            />
          </Box>
        </PageContent>
      </MainContent>
      <Drawer
        opened={opened}
        size={662}
        onClose={() => {
          closeUserDrawer();
        }}
        position="right"
        withCloseButton={false}
        className={classes.userDrawer}
        overlayOpacity={0.3}
        id={USER_DRAWER_ID}
      >
        <User
          title={!isEmpty(editObj) ? 'User Details' : 'Invite User'}
          onCancel={() => {
            closeUserDrawer();
          }}
          onSubmit={(values, tUserId) => {
            const { properties, ...otherValues } = values;
            if (tUserId) {
              editUser(
                tUserId,
                otherValues,
                actionCb(
                  () => {
                    handleAssignProperties(tUserId, properties);
                    notification({
                      title: 'Edit user successfull!',
                    });
                    reloadUsers(setting);
                    closeUserDrawer();
                  },
                  null,
                  'Error while edit.'
                )
              );
            } else {
              inviteUser(
                otherValues,
                actionCb(
                  (res) => {
                    handleAssignProperties(res.id, properties);
                    notification({
                      title: 'Invite user successfull!',
                    });
                    reloadUsers(setting);
                    closeUserDrawer();
                  },
                  null,
                  'Error while invite.'
                )
              );
            }
          }}
          loading={ivniteUserLoading}
          editLoading={editUserLoading}
          editObj={editObj}
          dataLoading={getAssignedPropertiesLoading || getUserLoading}
          drawerId={USER_DRAWER_ID}
        />
      </Drawer>
    </>
  );
};

export default UserManagement;
