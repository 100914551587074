import { LoadingOverlay } from 'src/components';
import classes from './ResidentDetail.module.scss';
import { useLoadingData } from './selectorData';

const Loading = () => {
  const { certDetailLoading, certNoteLoading } = useLoadingData();
  return (
    <LoadingOverlay
      visible={certDetailLoading || certNoteLoading}
      className={classes.loading}
    />
  );
};

export default Loading;
