import { Arrow, Badge } from 'src/components';
import {
  OPTION_ALL_VALUE,
  REPORT_STATUS_COLOR,
} from 'src/helpers/constants/options';
import {
  renderAlertsColumn,
  renderDateColumn,
  renderStandardColumn,
} from 'src/utils/table';
import { displayName } from 'src/utils/text';

export const getColumns = (isWorkflow) => {
  return [
    {
      key: 'alerts_count',
      render: renderAlertsColumn,
      title: 'Alerts',
    },
    {
      key: 'unit',
      render: (val) => renderStandardColumn(val?.unit_id || 'N/A'),
      title: 'Unit',
    },
    {
      key: 'property',
      render: (val) => {
        return renderStandardColumn(val?.name);
      },
      title: 'Property',
    },
    {
      key: 'residents',
      render: (val) => {
        return renderStandardColumn(
          displayName(val?.[0]?.first_name, val?.[0]?.last_name)
        );
      },
      title: 'Name',
    },
    ...(isWorkflow
      ? [
          {
            key: 'workflow_type',
            render: (val) => {
              return renderStandardColumn(val);
            },
            title: 'Type',
          },
        ]
      : [
          {
            key: 'types',
            render: (val) => {
              return renderStandardColumn(
                (val || [])
                  .map((v) => v.code)
                  .filter((v) => !!v)
                  .join(', ')
              );
            },
            title: 'Type',
          },
        ]),
    {
      key: 'status',
      render: (val) =>
        val ? <Badge color={REPORT_STATUS_COLOR[val]}>{val}</Badge> : '-',
      title: 'Status',
    },
    {
      key: 'due_date',
      render: renderDateColumn,
      title: 'Due Date',
    },
    {
      key: 'action',
      render: () => {},
      renderAction: () => {
        return <Arrow direction="right" />;
      },
    },
  ];
};
export const getCertificationFilters = (
  settings,
  search,
  page = 1,
  isWorkflow = false
) => {
  const returnObj: any = {
    statuses: settings.status,
    properties: settings.property.filter((val) => val !== OPTION_ALL_VALUE),
    limit: 30,
    page,
    isMore: page > 1,
    search,
  };
  if (!isWorkflow) {
    returnObj.types = settings.type;
  }
  return returnObj;
};
