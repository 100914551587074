import { CollapseProps, Collapse as ManCollapse } from '@mantine/core';
import cn from 'classnames';
import classes from './Collapse.module.scss';

type Props = CollapseProps & {
  className?: string;
};

const Collapse = ({ className = '', ...other }: Props) => {
  return <ManCollapse {...other} className={cn(classes.wrapper, className)} />;
};

export default Collapse;
