import { Dispatch } from 'redux';
import {
  apiAddCertificateNote,
  apiAddCertification,
  apiBuildCertificate,
  apiDeleteCertificate,
  apiDeleteCertificateFile,
  apiDeleteCertificateFullFile,
  apiDeleteCertificateSection,
  apiGetCertificateDetail,
  apiGetCertificateFields,
  apiGetCertificateList,
  apiGetCertificateNote,
  apiGetCertificationPending,
  apiGetCertifications,
  apiMarkCertificateCompleted,
  apiMarkCertificateSectionCompleted,
  apiSaveCertificateFields,
  apiUpdateCertification,
  apiUploadCertificateFullFile,
} from 'src/api';
import Types from '../types/certification';

export const syncSetCertificationFields = (data) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_SET_CERTIFICATION_FIELDS,
    payload: data,
  });
};
export const getCertificateList =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore },
      type: Types.GET_CERTIFICATE_LIST,
      asyncCall: () => {
        return apiGetCertificateList(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getCertificateDetail = (id, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: { certId: id },
    type: Types.GET_CERTIFICATE_DETAIL,
    asyncCall: () => {
      return apiGetCertificateDetail(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getCertifications =
  (params = {} as any, cb?: any) =>
  (dispatch: Dispatch) => {
    const { isMore, ...other } = params;
    dispatch({
      isAsyncCall: true,
      payload: { isMore, certificate_schema_id: params.certificate_schema_id },
      type: Types.GET_CERTIFICATIONS,
      asyncCall: () => {
        return apiGetCertifications(other);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getCertificationFields =
  (id, year: string, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_CERTIFICATION_FIELDS,
      asyncCall: () => {
        return apiGetCertificateFields(id, year, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const getCertificationPending = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_CERTIFICATION_PENDING,
    asyncCall: () => {
      return apiGetCertificationPending();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const getCertificateNote =
  (id: number, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.GET_CERTIFICATE_NOTE,
      asyncCall: () => {
        return apiGetCertificateNote(id, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const addCertification = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.ADD_CERTIFICATION,
    asyncCall: () => {
      return apiAddCertification(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const updateCertification =
  (id, data, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPDATE_CERTIFICATION,
      asyncCall: () => {
        return apiUpdateCertification(id, data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const buildCertificate =
  (data, cb?: any, key?: string) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.BUILD_CERTIFICATE,
      asyncCall: () => {
        return apiBuildCertificate(data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const deleteCertificate = (id, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.DELETE_CERTIFICATE,
    asyncCall: () => {
      return apiDeleteCertificate(id);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const deleteCertificateFile =
  (id, fileId, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.DELETE_CERTIFICATE_FILE,
      asyncCall: () => {
        return apiDeleteCertificateFile(id, fileId, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const saveCertificateFields =
  (id, data, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.SAVE_CERTIFICATE_FIELDS,
      asyncCall: () => {
        return apiSaveCertificateFields(id, data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const addCertificateNote =
  (data, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.ADD_CERTIFICATE_NOTE,
      asyncCall: () => {
        return apiAddCertificateNote(data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const uploadCertificateFullFile =
  (id, data, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPLOAD_CERTIFICATE_FULL_FILE,
      asyncCall: () => {
        return apiUploadCertificateFullFile(id, data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const deleteCertificateFullFile =
  (id, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.UPLOAD_CERTIFICATE_FULL_FILE,
      asyncCall: () => {
        return apiDeleteCertificateFullFile(id, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const deleteCertificateSection =
  (id, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.DELETE_CERTIFICATE_SECTION,
      asyncCall: () => {
        return apiDeleteCertificateSection(id, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const markCertificateSectionCompleted =
  (id, data: any, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.MARK_CERTIFICCATE_SECTION_COMPLETED,
      asyncCall: () => {
        return apiMarkCertificateSectionCompleted(id, data, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
export const markCertificateCompleted =
  (id, key?: string, cb?: any) => (dispatch: Dispatch) => {
    dispatch({
      isAsyncCall: true,
      payload: {},
      type: Types.MARK_CERTIFICCATE_COMPLETED,
      asyncCall: () => {
        return apiMarkCertificateCompleted(id, key);
      },
      onSuccess: (_dispatch: any, response: any) => {
        if (cb) cb(response);
      },
      onFailure: (_dispatch: any, error: any) => {
        if (cb) cb(false, error);
      },
    });
  };
