import { AvatarProps, Avatar as ManAvatar } from '@mantine/core';
import cn from 'classnames';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import React from 'react';
import { SizeType } from 'src/types';
import classes from './Avatar.module.scss';

type Props = AvatarProps & {
  className?: string;
  editable?: boolean;
  onChange?: (val) => void;
  avtSrc?: string;
  circle?: boolean;
  isStatus?: boolean;
  isInactive?: boolean;
  statusText?: string;
  weight?: SizeType;
  fontSize?: SizeType;
  statusHasBorder?: boolean;
  notBorder?: boolean;
};

const Avatar = ({
  className = '',
  editable,
  children,
  onChange,
  avtSrc,
  circle,
  isStatus,
  isInactive,
  statusText,
  weight,
  fontSize,
  statusHasBorder,
  notBorder,
  ...other
}: Props) => {
  return (
    <ManAvatar
      {...other}
      className={cn(
        classes.wrapper,
        {
          [classes.editable]: editable,
          [classes.circle]: circle,
          [classes.isStatus]: isStatus,
          [classes.statusHasBorder]: statusHasBorder,
          [classes.isInactive]: isInactive,
          [classes.hasSrc]: !!avtSrc,
          [classes.notBorder]: notBorder,
        },
        classes[`status${statusText}`],
        classes[`weight${weight}`],
        classes[`fontSize${fontSize}`],
        className
      )}
    >
      {avtSrc ? (
        <ManAvatar src={avtSrc} className={classes.avtImg} />
      ) : (
        children
      )}
      {!!editable && (
        <div className={classes.editOverlay}>
          <label htmlFor="avatarUpload">Edit</label>
          <input
            type="file"
            id="avatarUpload"
            accept="image/png, image/gif, image/jpeg"
            style={{ display: 'none' }}
            onChange={(e) => {
              const thisFile = get(e, 'target.files[0]');
              if (thisFile && !!onChange) {
                onChange(thisFile);
              }
            }}
          />
        </div>
      )}
    </ManAvatar>
  );
};

const AvatarGroup = ({ className = '', items, ...other }) => {
  const reverseItems = cloneDeep(items).reverse();
  return (
    <ManAvatar.Group
      {...other}
      className={cn(
        classes.avatarGroup,
        classes[`spacing_${other.spacing}`],
        className
      )}
    >
      {reverseItems.map((item, i) => {
        return (
          <Avatar size={38} circle key={i} notBorder avtSrc={item.avtSrc}>
            {item.name}
          </Avatar>
        );
      })}
    </ManAvatar.Group>
  );
};

export { AvatarGroup };
export default Avatar;
