import { Reducer } from 'redux';

import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/resident';

const initialState = {
  residents: {
    ...defaultObj,
  },
};

const ResidentReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_RESIDENTS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          residents: {
            ...prevState.residents,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          residents: {
            loading: false,
            data: {
              ...payload,
              data: meta?.payload?.isMore
                ? [...(state.residents.data?.data || []), ...payload.data]
                : [...payload.data],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          residents: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    default:
      return state;
  }
};

export default ResidentReducer;
