import { OPTION_ALL_VALUE } from 'src/helpers/constants/options';
import {
  renderGrantedUsersColumn,
  renderStandardColumn,
} from 'src/utils/table';

export const getColumns = () => {
  return [
    {
      key: 'name',
      render: renderStandardColumn,
      title: 'Name',
    },
    {
      key: 'location',
      render: (_, obj) => [obj.city, obj.state].join(', '),
      title: 'Location',
    },
    {
      key: 'program_type',
      render: (val) => {
        return renderStandardColumn(val?.name);
      },
      title: 'Program Type',
    },
    {
      key: 'property_manager',
      render: renderGrantedUsersColumn('Property Manager'),
      title: 'Property Manager',
    },
    {
      key: 'rpm',
      render: renderGrantedUsersColumn('Regional Manager'),
      title: 'RPM',
    },
    {
      key: 'compliance_specialist',
      render: renderGrantedUsersColumn('Compliance Specialist'),
      title: 'Compliance Specialist',
    },
    // {
    //   key: 'action3',
    //   getItems: () => {
    //     return [];
    //   },
    //   render: () => {},
    // },
  ];
};
export const getFilters = (settings, search, page = 1) => {
  return {
    locations: settings.locations.filter((val) => val !== OPTION_ALL_VALUE),
    properties: settings.properties.filter((val) => val !== OPTION_ALL_VALUE),
    limit: 30,
    page,
    isMore: page > 1,
    search,
  };
};
