import cn from 'classnames';
import { Avatar, Card } from 'src/components';
import { getImageDataURI } from 'src/utils/file';
import { displayName, intialName } from 'src/utils/text';
import classes from './BasicInfo.module.scss';

interface Props {
  className?: string;
  firstName: string;
  lastName: string;
  email: string;
  title: string;
  onAvatarChange: (file, src) => void;
  avatarSrc: string;
}

const BasicInfo = ({
  className,
  firstName,
  lastName,
  email,
  title,
  onAvatarChange,
  avatarSrc,
}: Props) => {
  return (
    <Card title="Basic Info" className={cn(classes.wrapper, className)}>
      <Avatar
        size="xl"
        editable
        onChange={(tFile) => {
          getImageDataURI(tFile, (imageUrl) => {
            onAvatarChange(tFile, imageUrl);
          });
        }}
        avtSrc={avatarSrc}
      >
        {intialName(firstName, lastName)}
      </Avatar>
      <div className={classes.info}>
        <span>
          <span>Name</span>
          <span>{displayName(firstName, lastName)}</span>
        </span>
        <span>
          <span>Email</span>
          <span>{email}</span>
        </span>
        <span>
          <span>Title</span>
          <span>{title}</span>
        </span>
      </div>
    </Card>
  );
};

export default BasicInfo;
