import { FormikTextarea } from 'src/components/FormFields';
import NewReportSection from '../Section';
import classes from './AdditionalInformation.module.scss';

const AdditionalInformation = ({
  isDisabled = false,
  // onFileRemoved = null,
  values,
  onValuesChange,
}) => {
  return (
    <NewReportSection
      title="Additional Information"
      className={classes.wrapper}
      onNoValue={() => {
        return !values.additional_information;
      }}
    >
      <FormikTextarea
        name="additional_information"
        placeholder="Additional Information"
        maxLength={300}
        disabledOverlay={isDisabled}
        onChangeCb={onValuesChange}
      ></FormikTextarea>
      {/* <FormikUpload
        name="additional_upload"
        multiple={true}
        onFileRemoved={onFileRemoved}
        printBtnText="Upload files:"
        className={cn({
          [classes.uploadFiles]: !!values.additional_information,
        })}
      /> */}
    </NewReportSection>
  );
};

export default AdditionalInformation;
