import { MainContent, TopHeader } from 'src/components/Page';

const ResourceLinks = () => {
  return (
    <>
      <TopHeader
        breadcrumb={[
          {
            label: 'Resource Links',
          },
        ]}
      />
      <MainContent>
        <p>Resource Links page</p>
      </MainContent>
    </>
  );
};

export default ResourceLinks;
