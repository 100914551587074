import { Dispatch } from 'redux';
import { apiGetMe, apiLogin, apiUpdateProfile } from 'src/api';
import { LoginPayload } from 'src/api/auth';

import Types from '../types/auth';

export const getMe = (cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.GET_ME,
    asyncCall: () => {
      return apiGetMe();
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const login = (data: LoginPayload, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.LOGIN,
    asyncCall: () => {
      return apiLogin(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};
export const updateProfile = (data, cb?: any) => (dispatch: Dispatch) => {
  dispatch({
    isAsyncCall: true,
    payload: {},
    type: Types.UPDATE_PROFILE,
    asyncCall: () => {
      return apiUpdateProfile(data);
    },
    onSuccess: (_dispatch: any, response: any) => {
      if (cb) cb(response);
    },
    onFailure: (_dispatch: any, error: any) => {
      if (cb) cb(false, error);
    },
  });
};

export const syncSetToken = (token: string) => (dispatch: Dispatch) => {
  dispatch({
    type: Types.SYNC_SET_TOKEN,
    payload: token,
  });
};
export const syncUpdateMeData =
  (data: any, apart?: boolean) => (dispatch: Dispatch) => {
    dispatch({
      type: Types.SYNC_SET_ME_DATA,
      payload: { data, apart },
    });
  };
export const logOut = () => (dispatch: Dispatch) => {
  dispatch({
    type: Types.LOG_OUT,
  });
};
