import { Reducer } from 'redux';

import { defaultObj } from '../constants';
import { handleData } from '../middlewares/handleData';
import Types from '../types/workflow';

const initialState = {
  workflowPending: {
    ...defaultObj,
  },
  workflowBuilderList: {
    ...defaultObj,
  },
  workflowBuilderDetail: {
    ...defaultObj,
    previousWorkflowId: undefined,
  },
  deleteWorkflowBuilder: {
    ...defaultObj,
  },
  workflows: {
    ...defaultObj,
    previousWorkflowId: undefined,
  },
};

const WorkflowReducer: Reducer = (state = initialState, action) => {
  const { type, payload, meta } = action;

  switch (type) {
    case Types.GET_WORKFLOW_PENDING:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflowPending: {
            ...prevState.workflowPending,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflowPending: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflowPending: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_WORKFLOW_BUILDER_LIST:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflowBuilderList: {
            ...prevState.workflowBuilderList,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflowBuilderList: {
            loading: false,
            data: {
              ...payload,
              data: meta?.payload?.isMore
                ? [
                    ...(state.workflowBuilderList.data?.data || []),
                    ...payload.data,
                  ]
                : [...payload.data],
            },
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflowBuilderList: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_WORKFLOW_BUILDER_DETAIL:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflowBuilderDetail: {
            ...prevState.workflowBuilderDetail,
            loading: true,
            data:
              meta?.payload?.workflowId !==
              prevState.workflowBuilderDetail.previousWorkflowId
                ? {}
                : { ...prevState.workflowBuilderDetail.data },
            previousWorkflowId: meta?.payload?.workflowId,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflowBuilderDetail: {
            loading: false,
            data: payload,
            error: '',
            previousWorkflowId: meta?.payload?.workflowId,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflowBuilderDetail: {
            loading: false,
            data: {},
            error: payload,
            previousWorkflowId: meta?.payload?.workflowId,
          },
        }),
      });
    case Types.DELETE_WORKFLOW_BUILDER:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          deleteWorkflowBuilder: {
            ...prevState.deleteWorkflowBuilder,
            loading: true,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          deleteWorkflowBuilder: {
            loading: false,
            data: payload,
            error: '',
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          deleteWorkflowBuilder: {
            loading: false,
            data: {},
            error: payload,
          },
        }),
      });
    case Types.GET_WORKFLOWS:
      return handleData(state, action, {
        request: (prevState) => ({
          ...prevState,
          workflows: {
            ...prevState.workflows,
            loading: true,
            data:
              !meta?.payload?.isMore &&
              meta?.payload?.schema_id !==
                prevState.workflows.previousWorkflowId
                ? {}
                : { ...prevState.workflows.data },
            previousWorkflowId: meta?.payload?.schema_id,
          },
        }),
        success: (prevState) => ({
          ...prevState,
          workflows: {
            loading: false,
            data: {
              ...payload,
              data: meta?.payload?.isMore
                ? [...(state.workflows.data?.data || []), ...payload.data]
                : [...payload.data],
            },
            error: '',
            previousWorkflowId: meta?.payload?.schema_id,
          },
        }),
        failure: (prevState) => ({
          ...prevState,
          workflows: {
            loading: false,
            data: {},
            error: payload,
            previousWorkflowId: meta?.payload?.schema_id,
          },
        }),
      });
    default:
      return state;
  }
};

export default WorkflowReducer;
