import cn from 'classnames';
import { CardTitle, FormCardContent } from 'src/components';
import { FormikInput } from 'src/components/FormFields';
import { getIsEmptyEntities } from 'src/utils/object';
import NewReportSection from '../Section';
import { SectionFormArrayContent } from '../SectionFormArray';

const TreatmentApplicable = ({
  values,
  isDisabled = false,
  onValuesChange,
}) => {
  const commonProps = {
    values,
    gridColumn: 3,
    newObj: { name: '', address: '', phone_number: '' },
    addHidden: isDisabled,
    onAddCb: onValuesChange,
    onRemoveCb: onValuesChange,
  };
  const physicianProps = {
    ...commonProps,
    parentName: 'physician',
    renderChild: (index) => {
      return (
        <>
          <FormikInput
            name={`physician.${index}.name`}
            label="Physician Name"
            placeholder="Name"
            type="text"
            autoComplete="physician-name"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name={`physician.${index}.address`}
            label="Physician Address"
            placeholder="Address"
            type="text"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name={`physician.${index}.phone_number`}
            label="Physician Phone Number"
            placeholder="000-000-0000"
            inputType="mask"
            mask="999-999-9999"
            autoComplete="physician-phone-number"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
        </>
      );
    },
    newBtnText: '+ Add a Physician',
  };
  const hospitalProps = {
    ...commonProps,
    parentName: 'hospital',
    renderChild: (index) => {
      return (
        <>
          <FormikInput
            name={`hospital.${index}.name`}
            label="Hospital Name"
            placeholder="Name"
            type="text"
            autoComplete="hospital-name"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name={`hospital.${index}.address`}
            label="Hospital Address"
            placeholder="Address"
            type="text"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
          <FormikInput
            name={`hospital.${index}.phone_number`}
            label="Hospital Phone Number"
            placeholder="000-000-0000"
            inputType="mask"
            mask="999-999-9999"
            autoComplete="hospital-phone-number"
            errorOverlay
            disabledOverlay={isDisabled}
            onChangeCb={onValuesChange}
          />
        </>
      );
    },
    newBtnText: '+ Add a Hospital',
  };
  return (
    <NewReportSection
      title="Treatment (If Applicable)"
      onTitleNoValue={() => {
        return !values.date_treated;
      }}
      onNoValue={() => {
        return (
          !values.date_treated &&
          getIsEmptyEntities(values.physician) &&
          getIsEmptyEntities(values.hospital)
        );
      }}
    >
      <FormCardContent column={3}>
        <FormikInput
          name="date_treated"
          label="Date Treated"
          placeholder="00/00/0000"
          inputType="mask"
          mask="99/99/9999"
          autoComplete="date"
          errorOverlay
          disabledOverlay={isDisabled}
          onChangeCb={onValuesChange}
        />
      </FormCardContent>

      <CardTitle
        title="Physician Who Provided Treatment (If Applicable)"
        className={cn({
          'i-print-no-value': getIsEmptyEntities(values.physician),
        })}
      />
      <SectionFormArrayContent
        {...physicianProps}
        isNoValue={getIsEmptyEntities(values.physician)}
      />
      <CardTitle
        title="Hospital Information (If Applicable)"
        className={cn({
          'i-print-no-value': getIsEmptyEntities(values.hospital),
        })}
      />
      <SectionFormArrayContent
        {...hospitalProps}
        isNoValue={getIsEmptyEntities(values.hospital)}
      />
    </NewReportSection>
  );
};

export default TreatmentApplicable;
