import cn from 'classnames';
import { useState } from 'react';
import { ReactComponent as PlusSvg } from 'src/assets/icons/Plus.svg';
import { Button } from 'src/components';
import {
  FormikAdditionalChecks,
  FormikButtonCheck,
  FormikDropdown,
  FormikInput,
  FormikTextarea,
  FormikUpload,
} from 'src/components/FormFields';
import { ADDITIONAL_CHECKS_TYPES } from 'src/helpers/constants';
import { DEFAULT_ALL_OPTION } from 'src/helpers/constants/options';
import { useOptions } from 'src/helpers/hooks';
import {
  CertificationSectionStatus,
  CertificationSectionType,
} from 'src/types/certification';
import { confirmModalFn } from 'src/utils/modal';
import { filterOptions } from 'src/utils/options';
import { FormLine } from './Elements';
import classes from './Section.module.scss';

interface Props {
  className?: string;
  toggle: () => void;
  parentName: string;
  isSectionValid: boolean;
  onAddSection: () => void;
  type: CertificationSectionType;
  status: CertificationSectionStatus;
  isSub?: boolean;
  parentSectionTitle?: string;
  existingId?: number;
  onOpenDI?: (name?: string, value?: string) => void;
  onOpenDropdown?: (name?: string, value?: any) => void;
  selectedStatuses?: number[];
  onSectionDeleted: (isAdded?: boolean, cb?: any) => void;
}

const SectionForm = ({
  className,
  toggle,
  parentName,
  isSectionValid,
  onAddSection,
  type,
  status,
  isSub,
  parentSectionTitle,
  existingId,
  onOpenDI,
  onOpenDropdown,
  selectedStatuses,
  onSectionDeleted,
}: Props) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const tSelectedStatus = selectedStatuses || [];
  const {
    roleDropdownOptionsWithGroup,
    certificationNoticeOptions,
    certificationStatusOptions,
  } = useOptions();
  const isAdded = status === 'added' || !!existingId;
  const isSection = type === 'section';
  return (
    <div className={cn(classes.sectionForm, className)}>
      {isSub && <h4>Subsection to {parentSectionTitle || ''}</h4>}
      <FormLine item={isSection && 3}>
        <FormikInput
          name={`${parentName}.section_name`}
          label={`${
            isSection ? (isSub ? 'Subsection' : 'Section') : 'Notice'
          } Name`}
          placeholder={`Name ${isSection ? 'this' : 'Notices'} Section`}
          type="text"
          errorOverlay
          required
        />
        {isSection && (
          <FormikUpload
            name={`${parentName}.associated_file`}
            valueButtonHidden
            label="Associated File (If Applicable)"
            size="md"
            className={classes.associatedFile}
            buttonProps={{
              fullWidth: true,
            }}
          />
        )}
        <FormikDropdown
          items={[
            DEFAULT_ALL_OPTION,
            ...(isSection
              ? filterOptions(roleDropdownOptionsWithGroup, 'label', ['Guest'])
              : certificationNoticeOptions),
          ]}
          placeholder={'Select'}
          label={isSection ? 'Assigned To' : 'Notice Options'}
          name={`${parentName}.section_options`}
          displaySelectedAll
          placeholderGray
          errorOverlay
          radius="sm"
          required
        />
      </FormLine>
      <FormLine>
        {isSection && (
          <FormikDropdown
            isSingleSelected
            items={[
              // DEFAULT_ALL_OPTION,
              ...certificationStatusOptions.filter((tStatus) =>
                tSelectedStatus.includes(tStatus.value)
              ),
            ]}
            placeholder={'Select'}
            label="Assign Status"
            name={`${parentName}.assign_status`}
            displaySelectedAll
            placeholderGray
            errorOverlay
            radius="sm"
            className={classes.assignStatusField}
          />
        )}
        <FormikButtonCheck
          label="Additional Options (If Applicable)"
          buttons={[
            {
              name: `${parentName}.check_upload_file`,
              label: (
                <>
                  Upload File <PlusSvg />
                </>
              ),
            },
            {
              name: `${parentName}.check_schedule`,
              label: (
                <>
                  {isSection ? 'Date Input' : 'Scheduled Interview Date Input'}{' '}
                  <PlusSvg />
                </>
              ),
              onClick: isSection ? onOpenDI : undefined,
              disableCheck: isSection,
            },
            ...(isSection
              ? [
                  {
                    name: `${parentName}.check_dropdown`,
                    label: (
                      <>
                        Dropdown <PlusSvg />
                      </>
                    ),
                    onClick: onOpenDropdown,
                    disableCheck: true,
                  },
                ]
              : []),
          ]}
        />
      </FormLine>
      {isSection && (
        <FormikAdditionalChecks
          fields={[
            {
              name: `${parentName}.check_schedule`,
              idName: `${parentName}.check_schedule_id`,
              deleteName: `${parentName}.check_schedule_delete`,
              isStringArray: true,
              type: ADDITIONAL_CHECKS_TYPES.DATE_INPUT,
            },
            {
              name: `${parentName}.check_dropdown`,
              displayName: `${parentName}.check_dropdown.value`,
              type: ADDITIONAL_CHECKS_TYPES.DROPDOWN,
            },
          ]}
        />
      )}
      <FormLine>
        <FormikTextarea
          name={`${parentName}.notice_information`}
          placeholder={`${isSection ? '' : 'Notice '}${
            isSub ? 'Subsection' : 'Section'
          } Information (This will show up in the ${
            isSub ? 'subsections' : 'sections'
          } Tool Tip)`}
          type="text"
        />
      </FormLine>
      <div className={classes.footer}>
        <Button
          variant={'default'}
          onClick={() => {
            if (!isAdded) {
              onSectionDeleted(false);
            } else {
              confirmModalFn({
                children: <>Are you sure you want to delete?</>,
                onCancel: () => {},
                onConfirm: () => {
                  if (isAdded && !!existingId) {
                    setDeleteLoading(true);
                  }
                  onSectionDeleted(true, () => {
                    if (isAdded && !!existingId) {
                      setDeleteLoading(false);
                    }
                  });
                },
                labels: {
                  confirm: 'Delete',
                  cancel: 'Cancel',
                },
                color: 'red',
                centered: true,
              });
            }
          }}
          radius="md"
          textSize="md"
          textWeight="md"
          size="xs"
          color="red"
          className={classes.deleteBtn}
          loading={deleteLoading}
        >
          Delete
        </Button>
        <Button
          variant={'default'}
          onClick={() => {
            toggle();
          }}
          radius="md"
          textSize="md"
          textWeight="md"
          size="xs"
        >
          Cancel
        </Button>
        <Button
          radius="md"
          size="xs"
          textSize="md"
          textWeight="md"
          onClick={onAddSection}
          disabled={!isSectionValid}
        >
          {isAdded
            ? 'Save changes'
            : `Add ${isSub && isSection ? 'Subsection' : type}`}
        </Button>
      </div>
    </div>
  );
};

export default SectionForm;
